import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  HiOutlineCube,
  HiOutlineDatabase,
  HiOutlineChevronDown,
} from "react-icons/hi";
import { CiSearch } from "react-icons/ci";
import {
  MdOutlineRadioButtonChecked,
  MdOutlineRadioButtonUnchecked,
} from "react-icons/md";
import { BsCheckSquareFill, BsSquare } from "react-icons/bs";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import AccountDofusProduct from "./AccountDofusProduct";
import { testProduct } from "./utils/product";
import { Ri24HoursFill, RiRefreshLine } from "react-icons/ri";
import { IoFlashOutline, IoShieldCheckmarkOutline } from "react-icons/io5";
import { AiOutlineLike } from "react-icons/ai";

const Accountdofus = () => {
  const { pageNumber } = useParams();
  const navigate = useNavigate();
  const [toggleFilterProduct, setToogleFilterProduct] = useState(true);
  const [toggleWarrantyProduct, setToogleWarrantyProduct] = useState(true);
  const [checkOver, setCheckOver] = useState("");
  const [checkClicked, setCheckClicked] = useState("");
  const [checkOverWarranty, setCheckOverWarranty] = useState("");
  const [checkClickedWarranty, setCheckClickedWarranty] = useState("");
  const [products, setProducts] = useState(testProduct);
  const [searchTerm, setSearchTerm] = useState("");
  const [activePagination, setActivePagination] = useState(1);
  const [singleProductFilter, setSingleProductFilter] = useState([
    "Cra",
    "Ecaflip",
    "Sram",
    "Iop",
    "Osamodas",
    "Féca",
    "Sadida",
    "Eniripsa",
    "Roublard",
    "Eliotrope",
  ]);

  const [singleWarrantyFilter, setSingleWarrantyFilter] = useState([
    "7 Days",
    "14 Days",
    "20 Days",
    "30 Days",
    "3 Months",
    "6 Months",
    "1 Year",
  ]);

  console.log("Nombre de page: " + pageNumber);

  const [paginationBottom, setPaginationBottom] = useState([1, 2, 3, 4, 5]);

  const handleToggleProductFilter = () => {
    setToogleFilterProduct((prevState) => !prevState);
  };

  const handleToggleWarrantyFilter = () => {
    setToogleWarrantyProduct((prevState) => !prevState);
  };

  const handleChangePagination = (pagination) => {
    setActivePagination(pagination);
    let newPageNumber = Number(pagination);
    navigate(`/account-dofus&page=${newPageNumber > 0 && newPageNumber}`);
  };

  const handlePaginateLeft = () => {
    if (activePagination > 1) {
      setActivePagination((prevActivePagination) => prevActivePagination - 1);
    }
  };

  const handlePaginateRight = () => {
    if (activePagination < 5) {
      setActivePagination((prevActivePagination) => prevActivePagination + 1);
    }
  };

  // const changePageNumber = (newPageNumber) => {
  //   // Utilisez navigate pour naviguer vers la nouvelle URL avec le nouveau numéro de page
  //   navigate(`/account-dofus&page=${newPageNumber > 0 && newPageNumber}`);
  // };

  useEffect(() => {
    let newPageNumber = Number(activePagination);
    if (!isNaN(newPageNumber) && newPageNumber > 0) {
      navigate(`/account-dofus&page=${newPageNumber}`);
    }
  }, [activePagination]);

  /////////////////// FOR PRODUCT FILTER SECTION //////////////////////

  const handleDisplayCheckBox = (singleProduct) => {
    setCheckOver(singleProduct);
  };

  const handleRemoveOver = () => {
    setCheckOver("");
  };

  const handleDisplayCheckBoxClicked = (item) => {
    setCheckClicked(item);
  };

  /////////////// FOR WARRANTY SECTION ///////////////////////////////

  const handleDisplayOverWarranty = (singleProduct) => {
    setCheckOverWarranty(singleProduct);
  };

  const handleRemoveWarranty = () => {
    setCheckOverWarranty("");
  };

  const handleDisplayCheckBoxWarranty = (item) => {
    setCheckClickedWarranty(item);
  };

  return (
    <div className="flex items-center justify-center mt-[100px] mx-16 select-none">
      <div className="flex items-start flex-col gap-8">
        <div className="flex flex-col gap-2 items-start">
          <h2 className="text-2xl font-bold">
            Dofus Kamas - Achat Retro kamas - Kamas Dofus - iBendouma
          </h2>
          <p className="text-base font-normal">
            Ibendouma vous offre plusieurs avantages pour améliorer rapidement
            votre expérience de jeu. Tout d'abord, vous pouvez acheter des Kamas
            et comptes Dofus, Dofus Retro et ofus Touch à des prix abordables.
            De plus, la livraison est rapide et le stock est disponible. Vous
            bénéficiez également d'une sécurité à 100% pour vos transactions.
            Enfin, le service en ligne est disponible 24h/7j pour répondre à vos
            besoins.
          </p>
        </div>
        {/*  */}
        {/*  */}
        <div className="flex items-center gap-4 border-b-[3px] border-red-600 w-full">
          <div className="flex items-center gap-2 border p-2 cursor-pointer select-none bg-red-600 text-white">
            <span>
              <HiOutlineDatabase className="text-[38px] text-gray-500" />
            </span>
            <div className="flex flex-col items-start">
              <span className="text-base font-medium">Dofus Kamas</span>
              <span className="text-sm font-normal">76 Offres</span>
            </div>
          </div>

          {/*  */}
          {/*  */}

          <div className="flex items-center gap-1 border p-2 cursor-pointer select-none">
            <span>
              <HiOutlineCube className="text-[38px] text-gray-500" />
            </span>
            <div className="flex flex-col items-start">
              <span className="text-base font-medium">Dofus Articles</span>
              <span className="text-sm font-normal">1236 Offres</span>
            </div>
          </div>
        </div>
        <div className="flex items-center space-x-1 -mt-2">
          <span className="text-lg font-bold text-red-600">NB:</span>
          <span>Certains items et comptes ne sont pas encore disponible</span>
        </div>
        <div className="flex items-start justify-around w-full select-none my-8">
          <div className="relative">
            <input
              type="text"
              name="search"
              id="search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Recherchez le compte que vous voulez"
              className="text-base border-gray-400 border p-2 w-[350px] rounded outline-none"
            />
            <span className="absolute flex items-center justify-center p-2 transition duration-500 ease-in-out rounded cursor-pointer bg-red-600 hover:bg-red-500 text-white top-[3%] left-[89%]">
              <CiSearch className="text-[24px]" />
            </span>
          </div>
          <div className="flex items-start gap-2">
            <span className="text-sm text-gray-500 mt-[10px]">Trié selon:</span>
            <div className="flex flex-col items-end gap-2">
              <select
                name="sorted"
                id="sorted"
                className="text-base border-gray-400 border p-2 w-[350px] rounded outline-none"
              >
                <option value="Pertinence">Pertinence</option>
                <option value="Annonces récentes">Annonces récentes</option>
              </select>
              <div className="flex items-center gap-1">
                <span className="font-normal text-sm">Offres:</span>
                <span>765</span>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        {/*  */}

        <div className="flex items-start gap-8">
          <div className="flex flex-col items-start max-w-[250px]">
            <div className="flex flex-col items-start gap-2">
              <span className="text-lg font-bold">Prix</span>
              <div className="flex items-center gap-2">
                <input
                  type="text"
                  className="outline-none border-gray-400 border text-base p-2 w-[100px]"
                  placeholder="20MAD"
                />
                -
                <input
                  type="text"
                  className="outline-none border-gray-400 border text-base p-2 w-[100px]"
                  placeholder="5000MAD"
                />
              </div>
              <button className="outline-none bg-red-600 transition duration-500 ease-in-out text-white rounded border p-2 text-base w-full hover:bg-red-500">
                Réinitialiser
              </button>
            </div>
            <div className="flex flex-col items-start gap-4 mt-4 pb-4 border-b">
              <div
                className="flex items-center gap-2"
                onClick={handleToggleProductFilter}
              >
                <span className="text-lg font-bold">Class de serveurs</span>
                <HiOutlineChevronDown
                  className={`text-[22px] font-thin mt-1 transition duration-500 ease-in-out text-gray-500 cursor-pointer ${
                    !toggleFilterProduct ? "-rotate-180" : ""
                  }`}
                />
              </div>
              {toggleFilterProduct && (
                <div className="flex flex-col items-start gap-2">
                  {singleProductFilter?.map((singleProduct) => (
                    <div
                      className="flex items-center gap-2 cursor-pointer"
                      onClick={() =>
                        handleDisplayCheckBoxClicked(singleProduct)
                      }
                    >
                      {checkClicked && checkClicked === singleProduct ? (
                        <MdOutlineRadioButtonChecked
                          className={`text-red-400  transition duration-500 ease-in-out ${
                            checkClicked === singleProduct
                              ? "opacity-100"
                              : "opacity-0"
                          }`}
                        />
                      ) : (
                        <MdOutlineRadioButtonUnchecked
                          className={`text-red-400  transition duration-500 ease-in-out ${
                            checkOver === singleProduct
                              ? "opacity-100"
                              : "opacity-40"
                          }`}
                        />
                      )}

                      <span
                        className="text-lg font-normal"
                        onMouseEnter={() =>
                          handleDisplayCheckBox(singleProduct)
                        }
                        onMouseLeave={handleRemoveOver}
                        onClick={() =>
                          handleDisplayCheckBoxClicked(singleProduct)
                        }
                      >
                        {singleProduct}
                      </span>
                    </div>
                  ))}
                  <span className="text-red-500 text-lg font-normal">
                    See all
                  </span>
                </div>
              )}
            </div>
            {/*  */}
            {/*  */}
            <div className="flex flex-col items-start gap-4 mt-4 pb-4 border-b">
              <div
                className="flex items-center gap-2"
                onClick={handleToggleWarrantyFilter}
              >
                <span className="text-lg font-bold">Warranty time</span>
                <HiOutlineChevronDown
                  className={`text-[22px] font-thin mt-1 transition duration-500 ease-in-out text-gray-500 cursor-pointer ${
                    !toggleWarrantyProduct ? "-rotate-180" : ""
                  }`}
                />
              </div>
              {toggleWarrantyProduct && (
                <div className="flex flex-col items-start gap-2 cursor-pointer">
                  {singleWarrantyFilter?.map((singleProduct) => (
                    <div
                      className="flex items-center gap-2"
                      onClick={() =>
                        handleDisplayCheckBoxWarranty(singleProduct)
                      }
                    >
                      {checkClickedWarranty &&
                      checkClickedWarranty === singleProduct ? (
                        <BsCheckSquareFill
                          className={`text-red-400  transition duration-500 ease-in-out ${
                            checkClickedWarranty === singleProduct
                              ? "opacity-100"
                              : "opacity-0"
                          }`}
                        />
                      ) : (
                        <BsSquare
                          className={`text-red-400  transition duration-500 ease-in-out ${
                            checkOverWarranty === singleProduct
                              ? "opacity-100"
                              : "opacity-40"
                          }`}
                        />
                      )}

                      <span
                        className="text-lg font-normal"
                        onMouseEnter={() =>
                          handleDisplayOverWarranty(singleProduct)
                        }
                        onMouseLeave={handleRemoveWarranty}
                        onClick={() =>
                          handleDisplayCheckBoxWarranty(singleProduct)
                        }
                      >
                        {singleProduct}
                      </span>
                    </div>
                  ))}
                  <span className="text-red-500 text-lg font-normal">
                    See all
                  </span>
                </div>
              )}
            </div>

            {/*  */}
            {/*  */}
            <div></div>
            <div></div>
          </div>
          <div className="flex flex-col items-center justify-between flex-wrap gap-4">
            <div className="grid sm:mt-10 mt-2 xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-10 mx-auto p-0 w-full">
              {products
                ?.filter((product) =>
                  product?.name
                    ?.toLowerCase()
                    .includes(searchTerm?.toLowerCase())
                )
                ?.map((product) => (
                  <AccountDofusProduct key={product.id} product={product} />
                ))}
            </div>
            <div className="flex items-center gap-2 mt-4">
              <span
                className="text-xl bg-red-500 text-white p-[9px] rounded cursor-pointer transition duration-500 ease-in-out hover:bg-red-600"
                onClick={handlePaginateLeft}
              >
                <BiChevronLeft />
              </span>
              <div className="flex items-center gap-2">
                {paginationBottom?.map((pagination) => (
                  <span
                    onClick={() => handleChangePagination(pagination)}
                    className={`flex items-center w-10 justify-center text-sm  border p-2 rounded cursor-pointer transition duration-500 ease-in-out hover:bg-red-500 hover:text-white ${
                      pagination === activePagination
                        ? "bg-red-500 text-white hover:bg-red-600"
                        : "text-gray-400"
                    }`}
                  >
                    {pagination}
                  </span>
                ))}
              </div>
              <span
                className="text-xl bg-red-500 text-white p-[9px] rounded cursor-pointer transition duration-500 ease-in-out hover:bg-red-600"
                onClick={handlePaginateRight}
              >
                <BiChevronRight />
              </span>
            </div>
          </div>
        </div>

        {/*  */}
        {/*  */}

        <div></div>

        {/*  */}
        {/*  */}
        <div className="flex flex-wrap mt-20 gap-2 justify-center items-center mx-auto">
          <div className="flex flex-col items-center p-4 gap-4 w-[230px] bg-white">
            <span className="text-[50px]">
              <AiOutlineLike />
            </span>
            <span className="text-[17px] font-semibold">
              99,9% de Satisfaction
            </span>
            <span className="text-base font-normal text-center">
              iBendouma s'assure que les acheteurs reçoivent le produit
              commandé.
            </span>
          </div>
          <div className="flex flex-col items-center p-4 gap-4 w-[230px] bg-white">
            <span className="text-[50px]">
              <IoShieldCheckmarkOutline />
            </span>
            <span className="text-[17px] font-semibold">Paiement sécurisé</span>
            <span className="text-base font-normal text-center">
              Système de paiement en ligne sécurisé éprouvé par des millions
              d'utili...
            </span>
          </div>
          <div className="flex flex-col items-center p-4 gap-4 w-[230px] bg-white">
            <span className="text-[50px]">
              <IoFlashOutline />
            </span>
            <span className="text-[17px] font-semibold">Livraison Eclaire</span>
            <span className="text-base font-normal text-center">
              Plus de 95% des commandes livrées en moins de 15 minutes.
            </span>
          </div>
          <div className="flex flex-col items-center p-4 gap-4 w-[230px] bg-white">
            <span className="text-[50px]">
              <RiRefreshLine />
            </span>
            <span className="text-[17px] font-semibold">
              Satisfait ou Remboursé
            </span>
            <span className="text-base font-normal text-center">
              iBendouma garantit que les acheteurs reçoivent l'item commandé ou
              un...
            </span>
          </div>
          <div className="flex flex-col items-center p-4 gap-4 w-[230px] bg-white">
            <span className="text-[50px]">
              <Ri24HoursFill />
            </span>
            <span className="text-[17px] font-semibold">
              24 × 7 Support en Ligne
            </span>
            <span className="text-base font-normal text-center">
              Service disponible 24/7. Contactez-nous à tout moment!
            </span>
          </div>
        </div>

        {/*  */}
        {/*  */}

        <div className="flex w-full items-center justify-between flex-wrap mt-20">
          <div className="flex flex-col items-start gap-2">
            <h2 className="text-lg font-semibold">Groupe iBendouma</h2>
            <span className="text-base font-normal cursor-pointer transition duration-500 ease-in-out hover:text-red-600">
              Qui sommes-nous?
            </span>
            <span className="text-base font-normal cursor-pointer transition duration-500 ease-in-out hover:text-red-600">
              Être vendeur
            </span>
            <span className="text-base font-normal cursor-pointer transition duration-500 ease-in-out hover:text-red-600">
              Contacter iBendouma
            </span>
          </div>
          <div className="flex flex-col items-start gap-2">
            <h2 className="text-lg font-semibold">Soutien</h2>
            <span className="text-base font-normal cursor-pointer transition duration-500 ease-in-out hover:text-red-600">
              Centre d'aide
            </span>
            <span className="text-base font-normal cursor-pointer transition duration-500 ease-in-out hover:text-red-600">
              Comment acheter?
            </span>
            <span className="text-base font-normal cursor-pointer transition duration-500 ease-in-out hover:text-red-600">
              Comment vendre?
            </span>
          </div>
          <div className="flex flex-col items-start gap-2">
            <h2 className="text-lg font-semibold">Commerce</h2>
            <span className="text-base font-normal cursor-pointer transition duration-500 ease-in-out hover:text-red-600">
              Programme d'affiliation
            </span>
            <span className="text-base font-normal cursor-pointer transition duration-500 ease-in-out hover:text-red-600">
              Partenariat marketing
            </span>
            <span className="text-base font-normal cursor-pointer transition duration-500 ease-in-out hover:text-red-600">
              Game Value Calculator
            </span>
          </div>
          <div className="flex flex-col items-start gap-2">
            <h2 className="text-lg font-semibold">Régles</h2>
            <span className="text-base font-normal cursor-pointer transition duration-500 ease-in-out hover:text-red-600">
              Termes et conditions
            </span>
            <span className="text-base font-normal cursor-pointer transition duration-500 ease-in-out hover:text-red-600">
              Politique de confidentialité
            </span>
            <span className="text-base font-normal cursor-pointer transition duration-500 ease-in-out hover:text-red-600">
              Permissions
            </span>
          </div>
        </div>

        {/*  */}
        {/*  */}
        <div></div>
      </div>
    </div>
  );
};

export default Accountdofus;
