import React, { useState, useEffect } from "react";
import axios from "axios";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { io } from "socket.io-client";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FiPlus } from "react-icons/fi";

const Order = ({ servers }) => {
  const { user } = useSelector((state) => state.user);
  const [socket, setSocket] = useState(null);
  const { language } = useSelector((state) => state.language);
  const [serverSelected, setServerSelected] = useState("");
  const [amount, setAmount] = useState("1 000 000");
  const [amountCounter, setAmountCounter] = useState(amount / 1000000);

  const [userInfo, setUserInfo] = useState(user);

  // useEffect(() => {
  //   setSocket(io("ws://localhost:4001"));
  // }, []);

  useEffect(() => {
    setSocket(io("wss://iby.ibendouma.com"));
  }, []);

  const notifySuccessToAddOrder = (name) =>
    toast.success(
      "Votre commande N° " +
        name +
        " à été Créé avec succès, veuillez vous rendre sur votre profil pour suivre son état et passer le numero de commande au chat"
    );

  const notifySuccessToAddOrderEnglish = (name) =>
    toast.success(
      "Your order N° " +
        name +
        "has been successfully created, please go to your profile to track its status and pass the order number to the chat"
    );

  const notifyErrorToAddSoldeUser = () =>
    toast.error(
      "Veuiller remplir tous les champs avant de confirmer la commande"
    );
  const notifyErrorToAddSoldeUserEnglish = () =>
    toast.error("Please fill in all fields before confirming the order");

  const notifyUserError = () =>
    toast.error(
      "Merci de vous Connecter à votre compte avant de passer la commande."
    );
  const notifyUserErrorEnglish = () =>
    toast.error("Please log in to your account before placing the order.");

  function handleChatClick() {
    void window.Tawk_API.toggle();
  }

  const [userId, setUserId] = useState(user?.user);
  const [numBuy, setNumBuy] = useState("");
  const [gameName, setGameName] = useState("");
  const [jeu, setJeu] = useState("");
  const [infoDetails, setInfoDetails] = useState("");
  const [server, setServer] = useState("");
  const [pu, setPu] = useState(servers[0]?.serverPriceDh);
  const [prime, setPrime] = useState(0);
  const [qte, setQte] = useState(amountCounter);
  const [totalPrice, setTotalPrice] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [status, setStatus] = useState("En attente");
  const [serverFiltered, setServerFiltered] = useState(servers);
  const [email, setEmail] = useState("");
  const [amountSelected, setAmountSelected] = useState(1);
  const [comments, setComments] = useState("");

  function numberWithCommas(x) {
    x = x.toString();
    var pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(x)) x = x.replace(pattern, "$1 $2");
    return x;
  }

  const handleAmount = (e) => {
    setAmount(e.target.value);
  };

  useEffect(() => {
    if (amount && amount.length >= 7) {
      let amountToJoin = amount.split(" ").join("");
      setAmount(amountToJoin);
      let another = amountToJoin / 1000000;
      setAmountCounter(another);
      let num = numberWithCommas(amountToJoin);
      setAmount(num);
      // console.log(amount);
    }
  }, [amount]);

  //gameName
  //paymentInfoDetails

  useEffect(() => {
    setPu(
      servers.filter((server) => server?.serverName === serverSelected)[0]
        ?.serverPriceDh
    );

    setJeu(
      servers.filter((server) => server?.serverName === serverSelected)[0]
        ?.serverCategory
    );
  }, [serverSelected, servers]);

  useEffect(() => {
    let serverTotalPrice = pu * amountCounter;
    Number(serverTotalPrice);
    if (serverTotalPrice > 3000) {
      let num = serverTotalPrice + 50;
      Number(num);
      let numberFixed = Math.floor(num);
      setTotalPrice(numberFixed);
      setPrime(50);
    } else {
      let numberFixed = Math.floor(serverTotalPrice);
      setTotalPrice(numberFixed);
      setPrime(0);
    }
  }, [amountCounter, pu]);

  const codeGenerated = () => {
    const generateRandomCode =
      "0123456789abcdefghijklmnopkrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";

    let myCode = "";
    for (let i = 0; i < 7; i++) {
      let code = Math.floor(Math.random() * generateRandomCode.length);
      myCode += generateRandomCode[code];
    }
    return myCode;
  };

  const handleAddOrder = () => {
    if (!user?.user) {
      language === "anglais" ? notifyUserErrorEnglish() : notifyUserError();
    } else if (
      !userId ||
      !jeu ||
      !gameName ||
      !amountCounter ||
      !pu ||
      !paymentMethod ||
      !infoDetails
    ) {
      language === "anglais"
        ? notifyErrorToAddSoldeUserEnglish()
        : notifyErrorToAddSoldeUser();
    } else {
      let paymentInfoDetails = `${paymentMethod}<br/>${infoDetails}`;
      try {
        axios({
          method: "post",
          url: `${process.env.REACT_APP_IBYTRADE_CLIENT_URL}/buy`,
          data: {
            userId,
            numBuy: codeGenerated(),
            jeu,
            server: serverSelected,
            pu,
            qte: amountCounter,
            totalPrice,
            detailUser: user?.person,
            paymentMethod,
            gameName,
            paymentInfoDetails,
            status,
            comments,
            firstname: user?.person?.firstname,
            lastname: user?.person?.lastname,
            email: user?.person?.email,
            country: user?.person?.country,
            city: user?.person?.city,
            address: user?.person?.address,
            phone: user?.person?.phone,
            currencymethod: paymentMethod,
          },
        }).then((res) => {
          language === "anglais"
            ? notifySuccessToAddOrderEnglish(res?.data?.numBuy)
            : notifySuccessToAddOrder(res?.data?.numBuy);

          socket.emit("user order", {
            commendOrderID: res?.data?._id,
            commendNum: res?.data?.numBuy,
            orderedName: `${res?.data?.detailUser?.lastname} ${res?.data?.detailUser?.firstname}`,
            dateCreated: res?.data?.createdAt,
          });
          handleChatClick();
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className="order">
      <div className="order_form">
        <div className="order_form_first">
          <div>
            <label htmlFor="server">
              {language === "anglais" && "Serveur:"}
              {language === "francais" && "Server:"}
            </label>
            <select
              name="server"
              id="server"
              value={serverSelected}
              onChange={(e) => setServerSelected(e.target.value)}
            >
              <option value="Choisissez le serveur">
                {language === "anglais" && "Choose server"}
                {language === "francais" && "Choisissez le serveur"}
              </option>
              {servers.map((server) => (
                <option key={server.id} value={server.serverName}>
                  {server.serverName}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label htmlFor="gamename">
              {language === "anglais" && "In-game name:"}
              {language === "francais" && "Nom en jeu:"}
            </label>
            <input
              type="text"
              name="gamename"
              id="gamename"
              placeholder={
                language === "anglais" ? "In-game name" : "Nom en jeu"
              }
              value={gameName}
              onChange={(e) => setGameName(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="paymentmethod">
              {language === "anglais" && "Payment methods:"}
              {language === "francais" && "Methodes de paiement:"}
            </label>
            <select
              name="paymentmethod"
              id="paymentmethod"
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}
            >
              <option value="">
                {language === "anglais" && "Payment methods:"}
                {language === "francais" && "Methodes de paiement:"}
              </option>
              <option value="cih bank">CIH Bank</option>
              <option value="attijariwafa bank">Attijariwafa Bank</option>
              <option value="barid bank">Barid Bank</option>
              <option value="Western Union">Western Union</option>
              <option value="Cash Plus">Cash Plus</option>
              <option value="ADV Cash">ADV Cash</option>
              <option value="Cash Plus">Cash Plus</option>
              <option value="Binance Pay">Binance Pay</option>
              <option value="Payeer">Payeer</option>
              <option value="Wise">Wise</option>
              <option value="TRC20">TRC20</option>
            </select>
          </div>
          <div>
            <label htmlFor="qty" style={{ display: "flex", gap: "5px" }}>
              {language === "anglais" && "quantity of kamas(M)"}
              {language === "francais" && "quantité de kamas(M)"}
              <span
                style={{
                  fontWeight: 800,
                }}
              >
                ({amountCounter} {language === "anglais" && "Million kamas"}
                {language === "francais" && "Millions de kamas"})
              </span>
            </label>
            <input
              type="text"
              name="qty"
              id="qty"
              placeholder="Saisisser la quantité"
              value={amount}
              onChange={handleAmount}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
            className="flex items-center bg-white w-full max-w-36 px-1 py-0.5 rounded"
          >
            <span className="mx-1 text-gray-700">
              <FiPlus size={23} />
            </span>
            <span className="text-gray-700 text-lg">{prime}MAD</span>
            <img
              src="/gift.jpg"
              alt="gift"
              style={{
                width: "30px",
                height: "30px",
                objectFit: "cover",
                objectPosition: "center",
                marginLeft: "10px",
              }}
            />
          </div>
        </div>

        <div className="order_form_second">
          <div>
            <label htmlFor="mail">
              {language === "anglais" && "contact email:"}
              {language === "francais" && "Email du contact:"}
            </label>
            <input
              type="email"
              name="mail"
              id="mail"
              placeholder={
                user?.user
                  ? user?.person?.email
                  : language === "anglais"
                  ? "Your email:"
                  : "Votre email:"
              }
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="fullname">
              {language === "anglais" && "Fullname:"}
              {language === "francais" && "Nom et prenom:"}
            </label>
            <input
              type="text"
              name="fullname"
              id="fullname"
              placeholder={
                user?.user
                  ? `${user?.person?.lastname} ${user?.person?.firstname}`
                  : language === "anglais"
                  ? "Fullname:"
                  : "Nom et prenom:"
              }
            />
          </div>
          <div>
            {paymentMethod === "cih bank" ||
            paymentMethod === "attijariwafa bank" ||
            paymentMethod === "barid bank" ? (
              <label htmlFor="fullname">
                {language === "anglais" && "RIB Complete:"}
                {language === "francais" && "RIB Complet:"}
              </label>
            ) : paymentMethod === "Western Union" ||
              paymentMethod === "Cash Plus" ? (
              <label htmlFor="fullname">
                {language === "anglais" && "Your city:"}
                {language === "francais" && "Votre Ville:"}
              </label>
            ) : paymentMethod === "Binance Pay" ||
              paymentMethod === "Payeer" ||
              paymentMethod === "Wise" ? (
              <label htmlFor="fullname">
                {language === "anglais" && "Payment email:"}
                {language === "francais" && "Email de paiement:"}
              </label>
            ) : paymentMethod === "ADV Cash" ? (
              <label htmlFor="fullname">
                {language === "anglais" && "Account number:"}
                {language === "francais" && "Numéro de compte:"}
              </label>
            ) : paymentMethod === "TRC20" ? (
              <label htmlFor="fullname">
                {language === "anglais" && "TRX address:"}
                {language === "francais" && "Adresse TRX:"}
              </label>
            ) : (
              ""
            )}

            {paymentMethod === "cih bank" ||
            paymentMethod === "attijariwafa bank" ||
            paymentMethod === "barid bank" ? (
              <input
                type="text"
                name="fullname"
                id="fullname"
                placeholder={language === "anglais" ? "Your RIB" : "Votre RIB"}
                value={infoDetails}
                onChange={(e) => setInfoDetails(e.target.value)}
              />
            ) : paymentMethod === "Western Union" ||
              paymentMethod === "Cash Plus" ? (
              <input
                type="text"
                name="fullname"
                id="fullname"
                placeholder={
                  language === "anglais" ? "Your city" : "Votre ville"
                }
                value={infoDetails}
                onChange={(e) => setInfoDetails(e.target.value)}
              />
            ) : paymentMethod === "Binance Pay" ||
              paymentMethod === "Payeer" ||
              paymentMethod === "Wise" ? (
              <input
                type="text"
                name="fullname"
                id="fullname"
                placeholder={
                  language === "anglais" ? "Payment email" : "Email de paiement"
                }
                value={infoDetails}
                onChange={(e) => setInfoDetails(e.target.value)}
              />
            ) : paymentMethod === "ADV Cash" ? (
              <input
                type="text"
                name="fullname"
                id="fullname"
                placeholder={
                  language === "anglais" ? "Account number" : "Numéro de compte"
                }
                value={infoDetails}
                onChange={(e) => setInfoDetails(e.target.value)}
              />
            ) : paymentMethod === "TRC20" ? (
              <input
                type="text"
                name="fullname"
                id="fullname"
                placeholder={
                  language === "anglais" ? "TRX address" : "Adresse TRX"
                }
                value={infoDetails}
                onChange={(e) => setInfoDetails(e.target.value)}
              />
            ) : (
              ""
            )}
          </div>
          <div>
            <label htmlFor="comment">
              {language === "anglais" && "Add comment:"}
              {language === "francais" && "Ajouter un commentaire:"}
            </label>
            <textarea
              className="comment"
              id="comment"
              placeholder={
                language === "anglais"
                  ? "Please add a comment"
                  : "Veuiller ajouter un commentaire"
              }
              value={comments}
              onChange={(e) => setComments(e.target.value)}
            ></textarea>
          </div>
        </div>
      </div>
      <div className="order_rest">
        <div className="order_details">
          <div>
            <span className="text-sm scustom:text-base">
              {language === "anglais" && "Price per million:"}
              {language === "francais" && "Prix par million:"}
            </span>
            <span
              className="flex items-center gap-[2px] text-sm scustom:text-base"
              style={{ color: "#CA780D", fontWeight: 600 }}
            >
              {serverSelected ? pu : 0} <span>MAD</span>
            </span>
          </div>
          <div>
            <span className="text-sm scustom:text-base">
              {language === "anglais" && "Total:"}
              {language === "francais" && "Montant total:"}
            </span>
            <span
              className="text-sm scustom:text-base"
              style={{ color: "#CA780D", fontWeight: 600 }}
            >
              {serverSelected ? totalPrice : 0} MAD
            </span>
          </div>
          <div
            className="text-sm scustom:text-base"
            style={{ display: "block" }}
          >
            Bonus
            <span
              className="text-sm scustom:text-base"
              style={{
                fontWeight: 600,
                marginLeft: "1px",
                marginRight: "1px",
                color: "#CA780D",
              }}
            >
              +50dhs
            </span>{" "}
            {language === "anglais" && "if order more than"}
            {language === "francais" && "si commande superieur à"}
            <span
              className="text-sm scustom:text-base"
              style={{
                fontWeight: 600,
                marginLeft: "1px",
                marginRight: "1px",
                color: "#CA780D",
              }}
            >
              +3000dhs
            </span>{" "}
          </div>
        </div>
        <div className="order_commands">
          <div>
            <button className="btn" onClick={handleAddOrder}>
              {language === "anglais" && "Add order"}
              {language === "francais" && "Commander"}
            </button>
            {language === "anglais" && (
              <span className="add_commande_login text-sm scustom:text-base">
                Please{" "}
                <Link
                  to="/login"
                  style={{
                    color: "#d97706",
                    borderBottom: "1px solid #d97706",
                  }}
                >
                  Login
                </Link>{" "}
                to your account before placing the order.
              </span>
            )}

            {language === "francais" && (
              <span className="add_commande_login text-sm scustom:text-base">
                Merci de vous{" "}
                <Link to="/login" style={{ color: "#CA780D" }}>
                  Connecter
                </Link>{" "}
                à votre compte avant de passer la commande.
              </span>
            )}
          </div>
          <div></div>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default Order;
