import React from "react";

import { useSelector } from "react-redux";

const HowtoBuy = () => {
  let { language } = useSelector((state) => state.language);
  const FAQData = [
    {
      title: "Quelle est la politique de retour de ibendouma.com ?",
      desc: "Notre programme de protection de l'acheteur à 100 % permet des retours faciles uniquement pour les produits qui ne correspondent pas à la description.",
      titlen: "What is the return policy ibendouma.com?",
      descen:
        "Our 100% buyer protection program allows easy returns only for products that do not match the description.",
      titles: "¿Cuál es la política de devolución ibendouma.com?",
      desces:
        "Nuestro programa de protección del comprador 100% permite devoluciones fáciles solo para productos que no coinciden con la descripción.",
    },
    {
      title: "Comment contester une commande ?",
      desc: "Vous pouvez soulever un litige dans les 2 jours suivant la livraison, en envoyant un email à info@ibendouma.com, en nous contactant sur le chat ou au +852 5162 5108....",
      titlen: "How do I dispute an order?",
      descen:
        "You can raise a dispute within 2 days of delivery, by sending an email to info@ibendouma.com, by contacting us on the chat or +852 5162 5108....",
      titles: "¿Cómo se disputa un pedido?",
      desces:
        "Puede plantear una disputa dentro de los 2 días de la entrega, enviando un correo electrónico a info@ibendouma.com, poniéndose en contacto con nosotros en el chat o al +852 5162 5108...",
    },
    {
      title: "Quand serai-je remboursé ?",
      desc: `Toute commande passée sur le site sera livrée dans les temps ou
      remboursée pour le cas échéant dans les 2 jours suivant. Cependant l’erreur étant
      humaine, n’hésitez pas à nous contacter si vous pensez qu’il y a
      eu erreur. Nous gardons tous les enregistrements nécessaires
      dans le cas d’une erreur ou d’une fausse contestation.`,
      titlen: "When will I be reimbursed?",
      descen: `Any order placed on the site will be delivered on time or
      refunded for if necessary within 2 days. However the error being
      human, feel free to contact us if you think there is
      error. We keep all necessary records
      in the case of an error or false dispute.`,
      titles: "¿Cuándo se me reembolsará?",
      desces: `Cualquier pedido realizado en el sitio será entregado a tiempo o
      reembolsado para si es necesario dentro de 2 días. Sin embargo, el error es
      humano, no dude en ponerse en contacto con nosotros si cree que hay
      error. Mantenemos todos los registros necesarios
      en caso de error o disputa falsa.`,
    },
    {
      title: "Quels sont les frais de livraison ?",
      desc: "Les frais de livraison varient selon les produits et aussi votre localité. Pour plus d'information sur les frais de livraison, veuiller nous contacter sur le chat.",
      titlen: "What are the delivery costs?",
      descen:
        "Delivery costs vary according to the products and also your locality. For more information on delivery costs, please contact us on the chat.",
      titles: "¿Cuáles son los gastos de envío?",
      desces:
        "Los gastos de envío varían según los productos y también su localidad. Para obtener más información sobre los gastos de envío, póngase en contacto con nosotros en el chat.",
    },
    {
      title: "Quel est le délai de livraison estimé ?",
      desc: "Une fois la commande passée, contactez-nous sur le chat pour discuter des détails de livraison avec le service clientèle, votre commande sera livrée maximum dans les 15 minutes qui suivent.",
      titlen: "What is the estimated delivery time?",
      descen:
        "Once the order is placed, contact us on the chat to discuss delivery details with customer service, your order will be delivered maximum within 15 minutes.",
      titles: "¿Cuál es el tiempo estimado de entrega?",
      desces:
        "Una vez que se realiza el pedido, póngase en contacto con nosotros en el chat para discutir los detalles de entrega con el servicio al cliente, su pedido se entregará como máximo en 15 minutos.",
    },
    {
      title: "Comment la livraison sera-t-elle effectuée ?",
      desc: "Nous essayons de traiter toutes les livraisons le plus rapidement possible, de ce fait pour éviter quelques malentendus vous nous communiquerez quelques informations relatives à la commande  sur le chat pour le bon déroulement de la livraison.",
      titlen: "How will delivery be made?",
      descen:
        "We try to process all deliveries as quickly as possible, so to avoid some misunderstandings you will communicate us some information about the order on the chat for the smooth running of the delivery.",
      titles: "¿Cómo se hará la entrega?",
      desces:
        "Tratamos de procesar todas las entregas lo más rápido posible, por lo que para evitar algunos malentendidos nos comunicará alguna información sobre el pedido en el chat para el buen funcionamiento de la entrega.",
    },

    {
      title:
        "La navigation sur le site est-elle sécurisée ? Mes données sont-elles protégées ? Le paiement est-il sécurisé ?",
      desc: " Nous accordons une importance toute particulière à la sécurité de nos clients et de leurs informations. Dès votre arrivée sur www.ibendouma.com, votre navigation est sécurisée grâce au certificat SSL dont bénéficie notre site. Vos données sont ainsi cryptées et personne ne peut y accéder en dehors de vous.",
      titlen:
        "Is browsing the site secure? Is my data protected? Is payment secure?",
      descen:
        "We attach particular importance to the security of our customers and their information. As soon as you arrive at www.ibendouma.com, your browsing is secure thanks to the SSL certificate that benefits our site. Your data is thus encrypted and no one can access it outside of you.",
      titles:
        "¿Es segura la navegación por el sitio? ¿Están protegidos mis datos? ¿Es seguro el pago?",
      desces:
        "Damos especial importancia a la seguridad de nuestros clientes y su información. Tan pronto como llegue a www.ibendouma.com, su navegación es segura gracias al certificado SSL que beneficia a nuestro sitio. Por lo tanto, sus datos están cifrados y nadie puede acceder a ellos fuera de usted.",
    },
    {
      title:
        "Je n'ai pas de carte bancaire, est-il possible de payer autrement sur www.ibendouma.com?",
      desc: "Pour le plus grand confort des utilisateurs de notre service, il est possible de payer avec différents moyens de paiement, tous sécurisés comme expliqué dans la question « la navigation sur le site est-elle sécurisée ? Mes données sont-elles protégées ? Le paiement est-il sécurisé ? » Ibendouma vous propose plusieurs moyens de paiement locaux, par crypto et par carte.",
      titlen:
        "I don’t have a bank card, is it possible to pay otherwise on www.ibendouma.com?",
      descen:
        "For the greatest comfort of the users of our service, it is possible to pay with different means of payment, all secured as explained in the question «is browsing the site secure? Is my data protected? Is the payment secure?” Ibendouma offers several local payment methods, by crypto and card.",
      titles:
        "No tengo tarjeta bancaria, ¿es posible pagar lo contrario en www.ibendouma.com?",
      desces:
        "Para mayor comodidad de los usuarios de nuestro servicio, es posible pagar con diferentes medios de pago, todos asegurados como se explica en la pregunta «es la navegación por el sitio seguro? ¿Mis datos están protegidos? ¿Es seguro el pago? Ibendouma ofrece varios métodos de pago locales, por cripto y tarjeta.",
    },
    {
      title:
        "J'ai passé une commande depuis plus de 48 heures, pourquoi ma commande n'est toujours pas livrée ?",
      desc: "Chez Ibendouma, nous attachons une importance toute particulière à la satisfaction de nos clients. Si jamais votre commande n'a pas été livrée en 48 heures, c'est donc probablement pour l'une des raisons citées ci-dessous. Dans tous les cas, n'hésitez pas à joindre notre équipe à l'adresse info@ibendouma.com ou sur le chat, nos conseillers veilleront à résoudre votre problème dans les plus brefs délais. Vous ne répondez pas au téléphone et le livreur n'arrive pas à trouver votre domicile . Dans ce cas de figure, nous essayons toujours d'insiter de vous contacter par téléphone ainsi que par mail. Si vous ne répondez pas, nous sommes alors obligés d'attendre le lendemain pour procéder à la livraison.",
      titlen:
        "I have placed an order for more than 48 hours, why is my order still not delivered?",
      descen:
        "At Ibendouma, we attach particular importance to the satisfaction of our customers. If your order has not been delivered in 48 hours, it is probably for one of the reasons mentioned below. In any case, do not hesitate to contact our team at info@ibendouma.com or on chat, our advisors will make sure to solve your problem as soon as possible. You do not answer the phone and the delivery man cannot find your home. In this case, we always try to contact you by phone and email. If you do not respond, we are forced to wait until the next day to proceed with the delivery.",
      titles:
        "He realizado un pedido por más de 48 horas, ¿por qué mi pedido aún no se entrega?",
      desces:
        "En Ibendouma damos especial importancia a la satisfacción de nuestros clientes. Si su pedido no ha sido entregado en 48 horas, es probablemente por una de las razones mencionadas a continuación. En cualquier caso, no dude en ponerse en contacto con nuestro equipo en info@ibendouma.com o en chat, nuestros asesores se asegurarán de resolver su problema lo antes posible. Usted no contesta el teléfono y el repartidor no puede encontrar su casa. En este caso, siempre tratamos de contactarlo por teléfono y correo electrónico. Si no responde, nos vemos obligados a esperar hasta el día siguiente para proceder con la entrega.",
    },
    {
      title:
        "Puis-je modifier mes produits / mon adresse de livraison une fois ma commande validée ?",
      desc: "Notre site ne vous permet pas pour le moment de modifier une commande validée. Cependant, si vous vous rendez compte d'un oubli ou d'une erreur avant la livraison, n'hésitez pas à contacter notre équipe à l'adresse info@ibendouma.com ou sur le chat. Notre service clientèle fera le maximum pour satisfaire votre demande dans les plus brefs délais.",
      titlen:
        "Can I change my products/ delivery address once my order is validated?",
      descen:
        "Our site does not allow you at the moment to modify a validated order. However, if you notice an oversight or an error before delivery, do not hesitate to contact our team at info@ibendouma.com or on the chat. Our customer service will do its utmost to satisfy your request as soon as possible.",
      titlen:
        "¿Puedo cambiar mis productos/ dirección de entrega una vez validado mi pedido?",
      descen:
        "Nuestro sitio no le permite en este momento modificar un pedido validado. Sin embargo, si observa un descuido o un error antes de la entrega, no dude en ponerse en contacto con nuestro equipo en info@ibendouma.com o en el chat. Nuestro servicio de atención al cliente hará todo lo posible para satisfacer su solicitud lo antes posible.",
    },
  ];
  return (
    <div className="flex flex-col items-center justify-center mx-auto px-4 mt-4 xcustom:mt-24 mb-12">
      <h1 className="flex items-center justify-center text-2xl font-semibold">
        FAQS
      </h1>
      <div className="bg-white">
        <div className="lg:py-20 py-4 lg:px-4">
          <div className="flex-rows">
            <div className="flex flex-col gap-4">
              {FAQData.map((q, i) => (
                <div className="flex flex-col items-start gap-4">
                  {language === "anglais" && (
                    <h2 className="text-lg font-semibold">{q?.titlen}</h2>
                  )}
                  {language === "francais" && (
                    <h2 className="text-lg font-semibold">{q?.title}</h2>
                  )}
                  {language === "espagnol" && (
                    <h2 className="text-lg font-semibold">{q?.titles}</h2>
                  )}
                  {language === "anglais" && (
                    <p className="text-base">{q?.descen}</p>
                  )}
                  {language === "francais" && (
                    <p className="text-base">{q?.desc}</p>
                  )}
                  {language === "espagnol" && (
                    <p className="text-base">{q?.desces}</p>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowtoBuy;
