import React from "react";
import { useSelector } from "react-redux";
import Accordion from "./Accordion";
// import creditcard_pay from "../assets/hibalogo/cardspay.png";
// import google_pay from "../assets/newpay/google_pay.webp";
// import paypal from "../assets/hibalogo/paypal.png";
// import crypto from "../assets/hibalogo/crypto.png";
// import usdt from "../assets/newpay/usdt.webp";
// import coinpal from "../assets/hibalogo/coinpal.png";

const MobileFooter = () => {
    let { language } = useSelector((state) => state.language);
  return (
    <div className="flex flex-col items-start xcustom:hidden bg-[#18191a] static antialiased bottom-0 left-0 right-0 w-full z-[60]">
      <Accordion />
      <p className="w-full flex gap-1 pt-1 pb-2 border-t-[1px] border-[#a1a1aa]"></p>
      <p className="flex w-full px-4 pb-2 pt-4 text-sm text-[#a1a1aa]">
        &copy; 2024 JBK Services INTERNATIONAL FZ-LLC, Compass Building, Al
        Shohada Road, AL Hamra Industrial Zone-FZ, Ras Al Khaimah, United Arab
        Emirates JBIK INTERNATIONAL CO., LIMITED (payment processing for
        Cardpay). Registered address :ROOMS 1318-20,HOLLYWOODPLAZA, 610 NATHAN
        ROAD, MONGKOK KOWLOON
      </p>
      <p className="flex w-full px-4 pb-4 pt-1 text-sm antialiased text-[#a1a1aa]">
        {language === "anglais" &&
          `Copyright © 2019 - ${new Date().getFullYear()}, ibendouma.com. All rights reserved to ibendouma Limited`}
        {language === "espagnol" &&
          `Copyright © 2019 - ${new Date().getFullYear()}, ibendouma.com. Todos los derechos están reservados a ibendouma Limited`}
        {language === "francais" &&
          `Copyright © 2019 - ${new Date().getFullYear()}, ibendouma.com. Tous droits réservés à ibendouma Limited`}
      </p>
    </div>
  );
};

export default MobileFooter;
