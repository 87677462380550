import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { RiDeleteBinLine } from "react-icons/ri";
import { FiEdit } from "react-icons/fi";
import { BiSearch } from "react-icons/bi";
import { HiEye } from "react-icons/hi";
import { useSelector } from "react-redux";

function Table({ isViewProduct, setIsViewProduct, setIdPopupUser }) {
  const { language } = useSelector((state) => state.language);
  const Head = "text-xs font-semibold px-6 py-2 uppercase";
  const Text = "px-5 text-sm py-3 leading-6 whitespace-nowrap";
  const badge = " py-1 px-2 rounded-full text-xs font-semibold";
  const [orders, setOrders] = useState([]);
  const [idFilter, setIdFilter] = useState("");
  const [idToEdit, setIdToEdit] = useState("");
  const [status, setStatus] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const successDeleteOrder = () =>
    toast.success("Commande supprimée avec succès");

  const successUpdatedOrder = () =>
    toast.success("Commande mise à jour avec succès");

  useEffect(() => {
    const getOrders = () => {
      axios
        .get(`${process.env.REACT_APP_CLIENT_URL}/orders`)
        .then((res) => {
          setOrders(res?.data);
        })
        .catch((err) => console.log(err));
    };

    getOrders();
  }, []);

  const convertDate = (date) => {
    const dateConverted = new Date(date).toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    return dateConverted;
  };

  const getOrdersUpdated = () => {
    axios
      .get(`${process.env.REACT_APP_CLIENT_URL}/orders`)
      .then((res) => {
        setOrders(res?.data);
      })
      .catch((err) => console.log(err));
  };

  const handleRemoveOrder = (orderId) => {
    axios({
      method: "delete",
      url: `${process.env.REACT_APP_CLIENT_URL}/orders/${orderId}`,
    }).then((res) => {
      successDeleteOrder();
      setIdFilter(res?.data?._id);
    });
  };

  const handleUpdateOrder = (orderIdToUpdate) => {
    axios({
      method: "put",
      url: `${process.env.REACT_APP_CLIENT_URL}/orders/${orderIdToUpdate}`,
      data: {
        status,
      },
    }).then((res) => {
      getOrdersUpdated();
      setIdToEdit("");
      successUpdatedOrder();
    });
  };

  useEffect(() => {
    setOrders(orders?.filter((order) => order?._id !== idFilter));
  }, [idFilter]);

  const handleSetIdToUpdate = (idEdited) => {
    setIdToEdit(idEdited);
  };
  const handleCancelSetId = () => {
    setIdToEdit("");
  };

  const handleView = (idOrder) => {
    setIsViewProduct(true);
    setIdPopupUser(idOrder);
  };

  const Rows = ({ order }) => {
    return (
      <tr key={order?._id}>
        <td className={`${Text} font-medium`}>{order?.orderNum}</td>
        <td className={`${Text} text-center`}>
          {convertDate(order?.createdAt)}
        </td>

        <td className={`${Text} text-center`}>{order?.paymentType}</td>
        {order?._id === idToEdit ? (
          <td>
            <select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              className="text-base text-gray-700 font-semibold"
            >
              <option value="En attente">
                {language === "francais" && "En attente"}
                {language === "anglais" && "Pending"}
                {language === "espagnol" && "Pendiente"}
              </option>
              <option value="Payée">
                {language === "francais" && "payée"}
                {language === "anglais" && "Paid"}
                {language === "espagnol" && "Pagar"}
              </option>
              <option value="Annulée">
                {language === "francais" && "Annulée"}
                {language === "anglais" && "canceled"}
                {language === "espagnol" && "Cancelada"}
              </option>
            </select>
          </td>
        ) : (
          <td className={`${Text} text-center`}>
            {order.status === "Payée" && (
              <span className={`${badge} text-subMain bg-green-100`}>
                {order?.status}
              </span>
            )}
            {order.status === "En attente" && (
              <span className={`${badge} text-star bg-orange-100`}>
                {order?.status}
              </span>
            )}
            {order?.status === "Annulée" && (
              <span className={`${badge} text-flash bg-red-100`}>
                {order?.status}
              </span>
            )}
          </td>
        )}

        <td className={`${Text} font-bold text-center`}>{order?.total}.00</td>

        <td className={`${Text} float-right flex gap-2`}>
          {order?._id !== idToEdit && (
            <button
              className="border text-lg text-main border-main rounded flex-colo w-6 h-6"
              onClick={() => handleView(order?._id)}
            >
              <HiEye />
            </button>
          )}

          {order?._id !== idToEdit && (
            <button
              className="border text-lg text-flash border-flash rounded flex-colo w-6 h-6"
              onClick={() => handleRemoveOrder(order?._id)}
            >
              <RiDeleteBinLine />
            </button>
          )}

          {order?._id === idToEdit ? (
            <div className="flex items-center gap-2">
              <button
                className="flex items-center text-sm p-2 rounded justify-center bg-green-500 text-white transition duration-300 ease-in-out hover:bg-green-600"
                onClick={() => handleUpdateOrder(order?._id)}
              >
                Valider
              </button>
              <button
                className="flex items-center  text-sm p-2 rounded justify-center bg-red-500 text-white transition duration-300 ease-in-out hover:bg-red-600"
                onClick={handleCancelSetId}
              >
                Annuler
              </button>
            </div>
          ) : (
            <button
              className="border text-lg text-orange-500 border-orange-500 rounded flex-colo w-6 h-6"
              onClick={() => handleSetIdToUpdate(order?._id)}
            >
              <FiEdit />
            </button>
          )}
        </td>
      </tr>
    );
  };

  return (
    <div className="w-full flex flex-col relative overflow-hidden overflow-x-scroll">
      <div className="w-full relative mb-8">
        <input
          type="text"
          name="search"
          id="search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Rechercher la commande par son ID"
          className="text-base p-3 rounded w-full  border-none text-gray-400 bg-gray-900"
        />
        <span className="absolute text-gray-400 text-3xl left-[96%] top-[18%]">
          <BiSearch />
        </span>
      </div>
      <table className="table-auto min-w-full border border-deepest divide-y divide-gray-200">
        <thead>
          <tr className="bg-deepest">
            <th scope="col" className={`${Head} text-left`}>
              ID
            </th>
            <th scope="col" className={`${Head} text-center`}>
              Date
            </th>

            <th scope="col" className={`${Head} text-center`}>
              Method
            </th>
            <th scope="col" className={`${Head} text-center`}>
              Status
            </th>
            <th scope="col" className={`${Head} text-center`}>
              Total
            </th>
            <th scope="col" className={`${Head} text-end`}>
              Action
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-deepest">
          {orders
            ?.filter((order) =>
              order?.orderNum.toLowerCase().includes(searchTerm.toLowerCase())
            )
            ?.map((order) => Rows({ order }))}
        </tbody>
      </table>
    </div>
  );
}

export default Table;
