import React from "react";
import { IoShieldCheckmarkOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

const Product = ({ product }) => {
  return (
    <Link to={`/seller/${"Brawl_stars"}/${"tyron_ply"}/${12516464678}`}>
      <div className="flex flex-col items-start gap-2 p-2 w-full cursor-pointer transition duration-500 ease-in-out hover:shadow-lg">
        <img
          src={product.picture}
          alt={product.name}
          className="w-full max-w-[250px] object-cover object-center"
        />
        <div className="flex">
          <p className="font-bold text-base w-full max-w-[250px]">
            {product.name}
          </p>
        </div>

        <div className="flex items-center gap-1">
          <IoShieldCheckmarkOutline className="text-green-400 mt-[2px]" />
          <span className="text-sm text-gray-400">{product.warranty}</span>
        </div>
        <div className="flex items-center gap-1 max-w-[250px]">
          <span className="text-black text-lg">A partir de </span>
          <span className="text-xl text-red-600 font-bold">
            {product.price} EUR
          </span>
        </div>
        {/* <p className="text-base font-semibold">{product.serveur}</p> */}
        <span className="text-lg text-green-500 font-bold">
          {product.stars} Stars
        </span>
      </div>
    </Link>
  );
};

export default Product;
