import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { singleHeader } from "../utils/igvoriginaldata";
import { igvproducts } from "../utils/igvoriginaldata";

const SingleMobileHeadingPage = () => {
  const { ingame } = useParams();
  const [isScroll, setIsScroll] = useState(0);
  const [letter, setLetter] = useState("A");
  const letterPosition = {};

  //   console.log(ingame);

  const checkIsLetterISTheSamePosition = () => {
    const scrollY = window.screenY || document.documentElement.scrollTop;

    for (const [letter, position] of Object.entries(letterPosition)) {
      if (position <= scrollY + 40) {
        setLetter(letter);
      }
    }
  };

  useEffect(() => {
    const handleScrolling = () => {
      checkIsLetterISTheSamePosition();
    };
    window.addEventListener("scroll", handleScrolling);
    return () => {
      window.removeEventListener("scroll", handleScrolling);
    };
  }, []);

  const handleScroll = () => {
    const isCroll = window.scrollY;
    setIsScroll(isCroll);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  //   console.log(igvproducts);
  if (ingame === "boosting" || ingame === "social-media")
    return (
      <div className="flex flex-col gap-2 items-start p-4 text-lg text-gray-800 mb-[280px]">
        <p>Les produits de {ingame} ne sont pas disponible pour le moment</p>
        <p>Merci de votre comprehension</p>
      </div>
    );

  return (
    <div className="flex xcustom:hidden flex-col items-start w-full p-2 bg-gray-100 overflow-x-hidden scroll-smooth">
      <div
        style={{
          top: isScroll > 50 ? 0 : 50,
        }}
        className="w-full flex flex-col items-start justify-between h-[50px] fixed top-50 bg-gray-100 z-[70] pt-1"
      >
        <span className="text-xs">
          {ingame === "monnaie-en-jeu" ? 170 : 214} results
        </span>
        {isScroll > 160 && (
          <span className="text-sm">
            -<strong>{letter}</strong>-
          </span>
        )}
      </div>
      <div className="flex flex-col items-start gap-2 mt-10">
        <span className="text-xs">
          <strong>Top Sale</strong>
        </span>
        <div className="overflow-x-scroll w-full max-w-[519px] topsale-scroll">
          <div className="flex items-center gap-4">
            {singleHeader
              ?.filter((product) => product.cat === ingame)
              ?.map((product) => (
                <Link
                  to={product?.link}
                  className="flex items-center w-40 gap-2 bg-white shadow-inner p-2 rounded"
                  key={product?.id}
                >
                  <img
                    src={product?.img}
                    alt={product?.title}
                    className="flex items-end border shadow-lg justify-center w-8 h-8 object-cover object-center rounded-full"
                  />
                  <div className="flex flex-col items-start gap-1">
                    <span className="text-black text-xs font-semibold antialiased">
                      <strong>{product?.title}</strong>
                    </span>
                    <div className="flex text-xs antialiased gap-1">
                      {product?.offers}
                      <span>offers</span>
                    </div>
                  </div>
                </Link>
              ))}
          </div>
        </div>
      </div>
      {igvproducts?.map((product) => (
        <div
          className="flex flex-col items-start gap-4 w-full mr-2"
          key={product?.id}
        >
          <p
            ref={(ref) => {
              if (ref && !letterPosition[product?.letterStart]) {
                letterPosition[product?.letterStart] =
                  ref.getBoundingClientRect().top + window.scrollY;
              }
            }}
            className="flex items-center text-sm antialiased mt-1"
          >
            -<strong>{product?.letterStart}</strong>-
          </p>
          <div className="w-full flex flex-col items-start gap-2">
            {product?.details?.slice(0, 6)?.map((productDetail) => (
              <Link
                className="flex items-center justify-between bg-white p-3 rounded-[8px] w-full"
                key={productDetail?.id}
                to={productDetail?.items[0]?.link?.split("fr")[1]}
                // style={{ display: i === 0 && "none" }}
              >
                <p className="text-sm  antialiased">{productDetail?.title}</p>
                <div className="flex gap-1 items-center bg-gray-100 rounded text-xs antialiased p-1">
                  {product?.details?.length}
                  <span>offers</span>
                </div>
              </Link>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SingleMobileHeadingPage;
