import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  DofusKamas,
  DofusRetro,
  DofusTouch,
  Wakfu,
} from "../components/dofusforibytrade";

import { Helmet } from "react-helmet";

import LoaderServer from "../components/LoaderServer";
import Order from "../components/Order";

const SellKamas = () => {
  const { language } = useSelector((state) => state.language);
  const [servers, setServers] = useState([]);
  const [loadingSell, setLoadingSell] = useState(false);

  function handleChatClick() {
    void window.Tawk_API.toggle();
  }

  useEffect(() => {
    const getServers = async () => {
      await axios
        .get(`${process.env.REACT_APP_IBYTRADE_CLIENT_URL}/server`)
        .then((res) => {
          setServers(res.data);
          setLoadingSell(true);
        });
    };
    getServers();
  }, []);
  return !loadingSell ? (
    <LoaderServer />
  ) : (
    <div className="sellkamas_container">
      {language === "anglais" ? (
        <div className="container mx-auto p-4 max-w-[880px] mt-8 xcustom:mt-28">
          <div className="bg-[#f4f4f4a1] shadow-md rounded-lg p-6 border-white">
            <h1 className="text-2xl uppercase text-gray-700 text-center mb-4">
              Sell Kamas | Kamas Sale
            </h1>
            <p className="text-gray-700 mb-4">
              Sell your Dofus Kamas at the best prices. Feel free to contact us
              if you want to sell your kamas.
            </p>
            <p className="text-gray-700 mb-4">
              <strong>Quantity:</strong> Minimum value of 200DH in kamas (CIH,
              ATTIJARI, and BARID bank).
            </p>
            <p className="text-gray-700 mb-4">
              All your sales recorded on the same payment account will be
              combined and sent in a single payment.
            </p>
            <p className="text-gray-700 mb-4">
              <strong>Payment methods:</strong> Bank transfers and CashPlus,
              WafaCash [Morocco]. Payment sent within 24 hours after the
              delivery of kamas. (Minimum of 300 DH in kamas)
            </p>
            <p className="text-gray-700 mb-4">
              You automatically get a bonus of 50 DH on each order sold over
              3000 DH.
            </p>
            <ol className="list-decimal list-inside text-gray-700 mb-4">
              <li>
                The bonus is calculated according to the total sum of each order
                sold (e.g., 3000 DH ==&gt; 3050 DH; 8000 DH ==&gt; 8050 DH;
                10000 DH ==&gt; 10050 DH).
              </li>
              <li>
                The bonus does not apply to the total of several different
                orders.
              </li>
            </ol>
            <p className="text-gray-700 mb-4">
              I don't sell Kamas, I want an exchange between servers.{" "}
              <span
                onClick={handleChatClick}
                className="text-[#d97706] underline cursor-pointer"
              >
                Click here for Kamas exchange
              </span>
            </p>
          </div>
        </div>
      ) : language === "francais" ? (
        <div className="container mx-auto p-4 max-w-[880px] mt-8 xcustom:mt-28">
          <div className="bg-[#f4f4f4a1] shadow-md rounded-lg p-6 border-white">
            <h1 className="text-2xl uppercase text-gray-700 text-center mb-4">
              Vendre des kamas | vente kamas
            </h1>
            <p className="text-gray-700 mb-4">
              Vendez vos Kamas Dofus à de meilleurs prix. N'hésitez pas à nous
              contacter si vous voulez vendre vos kamas.
            </p>
            <p className="text-gray-700 mb-4">
              <strong>Quantité :</strong> 200DH minimum de valeur en kamas (CIH,
              ATTIJARI et BARID banque).
            </p>
            <p className="text-gray-700 mb-4">
              Toutes vos ventes enregistrées sur le même compte de paiement
              seront combinées et envoyées en un seul paiement.
            </p>
            <p className="text-gray-700 mb-4">
              <strong>Méthodes de paiement :</strong> Virements bancaires et
              CashPlus, WafaCash [Maroc]. Paiement envoyé en 24 heures après la
              livraison des kamas. (300 Dhs de kamas au minimum)
            </p>
            <p className="text-gray-700 mb-4">
              Vous avez automatiquement un bonus de 50 Dhs sur chaque commande
              vendue de plus de 3000 Dhs.
            </p>
            <ol className="list-decimal list-inside text-gray-700 mb-4">
              <li>
                Le bonus est calculé selon la somme totale de chaque commande
                vendue (exemple 3000dh ==&gt; 3050 dh; 8000dh ==&gt; 8050 dh;
                10000dh ==&gt; 10050 dh).
              </li>
              <li>
                Le bonus ne s'applique pas sur le total de plusieurs différentes
                commandes.
              </li>
            </ol>
            <p className="text-gray-700 mb-4">
              Je ne vends pas de Kamas, je veux un échange entre serveurs.{" "}
              <span
                onClick={handleChatClick}
                className="text-[#d97706] underline cursor-pointer"
              >
                Cliquez ici Echange de kamas
              </span>
            </p>
          </div>
        </div>
      ) : (
        <div className="container mx-auto p-4 max-w-[880px] mt-8 xcustom:mt-28">
          <div className="bg-[#f4f4f4a1] shadow-md rounded-lg p-6 border-white">
            <h1 className="text-2xl uppercase text-gray-700 text-center mb-4">
              Vender Kamas | Venta de Kamas
            </h1>
            <p className="text-gray-700 mb-4">
              Vende tus Kamas de Dofus a los mejores precios. No dudes en
              contactarnos si quieres vender tus kamas.
            </p>
            <p className="text-gray-700 mb-4">
              <strong>Cantidad:</strong> Valor mínimo de 200DH en kamas (CIH,
              ATTIJARI y banco BARID).
            </p>
            <p className="text-gray-700 mb-4">
              Todas tus ventas registradas en la misma cuenta de pago se
              combinarán y enviarán en un solo pago.
            </p>
            <p className="text-gray-700 mb-4">
              <strong>Métodos de pago:</strong> Transferencias bancarias y
              CashPlus, WafaCash [Marruecos]. Pago enviado dentro de las 24
              horas posteriores a la entrega de kamas. (Mínimo de 300 DH en
              kamas)
            </p>
            <p className="text-gray-700 mb-4">
              Obtienes automáticamente un bono de 50 DH en cada pedido vendido
              por más de 3000 DH.
            </p>
            <ol className="list-decimal list-inside text-gray-700 mb-4">
              <li>
                El bono se calcula según la suma total de cada pedido vendido
                (por ejemplo, 3000 DH ==&gt; 3050 DH; 8000 DH ==&gt; 8050 DH;
                10000 DH ==&gt; 10050 DH).
              </li>
              <li>
                El bono no se aplica al total de varios pedidos diferentes.
              </li>
            </ol>
            <p className="text-gray-700 mb-4">
              No vendo Kamas, quiero un intercambio entre servidores.{" "}
              <span
                onClick={handleChatClick}
                className="text-[#d97706] underline cursor-pointer"
              >
                Haz clic aquí para intercambio de kamas
              </span>
            </p>
          </div>
        </div>
      )}

      <div className="sellkamas_order">
        <Order servers={servers} />
      </div>
      <div className="sellkamas">
        <Helmet>
          <title>Vendez vos kamas aux meilleurs taux du marché</title>
        </Helmet>
        <DofusKamas servers={servers} id="kamas" />
        <DofusRetro servers={servers} id="retro" />
        <DofusTouch servers={servers} id="touch" />
        <Wakfu servers={servers} id="wakfu" />
      </div>
    </div>
  );
};

export default SellKamas;
