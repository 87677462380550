import React from "react";
import { Link } from "react-router-dom";

const Product = ({ item }) => {
  return (
    <Link
      to={
        item?.title === "Paysafecard"
          ? "/paysafecard"
          : item?.title === "Objet Dofus"
          ? "/les-dofus"
          : `/dofus/${item?.category}`
      }
    >
      <div className="flex flex-col items-center cursor-pointer rounded bg-white shadow-lg transitions">
        <img
          alt={item?.title}
          src={item?.image}
          className="w-full h-40 object-cover"
        />

        <span className="flex items-center justify-center w-full bg-white text-[15px] p-2 font-[500]">
          {item?.title}
        </span>
      </div>
    </Link>
  );
};

export default Product;
