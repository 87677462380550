import creditcard_pay from "../../assets/newpay/creditcard_pay.webp";
import creditcardgooglepay from "../../assets/newpay/creditcardgooglepay.webp";
import google_pay from "../../assets/newpay/google_pay.webp";
import paypals from "../../assets/newpay/paypals.webp";
import paysafecard from "../../assets/newpay/paysafecard.webp";
import neosurf from "../../assets/newpay/neosurf.png";
import crypto from "../../assets/newpay/crypto.png";
import usdt from "../../assets/newpay/usdt.webp";
import coinpal from "../../assets/newpay/coinpal.png";

export const paymentMethods = [
  {
    id: 145287,
    name: "creditcard",
    fee: "3.5%",
    icon: creditcard_pay,
    w: "130px",
    h: "30px",
  },
  {
    id: 147325,
    name: "creditcardgooglepay",
    fee: "2.5%",
    icon: creditcardgooglepay,
    w: "130px",
    h: "30px",
  },
  {
    id: 871347,
    name: "google_pay",
    fee: "0%",
    icon: google_pay,
    w: "130px",
    h: "30px",
  },
  {
    id: 312651,
    name: "paypal",
    fee: "0.5%",
    icon: paypals,
    w: "130px",
    h: "30px",
  },
  //   {
  //     id: 164025,
  //     name: "coinpal",
  //     fee: "2%",
  //     icon: coinpal,
  //     w: "120px",
  //     h: "25px",
  //   },
  {
    id: 512379,
    name: "paysafecard",
    fee: "1%",
    icon: paysafecard,
    w: "130px",
    h: "30px",
  },
  {
    id: 317061,
    name: "neosurf",
    fee: "4.3%",
    icon: neosurf,
    w: "130px",
    h: "30px",
  },
  {
    id: 814672,
    name: "crypto",
    fee: "1%",
    icon: crypto,
    w: "130px",
    h: "30px",
  },
  {
    id: 846030,
    name: "usdt",
    fee: "1%",
    icon: usdt,
    w: "130px",
    h: "30px",
  },
];

export const generateCouponCode = () => {
  let alphaNumeric =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

  let couponCode = "";

  for (let i = 0; i < 20; i++) {
    let iter = Math.floor(Math.random() * alphaNumeric.length);
    let charac = alphaNumeric[iter];
    couponCode += charac;
  }
  return couponCode;
};
