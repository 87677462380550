import React, { useState } from "react";
import { IoIosClose } from "react-icons/io";

import { useSelector, useDispatch } from "react-redux";

import { addNewLanguage } from "../features/languageSliceSelected";
import { addNewCurrencyExchange } from "../features/currencyExchange";

const SideDrawer = ({ show, click }) => {
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.language);
  const [languageHeader, setLanguageHeader] = useState("anglais");
  const [selectCurrency, setSelectCurrency] = useState("");

  const sideDrawerClass = ["sidedrawer"];
  if (show) {
    sideDrawerClass.push("show");
  }

  const handleSelectLanguage = (e) => {
    dispatch(addNewLanguage(e.target.value));
    setLanguageHeader(e.target.value);
  };

  const handleSelectCurrency = (e) => {
    dispatch(addNewCurrencyExchange(e.target.value));
    setSelectCurrency(e.target.value);
  };

  return (
    <div className={sideDrawerClass.join(" ")}>
      <div className="flex flex-col w-full">
        <div className="flex items-center justify-between p-3 bg-black">
          <span className="text-[#f9fafb] antialiased">
            {language === "anglais" && "Settings"}
            {language === "francais" && "Paramètres"}
            {language === "espagnol" && "Ajustes"}
          </span>
          <IoIosClose className="text-[#f9fafb] text-[26px]" onClick={click} />
        </div>
        <div className="flex flex-col items-center justify-center gap-6 mt-8 mb-4 mx-auto">
          <div className="w-full flex flex-col items-start">
            <span className="text-base text-black font-bold">
              {language === "anglais" && "Language"}
              {language === "francais" && "Language"}
              {language === "espagnol" && "Idioma"}
            </span>
            <select
              name="language"
              id="language"
              value={languageHeader}
              onChange={handleSelectLanguage}
              className="w-[220px] text-base bg-white text-black outline-none mt-2 border border-[#33373e] px-4 py-2 antialiased"
            >
              <option value="anglais">
                {language === "francais" && "Anglais"}
                {language === "anglais" && "English"}
                {language === "espagnol" && "Inglés"}
              </option>
              <option value="francais">
                {language === "francais" && "Francais"}
                {language === "anglais" && "French"}
                {language === "espagnol" && "Francés"}
              </option>

              <option value="espagnol">
                {language === "francais" && "Espagnol"}
                {language === "anglais" && "Spanish"}
                {language === "espagnol" && "Español"}
              </option>
            </select>
          </div>
          <div className="w-full flex flex-col items-start">
            <span className="text-base text-black font-bold">
              {language === "anglais" && "Currency"}
              {language === "francais" && "Monnaie"}
              {language === "espagnol" && "Dinero"}
            </span>
            <select
              name="currency"
              id="currency"
              value={selectCurrency}
              onChange={handleSelectCurrency}
              className="w-[220px] text-base bg-white antialiased text-black outline-none mt-2 border border-[#33373e] px-4 py-2"
            >
              <option value="euro">EUR</option>
              <option value="dollar">USD</option>
              <option value="cad">CAD</option>
              <option value="gbp">GBP</option>
              <option value="mad">MAD</option>
              <option value="chf">CHF</option>
              <option value="rub">RUB</option>
            </select>
          </div>
        </div>
        <button
          className="absolute bottom-6 left-4 right-4 max-w-[220px] mx-auto text-base outline-none px-4 py-2 w-full text-red-500 border border-red-500"
          onClick={click}
        >
          Annuler
        </button>
      </div>
    </div>
  );
};

export default SideDrawer;
