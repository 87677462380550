import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { BsCheckSquare, BsCheckSquareFill } from "react-icons/bs";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { FiSquare } from "react-icons/fi";

import { addUser } from "../features/userSlice";

import spin from "../assets/spinners/spin.gif";

import { useNavigate } from "react-router-dom";

import axios from "axios";

import logo from "../assets/iben-logo.jpg";

const SignIn = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [checkRemember, setCheckRember] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);

  const notifyEmailError = () => toast.error("Email inconnu");

  const notifyEmailErrorEnglish = () => toast.error("Email unknown");

  const notifyPasswordError = () =>
    toast.error("Le mot de passe doit avoir 6 caracteres minimum");

  const notifyPasswordErrorEnglish = () =>
    toast.error("Password must have a minimum of 6 characters");

  const emailErrorBackend = (text) => toast.error(text);
  const notifyBackErrors = (nameErrors) => toast.error(nameErrors);

  const emailErrorBackendEnglish = () =>
    toast.error("Incorrect email or password");

  const dispatch = useDispatch();

  const { language } = useSelector((state) => state.language);

  const location = useLocation();
  const handleToggleCheckRemember = () => {
    setCheckRember((prevCheckRemember) => !prevCheckRemember);
  };
  const handleSubmit = () => {
    if (!email || !password) {
      if (!email) {
        language === "anglais" ? notifyEmailErrorEnglish() : notifyEmailError();
      }
      if (!password) {
        language === "anglais"
          ? notifyPasswordErrorEnglish()
          : notifyPasswordError();
      }
    } else {
      setLoadingUser(true);
      try {
        axios({
          method: "post",
          url: `${process.env.REACT_APP_CLIENT_URL}/users/login`,
          data: {
            email,
            password,
          },
          withCredentials: true,
        })
          .then((res) => {
            // console.log(res.data);
            dispatch(addUser(res.data));
            setLoadingUser(true);
            let userId = res?.data?.user;
            if (location.pathname === "/login") {
              navigate("/");
            } else {
              console.log("nothing");
            }
          })
          .catch(({ response }) => {
            notifyBackErrors(response?.data?.message);
            setLoadingUser(false);
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className="h-screen flex items-center justify-center bg-white">
      <div className="w-1/2 h-screen hidden scustom:flex items-center justify-center relative bg-red-600">
        <Link to="/">
          <div className="flex flex-col gap-2 items-center cursor-pointer">
            <img
              src={logo}
              alt="logo"
              className="h-[200px] w-[200px] rounded-full object-cover object-center pb-1 cursor-pointer"
            />
            <div className="flex flex-col items-start justify-between">
              <p className="text-3xl font-extrabold text-[#C9C9C9]">
                iBENDOUMA
              </p>
              <p className="text-xl font-bold text-[#959595]">GAME FOR GAMER</p>
            </div>
          </div>
        </Link>
      </div>
      <div className="w-1/2 h-screen flex flex-col gap-8 items-center justify-center m-0">
        {/* <h1 className="signin-title">
        {language === "anglais" && "Welcome to ibendouma"}
        {language === "francais" && "Bienvenu sur ibendouma"}
        {language === "espagnol" && "Bienvenido a ibendouma"}
      </h1> */}

        <h2 className="text-2xl custom:text-3xl font-normal text-gray-900">
          {language === "anglais" && "Log in !"}
          {language === "francais" && "Connectez-vous !"}
          {language === "espagnol" && "Acceso !"}
        </h2>
        <div className="flex flex-col items-start gap-4">
          <div className="flex flex-col items-start gap-2">
            <label
              htmlFor="email"
              className="block text-base font-medium text-gray-700"
            >
              Email
            </label>
            <input
              type="text"
              value={email}
              placeholder={
                language === "anglais" ? "Email address" : "Adresse e-mail"
              }
              id="email"
              onChange={(e) => setEmail(e.target.value)}
              className="appearance-none block w-[300px] p-2 border border-red-600 rounded-md shadow-sm placeholder:text-gray-400 focus:outline-none focus:border-red-600 sm:text-sm"
            />
          </div>
          {/* <div className="signup-errors"></div> */}
          <div className="flex flex-col items-start gap-2">
            <label
              htmlFor="password"
              className="block text-base font-medium text-gray-700"
            >
              {language === "anglais" && "Password"}
              {language === "francais" && "Mot de passe"}
              {language === "espagnol" && "contraseña"}{" "}
            </label>
            <input
              type="password"
              value={password}
              placeholder={language === "anglais" ? "Password" : "Mot de passe"}
              id="password"
              onChange={(e) => setPassword(e.target.value)}
              className="appearance-none block w-[300px] p-2 border border-red-600 rounded-md shadow-sm placeholder:text-gray-400 focus:outline-none focus:border-red-600 sm:text-sm"
            />
          </div>
          {/* <div className="signup-errors">
            
          </div> */}

          <div className="flex flex-col items-center w-full gap-2">
            <div className="flex w-full gap-2 sm:gap-1 items-center mx-auto justify-between">
              <div
                className="flex items-center space-x-1"
                onClick={handleToggleCheckRemember}
              >
                {checkRemember ? (
                  <span className="text-red-600">
                    <BsCheckSquareFill />
                  </span>
                ) : (
                  <span className="icon">
                    <FiSquare />
                  </span>
                )}
                <span className="text-xs cursor-pointer">
                  {language === "anglais" && "Remember me"}
                  {language === "francais" && "Se souvenir de moi"}
                  {language === "espagnol" && "Recordarme"}
                </span>
              </div>
              <Link
                to="/resetpassword"
                className="text-gray-600 text-xs transition duration-500 ease-in-out hover:text-red-600"
              >
                {language === "anglais" && "Forgot your password"}
                {language === "francais" && "Mot de passe oublié"}
                {language === "espagnol" && "Contraseña olvidada"}?
              </Link>
            </div>
          </div>
          <div className="flex flex-col w-[300px]  gap-2">
            {loadingUser ? (
              <span className="flex items-center justify-center w-full rounded bg-red-600 text-white p-2 cursor-pointer">
                <img
                  src={spin}
                  alt="loading"
                  className=" w-[40px] h-[23px] object-contain"
                />
              </span>
            ) : (
              <span
                className="flex items-center justify-center w-[300px] rounded bg-red-600 text-white p-2 text-sm cursor-pointer transition duration-300 ease-in-out hover:bg-red-700"
                onClick={handleSubmit}
              >
                {language === "anglais" && "Sign In"}
                {language === "francais" && "Se connecter"}
                {language === "espagnol" && "Conectar"}
              </span>
            )}

            {language === "francais" && (
              <div className="text-sm w-full text-center">
                Pas de compte?{" "}
                <Link to="/register" className="text-red-300 underline">
                  inscrivez vous
                </Link>
              </div>
            )}
            {language === "anglais" && (
              <div className="text-sm w-full text-center">
                You haven't account?{" "}
                <Link to="/register" className="text-red-300 underline">
                  sign up
                </Link>
              </div>
            )}
            {language === "espagnol" && (
              <div className="text-sm w-full text-center">
                Sin cuenta?{" "}
                <Link to="/register" className="text-red-300 underline">
                  registrarse
                </Link>
              </div>
            )}
          </div>
        </div>

        {/* <div className="signin-copyright">
        Copyright &copy; 2019 - {new Date().getFullYear()},{" "}
        {language === "francais" &&
          "Tous les droits sont réservés à ibendouma Limited."}
        {language === "anglais" &&
          "All rights are reserved to ibendouma Limited."}
        {language === "espagnol" &&
          "Todos los derechos están reservados a ibendouma Limited."}
      </div> */}
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </div>
  );
};

export default SignIn;
