import React, { useState, useEffect } from "react";
import SideBar from "../screens/Dashboard/SideBar";
import AddProduct from "./seller/AddProduct";
import ShowOrders from "./seller/ShowOrders";
import ChangePass from "./seller/ChangePass";
import VerifyIdentity from "./seller/VerifyIdentity";

const Shop = () => {
  const [activeItem, setActiveItem] = useState("add_product");
  const [items, setItems] = useState([
    {
      id: "1346hkjkh",
      name: "add_product",
    },
    {
      id: "8421hkjkh",
      name: "show_orders",
    },
    {
      id: "8421ndomq",
      name: "change_password",
    },
    {
      id: "8421kpwvu",
      name: "verify_identity",
    },
  ]);

  const handleSetItem = (item) => {
    setActiveItem(item);
  };

  return (
    <SideBar>
      <div className="flex items-start gap-4 w-full select-none">
        <div className="flex flex-col h-screen bg-red-600 w-[200px] p-2">
          <h2 className="text-lg text-white  w-full">Seller Dashboard</h2>
          <div className="flex flex-col items-start gap-2 mt-8">
            <span className="text-lg text-white ">Shop</span>
            <div className="flex flex-col items-start gap-1 ml-2">
              <div>
                <span
                  className="text-white  text-sm cursor-pointer duration-500 ease-in-out transition hover:font-bold"
                  onClick={() => handleSetItem("add_product")}
                  style={{
                    fontWeight: activeItem === "add_product" && "bold",
                  }}
                >
                  Add product
                </span>
              </div>
              <div>
                <span
                  className="text-white  text-sm cursor-pointer duration-500 ease-in-out transition hover:font-bold"
                  onClick={() => handleSetItem("show_orders")}
                  style={{
                    fontWeight: activeItem === "show_orders" && "bold",
                  }}
                >
                  Show orders
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start gap-2 mt-8">
            <span className="text-lg text-white ">Confidentiality</span>
            <div className="flex flex-col items-start gap-1 ml-2">
              <div>
                <span
                  className="text-white  text-sm cursor-pointer duration-500 ease-in-out transition hover:font-bold"
                  onClick={() => handleSetItem("change_password")}
                  style={{
                    fontWeight: activeItem === "change_password" && "bold",
                  }}
                >
                  Change password
                </span>
              </div>
              <div>
                <span
                  className="text-white  text-sm cursor-pointer duration-500 ease-in-out transition hover:font-bold"
                  onClick={() => handleSetItem("verify_identity")}
                  style={{
                    fontWeight: activeItem === "verify_identity" && "bold",
                  }}
                >
                  Verify identity
                </span>
              </div>
            </div>
          </div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="">
          {activeItem === "add_product" && <AddProduct />}
          {activeItem === "show_orders" && <ShowOrders />}
          {activeItem === "change_password" && <ChangePass />}
          {activeItem === "verify_identity" && <VerifyIdentity />}
        </div>
      </div>
    </SideBar>
  );
};

export default Shop;
