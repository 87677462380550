export const categories = [
    "Boissons",
    "Petit-déjeuner",
    "Riz",
    "Huile",
    "Alimentaire",
    "Fruits-viandes",
    "Nettoyage",
    "Produits-bébé",
    "Épicerie salée",
    "Produits locaux",
    "Divers produits",
    "Charcuterie",
  ];