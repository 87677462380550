import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import {
  HiOutlineCube,
  HiOutlineDatabase,
  HiOutlineChevronDown,
} from "react-icons/hi";
import { CiSearch } from "react-icons/ci";
import {
  MdOutlineRadioButtonChecked,
  MdOutlineRadioButtonUnchecked,
} from "react-icons/md";
import { BsCheckSquareFill, BsSquare } from "react-icons/bs";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
// import AccountDofusProduct from "./AccountDofusProduct";
import { testProduct } from "../utils/productContainer";
import { Ri24HoursFill, RiRefreshLine } from "react-icons/ri";
import { IoFlashOutline, IoShieldCheckmarkOutline } from "react-icons/io5";
import { AiOutlineLike } from "react-icons/ai";
import AccountDofusProduct from "../pages/AccountDofusProduct";
import { getProducts, paginationTabs } from "../utils/igvoriginaldata";

import noAvailable from "../assets/no-available.jpg";
import { useSelector } from "react-redux";

const IgvProduct = () => {
  const { title, name } = useParams();
  const searchchedProducts = getProducts(title, name);
  const { language } = useSelector((state) => state.language);
  //   console.log(searchchedProducts);
  const [products, setProducts] = useState(searchchedProducts?.products);
  const [toggleFilterProduct, setToogleFilterProduct] = useState(true);
  const [toggleWarrantyProduct, setToogleWarrantyProduct] = useState(true);
  const [checkOver, setCheckOver] = useState("");
  const [checkClicked, setCheckClicked] = useState("");
  const [checkOverWarranty, setCheckOverWarranty] = useState("");
  const [checkClickedWarranty, setCheckClickedWarranty] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [activePagination, setActivePagination] = useState(1);
  const [pageStep, setPageStep] = useState(10);
  const [getItemsPrices, setGetItemsPrices] = useState([]);
  const [priceFiltered, setPriceFiltered] = useState("");
  const [warrantyFiltered, setWarrantyFiltered] = useState("");

  const [singleWarrantyFilter, setSingleWarrantyFilter] = useState([
    "1 Days Warranty",
    "7 Days Warranty",
    "14 Days Warranty",
    "15 Days Warranty",
    "30 Days Warranty",
  ]);

  useEffect(() => {
    const searchchedProducts = getProducts(title, name);
    setProducts(searchchedProducts?.products);
  }, [title, name]);

  const [priceStringFilter, setPriceStringFilter] = useState([
    {
      id: 0,
      titleFr: "Meilleur correspondance",
      titleEn: "Best match",
    },
    {
      id: 1,
      titleFr: "Prix le plus bas en premier",
      titleEn: "Lowest price first",
    },
    {
      id: 2,
      titleFr: "Prix le plus élevé en premier",
      titleEn: "Highest price first",
    },
    {
      id: 3,
      titleFr: "Annonces les plus recentes",
      titleEn: "Most recent announcements",
    },
  ]);

  const total = Math.ceil(searchchedProducts?.products?.length / 10);
  let allPages = searchchedProducts?.products?.length || 0;
  const pagination = paginationTabs(activePagination, total);

  const checkIfPriceIsAvailable = (chaine) => {
    let price;
    const regex = /[-+]?[0-9]*\.?[0-9]+/g;
    if (regex.test(chaine)) {
      price = chaine.match(regex)[0];
    } else {
      price = Math.floor(Math.random() * 100);
    }
    return Number(price);
  };

  useEffect(() => {
    let itemsPrices = products?.map((product) => {
      return {
        id: product.id,
        price: checkIfPriceIsAvailable(product.price),
        currency: product.currency,
      };
    });
    setGetItemsPrices(itemsPrices);
  }, []);

  //   console.log(getItemsPrices);

  //   console.log("Nombre de page: " + pageNumber);

  const handleChangePagination = (paginate) => {
    setActivePagination(paginate);
    setPageStep((prevPageStep) => {
      if (allPages - paginate * 10 >= 0) {
        return paginate * 10;
      } else {
        return prevPageStep + (allPages - prevPageStep);
      }
    });
  };

  const handlePaginateLeft = () => {
    if (activePagination > 1)
      setActivePagination((prevActivePagination) => prevActivePagination - 1);
    setPageStep((prevPageStep) => prevPageStep - 10);
  };
  const handlePaginateRight = () => {
    if (activePagination < total) {
      setActivePagination((prevActivePagination) => prevActivePagination + 1);
      setPageStep((prevPageStep) => {
        if (allPages - activePagination * 10 >= 0) {
          return prevPageStep + 8;
        } else {
          return prevPageStep + (allPages - prevPageStep);
        }
      });
    }
  };

  useEffect(() => {
    setProducts(
      products?.filter((product) => {
        if (!warrantyFiltered) {
          return true;
        } else {
          return product?.warranty
            ?.toLowerCase()
            .includes(warrantyFiltered?.toLowerCase());
        }
      })
    );
  }, [warrantyFiltered]);

  useEffect(() => {
    let price = Number(priceFiltered);
    if (price === 0) {
      if (products) {
        setProducts([...products]);
      }
    }
    if (price === 1) {
      if (products) {
        setProducts(
          [...products]?.sort(
            (a, b) =>
              checkIfPriceIsAvailable(a.price) -
              checkIfPriceIsAvailable(b.price)
          )
        );
      }
    }
    if (price === 2) {
      if (products) {
        setProducts(
          [...products]?.sort(
            (a, b) =>
              checkIfPriceIsAvailable(b.price) -
              checkIfPriceIsAvailable(a.price)
          )
        );
      }
    }
    if (price === 2) {
      if (products) {
        setProducts([...products]?.sort(() => Math.random() - 0.5));
      }
    }
  }, [priceFiltered]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    params.set("page", "1");
    const previousSearchTerm = params.get("query");
    if (searchTerm !== previousSearchTerm) {
      if (searchTerm) {
        params.set("query", searchTerm.toString());
      } else {
        params.delete("query");
      }
      const queryString = params.toString();
      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}?${queryString}`
      );
    }
  }, [searchTerm]);

  // useEffect(() => {
  //   const params = new URLSearchParams();
  //   if (activePagination) {
  //     params.set("page", activePagination);
  //   } else {
  //     params.delete("page");
  //   }
  //   const pageString = params.toString();
  //   window.history.replaceState(
  //     {},
  //     "",
  //     `${window.location.pathname}?${pageString}`
  //   );
  // }, [activePagination]);

  const checDashIncludes = (chaine) => {
    if (chaine.includes("-")) {
      return chaine.split("-").join(" ");
    } else {
      return chaine;
    }
  };

  return (
    <div className="flex items-center justify-center mt-2 xcustom:mt-[120px] mx-2 xcustom:mx-8 select-none">
      <div className="flex items-start flex-col gap-4">
        <div className="flex flex-col gap-1 items-start mx-2 bsm:mx-0">
          <span className="text-red-400 text-xs">
            <Link to="/">Home</Link> / {`${title} ${name}`}
          </span>
          <span className="text-lg">{checDashIncludes(title)}</span>
        </div>
        {/*  */}
        {/*  */}
        <div className="flex flex-col items-start mx-2 bsm:mx-0">
          <span className="text-sm">
            {" "}
            {language === "francais" ? "Acheter des" : "Buy some"} {name}{" "}
            {checDashIncludes(title)} -{" "}
            {language === "francais" ? "Marché" : "Market"}{" "}
            {checDashIncludes(title)}{" "}
          </span>
          <span className="text-sm">
            {language === "francais" ? "Acheter" : "Buy"}{" "}
            {`${checDashIncludes(title)} ${name}`}.{" "}
            {`${checDashIncludes(title)} ${name}`}{" "}
            {language === "francais"
              ? `à un prix avantageux !
            Assistance clientèle 24/7. Livraison sécurisée et rapide.`
              : `at a great price!
            24/7 customer support. Secure and fast delivery.`}
          </span>
        </div>
        <div className="flex items-start space-x-1 mt-0 xcustom:-mt-4 mx-2 bsm:mx-0">
          <span className="text-base font-bold text-red-600">NB:</span>
          <span className="text-sm">
            {language === "francais"
              ? "Certains items, comptes ne sont pas encore disponible et les prix affichés sont temporaires et ne sont pas corrects"
              : "Some items and accounts are not yet available and the prices displayed are temporary and are not correct"}
          </span>
        </div>
        <div className="hidden xcustom:flex items-center justify-between w-[320px] bsm:w-full select-none my-1 bg-red-100 rounded-[20px] mx-2 bsm:mx-0">
          <div className="flex items-center gap-2">
            <select
              name="warranty"
              id="warranty"
              className="text-xs bsm:text-sm px-2 py-2 bg-red-400 text-white rounded-tl-[20px] rounded-bl-[20px] outline-none"
              onChange={(e) => setWarrantyFiltered(e.target.value)}
            >
              <option value="" disabled selected className="text-white">
                {`${checDashIncludes(title)} ${name}`}
              </option>
              {singleWarrantyFilter.map((warranty, index) => (
                <option key={index} value={warranty}>
                  {warranty}
                </option>
              ))}
            </select>
            <select
              name="price"
              id="price"
              className="text-xs  bsm:text-sm p-2 bg-red-100 rounded-tl-[20px] rounded-bl-[20px] outline-none"
              style={{
                maxWidth: language === "francais" ? "195px" : "120px",
              }}
              onChange={(e) => setPriceFiltered(e.target.value)}
            >
              {priceStringFilter?.map((item, index) => (
                <option key={index} value={item.id} className="p-2">
                  {language === "francais" ? item.titleFr : item.titleEn}
                </option>
              ))}
            </select>
          </div>
          <div className="relative hidden scustom:flex items-center justify-center mr-1">
            <input
              type="text"
              name="search"
              id="search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search in-game items"
              className="text-sm border-gray-400 p-[6px] w-full search:w-[300px] rounded outline-none rounded-tr-[20px] rounded-br-[20px]"
            />
            <span className="absolute flex items-center justify-center p-2 transition duration-300 ease-in-out rounded-full cursor-pointer bg-red-600 hover:bg-red-500 text-white top-[3%] left-[89%]">
              <CiSearch className="text-[16px]" />
            </span>
          </div>
        </div>
        {/* <div className="relative w-[300px] bsm:w-full mx-2 bsm:mx-0 flex scustom:hidden items-center bg-red-100 my-1 justify-center mr-1 rounded-[20px]">
          <input
            type="text"
            name="search"
            id="search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search in-game items"
            className="text-sm border rounded-[20px] border-red-100 p-[6px] w-full search:w-[300px] outline-none rounded-tr-[20px] rounded-br-[20px]"
          />
          <span className="absolute flex items-center justify-center p-2 transition duration-300 ease-in-out rounded-full cursor-pointer bg-red-600 hover:bg-red-500 text-white top-[3%] left-[89%]">
            <CiSearch className="text-[16px]" />
          </span>
        </div> */}
        {/*  */}
        {/*  */}

        <div className="flex items-start gap-8 mt-0">
          {(products?.length < 1 || !products) && (
            <div className="flex flex-col items-center ml-[60px] scustom:ml-[100px] custom:ml-[120px] rmlg:ml-[180px] cartcust:ml-[200px] cusmd:ml-[250px] loglg:ml-[300px] cusshe:ml-[320px] cuslg:ml-[350px] blg:ml-[380px] justify-center mx-auto gap-2 text-sm">
              <img
                className="flex mr-20 scustom:ml-0 w-60 h-60 rmlg:w-80 rmlg:h-80 object-cover"
                src={noAvailable}
                alt={`${title} ${name} pas disponible`}
              />
              <p className="flex flex-wrap">
                {language === "francais" ? "Le produit" : "The product"} {title}{" "}
                {name}{" "}
                {language === "francais"
                  ? "n'est pas disponible pour le moment"
                  : "is not available at the moment"}{" "}
              </p>
              <p className="flex flex-wrap">
                {language === "francais"
                  ? "Veuiller contacter notre service clientele pour plus d'informations"
                  : "Please contact our customer service for more information"}
              </p>{" "}
            </div>
          )}
          <div className="flex flex-col items-center justify-between flex-wrap gap-4">
            <div className="grid sm:mt-10 mt-2 lettexrxl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-10 xcustom:mx-auto p-0 w-full">
              {products
                ?.filter((product) => product.imageUrl)
                ?.filter((product) =>
                  product?.title
                    ?.toLowerCase()
                    .includes(searchTerm?.toLowerCase())
                )
                .slice(0, pageStep)
                ?.map((product) => (
                  <AccountDofusProduct
                    key={product?.id}
                    product={product}
                    title={title}
                    name={name}
                  />
                ))}
            </div>

            <div className="flex items-center gap-2 mt-4 mb-8">
              {total > 1 && (
                <span
                  className="text-xl bg-red-500 text-white p-[9px] rounded cursor-pointer transition duration-500 ease-in-out hover:bg-red-600"
                  onClick={handlePaginateLeft}
                >
                  <BiChevronLeft />
                </span>
              )}

              <div className="flex items-center gap-2">
                {pagination?.map((pagination, index) => (
                  <span
                    onClick={() => handleChangePagination(pagination)}
                    key={index}
                    className={`flex items-center w-10 justify-center text-sm  border p-2 rounded cursor-pointer transition duration-500 ease-in-out hover:bg-red-500 hover:text-white ${
                      pagination === activePagination
                        ? "bg-red-500 text-white hover:bg-red-600"
                        : "text-gray-400"
                    }`}
                  >
                    {pagination}
                  </span>
                ))}
              </div>
              {total > 1 && (
                <span
                  className="text-xl bg-red-500 text-white p-[9px] rounded cursor-pointer transition duration-500 ease-in-out hover:bg-red-600"
                  onClick={handlePaginateRight}
                >
                  <BiChevronRight />
                </span>
              )}
            </div>
          </div>
        </div>

        {/*  */}
        {/*  */}

        {/* <div className="flex w-full items-center justify-between flex-wrap my-32">
          <div className="flex flex-col items-start gap-2">
            <h2 className="text-base font-semibold">
              {language === "francais" ? "Groupe iBendouma" : "iBendouma Group"}
            </h2>
            <span className="text-sm font-normal cursor-pointer transition duration-500 ease-in-out hover:text-red-600">
              {language === "francais" ? "Qui sommes-nous?" : "What about us?"}
            </span>
            <span className="text-sm font-normal cursor-pointer transition duration-500 ease-in-out hover:text-red-600">
              {language === "francais" ? "Être vendeur" : "Be a seller"}
            </span>
            <span className="text-sm font-normal cursor-pointer transition duration-500 ease-in-out hover:text-red-600">
              {language === "francais"
                ? "Contacter iBendouma"
                : "Contact iBendouma"}
            </span>
          </div>
          <div className="flex flex-col items-start gap-2">
            <h2 className="text-base font-semibold">
              {language === "francais" ? "Soutien" : "Support"}
            </h2>
            <span className="text-sm font-normal cursor-pointer transition duration-500 ease-in-out hover:text-red-600">
              {language === "francais" ? "Centre d'aide" : "Help Center"}
            </span>
            <span className="text-sm font-normal cursor-pointer transition duration-500 ease-in-out hover:text-red-600">
              {language === "francais" ? "Comment acheter?" : "How to buy?"}
            </span>
            <span className="text-sm font-normal cursor-pointer transition duration-500 ease-in-out hover:text-red-600">
              {language === "francais" ? "Comment vendre?" : "How to sell?"}
            </span>
          </div>
          <div className="flex flex-col items-start gap-2">
            <h2 className="text-base font-semibold">
              {language === "francais" ? "Commerce" : "Trade"}
            </h2>
            <span className="text-sm font-normal cursor-pointer transition duration-500 ease-in-out hover:text-red-600">
              {language === "francais"
                ? "Programme d'affiliation"
                : "Affiliate program"}
            </span>
            <span className="text-sm font-normal cursor-pointer transition duration-500 ease-in-out hover:text-red-600">
              {language === "francais"
                ? "Partenariat marketing"
                : "Marketing partnership"}
            </span>
            <span className="text-sm font-normal cursor-pointer transition duration-500 ease-in-out hover:text-red-600">
              Game Value Calculator
            </span>
          </div>
          <div className="flex flex-col items-start gap-2">
            <h2 className="text-base font-semibold">
              {language === "francais" ? "Régles" : "Rules"}
            </h2>
            <span className="text-sm font-normal cursor-pointer transition duration-500 ease-in-out hover:text-red-600">
              {language === "francais"
                ? "Termes et conditions"
                : "Terms and conditions"}
            </span>
            <span className="text-sm font-normal cursor-pointer transition duration-500 ease-in-out hover:text-red-600">
              {language === "francais"
                ? "Politique de confidentialité"
                : "Privacy Policy"}
            </span>
            <span className="text-sm font-normal cursor-pointer transition duration-500 ease-in-out hover:text-red-600">
              {language === "francais" ? "Permissions" : "Permissions"}
            </span>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default IgvProduct;
