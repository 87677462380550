import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import {
  HiOutlineCube,
  HiOutlineDatabase,
  HiOutlineChevronDown,
} from "react-icons/hi";
import { CiSearch } from "react-icons/ci";
import {
  MdOutlineRadioButtonChecked,
  MdOutlineRadioButtonUnchecked,
} from "react-icons/md";
import { BsCheckSquareFill, BsSquare } from "react-icons/bs";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import Product from "./Product";
import { testProduct } from "../utils/productContainer";
import { Ri24HoursFill, RiRefreshLine } from "react-icons/ri";
import { IoFlashOutline, IoShieldCheckmarkOutline } from "react-icons/io5";
import { AiOutlineLike } from "react-icons/ai";

const Announcement = () => {
  const [toggleFilterProduct, setToogleFilterProduct] = useState(true);
  const [toggleWarrantyProduct, setToogleWarrantyProduct] = useState(true);
  const [checkOver, setCheckOver] = useState("");
  const [checkClicked, setCheckClicked] = useState("");
  const [checkOverWarranty, setCheckOverWarranty] = useState("");
  const [checkClickedWarranty, setCheckClickedWarranty] = useState("");
  const [products, setProducts] = useState(testProduct);
  const [activePagination, setActivePagination] = useState(1);
  const [searchParam, setSearchParam] = useState(1);
  const { category } = useParams();
  const { search } = useLocation();
  const navigate = useNavigate();
  const searchKey = search.split("=")[1];

  const [paginationBottom, setPaginationBottom] = useState([
    1, 2, 3, 4, 5, 7, 8, 9, 10,
  ]);

  const handleToggleProductFilter = () => {
    setToogleFilterProduct((prevState) => !prevState);
  };

  const handleToggleWarrantyFilter = () => {
    setToogleWarrantyProduct((prevState) => !prevState);
  };

  const handleChangePagination = (pagination) => {
    setActivePagination(pagination);
    setSearchParam(pagination);
    navigate(`/announcement/${category}?page=${pagination}`);
  };

  /////////////////// FOR PRODUCT FILTER SECTION //////////////////////

  const handleDisplayCheckBox = (singleProduct) => {
    setCheckOver(singleProduct);
  };

  const handleRemoveOver = () => {
    setCheckOver("");
  };

  const handleDisplayCheckBoxClicked = (item) => {
    setCheckClicked(item);
  };

  /////////////// FOR WARRANTY SECTION ///////////////////////////////

  const handleDisplayOverWarranty = (singleProduct) => {
    setCheckOverWarranty(singleProduct);
  };

  const handleRemoveWarranty = () => {
    setCheckOverWarranty("");
  };

  const handleDisplayCheckBoxWarranty = (item) => {
    setCheckClickedWarranty(item);
  };

  useEffect(() => {
    setSearchParam(searchKey);
  }, [search]);

  return (
    <div className="flex items-center justify-center mt-36 mx-4 p-4">
      <div className="flex items-start flex-col gap-8">
        <div className="flex flex-col gap-2 items-start">
          <h2 className="text-2xl font-bold">
            Dofus Kamas - Achat Retro kamas - Kamas Dofus - iBendouma
          </h2>
          <p className="text-base font-normal">
            Ibendouma vous offre plusieurs avantages pour améliorer rapidement
            votre expérience de jeu. Tout d'abord, vous pouvez acheter des Kamas
            Dofus Retro à des prix abordables. De plus, la livraison est rapide
            et le stock disponible est énorme. Vous bénéficiez également d'une
            sécurité à 100% pour vos transactions. Enfin, le service en ligne
            est disponible 24h/7j pour répondre à vos besoins.
          </p>
        </div>
        {/*  */}
        {/*  */}
        <div className="flex items-center gap-4 border-b-[3px] border-red-600 w-full">
          <div className="flex items-center gap-2 border p-2 cursor-pointer select-none bg-red-600 text-white">
            <span>
              <HiOutlineDatabase className="text-[38px] text-gray-500" />
            </span>
            <div className="flex flex-col items-start">
              <span className="text-base font-medium">Dofus Kamas</span>
              <span className="text-sm font-normal">76 Offres</span>
            </div>
          </div>

          {/*  */}
          {/*  */}

          <div className="flex items-center gap-1 border p-2 cursor-pointer select-none">
            <span>
              <HiOutlineCube className="text-[38px] text-gray-500" />
            </span>
            <div className="flex flex-col items-start">
              <span className="text-base font-medium">Dofus Articles</span>
              <span className="text-sm font-normal">1236 Offres</span>
            </div>
          </div>
        </div>
        <div className="w-full flex items-start justify-around select-none my-2">
          <div className="relative">
            <input
              type="text"
              name="search"
              id="search"
              placeholder="Recherchez le vendeur que vous voulez"
              className="text-base border-gray-400 border p-2 w-[350px] rounded outline-none"
            />
            <span className="absolute flex items-center justify-center p-2 transition duration-500 ease-in-out rounded cursor-pointer bg-red-600 hover:bg-red-500 text-white top-[3%] left-[89%]">
              <CiSearch className="text-[24px]" />
            </span>
          </div>
          <div className="flex items-start gap-2">
            <span className="text-sm text-gray-500 mt-[10px]">Trié selon:</span>
            <div className="flex flex-col items-end gap-2">
              <select
                name="sorted"
                id="sorted"
                className="text-base border-gray-400 border p-2 w-[350px] rounded outline-none"
              >
                <option value="Pertinence">Pertinence</option>
                <option value="Annonces récentes">Annonces récentes</option>
              </select>
              <div className="flex items-center gap-1 text-sm text-gray-500">
                <span className="">Offres:</span>
                <span>765</span>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        {/*  */}

        <div className="flex items-start gap-8">
          <div className="flex flex-col items-start max-w-[250px]">
            {/*  */}
            {/*  */}
            <div></div>
            <div></div>
          </div>
          <div className="flex flex-col items-center justify-between flex-wrap gap-4">
            <div className="grid sm:mt-10 mt-2 xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-10 mx-auto p-0 w-full">
              {products?.map((product) => (
                <Product key={product.id} product={product} />
              ))}
            </div>
            <div className="flex items-center gap-2 mt-4">
              <span className="text-xl bg-red-500 text-white p-[9px] rounded cursor-pointer transition duration-500 ease-in-out hover:bg-red-600">
                <BiChevronLeft />
              </span>
              <div className="flex items-center gap-2">
                {paginationBottom?.map((pagination) => (
                  <span
                    onClick={() => handleChangePagination(pagination)}
                    className={`flex items-center w-10 justify-center text-sm  border p-2 rounded cursor-pointer transition duration-500 ease-in-out hover:bg-red-500 hover:text-white ${
                      pagination === activePagination
                        ? "bg-red-500 text-white hover:bg-red-600"
                        : "text-gray-400"
                    }`}
                  >
                    {pagination}
                  </span>
                ))}
              </div>
              <span className="text-xl bg-red-500 text-white p-[9px] rounded cursor-pointer transition duration-500 ease-in-out hover:bg-red-600">
                <BiChevronRight />
              </span>
            </div>
          </div>
        </div>

        {/*  */}
        {/*  */}

        <div></div>

        {/*  */}
        {/*  */}

        {/*  */}
        {/*  */}

        <div className="flex w-full items-center justify-between flex-wrap mt-20">
          <div className="flex flex-col items-start gap-2">
            <h2 className="text-lg font-semibold">Groupe iBendouma</h2>
            <span className="text-base font-normal cursor-pointer transition duration-500 ease-in-out hover:text-red-600">
              Qui sommes-nous?
            </span>
            <span className="text-base font-normal cursor-pointer transition duration-500 ease-in-out hover:text-red-600">
              Être vendeur
            </span>
            <span className="text-base font-normal cursor-pointer transition duration-500 ease-in-out hover:text-red-600">
              Contacter iBendouma
            </span>
          </div>
          <div className="flex flex-col items-start gap-2">
            <h2 className="text-lg font-semibold">Soutien</h2>
            <span className="text-base font-normal cursor-pointer transition duration-500 ease-in-out hover:text-red-600">
              Centre d'aide
            </span>
            <span className="text-base font-normal cursor-pointer transition duration-500 ease-in-out hover:text-red-600">
              Comment acheter?
            </span>
            <span className="text-base font-normal cursor-pointer transition duration-500 ease-in-out hover:text-red-600">
              Comment vendre?
            </span>
          </div>
          <div className="flex flex-col items-start gap-2">
            <h2 className="text-lg font-semibold">Commerce</h2>
            <span className="text-base font-normal cursor-pointer transition duration-500 ease-in-out hover:text-red-600">
              Programme d'affiliation
            </span>
            <span className="text-base font-normal cursor-pointer transition duration-500 ease-in-out hover:text-red-600">
              Partenariat marketing
            </span>
            <span className="text-base font-normal cursor-pointer transition duration-500 ease-in-out hover:text-red-600">
              Game Value Calculator
            </span>
          </div>
          <div className="flex flex-col items-start gap-2">
            <h2 className="text-lg font-semibold">Régles</h2>
            <span className="text-base font-normal cursor-pointer transition duration-500 ease-in-out hover:text-red-600">
              Termes et conditions
            </span>
            <span className="text-base font-normal cursor-pointer transition duration-500 ease-in-out hover:text-red-600">
              Politique de confidentialité
            </span>
            <span className="text-base font-normal cursor-pointer transition duration-500 ease-in-out hover:text-red-600">
              Permissions
            </span>
          </div>
        </div>

        {/*  */}
        {/*  */}
        <div></div>
      </div>
    </div>
  );
};

export default Announcement;
