import pic from "../assets/products/compte.png";

export const data = [
  /////////////////// LETTER A //////////////////////////

  {
    id: 88705,
    letterStart: "A",
    details: [
      { id: 857051, name: "Atlantica Online", categories: ["Or"] },
      {
        id: 857052,
        letterStart: "A",
        name: "APEX Legends",
        categories: [
          {
            id: "unlxdoXCWDtjAzIqLjZtOFWAHQeJBec",
            title: "Accounts",
            link: "https://www.igv.com/fr/albion-online/accounts",
          },
          {
            id: "ILvZ5fvuNDY9kgJwcgxUiA2hg4V5Coq",
            title: "Items",
            link: "https://www.igv.com/fr/albion-online/items",
          },
          {
            id: "RwPStXhibpdhtC1aBsQAQslw3mJ7vK9",
            title: "Silver",
            link: "https://www.igv.com/fr/albion-online/silver",
          },
        ],
      },
      {
        id: 847053,
        letterStart: "A",
        name: "APEX Legends Mobile",
        categories: ["Comptes"],
      },
      {
        id: 837054,
        letterStart: "A",
        name: "Aion Classic",
        categories: ["Kinah"],
      },
      {
        id: 818055,
        letterStart: "A",
        name: "Amazon Prime",
        categories: ["Membership"],
      },
      {
        id: 816056,
        letterStart: "A",
        name: "Albion Online",
        categories: ["Silver", "Articles"],
      },
      {
        id: 814057,
        letterStart: "A",
        name: "Animal Crossing:New Horizons",
        categories: ["Bells", "Articles"],
      },
      {
        id: 813058,
        letterStart: "A",
        name: "Apple One",
        categories: ["Articles"],
      },
      {
        id: 813159,
        letterStart: "A",
        name: "ArcheAge",
        categories: ["Or"],
      },
      {
        id: 813261,
        letterStart: "A",
        name: "ArcheAge Unchained",
        categories: ["Or"],
      },
      {
        id: 813362,
        letterStart: "A",
        name: "ARK:Survival Evolved",
        categories: ["Articles"],
      },
      {
        id: 813463,
        letterStart: "A",
        name: "AFK Arena",
        categories: ["Comptes"],
      },
      {
        id: 813564,
        letterStart: "A",
        name: "Arena of Valor",
        categories: ["Voucher"],
      },
    ],
  },

  /////////////////// LETTER B //////////////////////////

  {
    id: 81365,
    letterStart: "B",
    details: [
      {
        id: 413511,
        letterStart: "B",
        name: "Brawl Stars",
        categories: ["Comptes"],
      },
      {
        id: 413512,
        letterStart: "B",
        name: "Broken Ranks",
        categories: ["Comptes"],
      },
      {
        id: 413513,
        letterStart: "B",
        name: "Brown Dust",
        categories: ["Comptes"],
      },
      {
        id: 413514,
        letterStart: "B",
        name: "Blade and Soul",
        categories: ["Or"],
      },
      {
        id: 413515,
        letterStart: "B",
        name: "Bless Unleashed",
        categories: ["StarSeeds"],
      },
      {
        id: 413516,
        letterStart: "B",
        name: "Black Desert Online",
        categories: ["Silver"],
      },
      {
        id: 413517,
        letterStart: "B",
        name: "Borderlands 3",
        categories: ["Articles"],
      },
      {
        id: 413517,
        letterStart: "B",
        name: "Back 4 Blood",
        categories: ["Comptes"],
      },
      {
        id: 413518,
        letterStart: "B",
        name: "Badlanders",
        categories: ["Articles"],
      },
      {
        id: 413518,
        letterStart: "B",
        name: "Battlefield",
        categories: ["Comptes"],
      },
    ],
  },

  /////////////////// LETTER C //////////////////////////

  {
    id: 88395,
    letterStart: "C",
    details: [
      {
        id: 413519,
        letterStart: "C",
        name: "Call of Duty:Modern Warfare",
        categories: ["Comptes"],
      },
      {
        id: 413520,
        letterStart: "C",
        name: "Clash of Clans",
        categories: ["Comptes"],
      },
      {
        id: 413521,
        letterStart: "C",
        name: "Clash Royale",
        categories: ["Comptes"],
      },
      {
        id: 413522,
        letterStart: "C",
        name: "CS:GO",
        categories: ["Articles", "Comptes"],
      },
      {
        id: 413523,
        letterStart: "C",
        name: "Castle Clash",
        categories: ["Comptes"],
      },
      {
        id: 413524,
        letterStart: "C",
        name: "Crossout",
        categories: ["Credits", "Comptes"],
      },
      {
        id: 413525,
        letterStart: "C",
        name: "Cabal Online",
        categories: ["Alz"],
      },
      {
        id: 413526,
        letterStart: "C",
        name: "Chimeraland",
        categories: ["Currency"],
      },
      {
        id: 413526,
        letterStart: "C",
        name: "Crunchyroll",
        categories: ["Crunchyroll Premium"],
      },
    ],
  },

  {
    id: 88315,
    letterStart: "D",
    details: [
      {
        id: 413519,
        letterStart: "D",
        name: "Dofus",
        categories: ["Kamas", "Comptes", "Articles"],
      },
      {
        id: 413520,
        letterStart: "D",
        name: "Dofus Touch",
        categories: ["Kamas", "Comptes", "Articles"],
      },
      {
        id: 413521,
        letterStart: "D",
        name: "Dofus Retro",
        categories: ["Kamas", "Comptes", "Articles"],
      },
    ],
  },
];

export const mostSent = [
  /////////////////////// MOST SENT //////////////////////////
  {
    id: 65789,
    letterStart: "F",
    name: "FIFA 23",
    categories: ["Credits", "Comptes"],
  },
  {
    id: 89765,
    letterStart: "D",
    name: "Dofus",
    categories: ["Kamas", "Comptes", "Articles"],
  },
  {
    id: 89365,
    letterStart: "D",
    name: "Dofus Touch",
    categories: ["Kamas", "Comptes", "Articles"],
  },
  {
    id: 49765,
    letterStart: "D",
    name: "Dofus Retro",
    categories: ["Kamas", "Comptes", "Articles"],
  },
  {
    id: 30565,
    letterStart: "L",
    name: "Lost Ark",
    categories: ["Or", "Comptes", "Articles"],
  },
  {
    id: 28765,
    letterStart: "L",
    name: "League of Legends",
    categories: ["Comptes", "Gamepal"],
  },
  {
    id: 28765,
    letterStart: "R",
    name: "Rocket League",
    categories: ["Articles", "Credits"],
  },
  {
    id: 16305,
    letterStart: "W",
    name: "WOW Classic",
    categories: [
      "Or",
      "WotLKOr",
      "Classic",
      "Era/SOM",
      "Comptes",
      "Articles",
      "Boosting",
      "Services",
    ],
  },
  {
    id: 55505,
    letterStart: "V",
    name: "Valorant",
    categories: ["ComptesGift", "Card", "Boosting and Gamepal"],
  },

  {
    id: 66605,
    letterStart: "C",
    name: "Clash of Clans",
    categories: ["Comptes"],
  },
  {
    id: 77705,
    letterStart: "D",
    name: "Dofus Touch",
    categories: ["Kamas", "Articles", "Comptes"],
  },
  {
    id: 88805,
    letterStart: "F",
    name: "Fortinte",
    categories: ["Comptes", "Articles", "V bucks"],
  },
];

//////////// Données pour les commentaires ////////////////

export const commentData = [
  {
    id: 6578,
    firstname: "Mario",
    lastname: "Gomez",
    message: "Livraison rapide bravo",
    stars: 5,
    date: "1h ago",
  },
  {
    id: 6571,
    firstname: "Aymen",
    lastname: "Chahir",
    message: "Service d'assistance impeccable",
    stars: 5,
    date: "1 year ago",
  },
  {
    id: 6572,
    firstname: "Ilyass",
    lastname: "Bendouma",
    message: "Service d'assistance et qualité des produits impeccable",
    stars: 5,
    date: "2 months ago",
  },
  {
    id: 6573,
    firstname: "Yassine",
    lastname: "Diop",
    message: "Facile à comprendre",
    stars: 5,
    date: "2h ago",
  },
  {
    id: 6574,
    firstname: "John",
    lastname: "Doe",
    message: "I am very satisfied",
    stars: 5,
    date: "5h ago",
  },
  {
    id: 6575,
    firstname: "Hiba",
    lastname: "Zinaoui",
    message: "Satisfait du produit",
    stars: 5,
    date: "1h ago",
  },
  {
    id: 6576,
    firstname: "Manuella",
    lastname: "Romano",
    message: "Excellent produit",
    stars: 5,
    date: "10h ago",
  },
  {
    id: 6577,
    firstname: "Mouhamed",
    lastname: "Safir",
    message: "Satisfait de la livraison",
    stars: 5,
    date: "1 week ago",
  },
];

///////////////////////// Dofus Kamas /////////////////////////////////

export const dofusKamas = [];

///////////////////////// Dofus Retro ///////////////////////////////////

export const dofusRetro = [];

//////////////////////// Dofus Touch /////////////////////////////////////

export const dofusTouch = [];

/////////////////////// SELLER INFORMATIONS //////////////////////////////////

export const sellerInfos = [
  {
    firsname: "Johnson",
    stars: 4.5,
    deleiverySpeed: 5,
    price: 23.87,
  },
  {
    firsname: "Mounir",
    stars: 4.5,
    deleiverySpeed: 15,
    price: 24.17,
  },
  {
    firsname: "Yassine",
    stars: 4.5,
    deleiverySpeed: 10,
    price: 24.57,
  },
  {
    firsname: "Amir",
    stars: 4.5,
    deleiverySpeed: 5,
    price: 25.16,
  },
  {
    firsname: "Mouhamed",
    stars: 4.5,
    deleiverySpeed: 5,
    price: 25.7,
  },
  {
    firsname: "Ilyass",
    stars: 4.5,
    deleiverySpeed: 5,
    price: 26.5,
  },
  {
    firsname: "Ibendouma",
    stars: 4.5,
    deleiverySpeed: 5,
    price: 26.75,
  },
  {
    firsname: "Tariq",
    stars: 4.5,
    deleiverySpeed: 5,
    price: 26.8,
  },
  {
    firsname: "Tarmin",
    stars: 4.5,
    deleiverySpeed: 5,
    price: 26.95,
  },
  {
    firsname: "Aymen",
    stars: 4.5,
    deleiverySpeed: 5,
    price: 27.1,
  },
];

////////////////////// PRODUCTS TESTS /////////////////////////////////////

export const testProduct = [
  {
    id: 2345,
    name: "Brawl Stars Hot Brawler",
    picture: pic,
    warranty: "7 jours de garantie",
    serveur: "supercell ID",
    stars: 4.9,
    price: "6.70",
  },
  {
    id: 1345,
    name: "Brawl Stars Hot Brawler",
    picture: pic,
    warranty: "7 jours de garantie",
    serveur: "supercell ID",
    stars: 4.9,
    price: "34.00",
  },
  {
    id: 4345,
    name: "Brawl Stars Hot Brawler",
    picture: pic,
    warranty: "7 jours de garantie",
    serveur: "supercell ID",
    stars: 4.8,
    price: "25.50",
  },
  {
    id: 6345,
    name: "Brawl Stars Hot Brawler",
    picture: pic,
    warranty: "7 jours de garantie",
    serveur: "supercell ID",
    stars: 4.8,
    price: "10.00",
  },
  {
    id: 2315,
    name: "Brawl Stars Hot Brawler",
    picture: pic,
    warranty: "7 jours de garantie",
    serveur: "supercell ID",
    stars: 4.8,
    price: "8.90",
  },
  {
    id: 2375,
    name: "Brawl Stars Hot Brawler",
    picture: pic,
    warranty: "7 jours de garantie",
    serveur: "supercell ID",
    stars: 4.8,
    price: "18.50",
  },
  {
    id: 2348,
    name: "Brawl Stars Hot Brawler",
    picture: pic,
    warranty: "7 jours de garantie",
    serveur: "supercell ID",
    stars: 4.7,
    price: "34.67",
  },
  {
    id: 2349,
    name: "Brawl Stars Hot Brawler",
    picture: pic,
    warranty: "7 jours de garantie",
    serveur: "supercell ID",
    stars: 4.7,
    price: "25.80",
  },
  {
    id: 1745,
    name: "Brawl Stars Hot Brawler",
    picture: pic,
    warranty: "7 jours de garantie",
    serveur: "supercell ID",
    stars: 4.7,
    price: "46.70",
  },
  {
    id: 1254,
    name: "Brawl Stars-58 Brawlers-23073 Trophies-4 Max Personnages",
    picture: pic,
    warranty: "7 jours de garantie",
    serveur: "supercell ID",
    stars: 4.6,
    price: "75.00",
  },
];

///////////////////////////////////////////////////////////////////////////
