import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import AOS from "aos";
import "aos/dist/aos.css";

import { Footer, Header, SignIn, SignUp, Cart } from "./components";
import SecurePayment from "./components/SecurePayment";
import HeaderPaymenPage from "./components/stepper/HeaderPaymenPage";
import Welcome from "./components/Welcome";
// import ADXPage from "./components/ADXPage";

import { Helmet } from "react-helmet";


// import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

import { useSelector } from "react-redux";
import {
  Home,
  BuyKamas,
  ProductDetails,
  Faqs,
  PrivacyPolicy,
  TermAndConditions,
  KamasPrice,
  Contact,
  PaymentPage,
} from "./pages";
import Profil from "./pages/Profil";
import CheckoutSuccess from "./components/CheckoutSuccess";
import PaymentUsdt from "./pages/PaymentUsdt";
import ResetPassword from "./components/ResetPassword";
import SideDrawer from "./components/SideDrawer";
import BackDrop from "./components/BackDrop";
import SubHeader from "./components/SubHeader";
import RembAndAnnul from "./components/RembAndAnnul";
import Livraison from "./components/Livraison";
import PaysafeCard from "./pages/PaysafeCard";
import LesDofus from "./pages/LesDofus";
import PaiementSuccess from "./pages/PaiementSuccess";
import CoinpalSuccessPage from "./pages/CoinpalSuccessPage";
import SubCat from "./components/SubCat";
import Hero from "./components/Hero";
import Separator from "./components/Separator";
import AccountPage from "./pages/AccountPage";
import Dashboard from "./screens/Dashboard/Dashboard";
import Orders from "./screens/Dashboard/Orders";
import UpdateProfile from "./screens/Dashboard/UpdateProfile";
import Password from "./screens/Dashboard/Password";
import PersonalInfos from "./screens/Dashboard/PersonalInfos";
import CheckoutPage from "./pages/CheckoutPage";
import DetailFif from "./components/DetailFif";
import HowtoBuy from "./components/HowtoBuy";
import Announcement from "./pages/Announcement";
import SellerPageDetail from "./pages/SellerPageDetail";
import Shop from "./pages/Shop";
import SellKamas from "./pages/SellKamas";
import Accountdofus from "./pages/Accountdofus";
import axios from "axios";
import IgvProduct from "./components/IgvProduct";
import MobileNavbar from "./components/MobileNavbar";
import SingleMobileHeadingPage from "./components/SingleMobileHeadingPage";
const App = () => {
  const location = useLocation();
  const { user } = useSelector((state) => state.user);
  const { language } = useSelector((state) => state.language);
  const [toggleArrowProfile, setToggleArrowProfile] = useState(false);
  const [sideToggle, setSideToggle] = useState(false);
  const [isViewProduct, setIsViewProduct] = useState(false);
  const [idPopupUser, setIdPopupUser] = useState("");
  const [truspilotData, setTrustpilotData] = useState([]);
  // console.log(truspilotData);

  const handleToggleArrow = (para) => (e) => {
    e.stopPropagation();
    if (para === "yes") {
      setToggleArrowProfile(false);
    } else {
      setToggleArrowProfile(true);
    }
  };

  AOS.init();

  const getData = async () => {
    const options = {
      method: "GET",
      url: "https://trustpilot4.p.rapidapi.com/",
      params: {
        domain: "ibendouma.com",
        page: "1",
      },
      headers: {
        "X-RapidAPI-Key": "454ccbe31bmsh7828de4155ab93ap195ad7jsn32ad77105be5",
        "X-RapidAPI-Host": "trustpilot4.p.rapidapi.com",
      },
    };

    try {
      const response = await axios.request(options);
      setTrustpilotData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
      <div className="bg-[#ffffff]" onClick={handleToggleArrow("yes")}>
        <div className="navHeadear">
          {location.pathname === "/paymentpage" && <HeaderPaymenPage />}
          {/* {location.pathname === "/register" && <Welcome />} */}
          {/* {location.pathname === "/login" && <Welcome />} */}

          {location.pathname === "/register" ||
            location.pathname === "/login" ||
            location.pathname === "/contact" ||
            location.pathname === "/resetpassword" ||
            location.pathname === "/paymentpage" || (
              <>
                <Header
                  toggleArrowProfile={toggleArrowProfile}
                  handleToggleArrow={handleToggleArrow}
                  click={() => setSideToggle(!sideToggle)}
                />
                <MobileNavbar
                  toggleArrowProfile={toggleArrowProfile}
                  handleToggleArrow={handleToggleArrow}
                  click={() => setSideToggle(!sideToggle)}
                />
              </>
            )}
          {/* {location.pathname === "/" && <Separator />} */}
          {location.pathname === "/" && <Hero />}
          <SubHeader
            click={() => setSideToggle(!sideToggle)}
            toggleArrowProfile={toggleArrowProfile}
            handleToggleArrow={handleToggleArrow}
          />
          <SideDrawer show={sideToggle} click={() => setSideToggle(false)} />
          <BackDrop show={sideToggle} click={() => setSideToggle(false)} />

          {/* {location.pathname === "/register" ||
          location.pathname === "/login" ||
          location.pathname === "/partner" ||
          location.pathname === "/paymentpage" || <Navbar />} */}
          <Helmet>
            <title>
              Ibendouma, buy Dofus Kamas, Dofus Touch and Dofus Retro
            </title>
            <meta
              name="description"
              content="Ibendouma will prepare you the stock you want as soon as possible and offering you the best price on the market. You can buy your Dofus, Dofus touch and Retro Dofus products from iben douma. The ibendouma team remains at your disposal."
            />
            <meta
              name="keywords"
              content="Buy Dofus, Dofus Touch, Dofus Retro"
            />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0"
            />
          </Helmet>
        </div>
        <div className="main" onClick={handleToggleArrow("yes")}>
          <Routes>
            <Route path="/" element={<Home truspilotData={truspilotData} />} />
            <Route path="/buykamas" element={<BuyKamas />} />
            <Route path="/dofus/:productId" element={<ProductDetails />} />
            <Route path="/login" element={<SignIn />} />
            <Route path="/register" element={<SignUp />} />
            <Route
              path="/profil"
              element={
                user?.person?._id ? (
                  <Dashboard
                    isViewProduct={isViewProduct}
                    setIsViewProduct={setIsViewProduct}
                    setIdPopupUser={setIdPopupUser}
                  />
                ) : (
                  <SignIn />
                )
              }
            />
            <Route path="/kamas-price" element={<KamasPrice />} />
            <Route path="/how-to-buy" element={<HowtoBuy />} />
            <Route path="/paysafecard" element={<PaysafeCard />} />
            <Route path="/cart" element={user?.user ? <Cart /> : <Home />} />
            <Route path="/dofus/:dofusId/:serverId" element={<PaymentPage />} />
            <Route path="/account/:accountId" element={<AccountPage />} />
            <Route path="/faqs" element={<Faqs />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/les-dofus" element={<LesDofus />} />
            <Route path="/announcement/:category" element={<Announcement />} />
            <Route
              path="/seller/:productName/:sellername/:productId"
              element={<SellerPageDetail />}
            />
            <Route path="/payment-success" element={<PaiementSuccess />} />
            <Route path="/checkout-success" element={<CheckoutSuccess />} />
            <Route
              path="/payment-coinpal-success"
              element={<CoinpalSuccessPage />}
            />
            {/* <Route path="/ads.txt" element={<ADXPage />} /> */}
            <Route path="/checkoutcancel" element={<Cart />} />
            <Route
              path="/update-profil"
              element={user?.person?._id ? <UpdateProfile /> : <SignIn />}
            />
            {/* <Route
              path="/myinbox"
              element={user?.person?._id ? <Inbox /> : <SignIn />}
            /> */}
            <Route
              path="/account-dofus&page=:pageNumber"
              element={<Accountdofus />}
            />
            <Route path="/:title/:name" element={<IgvProduct />} />
            <Route
              path="/orders"
              element={user?.person?._id ? <Orders /> : <SignIn />}
            />
            <Route
              path="/shop"
              element={user?.person?._id ? <Shop /> : <SignIn />}
            />
            <Route
              path="/password"
              element={user?.person?._id ? <Password /> : <SignIn />}
            />
            <Route path="/ibytrade/vendre-des-kamas" element={<SellKamas />} />
            <Route
              path="/personal-infos"
              element={user?.person?._id ? <PersonalInfos /> : <SignIn />}
            />
            <Route path="/checkout-page" element={<CheckoutPage />} />

            {/* <Route path="/pay" element={<Pay />} /> */}
            <Route
              path="/paymentusdt/:userid/:valuetoconverter"
              element={<PaymentUsdt />}
            />
            <Route path="/checkoutcancel" element={<Cart />} />
            <Route path="/:title/:name/:idgame" element={<DetailFif />} />
            <Route path="/ingame/:ingame" element={<SingleMobileHeadingPage />} />

            {/* <Route
            path="/paymentbtc/:userid/:valuetoconverter"
            element={<PaymentBtc />}
          /> */}

            {/* <Route
            path="/paymenteth/:userid/:valuetoconverter"
            element={<PaymentEth />}
          /> */}
            {/* <Route
            path="/paymentltc/:userid/:valuetoconverter"
            element={<PaymentLtc />}
          /> */}
            {/* <Route
            path="/paymentbnb/:userid/:valuetoconverter"
            element={<PaymentBnb />}
          /> */}

            <Route path="/secure-payment" element={<SecurePayment />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route path="/refund" element={<RembAndAnnul />} />
            <Route path="/delivery-policy" element={<Livraison />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route
              path="/term-and-conditions"
              element={<TermAndConditions />}
            />
            <Route path="*" element={<Home />} />
          </Routes>
        </div>
        <div className="footer">
          {location.pathname === "/register" ||
            location.pathname === "/partner" ||
            location.pathname === "/login" ||
            location.pathname === "/payment-success" ||
            location.pathname === "/update-profil" ||
            location.pathname === "/orders" ||
            location.pathname === "/password" ||
            location.pathname === "/personal-infos" ||
            location.pathname === "/profil" ||
            // location.pathname === "/myinbox" ||
            // location.pathname === "/shop" ||
            location.pathname === "/resetpassword" ||
            location.pathname === "/how-to-buy" ||
            location.pathname === "/how-to-buy" ||
            location.pathname === "/contact" || <Footer />}
        </div>
      </div>
  );
};

export default App;
