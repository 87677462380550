import React, { useState, useEffect } from "react";
import SideBar from "./SideBar";

import axios from "axios";

import { HiEye } from "react-icons/hi";
import { BiSearch } from "react-icons/bi";

import { useSelector } from "react-redux";

function Orders({ setIsViewProduct, setIdPopupUser }) {
  const { user } = useSelector((state) => state.user);
  const Head = "text-xs font-semibold px-3 py-2 text-lg uppercase";
  const Text = "px-3 text-sm py-3 leading-6 whitespace-nowrap";
  const badge = "py-1 px-2 rounded-full text-xs font-semibold";
  const [orders, setOrders] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [ibyOrders, setIbyOrders] = useState([]);

  const { language } = useSelector((state) => state.language);

  const { currency } = useSelector((state) => state.currency);

  console.log(ibyOrders);

  useEffect(() => {
    const getUsersOrders = () => {
      axios
        .get(
          `${process.env.REACT_APP_CLIENT_URL}/order/find/${user?.person?._id}`
        )
        .then((res) => {
          setOrders(res?.data);
        })
        .catch((err) => console.log(err));
    };

    getUsersOrders();
  }, []);

  useEffect(() => {
    const getUsersBuys = async () => {
      await axios
        .get(
          `${process.env.REACT_APP_IBYTRADE_CLIENT_URL}/buy/user/${user?.user}`
        )
        .then((res) => setIbyOrders(res.data));
    };
    getUsersBuys();
  }, [user?.user]);

  const convertDate = (date) => {
    const dateConverted = new Date(date).toLocaleDateString("fr-FR", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    return dateConverted;
  };

  const handleView = (idOrder) => {
    setIsViewProduct(true);
    setIdPopupUser(idOrder);
  };

  const Rows = ({ order }, number) => {
    return (
      <tr key={order?._id}>
        <td className={`${Text} text-base font-bold`}>{order?.orderNum}</td>
        <td className={`${Text} font-bold text-center`}>
          {order?.totalPrice} {/*devise */}
        </td>
        <td className={`${Text} text-center text-base`}>
          {order?.products[0]?.character}
        </td>
        <td className={`${Text} text-center text-base`}>
          {order?.products[0]?.server}
        </td>
        <td className={`${Text} text-center text-base`}>
          {order?.products[0]?.category}
        </td>
        <td className={`${Text} text-center text-base font-bold`}>
          {order?.products[0]?.amount}M
        </td>
        <td className={`${Text} text-center text-base`}>
          {convertDate(order?.createdAt)}
        </td>

        <td className={`${Text} text-center`}>
          {order?.status === "Terminée" && (
            <span className={`${badge} text-subMain bg-green-100`}>
              {order?.status}
            </span>
          )}
          {order?.status === "En Cours de payment" && (
            <span className={`${badge} text-subMain bg-blue-100`}>
              {order?.status}
            </span>
          )}
          {order.status === "En attente" && (
            <span className={`${badge} text-star bg-orange-100`}>
              {order.status}
            </span>
          )}
          {order.status === "Annulée" && (
            <span className={`${badge} text-flash bg-red-100`}>
              {order?.status}
            </span>
          )}
        </td>
      </tr>
    );
  };

  return (
    <SideBar>
      <div className="flex flex-col gap-4">
        <div className="flex justify-between items-center gap-2">
          <h2 className="text-xl font-bold">Historique des commandes</h2>
          {user?.role === "Admin" && (
            <button className="bg-flash font-medium text-white py-2 px-6 rounded">
              Supprimer tout
            </button>
          )}
        </div>
        <div className="w-full relative mb-8">
          <input
            type="text"
            name="search"
            id="search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Rechercher la commande par son ID"
            className="text-base p-3 rounded w-full  border-none text-gray-400 bg-gray-900"
          />
          <span className="absolute text-gray-400 text-3xl left-[92%] md:left-[96%] top-[18%]">
            <BiSearch />
          </span>
        </div>
        <div className="w-full relative overflow-hidden overflow-x-scroll">
          <table className="table-auto min-w-full border border-deepest divide-y divide-gray-200">
            <thead>
              <tr className="bg-deepest">
                <th scope="col" className={`${Head} text-left`}>
                  {language === "francais" && "N° Commande"}
                  {language === "anglais" && "N° Order"}
                  {language === "espagnol" && "N° Pedido"}
                </th>
                <th scope="col" className={`${Head} text-center`}>
                  Total
                </th>

                <th scope="col" className={`${Head} text-center`}>
                  {language === "francais" && "Personnage"}
                  {language === "anglais" && "Character"}
                  {language === "espagnol" && "Personaje"}
                </th>
                <th scope="col" className={`${Head} text-center`}>
                  {language === "francais" && "Serveur"}
                  {language === "anglais" && "Server"}
                  {language === "espagnol" && "Servidor"}
                </th>
                <th scope="col" className={`${Head} text-center`}>
                  {language === "francais" && "Categorie"}
                  {language === "anglais" && "Category"}
                  {language === "espagnol" && "Categoría"}
                </th>
                <th scope="col" className={`${Head} text-center`}>
                  {language === "francais" && "Quantite (s)"}
                  {language === "anglais" && "Quantities"}
                  {language === "espagnol" && "cantidade (s)"}
                </th>
                <th scope="col" className={`${Head} text-center`}>
                  {language === "francais" && "Date"}
                  {language === "anglais" && "Date"}
                  {language === "espagnol" && "Fecha"}
                </th>
                <th scope="col" className={`${Head} text-center`}>
                  {language === "francais" && "Status"}
                  {language === "anglais" && "Status"}
                  {language === "espagnol" && "Status"}
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-deepest">
              {orders
                ?.filter((order) =>
                  order?.orderNum
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
                )
                .map((order) => Rows({ order }))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="w-full relative overflow-hidden overflow-x-scroll mt-40">
        <h2 className="flex items-center justify-center w-full text-lg bg-red-400 p-3 rounded text-white">
          MES VENTES
        </h2>
        <table className="table-auto min-w-full border border-deepest divide-y divide-gray-200">
          <thead>
            <tr className="bg-deepest">
              <th scope="col" className={`${Head} text-left`}>
                {language === "francais" && "N° Commande"}
                {language === "anglais" && "N° Order"}
                {language === "espagnol" && "N° Pedido"}
              </th>
              <th scope="col" className={`${Head} text-center`}>
                Total
              </th>

              <th scope="col" className={`${Head} text-center`}>
                {language === "francais" && "Personnage"}
                {language === "anglais" && "Character"}
                {language === "espagnol" && "Personaje"}
              </th>
              <th scope="col" className={`${Head} text-center`}>
                {language === "francais" && "Serveur"}
                {language === "anglais" && "Server"}
                {language === "espagnol" && "Servidor"}
              </th>
              <th scope="col" className={`${Head} text-center`}>
                {language === "francais" && "Categorie"}
                {language === "anglais" && "Category"}
                {language === "espagnol" && "Categoría"}
              </th>
              <th scope="col" className={`${Head} text-center`}>
                {language === "francais" && "Quantite (s)"}
                {language === "anglais" && "Quantities"}
                {language === "espagnol" && "cantidade (s)"}
              </th>
              <th scope="col" className={`${Head} text-center`}>
                {language === "francais" && "Date"}
                {language === "anglais" && "Date"}
                {language === "espagnol" && "Fecha"}
              </th>
              <th scope="col" className={`${Head} text-center`}>
                {language === "francais" && "Status"}
                {language === "anglais" && "Status"}
                {language === "espagnol" && "Status"}
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-deepest">
            {ibyOrders?.map((order) => (
              <tr key={order?._id}>
                <td className={`${Text} text-base font-bold`}>
                  {order?.numBuy}
                </td>
                <td className={`${Text} font-bold text-center`}>
                  {order?.totalPrice} {/*devise */}
                </td>
                <td className={`${Text} text-center text-base`}>
                  {order?.gameName}
                </td>
                <td className={`${Text} text-center text-base`}>
                  {order?.server}
                </td>
                <td className={`${Text} text-center text-base`}>
                  {order?.jeu}
                </td>
                <td className={`${Text} text-center text-base font-bold`}>
                  {order?.qte}M
                </td>
                <td className={`${Text} text-center text-base`}>
                  {convertDate(order?.createdAt)}
                </td>

                <td className={`${Text} text-center`}>
                  {order?.status === "Payée" && (
                    <span className={`${badge} text-subMain bg-green-100`}>
                      {order?.status}
                    </span>
                  )}
                  {order?.status === "En Cours de payment" && (
                    <span className={`${badge} text-subMain bg-blue-100`}>
                      {order?.status}
                    </span>
                  )}
                  {order.status === "En attente" && (
                    <span className={`${badge} text-star bg-orange-100`}>
                      {order.status}
                    </span>
                  )}
                  {order.status === "Annulée" && (
                    <span className={`${badge} text-flash bg-red-100`}>
                      {order?.status}
                    </span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </SideBar>
  );
}

export default Orders;
