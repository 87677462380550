import React, { useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";

import { BsArrowRight } from "react-icons/bs";

import { FcLineChart, FcCollaboration, FcBullish } from "react-icons/fc";
import { IoGameControllerOutline } from "react-icons/io5";
import { BsShop, BsCaretLeftFill, BsCaretRightFill } from "react-icons/bs";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./style.css";

import logoSwiper1 from "../assets/hero/dofus.jpg";
import callofduty from "../assets/hero/callofduty.jpg";
import bagPiece from "../assets/dofus/bag-piece.png";

import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Hero = () => {
  const { language } = useSelector((state) => state.language);
  const { ipAddr } = useSelector((state) => state.ipAddr);
  //  const [ipAddr, setIpAddr] = useState("France");
  const [prevEl, setPrevEl] = useState(null);
  const [nextEl, setNextEl] = useState(null);

  return (
    <div className="hidden xcustom:block">
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 5000, // Définir le délai à 5000 (5 secondes)
          disableOnInteraction: false,
        }}
        navigation={{ prevEl, nextEl }}
        modules={[Autoplay, Pagination, Navigation]}
        effect="slide"
        speed={1000}
        className="relative w-full h-[300px] lg:h-[500px] mt-[60px]"
      >
        <SwiperSlide>
          <div className="relative w-full h-full">
            <img
              src={logoSwiper1}
              alt="dofus"
              className="object-cover w-full h-full filteropacity-95 brightness-75 z-[9999]"
              style={{ objectPosition: "center center" }}
            />
            {/* <div className="absolute top-1/2 left-[52%] xl:left-[50%] transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-around  text-white z-10">
              <div className="flex flex-col items-start gap-6 mr-8">
                <p className="font-semibold text-white text-xl sm:text-2xl lg:text-3xl xl:text-3xl w-full md:w-[400px] lg:w-[600px] uppercase leading-[36px] sm:leading-[42px]">
                  {language === "francais" &&
                    `Plongez dans Dofus avec Ibendouma, achetez des Dofus Kamas,
                  Retro et Touch. Profitez de l'Expérience de Jeu Ultime avec
                  nos Offres Exclusives.`}
                  {language === "anglais" &&
                    `Go along in Dofus with Ibendouma, buy Dofus Kamas, Retro and Touch. Enjoy the Ultimate Gaming Experience with Exclusive Offers.`}
                  {language === "espagnol" &&
                    `Recorre Dofus con Ibendouma, compra Dofus Kamas, Retro y Touch. Disfruta de la Experiencia de Juego Definitiva con nuestras Ofertas Exclusivas.`}
                </p>
                <button className="relative text-lg bg-red-700 p-3 w-[240px] mxsm:w-[240px] text-white rounded-[4px] uppercase">
                  <Link to="/dofus/dofus-kamas">
                    {language === "francais" && "Acheter maintenant"}
                    {language === "anglais" && "buy now"}
                    {language === "espagnol" && "Comprar Ahora"}
                  </Link>
                </button>
              </div>
              <img
                src={bagPiece}
                alt="dofus"
                className="hidden custom:flex ml-8 w-[300px] h-[300px] lg:w-[350px] lg:h-[350px] xl:w-[400px] xl:h-[400px] -mt-8 bg-cover bg-center mix-blend-darken filter brightness-125"
              />
            </div> */}
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="relative w-full h-full">
            <img
              src={callofduty}
              alt="callofduty"
              className="object-cover w-full h-full filter"
              style={{ objectPosition: "center" }}
            />
            {/* <div className="absolute top-1/2 left-1/2 mxsm:left-[44%] custom:left-[36%] xl:left-[26%] transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-around  text-white z-10">
              <div className="flex flex-col items-start gap-6 mr-8">
                <p className="font-semibold text-white text-xl mxsm:text-2xl md:text-3xl lg:text-3xl w-full md:w-[500px] lg:w-[600px] uppercase leading-[36px] sm:leading-[42px]">
                  {language === "francais" &&
                    `Explorez l'univers intense de Call of Duty avec Ibendouma. Achetez des Points CoD (CP) pour vos jeux préférés, que ce soit Modern Warfare, Warzone ou Cold War.`}
                  {language === "anglais" &&
                    `Explore the intense world of Call of Duty with Ibendouma. Buy CoD Points (CP) for your favorite games, be it Modern Warfare, Warzone or Cold War.`}
                  {language === "espagnol" &&
                    `Explora el intenso universo de Call of Duty con Ibendouma. Compra puntos cod (CP) para tus juegos favoritos, ya sea Modern Warfare, Warzone o Cold War.`}
                </p>
                <button className="relative text-base mxsm:text-lg bg-red-700 p-3 w-[240px] mxsm:w-[240px] text-white rounded-[4px] uppercase">
                  {ipAddr === "Morocco" ? (
                    <Link to="/Call of Duty:Modern Warfare/accounts/631859314lo4c5d6067a5byu">
                      {language === "francais" && "Acheter maintenant"}
                      {language === "anglais" && "buy now"}
                      {language === "espagnol" && "Comprar Ahora"}
                    </Link>
                  ) : (
                    <span>
                      {language === "francais" && "Acheter maintenant"}
                      {language === "anglais" && "buy now"}
                      {language === "espagnol" && "Comprar Ahora"}
                    </span>
                  )}
                </button>
              </div>
            </div> */}
          </div>
        </SwiperSlide>
        <div className="w-full px-1 z-50 absolute top-2/4 flex justify-between">
          <button
            className="hover:bg-red-500 transition duration-500 ease-in-out hover:text-white rounded-full w-10 h-10 flex items-center justify-center bg-red-600 text-white shadow-xl cursor-pointer"
            ref={(node) => setPrevEl(node)}
          >
            <BsCaretLeftFill />
          </button>
          <button
            className="hover:bg-red-500 transition duration-500 ease-in-out rounded-full w-10 h-10 flex items-center justify-center bg-red-600 text-white shadow-xl cursor-pointer"
            ref={(node) => setNextEl(node)}
          >
            <BsCaretRightFill />
          </button>
        </div>
      </Swiper>
    </div>
  );
};

export default Hero;
