import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";

export default function Accordion() {
  let { language } = useSelector((state) => state.language);
  const [faqs, setFaqs] = useState([
    {
      question: "Support",
      answer: [
        {
          id: "bfcg",
          titlefr: "Comment acheter",
          titleEn: "How to buy",
          titleEs: "Cómo comprar",
          link: "/how-to-buy",
        },
        {
          id: "lqkz",
          titlefr: "À propos de la livraison",
          titleEn: "About delivery",
          titleEs: "Acerca de la entrega",
          link: "/delivery-policy",
        },
        {
          id: "lqkz",
          titlefr: "Politique de remboursement",
          titleEn: "Refund policy",
          titleEs: "Politica de reembolso",
          link: "/how-to-buy",
        },
      ],
      open: false,
    },
    {
      question: "À propos d'iBendouma",
      answer: [
        {
          id: "lqkz",
          titlefr: "Nous contacter",
          titleEn: "Contact us",
          titleEs: "Contactarnos",
          link: "/contact",
        },
      ],
      open: false,
    },
    {
      question: "Legal",
      answer: [
        {
          id: "jhuk",
          titlefr: "Politique de confidentialité",
          titleEn: "Privacy policy",
          titleEs: "Política de privacidad",
          link: "/privacy-policy",
        },
        {
          id: "wbap",
          titlefr: "Terms & Conditions",
          titleEn: "Terms & Conditions",
          titleEs: "Términos y Condiciones",
          link: "/term-and-conditions",
        },
      ],
      open: false,
    },
  ]);

  const toggleFAQ = (index) => {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
  };

  return (
    <div className="w-full p-4">
      {faqs.map((faq, index) => (
        <div
          key={index}
          className={
            "rounded-lg p-1 transition-all duration-300 " +
            (faq.open ? "mb-3" : "")
          }
          onClick={() => toggleFAQ(index)}
        >
          <div className="flex items-center justify-between">
            <span className="text-[#e5e7eb]">{faq?.question}</span>
            <span className="text-[#e5e7eb] cursor-pointer">
              {faq.open ? (
                <MdOutlineKeyboardArrowUp />
              ) : (
                <MdOutlineKeyboardArrowDown />
              )}
            </span>
          </div>
          <div
            className={
              "text-[#f1f5f9] mt-2 transition-all duration-300 " +
              (faq.open
                ? "opacity-100 max-h-[1000px]"
                : "opacity-0 max-h-0 overflow-hidden")
            }
          >
            <div className="flex flex-col items-start gap-1">
              {faq?.answer.map((answ) => (
                <Link
                  to={answ.link}
                  key={answ.id}
                  className="text-sm antialiased text-[#a1a1aa]"
                >
                  {language === "francais" && answ.titlefr}
                  {language === "anglais" && answ.titleEn}
                  {language === "espagnol" && answ.titleEs}
                </Link>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
