import React, { useState, useEffect } from "react";
import free from "../assets/allgames/free.png";
import refund from "../assets/allgames/refund.png";
import product from "../assets/allgames/product.png";
import warranty from "../assets/allgames/warranty.png";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

// import { BiCheckShield } from "react-icons/bi";

// import { addToGame } from "../features/gameSlices";

// import logo from "../assets/ibendouma-logo.png";
import { getSingleProduct } from "../utils/igvoriginaldata";

const DetailProduct = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { currency, currencyValue } = useSelector((state) => state.currency);
  const { language } = useSelector((state) => state.language);
  const [quantity, setQuantity] = useState(1);
  const [numItem, setNumItem] = useState(0);
  const [itemProduct, setItemProduct] = useState({});
  const [imagesScroller, setImagesScroller] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const { title, name, idgame } = useParams();

  // const product = getSingleProduct(title, name, idgame);

  // console.log(itemProduct);

  const checkIfPriceIsAvailable = (chaine) => {
    let price;
    const regex = /[-+]?[0-9]*\.?[0-9]+/g;
    if (regex.test(chaine)) {
      price = chaine.match(regex)[0];
    }
    return price;
  };

  useEffect(() => {
    const product = getSingleProduct(title, name, idgame);
    setItemProduct(product);
  }, []);

  const handleReduce = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handleIncrease = () => {
    setQuantity(quantity + 1);
  };

  const handleAddOrder = () => {
    if (!user?.person?._id) {
      toast.error("Veuiller vous connecter d'abord");
    } else {
      // dispatch(
      //   addToGame({
      //     id: itemProduct?.id,
      //     quantity: quantity,
      //     price: itemProduct?.price,
      //     total: (quantity * itemProduct.price).toFixed(2),
      //     image: itemProduct?.imageUrl,
      //     devise: itemProduct?.currency,
      //     description: itemProduct?.description,
      //     title: title,
      //     subtitle: name,
      //     category: "title",
      //     deleveryTime: "15 minutes",
      //     type: itemProduct?.warranty,
      //   })
      // );
      // window.location.href = "/checkout-page";
      language === "francais" &&
        toast.info(
          "Désolé, nous sommes en train d'intégrer nos méthodes de paiement et elles ne sont pas encore disponibles."
        );
      language === "anglais" &&
        toast.info(
          "Sorry, we are in the process of integrating our payment methods and they are not yet available."
        );
    }
  };

  return (
    <div className="flex flex-col items-start justify-center w-full mx-auto py-8 mt-12 sm:mt-32 mb-12">
      <div className="w-full flex items-start justify-around flex-wrap gap-4">
        <div className="flex flex-col items-center mt-16 sm:mt-1">
          <img
            src={itemProduct.imageUrl}
            alt="game"
            className="w-[300px] h-[300px] bcustom:w-[350px] bcustom:h-[350px] xl:w-[350px] xl:h-[350px] bg-cover rounded"
          />
        </div>
        <div className="flex flex-col items-start max-w-[600px] 2xl:max-w-[800px]">
          <div className="flex flex-col items-start border-b pb-8 border-gray-300 space-y-4 w-full">
            <h2 className="flex text-lg font-semibold">{itemProduct?.title}</h2>
            <p className="text-base capitalize">{`${title} ${name}`}</p>
            {/* <div className="flex items-center space-x-4">
              <span className="flex items-center justify-center p-2 bg-green-600 text-white rounded text-sm">
                <BiCheckShield className="mr-1" />
                {itemProduct?.warranty
                  ? itemProduct?.warranty
                  : "15 days Warranty"}
              </span>
              <span className="flex items-center justify-center p-2 bg-green-600 text-white rounded text-sm">
                <IoFlashOutline className="mr-1" />
                Delivery in 15 Min
              </span>
            </div> */}
          </div>
          <div className="flex flex-col items-start gap-8 mt-4 border-b pb-8 border-gray-300 w-full">
            <div className="flex items-center space-x-4">
              <div className="flex items-center space-x-4">
                <img
                  src={warranty}
                  alt="warranty"
                  className="w-[40px] h-[40px] object-cover"
                />
                <div className="flex flex-col items-start gap-2">
                  <p className="text-sm font-semibold">
                    {language === "anglais" && "delivery guarantees"}
                    {language === "espagnol" && "Garantías de entrega"}
                    {language === "francais" && "Garanties de livraison"}
                  </p>
                  <span className="text-xs text-gray-500 max-w-[250px]">
                    {language === "anglais" &&
                      "We offer a guaranteed delivery and indicate the delivery date on the payment page."}
                    {language === "espagnol" &&
                      "Ofrecemos entrega garantizada e indicamos la fecha de entrega en la página de pago."}
                    {language === "francais" &&
                      "Nous proposons une livraison garantie et indiquons la date de livraison sur la page de paiement."}
                  </span>
                </div>
              </div>
              <div className="blg:flex hidden items-center space-x-4 ">
                <img
                  src={product}
                  alt="warranty"
                  className="w-[40px] h-[40px] object-cover"
                />
                <div className="flex flex-col items-start gap-2">
                  <p className="text-sm font-semibold">
                    {language === "anglais" && "product warranty"}
                    {language === "espagnol" && "garantía de los productos"}
                    {language === "francais" && "Garantie des produits"}
                  </p>
                  <span className="text-xs text-gray-500 max-w-[250px]">
                    {language === "anglais" &&
                      "The products are covered by the seller with a guarantee on delivery."}
                    {language === "espagnol" &&
                      "Los productos están cubiertos por el vendedor con una garantía de entrega."}
                    {language === "francais" &&
                      "Les produits sont couverts par le vendeur avec une garantie à la livraison."}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <div className="flex items-center space-x-4">
                <img
                  src={refund}
                  alt="warranty"
                  className="w-[40px] h-[40px] object-cover"
                />
                <div className="flex flex-col items-start gap-2">
                  <p className="text-sm font-semibold">
                    {language === "anglais" && "satisfied or refunded"}
                    {language === "espagnol" && "satisfecho o reembolsado"}
                    {language === "francais" && "Satisfait ou remboursé"}
                  </p>
                  <span className="text-xs text-gray-500 max-w-[250px]">
                    {language === "anglais" &&
                      `If you encounter a problem during the warranty period on ibendouma, you can request a refund of your order.`}
                    {language === "espagnol" &&
                      `Si tiene algún problema durante el período de garantía en ibendouma, puede solicitar un reembolso de su pedido.`}
                    {language === "francais" &&
                      `Si vous rencontrez un problème pendant la période de
                    garantie sur ibendouma, vous pouvez demander un
                    remboursement de votre commande.`}
                  </span>
                </div>
              </div>
              <div className="blg:flex hidden items-center space-x-4">
                <img
                  src={free}
                  alt="warranty"
                  className="w-[40px] h-[40px] object-cover"
                />
                <div className="flex flex-col items-start gap-2">
                  <p className="text-sm font-semibold">
                    {language === "anglais" && "Free refund"}
                    {language === "espagnol" && "Reembolso gratuito"}
                    {language === "francais" && "Remboursement gratuit"}
                  </p>
                  <span className="text-xs text-gray-500 w-full xl:max-w-[250px]">
                    {language === "anglais" &&
                      `Ibendouma does not charge any fees. You will receive a refund equivalent to the amount you paid.`}
                    {language === "espagnol" &&
                      `Ibendouma no cobra ninguna tarifa. Usted recibirá un reembolso equivalente a la cantidad que pagó.`}
                    {language === "francais" &&
                      `Ibendouma ne facture aucun frais. Vous recevrez un
                      remboursement équivalent au montant que vous avez payé.`}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start gap-2">
          <div className="flex flex-col items-start gap-4 w-[300px] p-4 border border-gray-300 rounded select-none">
            {/* <div className="flex w-full space-x-4">
            <img
              src={logo}
              alt="ibendouma"
              className="w-[100px] h-[100px] object-cover rounded-full -mt-6"
            />
            <div className="flex flex-col items-start gap-2">
              <h2 className="text-lg font-bold text-gray-700">
                {language === "anglais" && "Ibendouma Seller"}
                {language === "espagnol" && "Vendedor Ibendouma"}
                {language === "francais" && "Vendeur Ibendouma"}
              </h2>
              <span className="flex items-center text-green-600 gap-1 text-[14px]">
                <BsFillStarFill />
                <BsFillStarFill />
                <BsFillStarFill />
                <BsFillStarFill />
                <BsStarHalf />
                4.7
              </span>
            </div>
          </div> */}

            <div className="w-full flex items-center justify-between">
              <span className="font-bold">
                {language === "anglais" && "Price"}
                {language === "espagnol" && "Precio"}
                {language === "francais" && "Prix"}:
              </span>
              <div className="flex flex-col">
                <span className="text-[26px] font-bold text-red-600">
                  {checkIfPriceIsAvailable(itemProduct?.price) * quantity}
                  <span className="uppercase text-[15px] text-red-600">
                    {itemProduct?.currency === "EUR" && "EUR"}
                    {itemProduct?.currency === "" && "MAD"}
                    {itemProduct?.currency === "USD" && "USD"}
                    {itemProduct?.currency === "mad" && "MAD"}
                    {itemProduct?.currency === "usdt" && "USDT"}
                    {itemProduct?.currency === "cad" && "CAD"}
                    {itemProduct?.currency === "chf" && "CHF"}
                    {itemProduct?.currency === "rub" && "RUB"}
                    {itemProduct?.currency === "gbp" && "GBP"}{" "}
                  </span>
                </span>
                <span className="flex items-end text-sm text-gray-500 ml-4">
                  ({checkIfPriceIsAvailable(itemProduct?.price) * quantity}{" "}
                  {itemProduct?.currency === "EUR" && "EUR"}
                  {itemProduct?.currency === "" && "MAD"}
                  {itemProduct?.currency === "USD" && "USD"}
                  {itemProduct?.currency === "mad" && "MAD"}
                  {itemProduct?.currency === "usdt" && "USDT"}
                  {itemProduct?.currency === "cad" && "CAD"}
                  {itemProduct?.currency === "chf" && "CHF"}
                  {itemProduct?.currency === "rub" && "RUB"}
                  {itemProduct?.currency === "gbp" && "GBP"} /{quantity}x)
                </span>
              </div>
            </div>
            <div className="flex item-center gap-0 w-full">
              <div className="flex text-sm flex-1 items-center justify-between border border-gray-300 p-2">
                <span
                  className="text-gray-500 cursor-pointer font-bold"
                  onClick={handleReduce}
                >
                  -
                </span>
                <span className="font-bold">{quantity}</span>
                <span
                  className="text-gray-500 cursor-pointer font-bold"
                  onClick={handleIncrease}
                >
                  +
                </span>
              </div>
              <span className="flex text-sm items-center justify-between border text-gray-400 border-gray-300 p-2">
                {quantity}x
              </span>
            </div>
            <button
              className="w-full flex items-center justify-center outline-none rounded text-base p-2 bg-red-600 text-white transition duration-300 ease-in-out hover:bg-red-700"
              onClick={handleAddOrder}
            >
              {language === "anglais" && "Buy now"}
              {language === "espagnol" && "Compra ahora"}
              {language === "francais" && "Achetez maintenant"}
            </button>
          </div>
          <div className="w-full flex flex-col items-start gap-2 bg-red-50 rounded p-4">
            <div className="flex items-center justify-between w-full">
              <span className="text-sm text-gray-500">
                {language === "francais"
                  ? "Description du produit"
                  : "Product Description"}
              </span>
              <span className="text-sm text-green-600 font-semibold">1.0</span>
            </div>
            <div className="flex items-center justify-between w-full">
              <span className="text-sm text-gray-500">
                {language === "francais"
                  ? "Vitesse de livraison"
                  : "Fast Delivery"}
              </span>
              <span className="text-sm text-green-600 font-semibold">3.0</span>
            </div>
            <div className="flex items-center justify-between w-full">
              <span className="text-sm text-gray-500">
                {language === "francais"
                  ? "Attitude de service"
                  : "Service attitude"}
              </span>
              <span className="text-sm text-green-600 font-semibold">1.0</span>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-start mt-12 ml-6 xl:ml-14">
        <p className="text-lg font-bold text-gray-700 pb-2 border-b-[2px] border-red-600">
          {language === "anglais" && "Description"}
          {language === "espagnol" && "Descripción"}
          {language === "francais" && "Description"}
        </p>
        <div className="flex mt-4 text-gray-700 text-base gap-4">
          <span className="underline">
            {language === "anglais" && "Type"}
            {language === "espagnol" && "Amable"}
            {language === "francais" && "Type"}
          </span>
          <span className="text-red-500">{title}</span>
        </div>
        <div className="flex text-gray-700 text-base gap-4">
          <span className="underline">
            {language === "anglais" && "Category"}
            {language === "espagnol" && "Categoría"}
            {language === "francais" && "Categorie"}
          </span>
          <span className="text-red-500">{name}</span>
        </div>
        <div className="flex flex-col items-start gap-2 mt-1">
          {itemProduct?.listingTag &&
            itemProduct?.listingTag?.slice(1)?.map((tag, index) => (
              <p className="text-gray-700 text-base " key={index}>
                {tag}
              </p>
            ))}
        </div>
        {language === "francais" && (
          <div className="flex flex-col items-start custom:w-full xl:max-w-[1000px] mt-2 text-sm custom:text-base text-gray-700 leading-10 pr-20">
            {itemProduct?.title}
            <p>
              Achetez des{" "}
              <strong>
                {name} {title}
              </strong>
            </p>
            <p>
              Evoluez plus rapidement et facilement en achetant des{" "}
              <strong>
                {name} {title}
              </strong>
              . Explorez le vaste univers de {title} dès maintenant, contactez
              nous!
            </p>
            <p>
              Nous sommes à votre disposition 24/7 pour vous aidez à améliorer
              votre expérience in-game et nous vous garantissons une expérience
              simple et fluide et un délai de livraison records.
            </p>
          </div>
        )}
        {language === "anglais" && (
          <div className="flex flex-col items-start custom:w-full xl:max-w-[1000px] mt-2 text-sm custom:text-base text-gray-700 leading-10 pr-20">
            <span className="font-bold text-gray-700">
              {itemProduct?.title}
            </span>
            {itemProduct.desc ? (
              <div className="flex flex-col items-start gap-4">
                {itemProduct?.desc.split("rty").map((item, index) => (
                  <p key={index} className="flex flex-col items-start">
                    {item}
                  </p>
                ))}
              </div>
            ) : (
              <div>
                <p>
                  Buy{" "}
                  <strong>
                    {title} {name}
                  </strong>
                </p>
                <p>
                  Scale more quickly and easily by purchasing{" "}
                  <strong>
                    {name} {title}
                  </strong>
                  . Explore the vast universe of {title} right now.
                </p>
                <p>
                  We are at your disposal 24/7 to help you improve your in-game
                  experience and we guarantee you a simple and smooth experience
                  and a record delivery time.
                </p>
              </div>
            )}
          </div>
        )}
        {language === "espagnol" && (
          <div className="flex flex-col items-start custom:w-full xl:max-w-[1000px] mt-2 text-sm custom:text-base text-gray-700 leading-10 pr-20">
            {itemProduct?.title}
            <p>
              Comprar{" "}
              <strong>
                {title} {name}
              </strong>
            </p>
            <p>
              Escale más rápida y fácilmente comprando{" "}
              <strong>
                {name} {title}
              </strong>
              . Explora el vasto universo de {title} ahora mismo.
            </p>
            <p>
              Estamos a tu disposición las 24 horas del día, los 7 días de la
              semana para ayudarte a mejorar tu experiencia en el juego y te
              garantizamos una experiencia sencilla y fluida. un tiempo de
              entrega récord.
            </p>
          </div>
        )}
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default DetailProduct;
