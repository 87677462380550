import React, { useState, useEffect } from "react";

import "react-loading-skeleton/dist/skeleton.css";


import { useSelector, useDispatch } from "react-redux";

import trust5 from "../assets/truspilot/stars-5-down.svg";
import trust1 from "../assets/truspilot/trustpilot1start.svg";
import defaultUser from "../assets/discussion/defaultUser.png";

import { FaCheckCircle } from "react-icons/fa";

import { Helmet } from "react-helmet";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";

import CookieConsent from "react-cookie-consent";

import { addNewCookState } from "../features/cookieSlices";

import medail from "../assets/medail.png";

import { MdStars } from "react-icons/md";

import axios from "axios";
import { addServers } from "../features/serversSlice";
import { addCurrencyVal } from "../features/currencyExchange";
import { Link } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { dofusProduct } from "./utils/product";

import { dofusobject } from "../CONSTANTS/data";
import { igvproducts } from "../utils/igvoriginaldata";

import { addNewIp } from "../features/ipSlice";
import { rmIpAdress } from "../features/ipSlice";
import { addNewCurrencyExchange } from "../features/currencyExchange";
import { addToggleState } from "../features/toggleIpSlice";

import { BsArrowRight } from "react-icons/bs";
import Product from "../components/Product";
import MobileHome from "./MobileHome";

const Home = ({ truspilotData }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  const { ipAddr } = useSelector((state) => state.ipAddr);

  // const [ipAddr, setIpAddr] = useState("France");

  const [letterStep, setLetterStep] = useState(8);

  const { currency, currencyValue } = useSelector((state) => state.currency);

  // const { products, loading } = useSelector((state) => state.products);
  // const [products, setProducts] = useState([...dofusProduct]);

  const { cook } = useSelector((state) => state.cook);
  const { language } = useSelector((state) => state.language);

  const { toggleIp } = useSelector((state) => state.toggleIp);

  let [screenSize, setScreenSize] = useState(window.innerWidth);

  const [comments, setComments] = useState([]);

  const [location, setLocation] = useState(null);
  const [prevEl, setPrevEl] = useState(null);
  const [nextEl, setNextEl] = useState(null);

  const [menuFilteredData, setMenuFilteredData] = useState(igvproducts);
  const [activeLetter, setActiveLetter] = useState("ALL");

  // console.log(menuFilteredData);

  const [ipAdress, setIpAdress] = useState("");
  // const [productCombined, setProductCombined] = useState([
  //   ...products,
  //   ...dofusobject,
  // ]);
  const requestLimiteError = (name) => toast.error(name);

  // console.log(products);
  // console.log(productCombined);

  // useEffect(() => {
  //   const userId = user?.user;
  //   const socket = io("http://localhost:4002");

  //   socket.emit("connection", userId);

  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);

  useEffect(() => {
    const getGeoLocate = async () => {
      await axios
        .get("https://api.ipify.org?format=json")
        .then((res) => setIpAdress(res?.data?.ip))
        .catch((error) => console.log(error));
      // fetch('https://api.ipify.org?format=json')
      // .then(response => response.json())
      // .then(data => console.log(data.ip))
      // .catch(error => console.error(error));
    };
    getGeoLocate();
  }, []);

  const convertDate = (date) => {
    const dateConverted = new Date(date).toLocaleDateString("en", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
    return dateConverted;
  };

  useEffect(() => {
    if (ipAdress) {
      const getCountry = async () => {
        await axios
          .get(`https://ipapi.co/${ipAdress}/json/`)
          .then((res) => {
            dispatch(rmIpAdress());
            dispatch(addToggleState(true));
            dispatch(addNewIp(res?.data?.country_name));
          })
          .catch((error) => console.log(error));
      };
      getCountry();
    }
  }, [toggleIp, ipAdress, dispatch]);

  useEffect(() => {
    const getComments = async () => {
      await axios
        .get(`${process.env.REACT_APP_CLIENT_URL}/users/create-comment`)
        .then((res) => {
          setComments(res?.data);
        })
        .catch(({ response }) => requestLimiteError(response));
    };
    getComments();
  }, [dispatch]);

  useEffect(() => {
    const fetchEuro = async () => {
      await axios
        .get(`${process.env.REACT_APP_CLIENT_URL}/euro`)
        .then((res) => dispatch(addCurrencyVal(res?.data[0]?.euro)))
        .catch((error) => console.log(error));
    };
    fetchEuro();
  }, [dispatch]);

  useEffect(() => {
    const getServers = async () => {
      await axios
        .get(`${process.env.REACT_APP_CLIENT_URL}/server`)
        .then((res) => dispatch(addServers(res.data)));
    };
    getServers();
  }, [dispatch]);

  useEffect(() => {
    if (ipAddr === "Morocco") {
      dispatch(addNewCurrencyExchange("mad"));
    } else {
      dispatch(addNewCurrencyExchange("euro"));
    }
  }, [ipAddr, dispatch]);

  const handleAcceptCookie = () => {
    dispatch(addNewCookState(false));
  };
  const handleDeclineCookie = () => {
    dispatch(addNewCookState(false));
  };

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleChangeItem = (letter) => {
    setActiveLetter(letter);
  };

  useEffect(() => {
    if (activeLetter === "ALL") {
      setMenuFilteredData(igvproducts);
    } else {
      setMenuFilteredData(
        igvproducts.filter((item) => item?.letterStart === activeLetter)
      );
    }
  }, [activeLetter]);

  useEffect(() => {
    try {
      axios({
        method: "get",
        url: `${process.env.REACT_APP_CLIENT_URL}/${currency}`,
      }).then((res) => {
        if (currency === "euro") {
          dispatch(addCurrencyVal(res?.data[0]?.euro));
        } else if (currency === "gbp") {
          dispatch(addCurrencyVal(res?.data[0]?.gbp));
        } else if (currency === "usdt") {
          dispatch(addCurrencyVal(res?.data[0]?.usdt));
        } else if (currency === "dollar") {
          dispatch(addCurrencyVal(res?.data[0]?.dollar));
        } else if (currency === "cad") {
          dispatch(addCurrencyVal(res?.data[0]?.cad));
        } else if (currency === "chf") {
          dispatch(addCurrencyVal(res?.data[0]?.chf));
        } else if (currency === "rub") {
          dispatch(addCurrencyVal(res?.data[0]?.rub));
        } else if (currency === "mad") {
          dispatch(addCurrencyVal(res?.data[0]?.mad));
        } else {
          dispatch(addCurrencyVal(res?.data[0]?.euro));
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, [currency, dispatch]);

  const dislocName = (name) => {
    if (name && typeof name === "string") {
      if (name.length === 0) {
        return "******";
      } else if (name.length === 1) {
        return name + "*****";
      } else if (name.length === 2) {
        return name[0] + "***" + name[1];
      } else if (name.length === 3) {
        return name[0] + name[1] + "***" + name[2];
      } else {
        return name[0] + name[1] + name[2] + "****" + name[name.length - 1];
      }
    } else {
      return "******";
    }
  };

  return (
    <>
      <div className="hidden xcustom:flex flex-col items-start m-5 gap-12 max-w-screen overflow-hidden">
        <Helmet>
          <title>
            Acheter Dofus, Dofus Touch, et Dofus Retro à des prix imbattables
            sur ibendouma
          </title>
        </Helmet>
        <Helmet>
          <title>
            Acheter Dofus, Dofus Touch, et Dofus Retro à des prix imbattables
            sur ibendouma
          </title>
          <meta
            property="og:title"
            content="Acheter Dofus, Dofus Touch, et Dofus retro"
          />
          <meta
            property="og:description"
            content=" Acheter Dofus, Dofus Touch, et Dofus Retro à des prix imbattables sur
          ibendouma"
          />
          <meta
            property="og:url"
            content="https://ibendouma.com/dofus/dofus-kamas"
          />
          <meta property="og:type" content="product" />
          <meta property="og:site_name" content="https://ibendouma.com" />
        </Helmet>
        <div className="flex flex-col items-start gap-4 w-full">
          <div className="flex items-center justify-between w-full">
            {/* <h1 className="text-lg px-4 py-2 bg-red-600 text-white rounded">
            Dofus
          </h1>
          <span className="text-[28px] font-bold text-red-600">
            <BsArrowRight />
          </span> */}
            <div className="flex items-center gap-1 flex-wrap w-full">
              {[
                "ALL",
                "A",
                "B",
                "C",
                "D",
                "E",
                "F",
                "G",
                "H",
                "K",
                "L",
                "M",
                "N",
                "O",
                "P",
                "R",
                "S",
                "T",
                "U",
                "V",
                "W",
                "X",
                "Y",
              ].map((item, index) => (
                <p
                  key={index}
                  className={`font-bold flex items-center justify-center ${
                    item === "ALL" || (item === "ALL" && activeLetter === "ALL")
                      ? "px-2"
                      : "px-4"
                  } py-2  text-[13px] font-normal shadow-2xl transition duration-500 ease-in-out  rounded hover:bg-red-600 hover:text-white mx-1 lettexrxl:mx-2 cursor-pointer select-none ${
                    item === activeLetter ? "bg-red-600 text-white" : "bg-white"
                  }`}
                  onClick={() => handleChangeItem(item)}
                >
                  {item}
                </p>
              ))}
            </div>
          </div>
          <div className="grid sm:mt-10 mt-2 xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-10 mx-auto p-0 w-full">
            {!dofusProduct?.length
              ? [1, 2, 3, 4, 5]?.map((item, i) => (
                  <div
                    key={i}
                    className="flex flex-col items-center w-full cursor-pointer rounded p-2 bg-white hover:shadow-lg transition"
                  >
                    <div className="bg-gray-300 w-full h-36 animate-pulse"></div>
                    <h3 className="font-semibold my-2 bg-gray-300 w-full h-7 animate-pulse"></h3>
                  </div>
                ))
              : dofusProduct?.map((product) => (
                  <Product key={product?._id} item={product} />
                ))}
            {dofusProduct ? (
              <Link to="/paysafecard">
                <div className="flex flex-col items-center cursor-pointer rounded bg-white shadow-lg transitions">
                  <img
                    alt={dofusobject[0]?.title}
                    src={dofusobject[0]?.image}
                    className="w-full h-40 object-cover"
                  />

                  <span className="flex items-center justify-center w-full bg-white text-[15px] p-2 font-[500]">
                    {dofusobject[0]?.title}
                  </span>
                </div>
              </Link>
            ) : (
              ""
            )}
            {dofusProduct ? (
              <Link to="/les-dofus">
                <div className="flex flex-col items-center cursor-pointer rounded bg-white shadow-lg transitions">
                  <img
                    alt={dofusobject[1]?.title}
                    src={dofusobject[1]?.image}
                    className="w-full h-40 object-cover"
                  />

                  <span className="flex items-center justify-center w-full bg-white text-[15px] p-2 font-[500]">
                    {dofusobject[1]?.title}
                  </span>
                </div>
              </Link>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="flex flex-col items-start gap-4 w-full mt-2">
          <div className="flex items-center justify-between w-full">
            <h1 className="text-base px-4 py-2 bg-red-600 text-white rounded">
              {language === "anglais" && "Other categories"}
              {language === "espagnol" && "Otras categorias"}
              {language === "francais" && "Autres categories"}
            </h1>
            <span className="text-[26px] font-bold text-red-600">
              <BsArrowRight />
            </span>
          </div>
          {menuFilteredData?.length <= 0 ? (
            <div className="flex items-center justify-center mx-auto gap-4 select-none">
              <Link to="/contact" className="text-base text-black">
                {language === "francais" &&
                  "Il n'y a pas le jeu que vous voulez?"}
                {language === "anglais" && "Isn’t there the game you want?"}
                {language === "espagnol" && "¿No es el juego que quieres?"}
              </Link>
              <Link
                to="/contact"
                className="text-base text-red-600 cursor-pointer"
              >
                {language === "francais" && "Contactez-nous s'il vous plaît"}
                {language === "anglais" && "Please contact us at"}
                {language === "espagnol" && "Por favor contáctenos"}
              </Link>
            </div>
          ) : (
            <div className="flex flex-col gap-4 items-start select-none mt-6">
              {menuFilteredData?.slice(0, letterStep)?.map((product) => (
                <div
                  className="flex items-start justify-between gap-12 pb-8 border-b w-screen"
                  key={product?.id}
                >
                  <p className="text-lg font-semibold text-gray-700">
                    {product?.letterStart}
                  </p>
                  <div className="grid lettexrxl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-4 mx-auto w-full">
                    {product?.details?.map((productDetail) => (
                      <div
                        className="flex flex-col gap-2 items-start mt-[2px]"
                        key={productDetail?.id}
                        // style={{ display: i === 0 && "none" }}
                      >
                        <p className="text-base font-semibold text-gray-700">
                          {productDetail?.title}
                        </p>
                        <div className="flex items-center gap-2">
                          {productDetail?.items?.map((item) => (
                            <Link
                              className="flex flex-wrap text-sm capitalize text-[#3174a1] cursor-pointer transition duration-500 ease-in-out hover:text-red-600 w-full"
                              key={item?.id}
                              to={item?.link?.split("fr")[1]}
                            >
                              {item?.title}
                            </Link>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        {!menuFilteredData?.length <= 0 && (
          <div
            className="flex items-center justify-center mx-auto"
            onClick={() =>
              setLetterStep((prevLetterStep) => prevLetterStep + 2)
            }
          >
            <button className="text-sm outline-none rounded bg-red-600 text-white px-4 py-2 transition duration-300 ease-in-out hover:bg-red-700">
              {language === "anglais" && "SEE MORE"}
              {language === "espagnol" && "VER MÁS"}
              {language === "francais" && "VOIR PLUS"}
            </button>
          </div>
        )}

        <div className="flex items-center justify-center mx-auto mt-6">
          <h1 className="text-[26px] font-bold" aria-label="reviews">
            {language === "anglais" &&
              "Rated 4.8 out of 5 based on over 11,245 reviews"}
            {language === "espagnol" &&
              "Clasificado 4.8 de 5 basado en más de 11,245 reseñas"}
            {language === "francais" &&
              "Noté 4,8 sur 5 basé sur plus de 11245 avis"}
          </h1>
        </div>
        <div className="mt-6 w-full mx-auto">
          <Swiper
            slidesPerView={4}
            spaceBetween={40}
            navigation={false}
            autoplay={true}
            speed={1000}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              1280: {
                slidesPerView: 4,
                spaceBetween: 40,
              },
            }}
            modules={[Navigation, Autoplay]}
          >
            {comments?.map((comment) => (
              <SwiperSlide key={comment?._id}>
                <div className="flex flex-col items-start gap-2 p-4 rounded-lg hover:shadow-lg transitions cursor-pointer select-none">
                  <div className="flex items-center space-x-3">
                    <img
                      src={medail}
                      alt="user reviews"
                      className="h-8 w-8 object-cover mt-2"
                    />
                    <div className="flex flex-col items-start">
                      <span className="text-base font-semibold">
                        {dislocName(comment?.commentLastname)}
                      </span>
                      <span className="flex items-center gap-0">
                        <MdStars className="text-[18px] text-red-600" />
                        <MdStars className="text-[18px] text-red-600" />
                        <MdStars className="text-[18px] text-red-600" />
                        <MdStars className="text-[18px] text-red-600" />
                        <MdStars className="text-[18px] text-red-600" />
                      </span>
                    </div>
                  </div>
                  <p className="text-sm text-gray-500 ml-2 h-14">
                    {comment?.comment}
                  </p>
                  <span className="text-sm text-gray-500 ml-2 mt-1">
                    {convertDate(
                      comment?.dateToCreated
                        ? comment?.dateToCreated
                        : "2023-09-20T14:55:31.008Z"
                    )}
                  </span>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <div className="flex flex-col items-center justify-center w-full my-10">
          <a
            href="https://fr.trustpilot.com/review/ibendouma.com"
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-col items-center gap-2"
          >
            <div className="flex gap-4">
              <h2 className="text-xl font-semibold mt-3">Excellent</h2>
              <img
                src={trust5}
                alt="Trustpilot ibendouma reviews"
                className="w-40"
              />
            </div>

            <div class="text-lg text-gray-800">
              <p className="flex gap-1 text-sm">
                <span>See all</span>
                <span className="font-semibold">
                  {truspilotData?.filters?.totalNumberOfReviews}
                </span>
                <span>reviews on</span>{" "}
                <img
                  className="w-4 h-4 object-cover object-center"
                  src={trust1}
                  alt="Trustpilot ibendouma review"
                />
                <span className="font-semibold">Truspilot</span>
              </p>
            </div>
          </a>
          <div className="mt-6 w-full mx-auto">
            <Swiper
              slidesPerView={4}
              spaceBetween={40}
              navigation={false}
              autoplay={true}
              speed={1000}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                1280: {
                  slidesPerView: 4,
                  spaceBetween: 40,
                },
              }}
              modules={[Navigation, Autoplay]}
            >
              {truspilotData?.reviews
                ?.filter((comment) => comment.rating >= 5)
                ?.map((comment) => (
                  <SwiperSlide key={comment?.consumer.id}>
                    <div className="flex flex-col items-start gap-2 p-4 border  shadow-mdtransitions cursor-pointer select-none">
                      <div className="flex items-center space-x-3">
                        <img
                          src={
                            comment?.consumer.imageUrl
                              ? comment?.consumer.imageUrl
                              : defaultUser
                          }
                          alt="user reviews"
                          className="flex items-center justify-center h-10 w-10 object-cover object-center rounded-full mt-2"
                        />
                        <div className="flex items-center gap-2">
                          <img
                            src={trust5}
                            className="w-36"
                            alt="user trustpilot review"
                          />
                          <div className="flex items-center gap-1">
                            <FaCheckCircle className="text-[#3f3f46]" />{" "}
                            <span className="text-sm text-[#3f3f46] font-semibold">
                              Verified
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="flex text-xs ml-2">
                        <span className="font-bold">
                          {comment?.consumer.displayName}
                        </span>
                        {/* review on <strong>iBendouma</strong> */}
                      </div>
                      <p className="text-sm h-14 ml-2">{comment?.text}</p>
                      {/* <span className="text-sm text-gray-500 ml-2 mt-1">
                  {convertDate(
                    comment?.dateToCreated
                      ? comment?.dateToCreated
                      : "2023-09-20T14:55:31.008Z"
                  )}
                </span> */}
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </div>

        <CookieConsent
          onAccept={handleAcceptCookie}
          enableDeclineButton="Refusé"
          onDecline={handleDeclineCookie}
          location="bottom"
          buttonText={
            language === "anglais"
              ? "I agree"
              : language === "francais"
              ? "J'accepte"
              : language === "espagnol"
              ? "Acepto"
              : "I agree"
          }
          declineButtonText={
            language === "anglais"
              ? "Denied"
              : language === "francais"
              ? "Refusé"
              : language === "espagnol"
              ? "Rechazó"
              : "Denied"
          }
          cookieName="ibendoumaconfidentialitycookie"
          style={{ background: "#000000" }}
          buttonStyle={{
            color: "#4e503b",
            fontSize: "13px",
          }}
          flipButtons
          expires={5000}
          visible={cook ? "show" : "hidden"}
          debug={true}
        >
          {language === "anglais" && (
            <div className="cookie-container">
              <p
                style={{
                  letterSpacing: "1px",
                }}
              >
                By continuing to navigate on the site{" "}
                <Link
                  to="/"
                  style={{
                    color: "#76bef2",
                    fontSize: "14px",
                  }}
                >
                  ibendouma.com
                </Link>{" "}
                it implies for the user, the full and unreserved acceptance of
                <Link
                  to="/privacy-policy/#cookie-privacy"
                  style={{
                    color: "#76bef2",
                    fontSize: "14px",
                  }}
                >
                  {" "}
                  cookies
                </Link>
                .<br></br>
              </p>
            </div>
          )}
          {language === "espagnol" && (
            <div className="cookie-container">
              <p>
                La conexión y la navegación en el sitio{" "}
                <Link
                  to="/"
                  style={{
                    color: "#004a99",
                    fontSize: "14px",
                  }}
                >
                  ibendouma.com
                </Link>{" "}
                por parte del usuario implica la aceptación plena y sin reservas
                de los cookies.<br></br>
              </p>
            </div>
          )}

          {language === "francais" && (
            <div className="cookie-container">
              <p>
                La connexion et la navigation sur le site{" "}
                <Link
                  to="/"
                  style={{
                    color: "#004a99",
                    fontSize: "14px",
                  }}
                >
                  ibendouma.com
                </Link>{" "}
                par l’utilisateur implique l'acceptation intégrale et sans
                réserve des cookies.<br></br>
              </p>
            </div>
          )}
        </CookieConsent>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
      <MobileHome truspilotData={truspilotData} />
    </>
  );
};

export default Home;
