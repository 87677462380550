import React, { useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";

import axios from "axios";

import { useSelector } from "react-redux";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Country } from "country-state-city";

import emailjs from "@emailjs/browser";

import { BsCheckSquareFill } from "react-icons/bs";
import { FiSquare } from "react-icons/fi";

import logo from "../assets/iben-logo.jpg";

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validCode, setValidCode] = useState("K95F");
  const [lastname, setLastname] = useState("");
  const [firstname, setFirstName] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [profil, setProfil] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [check, setcheck] = useState(false);

  const [countryList, setCountryList] = useState(Country.getAllCountries());
  const [nextStep, setNextStep] = useState(false);

  const { language } = useSelector((state) => state.language);

  const notifyEmail = () =>
    toast.success(
      "Vérifier votre adresse e-mail, le code de vérification vous est envoyé"
    );
  const notifyEmailEnglish = () =>
    toast.success(
      "Check your e-mail address, the verification code is sent to you"
    );

  const notifyErrorCode = () => toast.error("Votre code n'est pas valide");

  const notifyEmailEspanish = () =>
    toast.success(
      "Compruebe su dirección de correo electrónico, se le envía el código de verificación"
    );

  const notyFySuccessRegister = () => toast.success("Inscription réussie");

  const notyFySuccessRegisterEnglish = () =>
    toast.success("Registration successful");

  const notyFySuccessRegisterSpanish = () => toast.success("Registro exitoso");

  const notifyEmailError = () => toast.error("Email inconnu");

  const notifyEmailErrorEnglish = () => toast.error("Email unknown");

  const notifyErrorCheckError = () =>
    toast.error(
      "Veuillez valider les termes et conditions et la politique de confidentialité"
    );
  const codeErrorBackend = (text) => toast.error(text);
  const emailErrorBackend = (text) => toast.error(text);
  const passwordErrorBackend = (text) => toast.error(text);

  const notifyErrorCheckErrorEnglish = () =>
    toast.error("Please validate the terms and conditions and privacy policy");

  const notifyConfirmErrorPass = () =>
    toast.error("Les mot de passe ne correspondent pas");

  const notifyErrorConfirmPassEnglish = () =>
    toast.error("Passwords do not match");

  const notifyPasswordError = () =>
    toast.error("Le mot de passe doit avoir 6 caracteres minimum");

  const notifyFirstStepError = () =>
    toast.error("Veuillez remplir tous les champs avant de continuer");

  const notifyPasswordErrorEnglish = () =>
    toast.error("Password must have a minimum of 6 characters");
  const notifyvalidCodeErrorEnglish = () =>
    toast.error("Please enter a valid code");
  const notifyvalidCodedError = () =>
    toast.error("Veuillez entrer un code valide");

  const notifyPassLengthFrError = () =>
    toast.error("Le mot de passe doit doit avoir au minimum 8 caracteres");
  const notifyPassLengthEnError = () =>
    toast.error("The password must have at least 8 characters");

  const notifyPhoneFrError = () =>
    toast.error("Veuillez saisir un numero de telephone valide");
  const notifyPhoneEnError = () =>
    toast.error("Please enter a valid phone number");
  const notifyPhonLengtheFrError = () =>
    toast.error("Le numero de telephone doit avoir au moins 7 chiffres");
  const notifyPhoneLengthEnError = () =>
    toast.error("The phone number must have at least 7 digits");

  const notifyEmailFrError = () =>
    toast.error("Veuillez saisir un email valide");
  const notifyEmailEnError = () => toast.error("Please enter a valid email");

  const notifyPostalFrError = () =>
    toast.error("Le code postal n'est pas valide");
  const notifyPostalEnError = () => toast.error("The postal code is not valid");

  const notifyBackErrors = (nameErrors) => toast.error(nameErrors);

  const handleNextStep = () => {
    const phoneNumberRegex = /^(?:\+?[1-9])?([-.\s]?[0-9]{2,})+$/;
    const emailRegex = /^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/;
    if (
      !lastname ||
      !firstname ||
      !email ||
      !phone ||
      !password ||
      password.length < 8 ||
      password !== confirmPassword ||
      !phoneNumberRegex.test(phone) ||
      !emailRegex.test(email) ||
      phone.length < 7
    ) {
      if (!lastname || !firstname || !email || !phone || !password) {
        notifyFirstStepError();
      }
      if (password.length < 8) {
        language === "anglais" && notifyPassLengthEnError();
        language === "francais" && notifyPassLengthFrError();
      }
      if (password !== confirmPassword) {
        language === "anglais" && notifyErrorConfirmPassEnglish();
        language === "francais" && notifyConfirmErrorPass();
      }
      if (!emailRegex.test(email)) {
        language === "anglais" && notifyEmailEnError();
        language === "francais" && notifyEmailFrError();
      }
      if (!phoneNumberRegex.test(phone)) {
        language === "anglais" && notifyPhoneEnError();
        language === "francais" && notifyPhoneFrError();
      }
      if (phone.length < 7) {
        language === "francais" && notifyPhonLengtheFrError();
        language === "anglais" && notifyPhoneLengthEnError();
      }
    } else {
      setNextStep(true);
    }
  };

  const verifyUser = () => {
    if (!email || !password) {
      if (!email) {
        language === "anglais" && notifyEmailErrorEnglish();
        language === "francais" && notifyEmailError();
      }
      if (!password) {
        language === "anglais" && notifyPasswordErrorEnglish();
        language === "francais" && notifyPasswordError();
      }
    } else if (confirmPassword !== password) {
      language === "anglais" && notifyErrorConfirmPassEnglish();
      language === "francais" && notifyConfirmErrorPass();
    } else {
      try {
        const codeGenerated = () => {
          const generateRandomCode =
            "0123456789abcdefghijklmnopkrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";

          let myCode = "";
          for (let i = 0; i < 4; i++) {
            let code = Math.floor(Math.random() * generateRandomCode.length);
            myCode += generateRandomCode[code];
          }
          return myCode;
        };

        const codeSending = codeGenerated();
        var templateParams = {
          user_name: "ibendouma",
          user_email: email,
          message: `Votre code de confirmation est: ${codeSending}`,
        };

        emailjs
          .send(
            "service_65ohhqn",
            "template_2m3hhxh",
            templateParams,
            "JSZ-EVq9iVjCDp0k1"
          )
          .then(
            function (response) {
              axios({
                method: "post",
                url: `${process.env.REACT_APP_CLIENT_URL}/code`,
                data: {
                  code: codeSending,
                },
              }).then((res) => {
                console.log(res.data);
                language === "francais" && notifyEmail();
                language === "anglais" && notifyEmailEnglish();
                language === "espagnol" && notifyEmailEspanish();
              });
              console.log(response);
            },
            function (error) {
              console.log("FAILED...", error);
            }
          );
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleSubmit = () => {
    const postalRegex = /^\d{5}$/;

    if (!address) {
      notifyFirstStepError();
    } else if (!postalRegex.test(postalCode)) {
      language === "anglais" && notifyPostalFrError();
      language === "francais" && notifyPostalEnError();
    } else if (!city) {
      notifyFirstStepError();
    } else if (!validCode) {
      language === "francais" && notifyvalidCodedError();
      language === "anglais" && notifyvalidCodeErrorEnglish();
    } else if (!check) {
      language === "anglais" && notifyErrorCheckErrorEnglish();
      language === "francais" && notifyErrorCheckError();
    } else {
      try {
        axios({
          method: "post",
          url: `${process.env.REACT_APP_CLIENT_URL}/users/register`,
          data: {
            email,
            password,
            lastname,
            firstname,
            phone,
            city,
            address,
            country,
            code: validCode,
            profil,
            postalCode,
          },
        })
          .then((res) => {
            if (res.data.messageError) {
              codeErrorBackend(res.data.messageError);
            }
            if (res.data.errors) {
              emailErrorBackend(res.data.errors.email);
              passwordErrorBackend(res.data.errors.password);
            } else {
              language === "francais" && notyFySuccessRegister();
              language === "anglais" && notyFySuccessRegisterEnglish();
              language === "espagnol" && notyFySuccessRegisterSpanish();
              window.location = "/login";
            }
          })
          .catch(({ response }) => notifyBackErrors(response?.data?.message));
      } catch (error) {
        console.log(error);
        notifyErrorCode();
      }
    }
  };

  // const location = useLocation();

  return (
    <div className="h-screen flex items-center justify-center bg-white">
      <div className="w-1/2 hidden scustom:flex h-screen items-center justify-center relative bg-red-600">
        <Link to="/">
          <div className="flex flex-col gap-2 items-center">
            <img
              src={logo}
              alt="logo"
              className="h-[200px] w-[200px] rounded-full object-cover object-center pb-1 cursor-pointer"
            />
            <div className="flex flex-col items-start justify-between">
              <p className="text-3xl font-extrabold text-[#C9C9C9]">
                iBENDOUMA
              </p>
              <p className="text-xl font-bold text-[#959595]">GAME FOR GAMER</p>
            </div>
          </div>
        </Link>
      </div>
      <div className="w-1/2 flex h-screen flex-col items-center justify-center gap-4 mt-8 custom:mt-0">
        {!nextStep && (
          <div className="flex flex-col items-start gap-3 -mt-16">
            <h1 className="text-base custom:text-xl font-normal">
              {language === "francais" && "VOS INFORMATIONS PERSONNELLES"}
              {language === "anglais" && "YOUR PERSONAL INFORMATION"}
              {language === "espagnol" && "TU INFORMACIÓN PERSONAL"}
            </h1>
            <div className="flex flex-col items-start gap-1">
              <label
                htmlFor="lastname"
                className="text-base font-medium text-gray-700"
              >
                {language === "francais" && "Prenom"}
                {language === "anglais" && "Lastname"}
                {language === "espagnol" && "Nombre de pila"}
              </label>
              <input
                type="text"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
                name="lastname"
                id="lastname"
                className="appearance-none block w-[300px] custom:w-[300px] signup:w-[350px] text-sm p-2 border border-red-600 rounded-md shadow-sm placeholder:text-gray-400 focus:outline-none focus:border-red-600 sm:text-sm"
                placeholder={language === "anglais" ? "Last Name:" : "Prénom"}
              />
            </div>
            <div className="flex flex-col items-start gap-1">
              <label
                htmlFor="firstname"
                className="text-base font-medium text-gray-700"
              >
                {language === "francais" && "Nom"}
                {language === "anglais" && "Firstname"}
                {language === "espagnol" && "Apellido"}
              </label>
              <input
                type="text"
                value={firstname}
                onChange={(e) => setFirstName(e.target.value)}
                name="firstname"
                id="firstname"
                className="appearance-none block w-[300px] custom:w-[300px] signup:w-[350px] text-sm p-2 border border-red-600 rounded-md shadow-sm placeholder:text-gray-400 focus:outline-none focus:border-red-600 sm:text-sm"
                placeholder={language === "anglais" ? "First Name:" : "Nom"}
              />
            </div>

            <div className="flex flex-col items-start gap-1">
              <label
                htmlFor="email"
                className="text-base font-medium text-gray-700"
              >
                {language === "francais" && "Email"}
                {language === "anglais" && "E-mail"}
                {language === "espagnol" && "Electrónico"}
              </label>
              <input
                type="email"
                name="emal"
                id="email"
                value={email}
                className="appearance-none block w-[300px] custom:w-[300px] signup:w-[350px] text-sm p-2 border border-red-600 rounded-md shadow-sm placeholder:text-gray-400 focus:outline-none focus:border-red-600 sm:text-sm"
                onChange={(e) => setEmail(e.target.value)}
                placeholder={
                  language === "anglais" ? "Email adress:" : "Adresse email"
                }
              />
            </div>

            <div className="flex flex-col items-start gap-1">
              <label
                htmlFor="phone"
                className="text-base font-medium text-gray-700"
              >
                {language === "francais" && "Téléphone"}
                {language === "anglais" && "Phone"}
                {language === "espagnol" && "Teléfono"}
              </label>
              <input
                type="tel"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                name="phone"
                id="phone"
                className="appearance-none block w-[300px] custom:w-[300px] signup:w-[350px] text-sm p-2 border border-red-600 rounded-md shadow-sm placeholder:text-gray-400 focus:outline-none focus:border-red-600 sm:text-sm"
                placeholder={language === "anglais" ? "Phone" : "Téléphone"}
              />
            </div>

            <div className="flex flex-col items-start gap-1">
              <label
                htmlFor="password"
                className="text-base font-medium text-gray-700"
              >
                {language === "francais" && "Mot de passe"}
                {language === "anglais" && "Password"}
                {language === "espagnol" && "Clave"}
              </label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                name="password"
                id="password"
                className="appearance-none block w-[300px] custom:w-[300px] signup:w-[350px] text-sm p-2 border border-red-600 rounded-md shadow-sm placeholder:text-gray-400 focus:outline-none focus:border-red-600 sm:text-sm"
                placeholder={
                  language === "anglais" ? "Password" : "Mot de passe"
                }
              />
            </div>

            <div className="flex flex-col items-start gap-1">
              <label
                htmlFor="password"
                className="text-base font-medium text-gray-700"
              >
                {language === "francais" && "Confirmation de mo de passe"}
                {language === "anglais" && "Confirmation"}
                {language === "espagnol" && "Confirmación"}
              </label>
              <input
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                required
                placeholder={
                  language === "anglais"
                    ? "Confirm password"
                    : "Confirmer le mot de passe"
                }
                value={confirmPassword}
                className="appearance-none block w-[300px] custom:w-[300px] signup:w-[350px] text-sm p-2 border border-red-600 rounded-md shadow-sm placeholder:text-gray-400 focus:outline-none focus:border-red-600 sm:text-sm"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            
            <button
              onClick={handleNextStep}
              className="flex items-center mt-2 justify-center outline-none rounded w-[300px] custom:w-[300px] signup:w-[350px] text-sm p-[10px] bg-red-600 text-white transition duration-300 ease-in-out hover:bg-red-700"
            >
              {language === "francais" && "Etape suivante"}
              {language === "anglais" && "Next step"}
              {language === "espagnol" && "Etapa siguiente"}
            </button>
            {language === "francais" && (
              <div className="text-sm w-full text-center">
                Vous avez déjà un compte?{" "}
                <Link to="/login" className="text-red-300 underline">
                  connectez vous
                </Link>
              </div>
            )}
            {language === "anglais" && (
              <div className="text-sm w-full text-center">
                Already have an account?{" "}
                <Link to="/login" className="text-red-300 underline">
                  sign in
                </Link>
              </div>
            )}
            {language === "francais" && (
              <div className="text-sm w-full text-center">
                Ya tienes una cuenta?{" "}
                <Link to="/login" className="text-red-300 underline">
                  acceso
                </Link>
              </div>
            )}
          </div>
        )}
        {nextStep && (
          <div className="flex flex-col items-center gap-4 -mt-12">
            <h1 className="text-base custom:text-xl">
              {language === "francais" && "INFORMATIONS DE FACTURATION"}
              {language === "anglais" && "INVOICE INFORMATION"}
              {language === "espagnol" && "INFORMACIONES DE FACTURACIÓN"}
            </h1>
            <div className="flex flex-col items-start gap-2">
              <label
                htmlFor="adress"
                className="text-base font-medium text-gray-700"
              >
                {language === "francais" && "Adresse"}
                {language === "anglais" && "Address"}
                {language === "espagnol" && "Dirección"}
              </label>
              <input
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                name="adress"
                id="adress"
                className="appearance-none block w-[300px] custom:w-[300px] signup:w-[350px] text-sm p-2 border border-red-600 rounded-md shadow-sm placeholder:text-gray-400 focus:outline-none focus:border-red-600 sm:text-sm"
                placeholder={language === "anglais" ? "Adress" : "Adresse"}
              />
            </div>
            <div className="flex flex-col items-start gap-2">
              <label
                htmlFor=""
                className="text-base font-medium text-gray-700"
              >
                {language === "francais" && "Pays"}
                {language === "anglais" && "Country"}
                {language === "espagnol" && "País"}
              </label>
              <select
                name="country"
                id="country"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                className=" block w-[300px] custom:w-[300px] signup:w-[350px] text-sm p-2 border border-red-600 rounded-md shadow-sm placeholder:text-gray-400 focus:outline-none focus:border-red-600 sm:text-sm"
              >
                <option value="Choisir un pays">
                  {" "}
                  {language === "francais" && "Choisir un pays"}
                  {language === "anglais" && "Select a country"}
                  {language === "espagnol" && "Elegir país"}
                </option>
                {countryList.slice(2).map((singleCountry) => (
                  <option value={singleCountry?.name}>
                    {singleCountry?.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex flex-col items-start gap-2">
              <label
                htmlFor=""
                className="text-base font-medium text-gray-700"
              >
                {" "}
                {language === "francais" && "Ville"}
                {language === "anglais" && "City"}
                {language === "espagnol" && "Pueblo"}
              </label>
              <input
                type="text"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                className="appearance-none block w-[300px] custom:w-[300px] signup:w-[350px] text-sm p-2 border border-red-600 rounded-md shadow-sm placeholder:text-gray-400 focus:outline-none focus:border-red-600 sm:text-sm"
                placeholder={
                  language === "anglais" ? "Your city" : "Votre ville"
                }
              />
            </div>

            <div className="flex flex-col items-start gap-2">
              <label
                htmlFor="postal"
                className="text-base font-medium text-gray-700"
              >
                {language === "francais" && "Code Postal"}
                {language === "anglais" && "zip code"}
                {language === "espagnol" && "código postal"}
              </label>
              <input
                type="text"
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
                name="postal"
                id="postal"
                className="appearance-none block w-[300px] custom:w-[300px] signup:w-[350px] text-sm p-2 border border-red-600 rounded-md shadow-sm placeholder:text-gray-400 focus:outline-none focus:border-red-600 sm:text-sm"
                placeholder={
                  language === "anglais" ? "Zip code" : "Code Postal"
                }
              />
            </div>
            <div className="flex items-start text-sm sm:text-base gap-1">
              {check ? (
                <span className="text-red-600 mt-[1px]">
                  <BsCheckSquareFill />
                </span>
              ) : (
                <span className="mt-[1px]" onClick={() => setcheck(true)}>
                  <FiSquare />
                </span>
              )}
              {language === "francais" && (
                <div className="flex items-center text-xs w-[300px] custom:w-[300px] signup:w-[350px] flex-wrap">
                  J'accepte les{" "}
                  <Link
                    to="/term-and-conditions"
                    className="ml-[2px] mr-[2px] font-semibold"
                  >
                    termes et conditions
                  </Link>{" "}
                  et la{" "}
                  <Link to="/privacy-policy" className="font-semibold ml-[2px]">
                    politique de confidentialité
                  </Link>
                </div>
              )}
              {language === "anglais" && (
                <div className="flex items-center text-xs w-[300px] custom:w-[300px] signup:w-[350px] flex-wrap">
                  I accept the{" "}
                  <Link
                    to="/term-and-conditions"
                    className="ml-[2px] mr-[2px] font-semibold"
                  >
                    terms and conditions
                  </Link>{" "}
                  and the{" "}
                  <Link to="/privacy-policy" className="font-semibold ml-[2px]">
                    privacy policy
                  </Link>
                </div>
              )}
              {language === "espagnol" && (
                <div className="flex items-center text-xs w-[300px] custom:w-[300px] signup:w-[350px] flex-wrap">
                  Acepto los{" "}
                  <Link
                    to="/term-and-conditions"
                    className="ml-[2px] mr-[2px] font-semibold"
                  >
                    términos y condiciones
                  </Link>{" "}
                  y la{" "}
                  <Link to="/privacy-policy" className="font-semibold ml-[2px]">
                    política de privacidad
                  </Link>
                </div>
              )}
            </div>
            <button
              onClick={handleSubmit}
              className="flex items-center mt-2 justify-center outline-none rounded w-[300px] custom:w-[300px] signup:w-[350px] text-sm p-[10px] bg-red-600 text-white transition duration-300 ease-in-out hover:bg-red-700"
            >
              {language === "francais" && "Confirmer l'inscription"}
              {language === "anglais" && "Confirm registration"}
              {language === "espagnol" && "Confirmar registro"}
            </button>
          </div>
        )}
      </div>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default SignUp;
