import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { FiUploadCloud } from "react-icons/fi";
import SideBar from "./SideBar";
import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";

import axios from "axios";
import { removeUser, updateUser } from "../../features/userSlice";

function UpdateProfile() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state?.user);
  const { language } = useSelector((state) => state.language);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [profil, setProfil] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [base64Image, setBase64Image] = useState("");

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    multiple: false,
    maxSize: 100000, // la taille maximale de l'image
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0]; // Récupérer le premier fichier du tableau
      setSelectedFile(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setBase64Image(reader.result);
      };
      reader.readAsDataURL(file);
    },
  });

  const handleUpdate = () => {
    axios({
      method: "put",
      url: `${process.env.REACT_APP_CLIENT_URL}/users/${user?.person?._id}`,
      data: {
        lastname,
        firstname,
        phone,
        address,
        profil: base64Image,
      },
    })
      .then((res) => {
        dispatch(updateUser(res?.data));
        toast.success("Votre Profil a été mis à jour avec succès!");
        setFirstname("");
        setLastname("");
        setPhone("");
        setAddress("");
        setBase64Image("");
      })
      .catch((error) => console.log(error));
  };

  const handleDeleteUser = () => {
    axios({
      method: "delete",
      url: `${process.env.REACT_APP_CLIENT_URL}/users/${user?.person?._id}`,
    })
      .then((res) => {
        dispatch(removeUser());
        toast.success("Votre compte a été supprimé");
        window.location.href = "/";
      })
      .catch((error) => console.log(error));
  };

  return (
    <SideBar>
      <div className="flex flex-col gap-4">
        <h2 className="text-xl font-bold">
          {language === "francais" && "Profil"}
          {language === "anglais" && "Profile"}
          {language === "espagnol" && "Perfil"}
        </h2>
        <div className="w-full text-center float-right">
          {base64Image ? (
            <img
              src={base64Image}
              alt="profile"
              className="h-52 w-52 object-cover object-center rounded-[10px]"
            />
          ) : (
            <div
              className="px-6 pt-5 pb-6 border-2 border-dashed rounded-md cursor-pointer"
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <span className="mx-auto flex justify-center">
                <FiUploadCloud className="text-3xl text-main" />
              </span>
              <p className="text-sm mt-2">
                {language === "francais" && "Glissez votre image ici"}
                {language === "anglais" && "Drag your image here"}
                {language === "espagnol" && "Arrastra tu imagen aquí"}
              </p>
              <em className="text-xs text-gray-400">
                {language === "francais" &&
                  "(Seulement *.jpeg et *.png images sera acceptée)"}
                {language === "anglais" &&
                  "(Only *.jpeg and *.png images will be accepted)"}
                {language === "espagnol" &&
                  "(Solo se aceptarán *.jpeg y *.png images)"}
              </em>
            </div>
          )}
        </div>
        <div className="grid sm:grid-cols-2 gap-4">
          <div className="text-sm">
            <label className="font-semibold text-gray-400">
              {language === "francais" && "Prénom"}
              {language === "anglais" && "Lastname"}
              {language === "espagnol" && "Nombre"}:
            </label>
            <input
              required
              type="text"
              placeholder={user?.lastname}
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
              className="w-full text-sm mt-2 p-4 border border-gray-300 rounded text-gray-500 bg-white outline-none"
            />
          </div>
          <div className="text-sm">
            <label className="font-semibold text-gray-400">
              {language === "francais" && "Nom"}
              {language === "anglais" && "Firstname"}
              {language === "espagnol" && "apellido"}:
            </label>
            <input
              required
              type="text"
              placeholder={user?.firstname}
              value={firstname}
              onChange={(e) => setFirstname(e.target.value)}
              className="w-full text-sm mt-2 p-4 border border-gray-300 rounded text-gray-500 bg-white outline-none"
            />
          </div>
        </div>
        <div className="grid sm:grid-cols-2 gap-4">
          <div className="text-sm">
            <label className="font-semibold text-gray-400">
              {language === "francais" && "Adresse"}
              {language === "anglais" && "Address"}
              {language === "espagnol" && "Dirección"}
            </label>
            <input
              required
              type="text"
              placeholder="adresse"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              className="w-full text-sm mt-2 p-4 border border-gray-300 rounded text-gray-500 bg-white outline-none"
            />
          </div>

          <div className="text-sm">
            <label className="font-semibold text-gray-400">
              {language === "francais" && "Téléphone"}
              {language === "anglais" && "Phone"}
              {language === "espagnol" && "Teléfono"}
            </label>
            <input
              required
              type="tel"
              placeholder={user?.phone}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="w-full text-sm mt-2 p-4 border border-gray-300 rounded text-gray-500 bg-white outline-none"
            />
          </div>
        </div>
        <div className="flex gap-2 flex-wrap flex-col-reverse sm:flex-row justify-between items-center my-4">
          <button
            className="bg-main font-medium text-white py-3 px-6 rounded sm:w-auto w-full transition duration-300 ease-in-out hover:bg-green-700"
            onClick={handleUpdate}
          >
            {language === "francais" && "Mise à jour du profil"}
            {language === "anglais" && "Update profile"}
            {language === "espagnol" && "Actualizar el perfil"}
          </button>
          <button
            className="bg-flash font-medium text-white py-3 px-6 rounded sm:w-auto w-full transition duration-300 ease-in-out hover:bg-red-700"
            onClick={handleDeleteUser}
          >
            {language === "francais" && "Supprimer le compte"}
            {language === "anglais" && "Delete account"}
            {language === "espagnol" && "Eliminar la cuenta"}
          </button>
        </div>
      </div>
    </SideBar>
  );
}

export default UpdateProfile;
