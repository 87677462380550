import pic from "../../assets/products/compte.png";
import draconiros_cheat_best from "../../assets/images_account/draconiros_cheat_best.jpg";
import dracorinros_account from "../../assets/images_account/dracorinros_account.jpg";
import ecaflip_orukam from "../../assets/images_account/ecaflip_orukam.jpg";
import eliotrop_imagiro_account from "../../assets/images_account/eliotrop_imagiro_account.jpg";
import eliotrop_omega_certifie from "../../assets/images_account/eliotrop_omega_certifie.jpg";
import enutrof_imagiro_certifie from "../../assets/images_account/enutrof_imagiro_certifie.jpg";
import forgelance_orukam from "../../assets/images_account/forgelance_orukam.jpg";
import hellmina_full_stuff from "../../assets/images_account/hellmina_full_stuff.jpg";
import hellmina_hupper_fullstuff from "../../assets/images_account/hellmina_hupper_fullstuff.jpg";
import hypermage_opti_dofus from "../../assets/images_account/hypermage_opti_dofus.jpg";
import imagiro_omega_safe from "../../assets/images_account/imagiro_omega_safe.jpg";
import imagiro_panda_fullstuf from "../../assets/images_account/imagiro_panda_fullstuf.jpg";
import orukam_account from "../../assets/images_account/orukam_account.jpg";
import orukam_cra_full from "../../assets/images_account/orukam_cra_full.jpg";
import osamodas_orukam from "../../assets/images_account/osamodas_orukam.jpg";
import ouginak_imagiro from "../../assets/images_account/ouginak_imagiro.jpg";
import pandawa_orukam_account from "../../assets/images_account/pandawa_orukam_account.jpg";
import roub_obti_dofus from "../../assets/images_account/roub_obti_dofus.jpg";
import roublard_imagiro from "../../assets/images_account/roublard_imagiro.jpg";
import sadida_omega_imagiro from "../../assets/images_account/sadida_omega_imagiro.jpg";
import steamer_omega_imagiro from "../../assets/images_account/steamer_omega_imagiro.jpg";
import talkasha_fullstuff from "../../assets/images_account/talkasha_fullstuff.jpg";
import tylezia_sram_full from "../../assets/images_account/tylezia_sram_full.jpg";
import xelor_omega_imagiro from "../../assets/images_account/xelor_omega_imagiro.jpg";
import xelor_omega_orukam from "../../assets/images_account/xelor_omega_orukam.jpg";
import kamas from "../../assets/product-imageandlogo/dofus-kamas.png";
import touch from "../../assets/product-imageandlogo/dofus-touch.png";
import retro from "../../assets/product-imageandlogo/dofus-retro.png";

export const data = [
  /////////////////// LETTER A //////////////////////////

  {
    id: 88705,
    letterStart: "A",
    details: [
      { id: 857051, name: "Atlantica Online", categories: ["Or"] },
      {
        id: 857052,
        letterStart: "A",
        name: "APEX Legends",
        categories: ["Comptes"],
      },
      {
        id: 847053,
        letterStart: "A",
        name: "APEX Legends Mobile",
        categories: ["Comptes"],
      },
      {
        id: 837054,
        letterStart: "A",
        name: "Aion Classic",
        categories: ["Kinah"],
      },
      {
        id: 818055,
        letterStart: "A",
        name: "Amazon Prime",
        categories: ["Membership"],
      },
      {
        id: 816056,
        letterStart: "A",
        name: "Albion Online",
        categories: ["Silver", "Articles"],
      },
      {
        id: 814057,
        letterStart: "A",
        name: "Animal Crossing:New Horizons",
        categories: ["Bells", "Articles"],
      },
      {
        id: 813058,
        letterStart: "A",
        name: "Apple One",
        categories: ["Articles"],
      },
      {
        id: 813159,
        letterStart: "A",
        name: "ArcheAge",
        categories: ["Or"],
      },
      {
        id: 813261,
        letterStart: "A",
        name: "ArcheAge Unchained",
        categories: ["Or"],
      },
      {
        id: 813362,
        letterStart: "A",
        name: "ARK:Survival Evolved",
        categories: ["Articles"],
      },
      {
        id: 813463,
        letterStart: "A",
        name: "AFK Arena",
        categories: ["Comptes"],
      },
      {
        id: 813564,
        letterStart: "A",
        name: "Arena of Valor",
        categories: ["Voucher"],
      },
    ],
  },

  /////////////////// LETTER B //////////////////////////

  {
    id: 81365,
    letterStart: "B",
    details: [
      {
        id: 413511,
        letterStart: "B",
        name: "Brawl Stars",
        categories: ["Comptes"],
      },
      {
        id: 413512,
        letterStart: "B",
        name: "Broken Ranks",
        categories: ["Comptes"],
      },
      {
        id: 413513,
        letterStart: "B",
        name: "Brown Dust",
        categories: ["Comptes"],
      },
      {
        id: 413514,
        letterStart: "B",
        name: "Blade and Soul",
        categories: ["Or"],
      },
      {
        id: 413515,
        letterStart: "B",
        name: "Bless Unleashed",
        categories: ["StarSeeds"],
      },
      {
        id: 413516,
        letterStart: "B",
        name: "Black Desert Online",
        categories: ["Silver"],
      },
      {
        id: 413517,
        letterStart: "B",
        name: "Borderlands 3",
        categories: ["Articles"],
      },
      {
        id: 413517,
        letterStart: "B",
        name: "Back 4 Blood",
        categories: ["Comptes"],
      },
      {
        id: 413518,
        letterStart: "B",
        name: "Badlanders",
        categories: ["Articles"],
      },
      {
        id: 413518,
        letterStart: "B",
        name: "Battlefield",
        categories: ["Comptes"],
      },
    ],
  },

  /////////////////// LETTER C //////////////////////////

  {
    id: 88395,
    letterStart: "C",
    details: [
      {
        id: 413519,
        letterStart: "C",
        name: "Call of Duty:Modern Warfare",
        categories: ["Comptes"],
      },
      {
        id: 413520,
        letterStart: "C",
        name: "Clash of Clans",
        categories: ["Comptes"],
      },
      {
        id: 413521,
        letterStart: "C",
        name: "Clash Royale",
        categories: ["Comptes"],
      },
      {
        id: 413522,
        letterStart: "C",
        name: "CS:GO",
        categories: ["Articles", "Comptes"],
      },
      {
        id: 413523,
        letterStart: "C",
        name: "Castle Clash",
        categories: ["Comptes"],
      },
      {
        id: 413524,
        letterStart: "C",
        name: "Crossout",
        categories: ["Credits", "Comptes"],
      },
      {
        id: 413525,
        letterStart: "C",
        name: "Cabal Online",
        categories: ["Alz"],
      },
      {
        id: 413526,
        letterStart: "C",
        name: "Chimeraland",
        categories: ["Currency"],
      },
      {
        id: 413526,
        letterStart: "C",
        name: "Crunchyroll",
        categories: ["Crunchyroll Premium"],
      },
    ],
  },

  {
    id: 88315,
    letterStart: "D",
    details: [
      {
        id: 413519,
        letterStart: "D",
        name: "Dofus",
        categories: ["Kamas", "Comptes", "Articles"],
      },
      {
        id: 413520,
        letterStart: "D",
        name: "Dofus Touch",
        categories: ["Kamas", "Comptes", "Articles"],
      },
      {
        id: 413521,
        letterStart: "D",
        name: "Dofus Retro",
        categories: ["Kamas", "Comptes", "Articles"],
      },
    ],
  },
];

export const mostSent = [
  /////////////////////// MOST SENT //////////////////////////
  {
    id: 65789,
    letterStart: "F",
    name: "FIFA 23",
    categories: ["Credits", "Comptes"],
  },
  {
    id: 89765,
    letterStart: "D",
    name: "Dofus",
    categories: ["Kamas", "Comptes", "Articles"],
  },
  {
    id: 89365,
    letterStart: "D",
    name: "Dofus Touch",
    categories: ["Kamas", "Comptes", "Articles"],
  },
  {
    id: 49765,
    letterStart: "D",
    name: "Dofus Retro",
    categories: ["Kamas", "Comptes", "Articles"],
  },
  {
    id: 30565,
    letterStart: "L",
    name: "Lost Ark",
    categories: ["Or", "Comptes", "Articles"],
  },
  {
    id: 28765,
    letterStart: "L",
    name: "League of Legends",
    categories: ["Comptes", "Gamepal"],
  },
  {
    id: 28765,
    letterStart: "R",
    name: "Rocket League",
    categories: ["Articles", "Credits"],
  },
  {
    id: 16305,
    letterStart: "W",
    name: "WOW Classic",
    categories: [
      "Or",
      "WotLKOr",
      "Classic",
      "Era/SOM",
      "Comptes",
      "Articles",
      "Boosting",
      "Services",
    ],
  },
  {
    id: 55505,
    letterStart: "V",
    name: "Valorant",
    categories: ["ComptesGift", "Card", "Boosting and Gamepal"],
  },

  {
    id: 66605,
    letterStart: "C",
    name: "Clash of Clans",
    categories: ["Comptes"],
  },
  {
    id: 77705,
    letterStart: "D",
    name: "Dofus Touch",
    categories: ["Kamas", "Articles", "Comptes"],
  },
  {
    id: 88805,
    letterStart: "F",
    name: "Fortinte",
    categories: ["Comptes", "Articles", "V bucks"],
  },
];

//////////// Données pour les commentaires ////////////////

export const commentData = [
  {
    id: 6578,
    firstname: "Mario",
    lastname: "Gomez",
    message: "Livraison rapide bravo",
    stars: 5,
    date: "1h ago",
  },
  {
    id: 6571,
    firstname: "Aymen",
    lastname: "Chahir",
    message: "Service d'assistance impeccable",
    stars: 5,
    date: "1 year ago",
  },
  {
    id: 6572,
    firstname: "Ilyass",
    lastname: "Bendouma",
    message: "Service d'assistance et qualité des produits impeccable",
    stars: 5,
    date: "2 months ago",
  },
  {
    id: 6573,
    firstname: "Yassine",
    lastname: "Diop",
    message: "Facile à comprendre",
    stars: 5,
    date: "2h ago",
  },
  {
    id: 6574,
    firstname: "John",
    lastname: "Doe",
    message: "I am very satisfied",
    stars: 5,
    date: "5h ago",
  },
  {
    id: 6575,
    firstname: "Hiba",
    lastname: "Zinaoui",
    message: "Satisfait du produit",
    stars: 5,
    date: "1h ago",
  },
  {
    id: 6576,
    firstname: "Manuella",
    lastname: "Romano",
    message: "Excellent produit",
    stars: 5,
    date: "10h ago",
  },
  {
    id: 6577,
    firstname: "Mouhamed",
    lastname: "Safir",
    message: "Satisfait de la livraison",
    stars: 5,
    date: "1 week ago",
  },
];

///////////////////////// Dofus Kamas /////////////////////////////////

export const dofusKamas = [];

///////////////////////// Dofus Retro ///////////////////////////////////

export const dofusRetro = [];

//////////////////////// Dofus Touch /////////////////////////////////////

export const dofusTouch = [];

/////////////////////// SELLER INFORMATIONS //////////////////////////////////

export const sellerInfos = [
  {
    firsname: "Johnson",
    stars: 4.5,
    deleiverySpeed: 5,
    price: 23.87,
  },
  {
    firsname: "Mounir",
    stars: 4.5,
    deleiverySpeed: 15,
    price: 24.17,
  },
  {
    firsname: "Yassine",
    stars: 4.5,
    deleiverySpeed: 10,
    price: 24.57,
  },
  {
    firsname: "Amir",
    stars: 4.5,
    deleiverySpeed: 5,
    price: 25.16,
  },
  {
    firsname: "Mouhamed",
    stars: 4.5,
    deleiverySpeed: 5,
    price: 25.7,
  },
  {
    firsname: "Ilyass",
    stars: 4.5,
    deleiverySpeed: 5,
    price: 26.5,
  },
  {
    firsname: "Ibendouma",
    stars: 4.5,
    deleiverySpeed: 5,
    price: 26.75,
  },
  {
    firsname: "Tariq",
    stars: 4.5,
    deleiverySpeed: 5,
    price: 26.8,
  },
  {
    firsname: "Tarmin",
    stars: 4.5,
    deleiverySpeed: 5,
    price: 26.95,
  },
  {
    firsname: "Aymen",
    stars: 4.5,
    deleiverySpeed: 5,
    price: 27.1,
  },
];

////////////////////// PRODUCTS TESTS /////////////////////////////////////

export const testProduct = [
  {
    id: 2345,
    name: "Draconiros /Iop 200 opti cheat Best stuff en jeu",
    picture: draconiros_cheat_best,
    warranty: "30 jours de garantie",
    serveur: "Dofus Kamas",
    stars: 4.9,
    price: "7800",
  },
  {
    id: 1345,
    name: "Draconiros /Roub 200 opti Best meuilleur stuff en jeu",
    picture: dracorinros_account,
    warranty: "30 jours de garantie",
    serveur: "Dofus Kamas",
    stars: 4.9,
    price: "7805",
  },
  {
    id: 4345,
    name: "ECAFLIP 150 Orukam 100% Safe . Jamais Certifier !! (eca)",
    picture: ecaflip_orukam,
    warranty: "15 jours de garantie",
    serveur: "Dofus Kamas",
    stars: 4.8,
    price: "169",
  },
  {
    id: 6345,
    name: "Eliotrope 200 Imagiro,jamais certifié",
    picture: eliotrop_imagiro_account,
    warranty: "30 jours de garantie",
    serveur: "Dofus Kamas",
    stars: 4.8,
    price: "150",
  },
  {
    id: 2315,
    name: "ELIOTROP 200 OMEGA Imagiro 100% Safe . Jamais Certifier !!",
    picture: eliotrop_omega_certifie,
    warranty: "15 jours de garantie",
    serveur: "Dofus Kamas",
    stars: 4.8,
    price: "180",
  },
  {
    id: 2375,
    name: "Enutrof 200 Imagiro,jamais certifié",
    picture: enutrof_imagiro_certifie,
    warranty: "30 jours de garantie",
    serveur: "Dofus Kamas",
    stars: 4.8,
    price: "150",
  },
  {
    id: 2348,
    name: "FORGELANCE 200 Orukam 100% Safe . Jamais Certifier !! (forg)",
    picture: forgelance_orukam,
    warranty: "15 jours de garantie",
    serveur: "Dofus Kamas",
    stars: 4.7,
    price: "270",
  },
  {
    id: 2349,
    name: "HellMina/Eni 135 full Stuff +des cadeaux SAFE 100%",
    picture: hellmina_full_stuff,
    warranty: "14 jours de garantie",
    serveur: "Dofus Kamas",
    stars: 4.7,
    price: "540",
  },
  {
    id: 1745,
    name: "HellMina Hupper lvl 130 full pvp et pvm securise 100%",
    picture: hellmina_hupper_fullstuff,
    warranty: "14 jours de garantie",
    serveur: "Dofus Kamas",
    stars: 4.7,
    price: "500",
  },
  {
    id: 1254,
    name: "200 OMEGA Imagiro 100% Safe . Jamais Certifier !! (elio)",
    picture: imagiro_omega_safe,
    warranty: "15 jours de garantie",
    serveur: "Dofus Kamas",
    stars: 4.6,
    price: "185",
  },
];

export const dofusProduct = [
  {
    _id: "631859b53724c5d6067a5aba",
    category: "dofus-kamas",
    image: kamas,
    title: "Dofus Kamas",
    createdAt: "2022-09-07T08:43:33.107Z",
    updatedAt: "2022-09-12T08:31:29.446Z",
    description:
      "Pourquoi acheter des Kamas Dofus sur ibendouma? Rapidité Depuis la création du site ibytrade, la rapidité de livraison est notre principal objectif. Plus de 99% de nos commandes sont livrées entre 3 et 15 minutes. Chez ibytrade, vous pouvez acheter des Kamas Dofus à bas prix afin de bénéficier de nombreux avantages et d’améliorer votre expérience de jeu. Nous disposons de stocks de kamas sur tous les serveurs de Dofus et nous travaillons avec des fournisseurs fiables pour assurer le bon déroulement de la livraison. Nous vous garantissons un service d'EXCELLENTE qualité,24/7, rapide et sûr. Gagnez du temps et profitez pleinement de votre jeu avec ibytrade. Meilleur prix sur le marché Si vous trouvez mieux sur un autre magasin en ligne, contactez-nous et nous ferons bien notre possible pour le battre. Comment recevoir vos Kamas sur Dofus ? Après avoir passé la commande, notre agent vous contactera pour vous donner les informations de paiement et vous informera du point de rencontre (coordonnées de la map en jeu) pour vous livrer vos Kamas. Assurez-vous de pouvoir recevoir les messages privés en jeu, puis rendez vous aux coordonnées indiquées. 99% des commandes sont livrées dans un délai de 3-15 minutes. NB : Veuillez vérifier vos e-mails ou contactez notre service client en livechat si vous ne recevez pas les kamas dans les 15 prochaines minutes",
    headerDescription:
      "1M=1,000,000 Kamas.Attention: ibendouma ne vous demandera jamais de rendre vos kamas ou objets dofus dans le jeu, nous vous demanderons toujours de venir sur le « Chat en Direct  » pour discuter de n’importe quel problème. Ne donnez jamais vos kamas ou objets dofus à quelqu’un qui prétend être ibendouma.",
  },
  {
    _id: "63185ad03724c5d6067a5abc",
    category: "dofus-retro",
    image: retro,
    title: "Dofus Retro",
    createdAt: "2022-09-07T08:48:16.580Z",
    updatedAt: "2022-09-12T08:32:45.610Z",
    description:
      "Pourquoi acheter des Kamas Dofus sur ibendouma? Rapidité Depuis la création du site ibytrade , la rapidité de livraison est notre principal objectif. Plus de 99% de nos commandes sont livrées entre 3 et 15 minutes. Chez ibendouma, vous pouvez acheter des Kamas Dofus à bas prix afin de bénéficier de nombreux avantages et d’améliorer votre expérience de jeu. Nous disposons de stocks de kamas sur tous les serveurs de Dofus et nous travaillons avec des fournisseurs fiables pour assurer le bon déroulement de la livraison. Nous vous garantissons un service d'EXCELLENTE qualité,24/7, rapide et sûr. Gagnez du temps et profitez pleinement de votre jeu avec ibendouma.Meilleur prix sur le marché Si vous trouvez mieux sur un autre magasin en ligne, contactez-nous et nous ferons bien notre possible pour le battre. Comment recevoir vos Kamas sur Dofus ? Après avoir passé la commande, notre agent vous contactera pour vous donner les informations de paiement et vous informera du point de rencontre (coordonnées de la map en jeu) pour vous livrer vos Kamas. Assurez-vous de pouvoir recevoir les messages privés en jeu, puis rendez vous aux coordonnées indiquées. 99% des commandes sont livrées dans un délai de 3-15 minutes. NB : Veuillez vérifier vos e-mails ou contactez notre service client en livechat si vous ne recevez pas les kamas dans les 15 prochaines minutes",
    headerDescription:
      "1M=1,000,000 Kamas.Attention: ibendouma ne vous demandera jamais de rendre vos kamas ou objets dofus dans le jeu, nous vous demanderons toujours de venir sur le « Chat en Direct  » pour discuter de n’importe quel problème. Ne donnez jamais vos kamas ou objets dofus à quelqu’un qui prétend être ibendouma.",
  },
  {
    _id: "63185b9d3724c5d6067a5abe",
    category: "dofus-touch",
    image: touch,
    title: "Dofus Touch",
    createdAt: "2022-09-07T08:51:41.156Z",
    updatedAt: "2022-09-12T08:33:56.971Z",
    description:
      "Pourquoi acheter des Kamas Dofus sur ibendouma? Rapidité Depuis la création du site ibendouma, la rapidité de livraison est notre principal objectif. Plus de 99% de nos commandes sont livrées entre 3 et 15 minutes. Chez ibendouma, vous pouvez acheter des Kamas Dofus à bas prix afin de bénéficier de nombreux avantages et d’améliorer votre expérience de jeu. Nous disposons de stocks de kamas sur tous les serveurs de Dofus et nous travaillons avec des fournisseurs fiables pour assurer le bon déroulement de la livraison. Nous vous garantissons un service d'EXCELLENTE qualité,24/7, rapide et sûr. Gagnez du temps et profitez pleinement de votre jeu avec ibendouma.Meilleur prix sur le marché Si vous trouvez mieux sur un autre magasin en ligne, contactez-nous et nous ferons bien notre possible pour le battre. Comment recevoir vos Kamas sur Dofus ? Après avoir passé la commande, notre agent vous contactera pour vous donner les informations de paiement et vous informera du point de rencontre (coordonnées de la map en jeu) pour vous livrer vos Kamas. Assurez-vous de pouvoir recevoir les messages privés en jeu, puis rendez vous aux coordonnées indiquées. 99% des commandes sont livrées dans un délai de 3-15 minutes. NB : Veuillez vérifier vos e-mails ou contactez notre service client en livechat si vous ne recevez pas les kamas dans les 15 prochaines minutes",
    headerDescription:
      "1M=1,000,000 Kamas.Attention: ibendouma ne vous demandera jamais de rendre vos kamas ou objets dofus dans le jeu, nous vous demanderons toujours de venir sur le « Chat en Direct  » pour discuter de n’importe quel problème. Ne donnez jamais vos kamas ou objets dofus à quelqu’un qui prétend être ibendouma.",
  },
];

///////////////////////////////////////////////////////////////////////////
