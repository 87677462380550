import React, { useState, useEffect } from "react";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { useSelector, useDispatch } from "react-redux";
import { CiSearch } from "react-icons/ci";

import trust5 from "../assets/truspilot/stars-5-down.svg";
import trust1 from "../assets/truspilot/trustpilot1start.svg";
import defaultUser from "../assets/discussion/defaultUser.png";

import { FaCheckCircle } from "react-icons/fa";

import { Helmet } from "react-helmet";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";

import CookieConsent from "react-cookie-consent";

import { addNewCookState } from "../features/cookieSlices";

import medail from "../assets/medail.png";

import { MdStars } from "react-icons/md";

import axios from "axios";
import { addServers } from "../features/serversSlice";
import { addCurrencyVal } from "../features/currencyExchange";
import { Link } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { igvproducts } from "../utils/igvoriginaldata";

import { addNewIp } from "../features/ipSlice";
import { rmIpAdress } from "../features/ipSlice";
import { addNewCurrencyExchange } from "../features/currencyExchange";
import { addToggleState } from "../features/toggleIpSlice";
import coinmob from "../assets/mobileicon/coins.png";
import comptmob from "../assets/mobileicon/comptes_de_jeu.png";
import objmob from "../assets/mobileicon/objets_jeu.png";
import boostmob from "../assets/mobileicon/boost.png";
import socialmob from "../assets/mobileicon/social_media.png";
import MobileSearch from "./MobileSearch";

const MobileHome = ({ truspilotData }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  const { ipAddr } = useSelector((state) => state.ipAddr);
  const [commentPosition, setCommentPosition] = useState(0);

  // console.log(commentPosition)

  // const [ipAddr, setIpAddr] = useState("France");

  const requestLimiteError = (name) => toast.error(name);

  const convertDate = (date) => {
    const dateConverted = new Date(date).toLocaleDateString("en", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
    return dateConverted;
  };

  const dislocName = (name) => {
    if (name && typeof name === "string") {
      if (name.length === 0) {
        return "******";
      } else if (name.length === 1) {
        return name + "*****";
      } else if (name.length === 2) {
        return name[0] + "***" + name[1];
      } else if (name.length === 3) {
        return name[0] + name[1] + "***" + name[2];
      } else {
        return name[0] + name[1] + name[2] + "****" + name[name.length - 1];
      }
    } else {
      return "******";
    }
  };

  const [letterStep, setLetterStep] = useState(20);

  const { currency, currencyValue } = useSelector((state) => state.currency);
  const [activeItem, setActiveItem] = useState("A");
  const [isScrolled, setIsScrolled] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  const { cook } = useSelector((state) => state.cook);
  const { language } = useSelector((state) => state.language);

  const { toggleIp } = useSelector((state) => state.toggleIp);

  let [screenSize, setScreenSize] = useState(window.innerWidth);

  const [comments, setComments] = useState([]);

  const letterPositions = {};

  // Fonction pour mettre à jour activeItem lorsque la première lettre de chaque section atteint le haut de la fenêtre
  const updateActiveItem = () => {
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    for (const [letter, position] of Object.entries(letterPositions)) {
      if (position - 58 <= scrollTop) {
        setActiveItem(letter);
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      updateActiveItem();
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [location, setLocation] = useState(null);
  const [prevEl, setPrevEl] = useState(null);
  const [nextEl, setNextEl] = useState(null);

  const [menuFilteredData, setMenuFilteredData] = useState(igvproducts);
  const [activeLetter, setActiveLetter] = useState("ALL");

  const [ipAdress, setIpAdress] = useState("");

  useEffect(() => {
    const getGeoLocate = async () => {
      await axios
        .get("https://api.ipify.org?format=json")
        .then((res) => setIpAdress(res?.data?.ip))
        .catch((error) => console.log(error));
      // fetch('https://api.ipify.org?format=json')
      // .then(response => response.json())
      // .then(data => console.log(data.ip))
      // .catch(error => console.error(error));
    };
    getGeoLocate();
  }, []);

  useEffect(() => {
    if (ipAdress) {
      const getCountry = async () => {
        await axios
          .get(`https://ipapi.co/${ipAdress}/json/`)
          .then((res) => {
            dispatch(rmIpAdress());
            dispatch(addToggleState(true));
            dispatch(addNewIp(res?.data?.country_name));
          })
          .catch((error) => console.log(error));
      };
      getCountry();
    }
  }, [toggleIp, ipAdress, dispatch]);

  useEffect(() => {
    const getComments = async () => {
      await axios
        .get(`${process.env.REACT_APP_CLIENT_URL}/users/create-comment`)
        .then((res) => {
          setComments(res?.data);
        })
        .catch(({ response }) => requestLimiteError(response));
    };
    getComments();
  }, [dispatch]);

  useEffect(() => {
    const fetchEuro = async () => {
      await axios
        .get(`${process.env.REACT_APP_CLIENT_URL}/euro`)
        .then((res) => dispatch(addCurrencyVal(res?.data[0]?.euro)))
        .catch((error) => console.log(error));
    };
    fetchEuro();
  }, [dispatch]);

  useEffect(() => {
    const getServers = async () => {
      await axios
        .get(`${process.env.REACT_APP_CLIENT_URL}/server`)
        .then((res) => dispatch(addServers(res.data)));
    };
    getServers();
  }, [dispatch]);

  useEffect(() => {
    if (ipAddr === "Morocco") {
      dispatch(addNewCurrencyExchange("mad"));
    } else {
      dispatch(addNewCurrencyExchange("euro"));
    }
  }, [ipAddr, dispatch]);

  const handleAcceptCookie = () => {
    dispatch(addNewCookState(false));
  };
  const handleDeclineCookie = () => {
    dispatch(addNewCookState(false));
  };

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    setIsScrolled(scrollPosition);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //   useEffect(() => {
  //     if (activeLetter === "ALL") {
  //       setMenuFilteredData(igvproducts);
  //     } else {
  //       setMenuFilteredData(
  //         igvproducts.filter((item) => item?.letterStart === activeLetter)
  //       );
  //     }
  //   }, [activeLetter]);

  useEffect(() => {
    try {
      axios({
        method: "get",
        url: `${process.env.REACT_APP_CLIENT_URL}/${currency}`,
      }).then((res) => {
        if (currency === "euro") {
          dispatch(addCurrencyVal(res?.data[0]?.euro));
        } else if (currency === "gbp") {
          dispatch(addCurrencyVal(res?.data[0]?.gbp));
        } else if (currency === "usdt") {
          dispatch(addCurrencyVal(res?.data[0]?.usdt));
        } else if (currency === "dollar") {
          dispatch(addCurrencyVal(res?.data[0]?.dollar));
        } else if (currency === "cad") {
          dispatch(addCurrencyVal(res?.data[0]?.cad));
        } else if (currency === "chf") {
          dispatch(addCurrencyVal(res?.data[0]?.chf));
        } else if (currency === "rub") {
          dispatch(addCurrencyVal(res?.data[0]?.rub));
        } else if (currency === "mad") {
          dispatch(addCurrencyVal(res?.data[0]?.mad));
        } else {
          dispatch(addCurrencyVal(res?.data[0]?.euro));
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, [currency, dispatch]);

  //   console.log(letterPositions);

  return (
    <div className="flex xcustom:hidden flex-col items-start gap-18 max-w-screen overflow-hidden">
      <Helmet>
        <title>
          Acheter Dofus, Dofus Touch, et Dofus Retro à des prix imbattables sur
          ibendouma
        </title>
      </Helmet>
      <Helmet>
        <title>
          Acheter Dofus, Dofus Touch, et Dofus Retro à des prix imbattables sur
          ibendouma
        </title>
        <meta
          property="og:title"
          content="Acheter Dofus, Dofus Touch, et Dofus retro"
        />
        <meta
          property="og:description"
          content=" Acheter Dofus, Dofus Touch, et Dofus Retro à des prix imbattables sur
          ibendouma"
        />
        <meta
          property="og:url"
          content="https://ibendouma.com/dofus/dofus-kamas"
        />
        <meta property="og:type" content="product" />
        <meta property="og:site_name" content="https://ibendouma.com" />
      </Helmet>
      <div
        className="fixed flex mt-0 w-full z-[70] shadow-sm bg-white pb-2 pt-2"
        style={{
          top: isScrolled > 50 && 0,
        }}
      >
        <input
          type="search"
          placeholder={
            language === "francais"
              ? "Rechercher ce que vous voulez"
              : "Search your want"
          }
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="relative w-full px-8 py-1 rounded-full border border-black outline-none placeholder:text-[#4b5563] mx-5"
        />
        <span className="absolute top-[30%] left-[9%] cushelp:left-[8%] bsm:left-[7%] sm:left-[6%] text-[20px] text-[#4b5563] font-bold">
          <CiSearch />
        </span>
        <MobileSearch searchTerm={searchTerm} />
        <span
          style={{
            display: isScrolled > 150 ? "flex" : "none",
          }}
          className="absolute top-[83%] items-start text-[#4b5563] px-4 bg-[#e5e7eb] w-full z-[70]"
        >
          {activeItem}
        </span>
      </div>

      <div className="flex items-center justify-between w-full mt-16 px-4">
        <Link
          to="/ingame/monnaie-en-jeu"
          className="flex flex-col items-center gap-2"
        >
          <img
            className="w-10 h-10 object-cover object-center rounded-full"
            src={coinmob}
            alt="Monnaie en jeu"
          />
          <div className="flex flex-col items-center text-sm antialiased">
            <span>Monnaie</span>
            <span>en jeu</span>
          </div>
        </Link>
        <Link
          to="/ingame/compte-en-jeu"
          className="flex flex-col items-center gap-2"
        >
          <img
            className="w-10 h-10 object-cover object-center rounded-full"
            src={comptmob}
            alt="Compte de jeu"
          />
          <div className="flex flex-col items-center text-sm antialiased">
            <span>Comptes</span>
            <span>de jeu</span>
          </div>
        </Link>
        <Link to="/paysafecard" className="flex flex-col items-center gap-2">
          <img
            className="w-10 h-10 object-cover object-center rounded-full"
            src={objmob}
            alt="Objets en jeu"
          />
          <div className="flex flex-col items-center text-sm antialiased">
            <span>Objets</span>
            <span>en jeu</span>
          </div>
        </Link>
        <Link
          to="/ibytrade/vendre-des-kamas"
          className="flex flex-col items-center gap-2"
        >
          <img
            className="w-10 h-10 object-cover object-center rounded-full"
            src={boostmob}
            alt="Boosting"
          />
          <div className="flex flex-col items-center text-sm antialiased">
            {language === "francais" && (
              <>
                <span>Vendre</span>
                <span> des kamas</span>
              </>
            )}
            {language === "anglais" && (
              <>
                <span>Sell</span>
                <span>kamas</span>
              </>
            )}
            {language === "espagnol" && (
              <>
                <span>Venta</span>
                <span>de kamas</span>
              </>
            )}
          </div>
        </Link>
        <Link
          to="/ingame/social-media"
          className="flex flex-col items-center gap-2"
        >
          <img
            className="w-10 h-10 object-cover object-center rounded-full"
            src={socialmob}
            alt="Social media"
          />
          <div className="flex flex-col items-center text-sm antialiased">
            <span>Social</span>
            <span>media</span>
          </div>
        </Link>
      </div>

      <div className="flex flex-col items-start gap-4 w-full mt-2">
        {menuFilteredData?.length <= 0 ? (
          <div className="flex items-center justify-center mx-auto gap-4 select-none">
            <Link to="/contact" className="text-base text-black">
              {language === "francais" &&
                "Il n'y a pas le jeu que vous voulez?"}
              {language === "anglais" && "Isn’t there the game you want?"}
              {language === "espagnol" && "¿No es el juego que quieres?"}
            </Link>
            <Link
              to="/contact"
              className="text-base text-red-600 cursor-pointer"
            >
              {language === "francais" && "Contactez-nous s'il vous plaît"}
              {language === "anglais" && "Please contact us at"}
              {language === "espagnol" && "Por favor contáctenos"}
            </Link>
          </div>
        ) : (
          <div className="relative z-40 flex flex-col gap-4 items-start select-none mt-6">
            <div
              style={{
                position:
                  isScrolled > 150 && isScrolled < commentPosition
                    ? "fixed"
                    : "absolute",
                top: isScrolled >= 180 && 110,
              }}
              className="left-[90%] top-[0.3%] flex flex-col items-center bg-white/95 rounded"
            >
              {[
                // "TOP",
                "A",
                "B",
                "C",
                "D",
                "E",
                "F",
                "G",
                "H",
                "K",
                "L",
                "M",
                "N",
                "O",
                "P",
                "R",
                "S",
                "T",
                "U",
                "V",
                "W",
                "X",
                "Y",
              ].map((item, index) => (
                <span
                  key={index}
                  className={`flex items-center justify-center text-sm antialiased py-1 px-2 duration-300 ease-in-out ${
                    activeItem === item
                      ? "bg-yellow-500 rounded-[6px] text-white"
                      : ""
                  }`}
                >
                  {item}
                </span>
              ))}
            </div>
            {menuFilteredData?.map((product) => (
              <div
                className="flex flex-col items-start justify-between gap-6 w-screen"
                key={product?.id}
              >
                <p
                  ref={(ref) => {
                    if (ref && !letterPositions[product?.letterStart]) {
                      letterPositions[product?.letterStart] =
                        ref.getBoundingClientRect().top + window.scrollY;
                    }
                  }}
                  className="flex items-center text-base text-[#4b5563] px-4 bg-[#e5e7eb] w-full"
                >
                  {product?.letterStart}
                </p>
                <div className="grid grid-cols-2 gap-4 mx-5 w-full">
                  {product?.details?.map((productDetail) => (
                    <div
                      className="flex flex-col gap-2 items-start mt-[2px]"
                      key={productDetail?.id}
                      // style={{ display: i === 0 && "none" }}
                    >
                      <p className="text-sm font-bold text-black antialiased">
                        {productDetail?.title}
                      </p>
                      <div className="flex items-center gap-2">
                        {productDetail?.items?.map((item) => (
                          <Link
                            className="flex flex-wrap text-sm antialiased capitalize text-[#3174a1] cursor-pointer transition duration-500 ease-in-out hover:text-red-600 w-full"
                            key={item?.id}
                            to={item?.link?.split("fr")[1]}
                          >
                            {item?.title}
                          </Link>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <div
        ref={(ref) => {
          if (ref) {
            setCommentPosition(
              ref.getBoundingClientRect().top + window.scrollY - 550
            );
          }
        }}
        className="flex flex-col items-center justify-center gap-4 mx-5 mt-12 mb-4 z-[60]"
      >
        {comments?.slice(0, 11)?.map((comment) => (
          <div className="flex flex-col items-start gap-2 p-4 rounded-lg w-full shadow-md transitions cursor-pointer select-none bg-white border">
            <div className="flex items-center space-x-3">
              <img
                src={medail}
                alt="user reviews"
                className="h-8 w-8 object-cover mt-2"
              />
              <div className="flex flex-col items-start">
                <span className="text-base font-semibold">
                  {dislocName(comment?.commentLastname)}
                </span>
                <span className="flex items-center gap-0">
                  <MdStars className="text-[18px] text-red-600" />
                  <MdStars className="text-[18px] text-red-600" />
                  <MdStars className="text-[18px] text-red-600" />
                  <MdStars className="text-[18px] text-red-600" />
                  <MdStars className="text-[18px] text-red-600" />
                </span>
              </div>
            </div>
            <p className="text-sm text-gray-500 ml-2">{comment?.comment}</p>
            <span className="text-sm text-gray-500 ml-2 mt-1">
              {convertDate(
                comment?.dateToCreated
                  ? comment?.dateToCreated
                  : "2023-09-20T14:55:31.008Z"
              )}
            </span>
          </div>
        ))}
      </div>

      <div className="flex flex-col items-center justify-center w-full my-10 mx-auto">
        <a
          href="https://fr.trustpilot.com/review/ibendouma.com"
          target="_blank"
          rel="noopener noreferrer"
          className="flex flex-col items-center gap-2"
        >
          <div className="flex gap-4 mt-4">
            <h2 className="text-xl font-semibold mt-3">Excellent</h2>
            <img
              src={trust5}
              alt="Trustpilot ibendouma reviews"
              className="w-40"
            />
          </div>

          <div class="text-lg text-gray-800">
            <p className="flex gap-1 text-sm">
              <span>See all</span>
              <span className="font-semibold">
                {truspilotData?.filters?.totalNumberOfReviews}
              </span>
              <span>reviews on</span>{" "}
              <img
                className="w-4 h-4 object-cover object-center"
                src={trust1}
                alt="Trustpilot ibendouma review"
              />
              <span className="font-semibold">Truspilot</span>
            </p>
          </div>
        </a>
        <div className="mt-6 w-full mx-auto z-[60]">
          <Swiper
            slidesPerView={4}
            spaceBetween={40}
            navigation={false}
            autoplay={true}
            speed={1000}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              1280: {
                slidesPerView: 4,
                spaceBetween: 40,
              },
            }}
            modules={[Navigation, Autoplay]}
          >
            {truspilotData?.reviews
              ?.filter((comment) => comment.rating >= 5)
              ?.map((comment) => (
                <SwiperSlide key={comment?.consumer.id}>
                  <div className="flex flex-col items-start gap-2 p-4 border w-full max-w-[350px] shadow-md transition cursor-pointer select-none mx-5 z-[60]">
                    <div className="flex items-center space-x-3">
                      <img
                        src={
                          comment?.consumer.imageUrl
                            ? comment?.consumer.imageUrl
                            : defaultUser
                        }
                        alt="user reviews"
                        className="flex items-center justify-center w-12 h-12 trustxs:h-10 trustxs:w-10 object-cover object-center rounded-full mt-2"
                      />
                      <div className="hidden trustxs:flex items-center gap-2">
                        <img
                          src={trust5}
                          className="w-36"
                          alt="user trustpilot review"
                        />
                        <div className="flex items-center gap-1">
                          <FaCheckCircle className="text-[#3f3f46]" />{" "}
                          <span className="text-sm text-[#3f3f46] font-semibold">
                            Verified
                          </span>
                        </div>
                      </div>
                      <div className="flex flex-col items-start trustxs:hidden gap-2">
                        <img
                          src={trust5}
                          className="w-28"
                          alt="user trustpilot review"
                        />
                        <div className="flex items-center gap-1">
                          <FaCheckCircle className="text-[#3f3f46]" />{" "}
                          <span className="text-sm text-[#3f3f46] font-semibold">
                            Verified
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex text-xs ml-2">
                      <span className="font-bold">
                        {comment?.consumer.displayName}
                      </span>
                      {/* review on <strong>iBendouma</strong> */}
                    </div>
                    <p className="text-sm h-14 ml-2">{comment?.text}</p>
                    {/* <span className="text-sm text-gray-500 ml-2 mt-1">
                  {convertDate(
                    comment?.dateToCreated
                      ? comment?.dateToCreated
                      : "2023-09-20T14:55:31.008Z"
                  )}
                </span> */}
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>

      <CookieConsent
        onAccept={handleAcceptCookie}
        enableDeclineButton="Refusé"
        onDecline={handleDeclineCookie}
        location="bottom"
        buttonText={
          language === "anglais"
            ? "I agree"
            : language === "francais"
            ? "J'accepte"
            : language === "espagnol"
            ? "Acepto"
            : "I agree"
        }
        declineButtonText={
          language === "anglais"
            ? "Denied"
            : language === "francais"
            ? "Refusé"
            : language === "espagnol"
            ? "Rechazó"
            : "Denied"
        }
        cookieName="ibendoumaconfidentialitycookie"
        style={{ background: "#000000" }}
        buttonStyle={{
          color: "#4e503b",
          fontSize: "13px",
        }}
        flipButtons
        expires={5000}
        visible={cook ? "show" : "hidden"}
        debug={true}
      >
        {language === "anglais" && (
          <div className="cookie-container">
            <p
              style={{
                letterSpacing: "1px",
              }}
            >
              By continuing to navigate on the site{" "}
              <Link
                to="/"
                style={{
                  color: "#76bef2",
                  fontSize: "14px",
                }}
              >
                ibendouma.com
              </Link>{" "}
              it implies for the user, the full and unreserved acceptance of
              <Link
                to="/privacy-policy/#cookie-privacy"
                style={{
                  color: "#76bef2",
                  fontSize: "14px",
                }}
              >
                {" "}
                cookies
              </Link>
              .<br></br>
            </p>
          </div>
        )}
        {language === "espagnol" && (
          <div className="cookie-container">
            <p>
              La conexión y la navegación en el sitio{" "}
              <Link
                to="/"
                style={{
                  color: "#004a99",
                  fontSize: "14px",
                }}
              >
                ibendouma.com
              </Link>{" "}
              por parte del usuario implica la aceptación plena y sin reservas
              de los cookies.<br></br>
            </p>
          </div>
        )}

        {language === "francais" && (
          <div className="cookie-container">
            <p>
              La connexion et la navigation sur le site{" "}
              <Link
                to="/"
                style={{
                  color: "#004a99",
                  fontSize: "14px",
                }}
              >
                ibendouma.com
              </Link>{" "}
              par l’utilisateur implique l'acceptation intégrale et sans réserve
              des cookies.<br></br>
            </p>
          </div>
        )}
      </CookieConsent>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default MobileHome;
