import React, { useState } from "react";

import { useSelector } from "react-redux";
import { AiFillWarning } from "react-icons/ai";

import { IoIosRadioButtonOff, IoIosRadioButtonOn } from "react-icons/io";

import logimg from "../assets/product-imageandlogo/dofus-kamas.png";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import image1 from "../assets/checkout/image-1-checkout.jpg";
import image2 from "../assets/checkout/image-2-checkout.jpg";
import image3 from "../assets/checkout/image-3-checkout.jpg";

import coinFifa from "../assets/allgames/coins-fifa.jpg";

import { paymentMethods } from "../screens/utiles/data";

const CheckoutPage = () => {
  const { language } = useSelector((state) => state.language);
  const { game, count, total } = useSelector((state) => state.game);
  const [activePaymentMethod, setActivePaymentMethod] = useState("");

  const handleDisplayPaymentName = (name) => {
    setActivePaymentMethod(name);
  };

  const totals = [
    {
      name: "Sous-total",
      cost: total,
    },
    {
      name: "Discount Amount",
      cost: "0.00",
    },
    {
      name: "Frais de transaction",
      cost: "0.00",
    },
    {
      name: "Frais de paiement",
      cost: "0.00",
    },
  ];

  const paymentMethodErrorFr = () =>
    toast.error("Veuillez une méthode de paiement avant de continuer");
  const paymentMethodErrorEn = () =>
    toast.error("Please provide a payment method before proceeding");

  const paymentSuccessFr = () =>
    toast.success(
      "Votre commande a été note, veuillez ouvrir le chat pour valider votre achat."
    );
  const paymentSuccessEn = () =>
    toast.success(
      "Your order has been noted, please open the chat to validate your purchase."
    );

  function handleChatClick() {
    void window.Tawk_API.toggle();
  }

  const handlePAY = () => {
    if (!activePaymentMethod) {
      if (language === "francais") {
        paymentMethodErrorFr();
      } else {
        paymentMethodErrorEn();
      }
    } else {
      if (language === "francais") {
        paymentSuccessFr();
        handleChatClick();
      } else {
        paymentSuccessEn();
        handleChatClick();
      }
    }
  };

  return (
    <div className="flex flex-col mx-auto p-4 mt-36 mb-20 min-h-screen">
      <div className="flex flex-col items-center lg:flex-row lg:items-start">
        <div className="flex flex-col items-start w-full">
          <p className="text-2xl font-extrabold py-2">
            {language === "anglais" && "Order information"}
            {language === "espagnol" && "Información del pedido"}
            {language === "francais" && "Informations de commande"}
          </p>
          <div className="flex flex-col items-start w-full max-w-[1000px]">
            <span className="flex items-center text-base p-4 rounded bg-black text-white w-full">
              {language === "anglais" && "Seller"}
              {language === "espagnol" && "Vendedor"}
              {language === "francais" && "Vendeur"}: Ibendouma
            </span>
          </div>
          <div className="flex flex-col items-start p-4 gap-4 border border-gray-300 w-full max-w-[1000px]">
            <div className="flex flex-col gap-4 md:gap-0 md:flex-row items-start md:items-center justify-between w-full">
              <div className="flex items-start space-x-4">
                <img
                  src={game[0]?.image}
                  className="w-full rounded object-contain h-[132px] sm:h-[148px]"
                  alt="coin-fifa"
                />
                <div className="flex flex-col items-start justify-between w-full space-y-6">
                  <div className="flex flex-col items-start gap-1 sm:gap-2 w-full">
                    <span className="text-sm sm:text-base font-semibold text-gray-700 flex w-full">
                      {game[0]?.title}
                    </span>
                    <span className="text-sm text-gray-500">
                      {game[0]?.subtitle}
                    </span>
                  </div>
                  <div className="flex flex-col items-start gap-1 sm:gap-2">
                    <span className="text-sm text-gray-500">
                      {language === "anglais" && "Delivery speed"}
                      {language === "espagnol" && "Velocidad de entrega"}
                      {language === "francais" && "Vitesse de livraison"}:{" "}
                      {game[0]?.deleveryTime} Min
                    </span>
                    {game[0]?.type === "coins" && (
                      <span className="text-sm text-gray-500">
                        {language === "anglais" && "Quantities"}
                        {language === "espagnol" && "cantidade (s)"}
                        {language === "francais" && "Quantite (s)"} K
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <span className="flex items-center gap-4 text-base text-gray-500  ml-4 md:ml-0 p-2 sm:p-0">
                <span className="text-sm font-bold flex md:hidden">
                  {language === "anglais" && "Quantities"}
                  {language === "espagnol" && "cantidade (s)"}
                  {language === "francais" && "Quantite (s)"}:
                </span>
                x {game[0]?.quantity} {game[0]?.type === "coins" && "K"}
              </span>
              <span className="flex items-center gap-4 text-base text-gray-500 ml-4 md:ml-0 p-2 sm:p-0">
                <span className="text-sm font-bold flex md:hidden">
                  {language === "anglais" && "Price"}
                  {language === "espagnol" && "Precio"}
                  {language === "francais" && "Prix"}:
                </span>
                {(game[0]?.price / game[0]?.quantity).toFixed(2)}{" "}
                {game[0]?.devise === "euro" && "€"}
                {game[0]?.devise === "" && "MAD"}
                {game[0]?.devise === "dollar" && "$"}
                {game[0]?.devise === "mad" && "MAD"}
                {game[0]?.devise === "usdt" && "USDT"}
                {game[0]?.devise === "cad" && "CAD"}
                {game[0]?.devise === "chf" && "CHF"}
                {game[0]?.devise === "rub" && "RUB"}
                {game[0]?.devise === "gbp" && "£"}{" "}
              </span>
            </div>
            <span className="text-base font-semibold text-gray-700 my-2 ml-5">
              {language === "anglais" && "Shipping information"}
              {language === "espagnol" && "Información de entrega"}
              {language === "francais" && "Information de livraison"}
            </span>
            <div className="flex flex-col items-start p-4 gap-2 w-full">
              <label
                htmlFor="email"
                className="flex items-center text-base text-gray-500"
              >
                <span className="text-red-600">*</span>
                {language === "anglais" && "Contact email"}
                {language === "espagnol" && "Email de contacto"}
                {language === "francais" && "Email de contact"}:
              </label>
              <input
                type="email"
                placeholder="Email"
                className="w-full text-base p-3 rounded outline-none border border-gray-300"
              />
            </div>
            <div className="flex flex-col items-start p-4 gap-2 w-full">
              <label
                htmlFor="email"
                className="flex items-center text-base text-gray-500"
              >
                <span className="text-red-600">*</span>
                {language === "anglais" &&
                  "Only the current delivery method is supported"}
                {language === "espagnol" &&
                  "Solo se admite el método de entrega actual"}
                {language === "francais" &&
                  "Seule la méthode de livraison actuelle est prise en charge"}
                :
              </label>
              <input
                type="text"
                placeholder="FIFA Safe Trade Delivery"
                className="w-full text-base p-3 rounded outline-none border border-gray-300"
              />
            </div>
            <div className="flex items-start p-4 gap-2">
              <AiFillWarning className="text-[48px] -mt-4 text-red-600" />
              <div className="flex bg-red-100 border border-red-300 p-1 rounded">
                <p className="text-base text-red-400 shadow-sm">
                  {language === "anglais" &&
                    `The content of the information received according to the method of
                     current delivery will be filled in the order page after
                     your payment is successful. Please complete the
                     receipt information as soon as possible after payment
                     successful! At the same time, the system will call you back in
                     internal letters and emails, please pay attention
                     to relevant information.`}
                  {language === "espagnol" &&
                    `El contenido de la información recibida según el método de
                     entrega actual se completará en la página de pedido después de
                     que su pago sea exitoso. Por favor, complete
                     información de recepción tan pronto como sea posible después del pago
                     ¡Acertado! Al mismo tiempo, el sistema le recordará en forma
                     cartas internas y correos electrónicos, preste atención
                     a la información pertinente.`}
                  {language === "francais" &&
                    `Le contenu des informations reçues selon la méthode de
                     livraison actuelle sera rempli dans la page de commande après
                     que votre paiement soit réussi. Veuillez compléter les
                     informations de réception dès que possible après le paiement
                     réussi ! En même temps, le système vous rappellera sous forme
                     de lettres internes et d'e-mails, veuillez prêter attention
                     aux informations pertinentes.`}
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col mt-12 mb-12 items-start p-1 md:p-4 gap-4 border border-gray-300 w-full max-w-[1000px]">
            <h2 className="text-2xl font-bold">
              {" "}
              {language === "anglais" && "Conventional payment"}
              {language === "espagnol" && "Pago convencional"}
              {language === "francais" && "Paiement conventionnel"}
            </h2>
            <div className="flex flex-col items-start gap-6 w-full overflow-hidden">
              {paymentMethods?.map((payment) => (
                <div
                  key={payment?.id}
                  className="flex items-center space-x-2 w-full"
                >
                  <div
                    className="flex items-center gap-2 cursor-pointer"
                    onClick={() => handleDisplayPaymentName(payment?.name)}
                  >
                    {payment?.name === activePaymentMethod ? (
                      <IoIosRadioButtonOn className="text-red-600 text-[22px]" />
                    ) : (
                      <IoIosRadioButtonOff className="text-gray-500 text-[22px]" />
                    )}
                  </div>
                  <img
                    src={payment?.icon}
                    alt="payment"
                    className={`w-${payment?.w} h-${payment?.h} object-cover cursor-pointer`}
                    onClick={() => handleDisplayPaymentName(payment?.name)}
                  />
                  {payment?.name === "usdt" && <span>USDT(TRC20)</span>}
                </div>
              ))}
            </div>
          </div>
          <div></div>
          <div></div>
        </div>
        <div className="flex flex-col border border-gray-300 p-4 gap-4 static lg:sticky lg:top-1/3 mb-12 w-full md:w-[500px] ml-4 bg-white shadow-md rounded">
          {totals.map((total, i) => (
            <div
              key={i}
              className="flex items-center justify-between text-gray-500 text-base w-full"
            >
              {total?.name}
              <span>${total?.cost}</span>
            </div>
          ))}
          <div className="border-b border-gray-300"></div>
          <div className="flex items-center justify-between">
            <span className="text-base text-gray-500">Total</span>
            <span className="text-lg text-red-600 font-bold">
              {total} {game[0]?.devise === "euro" && "€"}
              {game[0]?.devise === "" && "MAD"}
              {game[0]?.devise === "dollar" && "$"}
              {game[0]?.devise === "mad" && "MAD"}
              {game[0]?.devise === "usdt" && "USDT"}
              {game[0]?.devise === "cad" && "CAD"}
              {game[0]?.devise === "chf" && "CHF"}
              {game[0]?.devise === "rub" && "RUB"}
              {game[0]?.devise === "gbp" && "£"}{" "}
            </span>
          </div>
          <button
            className="flex items-center justify-center px-6 py-2 text-lg outline-none bg-red-500 hover:bg-red-600 text-white font-semibold rounded-lg shadow-md hover:shadow-lg transition duration-300 ease-in-out"
            onClick={handlePAY}
          >
            Payer {total} {game[0]?.devise === "euro" && "€"}
            {game[0]?.devise === "" && "MAD"}
            {game[0]?.devise === "dollar" && "$"}
            {game[0]?.devise === "mad" && "MAD"}
            {game[0]?.devise === "usdt" && "USDT"}
            {game[0]?.devise === "cad" && "CAD"}
            {game[0]?.devise === "chf" && "CHF"}
            {game[0]?.devise === "rub" && "RUB"}
            {game[0]?.devise === "gbp" && "£"}{" "}
          </button>
          <p className="text-sm text-gray-600 font-semibold">
            {language === "anglais" &&
              "By placing this order, you accept our Terms of Use and understand our Privacy Policy."}
            {language === "espagnol" &&
              "Al realizar este pedido, usted acepta nuestras Condiciones de uso y entiende nuestra Política de privacidad."}
            {language === "francais" &&
              "En passant cette commande, vous acceptez nos Conditions d'utilisation et comprenez notre Politique de confidentialité."}
          </p>
          <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-3 grid-cols-3 gap-10 mx-auto p-0">
            <img src={image1} className="w-full object-contain" alt="pci" />
            <img
              src={image2}
              className="w-full object-contain"
              alt="trustpilot"
            />
            <img
              src={image3}
              className="w-full object-contain"
              alt="guarantee"
            />
          </div>
          <p className="text-xs text-gray-500">
            {language === "anglais" &&
              `We use the latest PCI DSS technology and secure SSL payment to encrypt all your data. This allows secure transmission and protects your data from access by third parties.`}
            {language === "espagnol" &&
              `Utilizamos la última tecnología PCI DSS y el pago seguro SSL para cifrar todos sus datos. Esto permite una transmisión segura y protege sus datos contra el acceso de terceros.`}
            {language === "francais" &&
              `Nous utilisons la dernière technologie PCI DSS et le paiement
               sécurisé SSL pour crypter toutes vos données. Cela permet une
               transmission sécurisée et protège vos données contre l'accès par des
               tiers.`}
          </p>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default CheckoutPage;
