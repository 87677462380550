import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  game: [],
  count: 0,
  total: 0,
};

export const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {
    addToGame: (state, action) => {
      state.loading = false;
      state.game = [action.payload];
      state.count = state.game.length;
      state.total = state.game.reduce((acc, item) => {
        const subTotal = item.quantity * item.price;
        return acc + subTotal;
      }, 0);
    },

    deleteTogame: (state, action) => {
      state.game = state.game.filter((item) => item.id !== action.payload);

      state.count = state.game.length;

      state.total = state.game.reduce((acc, item) => {
        const subTotal = item.quantity * item.price;
        return acc + subTotal;
      }, 0);
    },

    changeQuantity: (state, action) => {
      state.game = state.game.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            quantity: action.payload.quantity,
            total: action.payload.quantity * item.price,
          };
        } else {
          return item;
        }
      });
      state.total = state.game.reduce((acc, item) => {
        const subTotal = item.quantity * item.price;
        return acc + subTotal;
      }, 0);
    },
    removeGame: (state, action) => {
      state.game = [];
      state.count = 0;
      state.total = 0;
    },
  },
});

export const { addToGame, deleteTogame, changeQuantity, removeGame } =
  gameSlice.actions;
export default gameSlice.reducer;
