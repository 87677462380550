import React, { useState, useEffect } from "react";

import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { IoFlashOutline } from "react-icons/io5";
import { BiCheckShield } from "react-icons/bi";
import { IoIosStar, IoIosStarHalf } from "react-icons/io";
import { IoSend } from "react-icons/io5";
import { SlLike } from "react-icons/sl";
import { AiOutlineFire } from "react-icons/ai";
import { RiMessage2Fill } from "react-icons/ri";

import account from "../assets/products/fort.jpg";
import avatar from "../assets/products/avatar.jpg";

import free from "../assets/allgames/free.png";
import refund from "../assets/allgames/refund.png";
import product from "../assets/allgames/product.png";
import warranty from "../assets/allgames/warranty.png";

const SellerPageDetail = () => {
  const { language } = useSelector((state) => state.language);
  const { sellername, productId } = useParams();

  const [quantity, setQuantity] = useState(1);

  const handleReduce = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handleIncrease = () => {
    setQuantity(quantity + 1);
  };

  return (
    <div className="w-full flex flex-col items-start mt-36 mx-4 p-4">
      <div className="flex flex-col items-start space-y-2">
        <p className="text-sm text-gray-500">
          <Link to="/">Home</Link> /<Link to="/"> Fortnite</Link> /{" "}
          [PC/PSN/XBOX/NINTENDO] FA 122 SKINS | RAGE | MIDAS (FULLY GOLDEN) |
          HEAD BANGER | RUST BUCKET | REAPER AXE | BLUE TEAM LEADER | BLUE
          STRIKER |
        </p>
      </div>
      <div className="flex items-start space-x-8 mt-8">
        <div>
          <img
            src={account}
            alt="produit"
            className="h-[300px] w-[300px] object-cover object-center rounded"
          />
        </div>
        <div className="flex flex-col items-start gap-2">
          <p className="pb-4 border-b border-gray-300 font-semibold max-w-[900px]">
            [PC/PSN/XBOX/NINTENDO] FA 122 SKINS | RAGE | MIDAS (FULLY GOLDEN) |
            HEAD BANGER | RUST BUCKET | REAPER AXE | BLUE TEAM LEADER | BLUE
            STRIKER |
          </p>
          <div className="flex items-center space-x-8 pt-4">
            <div className="flex items-center p-2 text-sm gap-2 text-white bg-[#01B67A] rounded">
              <BiCheckShield className="text-[18px]" />
              20 Days Warranty
            </div>
            <div className="flex items-center p-2 text-sm gap-2 text-white bg-[#01B67A] rounded">
              <IoFlashOutline className="text-[18px]" />
              Delivery in 5 Min
            </div>
          </div>
          <div className="flex flex-col items-start w-full">
            <div className="flex flex-col items-start gap-8 mt-4 border-b pb-8 border-gray-300 w-full">
              <div className="flex items-center space-x-4">
                <div className="flex items-center space-x-4">
                  <img
                    src={warranty}
                    alt="warranty"
                    className="object-cover object-center border border-gray-400 rounded p-3"
                  />
                  <div className="flex flex-col items-start gap-2">
                    <p className="text-sm font-semibold">
                      {language === "anglais" && "delivery guarantees"}
                      {language === "espagnol" && "Garantías de entrega"}
                      {language === "francais" && "Garanties de livraison"}
                    </p>
                    <span className="text-xs text-gray-500 w-[320px]">
                      {language === "anglais" &&
                        "We offer a guaranted delivery and indicate the delivery date on the payment page."}
                      {language === "espagnol" &&
                        "Ofrecemos entrega garantizada e indicamos la fecha de entrega en la página de pago."}
                      {language === "francais" &&
                        "Nous proposons une livraison garantie et indiquons la date de livraison sur la page de paiement."}
                    </span>
                  </div>
                </div>
                <div className="flex items-center space-x-4">
                  <img
                    src={product}
                    alt="warranty"
                    className="object-cover object-center border border-gray-400 rounded p-3"
                  />
                  <div className="flex flex-col items-start gap-2">
                    <p className="text-sm font-semibold">
                      {language === "anglais" && "product warranty"}
                      {language === "espagnol" && "garantía de los productos"}
                      {language === "francais" && "Garantie des produits"}
                    </p>
                    <span className="text-xs text-gray-500 w-[320px]">
                      {language === "anglais" &&
                        "The products are covered by the seller with a guarantee on delivery."}
                      {language === "espagnol" &&
                        "Los productos están cubiertos por el vendedor con una garantía de entrega."}
                      {language === "francais" &&
                        "Les produits sont couverts par le vendeur avec une garantie à la livraison."}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex items-center space-x-4">
                <div className="flex items-center space-x-4">
                  <img
                    src={refund}
                    alt="warranty"
                    className="object-cover object-center border border-gray-400 rounded p-3"
                  />
                  <div className="flex flex-col items-start gap-2">
                    <p className="text-sm font-semibold">
                      {language === "anglais" && "satisfied or refunded"}
                      {language === "espagnol" && "satisfecho o reembolsado"}
                      {language === "francais" && "Satisfait ou remboursé"}
                    </p>
                    <span className="text-xs text-gray-500 w-[320px]">
                      {language === "anglais" &&
                        `If you encounter a problem during the warranty period on ibendouma, you can request a refund of your order.`}
                      {language === "espagnol" &&
                        `Si tiene algún problema durante el período de garantía en ibendouma, puede solicitar un reembolso de su pedido.`}
                      {language === "francais" &&
                        `Si vous rencontrez un problème pendant la période de
                    garantie sur ibendouma, vous pouvez demander un
                    remboursement de votre commande.`}
                    </span>
                  </div>
                </div>
                <div className="flex items-center space-x-4 mr-2">
                  <img
                    src={free}
                    alt="warranty"
                    className="object-cover object-center border border-gray-400 rounded p-3"
                  />
                  <div className="flex flex-col items-start gap-2">
                    <p className="text-sm font-semibold">
                      {language === "anglais" && "Free refund"}
                      {language === "espagnol" && "Reembolso gratuito"}
                      {language === "francais" && "Remboursement gratuit"}
                    </p>
                    <span className="text-xs text-gray-500 w-[320px]">
                      {language === "anglais" &&
                        `Ibendouma does not charge any fees. You will receive a refund equivalent to the amount you paid.`}
                      {language === "espagnol" &&
                        `Ibendouma no cobra ninguna tarifa. Usted recibirá un reembolso equivalente a la cantidad que pagó.`}
                      {language === "francais" &&
                        `Ibendouma ne facture aucun frais. Vous recevrez un
                      remboursement équivalent au montant que vous avez payé.`}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start w-[350px] gap-4 border border-gray-300 rounded p-4 select-none">
          <div className="w-full flex items-start gap-8">
            <div className="relative">
              <img
                src={avatar}
                alt="avatar"
                className="flex items-center justify-center object-cover object-center rounded-full"
              />
              <span className="absolute top-[60%] left-[60%] flex items-center justify-center p-1 cursor-pointer rounded-full bg-[#01B67A] text-white">
                <RiMessage2Fill className="text-[16px]" />
              </span>
            </div>
            <div className="flex flex-col items-start gap-1">
              <h2 className="text-lg font-bold">Juicy Fortnite</h2>
              <div className="flex items-center gap-1">
                <IoIosStar className="text-[#01B67A] text-sm -mt-[2px]" />
                <IoIosStar className="text-[#01B67A] text-sm -mt-[2px]" />
                <IoIosStar className="text-[#01B67A] text-sm -mt-[2px]" />
                <IoIosStar className="text-[#01B67A] text-sm -mt-[2px]" />
                <IoIosStarHalf className="text-[#01B67A] text-sm -mt-[2px]" />
                <span className="text-xs text-[#01B67A]">4.5</span>
              </div>
              <div className="flex items-center space-x-[20px] text-gray-700">
                <div className="flex items-center gap-2 cursor-pointer">
                  <SlLike className="text-[16px]" />
                  <span className="text-xs">92.80%</span>
                </div>
                <div className="flex items-center gap-1 cursor-pointer">
                  <AiOutlineFire className="text-[18px]" />
                  <span className="text-xs">3,250</span>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex items-center justify-between">
            <span>Prix:</span>
            <div className="flex flex-col items-end">
              <div className="flex items-center">
                <span className="text-[28px] font-bold text-red-600">
                  80.52
                </span>
                <span className="text-red-600 mt-[15px] font-bold ml-1">
                  EUR
                </span>
              </div>
              <p className="text-xs text-gray-400">
                (80.52849 EUR/{quantity}X)
              </p>
            </div>
          </div>
          <div className="flex item-center gap-0 w-full select-none">
            <div className="flex flex-1 items-center justify-between border border-gray-300 p-2">
              <span
                className="text-gray-500 cursor-pointer"
                onClick={handleReduce}
              >
                -
              </span>
              <span className="font-semibold">{quantity}</span>
              <span
                className="text-gray-500 cursor-pointer"
                onClick={handleIncrease}
              >
                +
              </span>
            </div>
            <span className="flex items-center justify-between border text-gray-400 border-gray-300 p-2">
              {quantity}x
            </span>
          </div>
          <div className="w-full flex items-center justify-between">
            <span className="text-sm text-gray-400">Vendu:0</span>
            <span className="text-sm text-gray-400">Stock:92</span>
          </div>
          <button className="w-full flex items-center justify-center outline-none rounded text-base px-4 py-2 bg-red-600 text-white">
            Ajouter une commande
          </button>
          <div className="w-full relative">
            <input
              type="text"
              placeholder="Envoyer un message"
              className="w-[296px] text-base p-2 rounded outline-none"
            />
            <button className="absolute text-[#01B67A] top-[23%] left-[94%]">
              <IoSend className="text-[22px]" />
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-start">
        <h3 className="text-[22px] text-gray-800 pb-1 border-b-[2px] border-red-600">
          Details du produit
        </h3>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div></div>
    </div>
  );
};

export default SellerPageDetail;
