import React from "react";
import SideBar from "./SideBar";
import { FaRegListAlt } from "react-icons/fa";
import { ImCancelCircle } from "react-icons/im";
import { FiTruck } from "react-icons/fi";
import { BsFillCheckCircleFill } from "react-icons/bs";
import Table from "../../components/Table";

function Dashboard({ isViewProduct, setIsViewProduct, setIdPopupUser }) {
  const Status = [
    {
      bg: "bg-orange-100",
      icon: FaRegListAlt,
      color: "text-star",
      title: "Commande totale",
      titlen: "Total order",
      titles: "Pedido total",
      total: 45,
    },
    {
      bg: "bg-red-100",
      icon: ImCancelCircle,
      color: "text-flash",
      title: "Commande annulée",
      titlen: "canceled order",
      titles: "pedido cancelado",
      total: 2,
    },
    {
      bg: "bg-blue-100",
      icon: FiTruck,
      color: "text-blue-700",
      title: "Commande en cours",
      titlen: "Pending order",
      titles: "Pedido pendiente",
      total: 0,
    },
    {
      bg: "bg-green-100",
      icon: BsFillCheckCircleFill,
      color: "text-main",
      title: "Commande complète",
      titlen: "pedido completo",
      titles: "Completed order",
      total: 12,
    },
  ];
  return (
    <SideBar>
      <Table
        isViewProduct={isViewProduct}
        setIsViewProduct={setIsViewProduct}
        setIdPopupUser={setIdPopupUser}
      />
    </SideBar>
  );
}

export default Dashboard;
