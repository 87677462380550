import React from "react";
import { NavLink } from "react-router-dom";
import { BsGridFill } from "react-icons/bs";
import { FaRegListAlt } from "react-icons/fa";
import { AiOutlineShop } from "react-icons/ai";
import { FaShopLock } from "react-icons/fa";
import { MdOutlineMessage } from "react-icons/md";
import { FiSettings } from "react-icons/fi";
import { RiLockPasswordLine } from "react-icons/ri";
import { BiLogOut } from "react-icons/bi";
import { BsFillFileEarmarkPersonFill } from "react-icons/bs";
import axios from "axios";

import { removeUser } from "../../features/userSlice";

import { useDispatch, useSelector } from "react-redux";

function SideBar({ children }) {
  const { user } = useSelector((state) => state.user);
  const { language } = useSelector((state) => state.language);
  const dispatch = useDispatch();
  const SideLink = [
    {
      namefr: "Tableau de bord",
      namen: "Dashboard",
      names: "panel de control",
      link: "/profil",
      icon: BsGridFill,
      isRole: user?.role === "Admin" ? true : false,
    },
    {
      namefr: "Commandes",
      namen: "Orders",
      names: "Pedidos",
      link: "/orders",
      icon: FaRegListAlt,
      isRole: null,
    },
    // {
    //   namefr: "Boutique",
    //   namen: "Shop",
    //   names: "Comercio",
    //   link: "/shop",
    //   icon: AiOutlineShop,
    //   isRole: null,
    // },
    // {
    //   namefr: "Mes messages",
    //   namen: "My messages",
    //   names: "Mis mensajes",
    //   link: "/myinbox",
    //   icon: MdOutlineMessage,
    //   isRole: null,
    // },
    {
      namefr: "Mise à jour du profil",
      namen: "Update profile",
      names: "actualizar el perfil",
      link: "/update-profil",
      icon: FiSettings,
      isRole: null,
    },
    {
      namefr: "Modifier le mot de passe",
      namen: "Change password",
      names: "Cambiar la contraseña",
      link: "/password",
      icon: RiLockPasswordLine,
      isRole: null,
    },
    {
      namefr: "Mes infos",
      namen: "My Info",
      names: "mis noticias",
      link: "/personal-infos",
      icon: BsFillFileEarmarkPersonFill,
      isRole: null,
    },
  ];
  const active = "bg-red-500 text-white";
  const hover = `hover:text-white hover:bg-red-500`;
  const inActive =
    "rounded font-medium my-2 text-base transitions flex gap-3 items-center p-3";
  const Hover = ({ isActive }) =>
    isActive ? `${active} ${inActive}` : `${inActive} ${hover}`;

  const handleLogout = () => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_CLIENT_URL}/users/logout`,
      withCredentials: true,
    })
      .then(() => {
        dispatch(removeUser());
        window.location.href = "/";
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="bg-deepGray mt-4 scustom:mt-28">
      <div className="min-h-screen container mx-auto px-2">
        <div className="xl:grid grid-cols-8 gap-10 items-start md:py-12 py-6">
          <div className="sticky col-span-2 bg-white p-6 rounded-md xl:mb-0 mb-5">
            {SideLink.map((item, i) => (
              <NavLink
                style={{
                  display:
                    item?.isRole === null || item?.isRole ? "flex" : "none",
                }}
                key={i}
                className={Hover}
                to={item.link}
              >
                <item.icon className="text-[20px]" />{" "}
                <p>
                  {language === "francais" && item.namefr}
                  {language === "anglais" && item.namen}
                  {language === "espagnol" && item.names}
                </p>
              </NavLink>
            ))}
            <button
              className="cursor-pointer mt-8 rounded font-medium text-base transitions flex gap-3 items-center p-3 bg-red-600 text-white w-full hover:bg-red-700"
              onClick={handleLogout}
            >
              <BiLogOut />
              {language === "francais" && "Se déconnecter"}
              {language === "anglais" && "Log out"}
              {language === "espagnol" && "desconectar"}
            </button>
          </div>
          <div
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="10"
            data-aos-duration="1000"
            className="col-span-6 rounded-md bg-white p-6"
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideBar;
