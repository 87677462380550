import React from "react";
import SideBar from "./SideBar";
import { useSelector } from "react-redux";

import defaultUser from "../../assets/user.png";

const PersonalInfos = () => {
  const { user } = useSelector((state) => state.user);
  const { language } = useSelector((state) => state.language);
  return (
    <SideBar>
      <div className="p-4">
        <h1 className="text-2xl font-semibold mb-4">
          {language === "francais" && "Informations Personnelles"}
          {language === "anglais" && "Personal informations"}
          {language === "espagnol" && "Información personal"}
        </h1>
        <div className="grid grid-cols-1 bg-red-100 p-4 rounded sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          <div>
            <h2 className="text-lg font-medium">
              {language === "francais" && "Prénom"}
              {language === "anglais" && "Lastname"}
              {language === "espagnol" && "Nombre"}:
            </h2>
            <p>{user?.person?.lastname}</p>
          </div>
          <div>
            <h2 className="text-lg font-medium">
              {language === "francais" && "Nom"}
              {language === "anglais" && "Firstname"}
              {language === "espagnol" && "apellido"}:
            </h2>
            <p>{user?.person?.firstname}</p>
          </div>
          <div>
            <h2 className="text-lg font-medium">
              {language === "francais" && "Adresse"}
              {language === "anglais" && "Address"}
              {language === "espagnol" && "Dirección"}:
            </h2>
            <p>{user?.person?.address}</p>
          </div>
          <div>
            <h2 className="text-lg font-medium">
              {language === "francais" && "Ville"}
              {language === "anglais" && "City"}
              {language === "espagnol" && "Ciudad"}:
            </h2>
            <p>{user?.person?.city}</p>
          </div>
          <div>
            <h2 className="text-lg font-medium">
              {language === "francais" && "Code Postal"}
              {language === "anglais" && "Postal code"}
              {language === "espagnol" && "Código postal"}:
            </h2>
            <p>{user?.person?.postalCode}</p>
          </div>
          <div>
            <h2 className="text-lg font-medium">
              {language === "francais" && "Pays"}
              {language === "anglais" && "Country"}
              {language === "espagnol" && "País"}:
            </h2>
            <p>{user?.person?.country}</p>
          </div>
          <div>
            <h2 className="text-lg font-medium">Email:</h2>
            <p>{user?.person?.email}</p>
          </div>
          <div>
            <h2 className="text-lg font-medium">
              {language === "francais" && "Téléphone"}
              {language === "anglais" && "Phone"}
              {language === "espagnol" && "Teléfono"}:
            </h2>
            <p>{user?.person?.phone}</p>
          </div>
          <div>
            <h2 className="text-lg font-medium">
              {language === "francais" && "Profil"}
              {language === "anglais" && "Profile"}
              {language === "espagnol" && "Perfil"}
            </h2>
            <img
              src={user?.person?.profil ? user?.person?.profil : defaultUser}
              alt="profil"
              className="w-32 h-32 rounded"
            />
          </div>
        </div>
      </div>
    </SideBar>
  );
};

export default PersonalInfos;
