import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MdLockOutline } from "react-icons/md";
import {
  MdRadioButtonChecked,
  MdRadioButtonUnchecked,
  MdOutlineWarning,
} from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";

// import Loader from "../components/Loader";

import "react-loading-skeleton/dist/skeleton.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  changeQuantity,
  addCharacterByPaymentpage,
} from "../features/productInfoSlice";

import image1 from "../assets/checkout/image-1-checkout.jpg";
import image2 from "../assets/checkout/image-2-checkout.jpg";
import image3 from "../assets/checkout/image-3-checkout.jpg";

import creditcard_pay from "../assets/newpay/creditcard_pay.webp";
import creditcardgooglepay from "../assets/newpay/creditcardgooglepay.webp";
import google_pay from "../assets/newpay/google_pay.webp";
import paypals from "../assets/newpay/paypals.webp";
// import paysafecard from "../assets/newpay/paysafecard.webp";
import neosurf from "../assets/newpay/neosurf.webp";
import crypto from "../assets/newpay/crypto.webp";
import usdt from "../assets/newpay/usdt.webp";
import coinpal from "../assets/newpay/coinpal.png";

// import noverif from "../assets/payment-checkout/noverif.png";
import barid from "../assets/payment-checkout/barid_bank.png";
import cih from "../assets/payment-checkout/cih_bank.png";
import atti from "../assets/payment-checkout/atti_bank.png";
// import bmce from "../assets/payment-checkout/bmce_bank.png";
import wu from "../assets/payment-checkout/wu.png";
import cdm from "../assets/payment-checkout/cdm_bank.png";
import cashplus from "../assets/payment-checkout/cashplus.png";
import wafacash from "../assets/payment-checkout/wafacash.png";
import marocbank from "../assets/ma-bank/marocbank.webp";

import axios from "axios";

//J'ai remplacé bnb par sofort
//J'ai remplacé paypal par giropay

import SignIn from "../components/SignIn";
import PaymentSignUp from "../components/PaymentSignUp";

import PaymentSignin from "../components/PaymentSignin";
import { addNewOrderId } from "../features/orderIdSlice";

const PaymentPage = () => {
  const [isScrolled, setIsScrolled] = useState(0);
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  const productInfo = useSelector((state) => state.productsinfo);
  const { user } = useSelector((state) => state.user);
  const [comment, setComment] = useState("");
  const [login, setLogin] = useState(false);
  const [register, setRegister] = useState(false);
  const [curVal, setCurVal] = useState("");

  // const { ipAddr } = useSelector((state) => state.ipAddr);

  const [ipAddr, setIpAddr] = useState("France");

  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    if (scrollPosition >= 0 && scrollPosition <= 850) {
      setIsScrolled(scrollPosition);
    } else {
      setIsScrolled(850);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function handleChatClick() {
    void window.Tawk_API.toggle();
  }

  const commentErrorUser = () =>
    toast.error("Veuillez vous connecter avant d'ajouter un commentaire");

  const commentErrorVal = () =>
    toast.error("Veuillez remplir le champ de commentaire avant de confirmer");

  const commentSuccess = () =>
    toast.success("Merci d'avoir ajouter un commentaire !");

  const handleConfirmComment = () => {
    if (!user?.user) {
      commentErrorUser();
    } else if (!comment) {
      commentErrorVal();
    } else {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_CLIENT_URL}/users/create-comment`,
        data: {
          commentEmail: user?.person?.email,
          commentLastname: user?.person?.lastname,
          commentFirstname: user?.person?.firstname,
          comment: comment,
          image: user?.person?.profil,
        },
      })
        .then((res) => {
          // console.log(res);
          commentSuccess();
          setComment("");
        })
        .catch((error) => console.log(error));
    }
  };

  function numberWithCommas(x) {
    x = x.toString();
    var pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(x)) x = x.replace(pattern, "$1, $2");
    return x;
  }

  const { currency } = useSelector((state) => state.currency);

  const { currencyValue } = useSelector((state) => state.currency);

  const dispatch = useDispatch();

  useEffect(() => {
    if (currency === "euro") {
      setCurVal("EUR");
    } else if (currency === "dollar") {
      setCurVal("USD");
    } else if (currency === "gbp") {
      setCurVal("GBP");
    } else if (currency === "cad") {
      setCurVal("CAD");
    } else if (currency === "chf") {
      setCurVal("CHF");
    } else if (currency === "rub") {
      setCurVal("RUB");
    } else if (currency === "mad") {
      setCurVal("MAD");
    } else {
      setCurVal("USD");
    }
  }, [currency]);

  const { language } = useSelector((state) => state.language);
  const [totalPrice, setTotalPrice] = useState(0);
  const [selectedChange, setSelectedChange] = useState(
    productInfo?.serverSelected
  );
  const [character1, setCharacter1] = useState("");
  const [character2, setCharacter2] = useState("");
  const [loader, setLoader] = useState(false);

  const [visamastercardPay, setVisamastercardPay] = useState(false);
  const [creditcardPay, setCreditcardPay] = useState(false);
  const [paysafecardPay, setPaysafecardPay] = useState(false);
  const [googlePay, setGooglePay] = useState(false);
  const [creditcardgooglePay, setCreditcardgooglePay] = useState(false);
  const [usdtPay, setUsdtPay] = useState(false);
  const [paypalPay, setPaypalPay] = useState(false);
  const [visamasterPay, setVisamasterPay] = useState(false);
  const [credicardPay, setCredicardPay] = useState(false);
  const [idealPay, setIdealPay] = useState(false);
  const [cryptoPay, setCryptoPay] = useState(false);
  const [neosurfPay, setNeosurfPay] = useState(false);
  const [skrillPay, setSkrillPay] = useState(false);
  const [ltcPay, setLtcPay] = useState(false);
  const [bnbPay, setBnbPay] = useState(false);
  const [cihPay, setCihPay] = useState(false);
  const [baridPay, setBaridPay] = useState(false);
  const [attiPay, setAttiPay] = useState(false);
  const [bmcePay, setBmcePay] = useState(false);
  const [cdmPay, setCdmPay] = useState(false);
  const [wafaPay, setWafaPay] = useState(false);
  const [cashPay, setCashPay] = useState(false);
  const [sepaPay, setSepaPay] = useState(false);
  const [binancePay, setBinancePay] = useState(false);
  const [skrillibenPay, setSkrillIbenPay] = useState(false);
  const [coinpalPay, setCoinpalPay] = useState(false);
  const [paypalOrder, setPaypalOrder] = useState({});

  const notifyNotRegisteredPaymentFrench = () =>
    toast.error("Veuillez vous connecter d'abord avant de faire une commande");

  const notifyNotRegisteredPaymentEnglish = () =>
    toast.error("Please login first before placing an order");

  const notifyfirstCharacterRefFrench = () =>
    toast.error("Le nom du personnage ne doit pas être vide");

  const notifyfirstCharacterRefEnglish = () =>
    toast.error("Character name must not be empty");

  const notifyconfirmCharacterRefFrench = () =>
    toast.error("Les noms de personnages ne correspondent pas");

  const notifyconfirmCharacterRefEnglish = () =>
    toast.error("Character names don't match");

  const notifycheckErrorFrench = () =>
    toast.error(
      "Veuillez valider les termes et conditions et la politique de confidentialité"
    );

  const notifycheckErrorEnglish = () =>
    toast.error(
      "Please validate the terms and conditions and the privacy policy"
    );

  const notyFysuccessMessageFrench = () =>
    toast.success(
      "Votre commande a été créé avec succéss, vous pouvez verifier sur votre profil, mes commandes"
    );

  const notyFysuccessMessageEnglish = () =>
    toast.success(
      "Your order has been created successfully, you can check on your profile, my orders"
    );

  const notyFysuccessMessageEspagnol = () =>
    toast.success(
      "Su pedido ha sido creado con éxito, puede comprobar en su perfil, mis pedidos"
    );

  const notifyNotSigninFrench = () =>
    toast.error("Veuillez vous connecter d'abord avant de faire une commande");

  const notifyPaymentErrorFrench = () =>
    toast.error("Veuillez choisir une méthode de paiements valide.");

  const notifyPaymentErrorEnglish = () =>
    toast.error("Please choose a valid payment method.");

  const notifyPaymentErrorSpanish = () =>
    toast.error("Elija un método de pago válido.");

  const notifyTotalPriceFrench = () =>
    toast.error("Le prix de la commande ne doit pas être nul");

  const notifyTotalPriceEnglish = () =>
    toast.error("Order price must be valid");

  const notifyNotSigninEnglish = () =>
    toast.error("Please login first before placing an order");

  const notyFySuccessCharacterFrench = () =>
    toast.success("Nom du personnage validé avec succéss");

  const notyFySuccessCharacterEnglish = () =>
    toast.success("Character name validated successfully");

  const handleDisplayLoginOrRegister = (e) => {
    if (e.target.id === "login") {
      setLogin(true);
      setRegister(false);
    } else if (e.target.id === "register") {
      setRegister(true);
      setLogin(false);
    } else {
      setLogin(false);
      setRegister(false);
    }
  };

  const fixedPriceBuyNow = (val) => {
    let numberToReturn = 0;
    if (val !== "undefinded" && typeof val !== "string" && val !== "") {
      numberToReturn = val.toFixed(2);
    }
    return numberToReturn;
  };

  useEffect(() => {
    setTotalPrice((productInfo?.amount / 1000000) * productInfo?.price);
  }, [productInfo, selectedChange]);

  useEffect(() => {
    setTotalPrice((productInfo?.amount / 1000000) * productInfo?.price);
  }, [productInfo, selectedChange]);

  const handleChangeValue = (e) => {
    setSelectedChange(e.target.value);
    dispatch(changeQuantity(e.target.value));
  };
  const orderNumGenerated = () => {
    const generateOrderNum = "0123456789";

    let myCode = "";
    for (let i = 0; i < 6; i++) {
      let code = Math.floor(Math.random() * generateOrderNum.length);
      myCode += generateOrderNum[code];
    }
    return myCode;
  };

  useEffect(() => {
    const newProduct = {
      productId: productInfo?.productId,
      category: productInfo?.category,
      server: productInfo?.server,
      qty: productInfo?.amount,
      amount: productInfo?.amount / 1000000,
      price: Number(productInfo?.price),
      character: character1,
    };

    setPaypalOrder({
      userId: user?.user,
      orderNum: orderNumGenerated(),
      detailUser: user?.person,
      products: [newProduct],
      address: "",
      status: "En attente",
      totalPrice: Number(fixedPriceBuyNow(totalPrice)),
      paymentMethod: "",
    });
  }, [productInfo]);

  const payCheck = document.getElementById("payCheckPayment");

  const handleAddOrdersPayment = () => {
    if (!character1 || character1 !== character2) {
      language === "anglais" && notifyfirstCharacterRefEnglish();
      language === "francais" && notifyfirstCharacterRefFrench();
    } else if (!payCheck.checked) {
      language === "anglais" && notifycheckErrorEnglish();
      language === "francais" && notifycheckErrorFrench();
    } else if (!user.user) {
      language === "anglais" && notifyNotRegisteredPaymentEnglish();
      language === "francais" && notifyNotRegisteredPaymentFrench();
    } else if (totalPrice < 0) {
      language === "anglais" && notifyTotalPriceEnglish();
      language === "francais" && notifyTotalPriceFrench();
    } else {
      let orderNum = orderNumGenerated();
      const newProduct = {
        productId: productInfo?.productId,
        category: productInfo?.category,
        server: productInfo?.server,
        qty: productInfo?.amount,
        amount: productInfo?.amount / 1000000,
        price: Number(productInfo?.price),
        character: character1,
      };
      const orderData = {
        userId: user?.user,
        orderNum: orderNum,
        detailUser: user?.person,
        products: [newProduct],
        address: "",
        status: "En attente",
        totalPrice: Number(fixedPriceBuyNow(totalPrice)),
        paymentMethod: "",
        orderIdPaid: "",
        cur: productInfo?.cur,
      };

      let orderToPush = [];
      orderToPush.push(orderData);
      let payments = [];
      let cur = "";

      if (currency === "euro") {
        if (visamastercardPay || visamasterPay || credicardPay) {
          orderData.paymentMethod = "Carte bancaire";
          payments = ["card"];
          cur = "eur";
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/create-checkout-session`,
            data: { line_items: orderToPush, devise: cur, pay: payments },
          })
            .then((res) => {
              window.location = res.data.url;
            })
            .then(() => {
              axios({
                method: "post",
                url: `${process.env.REACT_APP_CLIENT_URL}/order`,
                data: orderData,
              })
                .then((res) => {})
                .catch((error) => console.log(error));
            })
            .catch((error) => console.log(error));
        } else if (idealPay) {
          orderData.paymentMethod = "Ideal";
          payments = ["ideal"];
          cur = "eur";
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/create-checkout-session`,
            data: { line_items: orderToPush, devise: cur, pay: payments },
          })
            .then((res) => {
              window.location = res.data.url;
              // console.log(res.data.url);
              // language === "anglais" && notyFysuccessMessageEnglish();
              // language === "francais" && notyFysuccessMessageFrench();
            })
            .then(() => {
              axios({
                method: "post",
                url: `${process.env.REACT_APP_CLIENT_URL}/order`,
                data: orderData,
              })
                .then((res) => {
                  // console.log(res.data);
                  // language === "anglais" && notyFySuccessOrderEnglish();
                  // language === "francais" && notyFySuccessOrderFrench();
                })
                .catch((error) => console.log(error));
            })
            .catch((error) => console.log(error));
        } else if (cryptoPay) {
          orderData.paymentMethod = "Coinbase";
          getCoinbaseCommerce();
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          });
        } else if (paypalPay) {
          // let productId = "";
          // const handlePayment = async () => {
          //   try {
          //     const response = await axios.post(
          //       `${process.env.REACT_APP_CLIENT_URL}/paypal/create-paypal-order`,
          //       {
          //         currency_code: "EUR",
          //         value: fixedPriceBuyNow(totalPrice),
          //       }
          //     );

          //     dispatch(
          //       addNewOrderId({
          //         id: response.data.id,
          //         page: "paymentPage",
          //         productId: response.data.id,
          //       })
          //     );
          //     orderData.orderIdPaid = response.data.id;
          //     axios({
          //       method: "post",
          //       url: `${process.env.REACT_APP_CLIENT_URL}/order`,
          //       data: orderData,
          //     }).then((res) => {
          //       productId = res.data._id;
          //       // alert(
          //       //   "Payment methods are not yet available.Go to the chat to discuss and pay your order"
          //       // );
          //       // language === "anglais" && notyFysuccessMessageEnglish();
          //       // language === "espagnol" && notyFysuccessMessageEspagnol();
          //       // language === "francais" && notyFysuccessMessageFrench();
          //       // handleChatClick();
          //     });

          //     const { href } = response.data.links[1];

          //     // Redirection vers la page de paiement PayPal
          //     // window.open(href, "_blank");
          //     // // console.log(response.data);
          //     window.location.href = href;
          //   } catch (error) {
          //     console.error(
          //       "Erreur lors de la création de la commande PayPal :",
          //       error
          //     );
          //   }
          // };

          // handlePayment();

          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          }).then((res) => {
            alert(
              "Payment methods are not yet available.Go to the chat to discuss and pay your order"
            );
            language === "anglais" && notyFysuccessMessageEnglish();
            language === "espagnol" && notyFysuccessMessageEspagnol();
            language === "francais" && notyFysuccessMessageFrench();
            handleChatClick();
          });

          // const clientId =
          //   "AfO_TRWnrEes1om_ER4PoGIJa3uRYtbMJhCGwvrzO0-i8B1OiVbthTUSc78KiHVklczmiRDoRMiTZkkb";
          // window.location = `/paymentbtc/${user?.user}/${Number(
          //   fixedPriceBuyNow(totalPrice)
          // )}`;
          // axios({
          //   method: "post",
          //   url: `${process.env.REACT_APP_CLIENT_URL}/order`,
          //   data: orderData,
          // }).then((res) => {
          //   alert(
          //     "Payment methods are not yet available.Go to the chat to discuss and pay your order"
          //   );
          //   language === "anglais" && notyFysuccessMessageEnglish();
          //   language === "espagnol" && notyFysuccessMessageEspagnol();
          //   language === "francais" && notyFysuccessMessageFrench();
          //   handleChatClick();
          // });
          // const paypalUrl = `https://www.paypal.com/checkoutnow?client-id=AfO_TRWnrEes1om_ER4PoGIJa3uRYtbMJhCGwvrzO0-i8B1OiVbthTUSc78KiHVklczmiRDoRMiTZkkb&amount=20&currency=USD`;

          // window.open(paypalUrl, "_blank");
          // window.location = `/paymentbtc/${user?.user}/${Number(
          //   fixedPriceBuyNow(totalPrice)
          // )}`;
          // axios({
          //   method: "post",
          //   url: `${process.env.REACT_APP_CLIENT_URL}/order`,
          //   data: orderData,
          // }).then((res) => {
          //   alert(
          //     "Payment methods are not yet available.Go to the chat to discuss and pay your order"
          //   );
          //   language === "anglais" && notyFysuccessMessageEnglish();
          //   language === "espagnol" && notyFysuccessMessageEspagnol();
          //   language === "francais" && notyFysuccessMessageFrench();
          //   handleChatClick();
          // });
        } else if (creditcardPay) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          }).then((res) => {
            alert(
              "Payment methods are not yet available.Go to the chat to discuss and pay your order"
            );
            language === "anglais" && notyFysuccessMessageEnglish();
            language === "espagnol" && notyFysuccessMessageEspagnol();
            language === "francais" && notyFysuccessMessageFrench();
            handleChatClick();
          });
        } else if (paysafecardPay) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          }).then((res) => {
            alert(
              "Payment methods are not yet available.Go to the chat to discuss and pay your order"
            );
            language === "anglais" && notyFysuccessMessageEnglish();
            language === "espagnol" && notyFysuccessMessageEspagnol();
            language === "francais" && notyFysuccessMessageFrench();
            handleChatClick();
          });
        } else if (googlePay) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          }).then((res) => {
            alert(
              "Payment methods are not yet available.Go to the chat to discuss and pay your order"
            );
            language === "anglais" && notyFysuccessMessageEnglish();
            language === "espagnol" && notyFysuccessMessageEspagnol();
            language === "francais" && notyFysuccessMessageFrench();
            handleChatClick();
          });
        } else if (creditcardgooglePay) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          }).then((res) => {
            alert(
              "Payment methods are not yet available.Go to the chat to discuss and pay your order"
            );
            language === "anglais" && notyFysuccessMessageEnglish();
            language === "espagnol" && notyFysuccessMessageEspagnol();
            language === "francais" && notyFysuccessMessageFrench();
            handleChatClick();
          });
        } else if (coinpalPay) {
          orderData.paymentMethod = "coinpal";

          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/coinpal/make-payment`,
            data: {
              currency: "EUR",
              amount: fixedPriceBuyNow(totalPrice),
              email: user?.person?.email,
              description: `${productInfo?.category} - ${productInfo?.server}`,
              orderNum: orderNum,
            },
          }).then((res) => {
            window.location.href = res?.data?.nextStepContent;
          });
        } else if (bnbPay) {
          orderData.paymentMethod = "Sofort";
          payments = ["sofort"];
          cur = "eur";
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/create-checkout-session`,
            data: { line_items: orderToPush, devise: cur, pay: payments },
          })
            .then((res) => {
              window.location = res.data.url;
              // console.log(res.data.url);
              // language === "anglais" && notyFysuccessMessageEnglish();
              // language === "francais" && notyFysuccessMessageFrench();
            })
            .then(() => {
              axios({
                method: "post",
                url: `${process.env.REACT_APP_CLIENT_URL}/order`,
                data: orderData,
              })
                .then((res) => {
                  // console.log(res.data);
                  // language === "anglais" && notyFySuccessOrderEnglish();
                  // language === "francais" && notyFySuccessOrderFrench();
                })
                .catch((error) => console.log(error));
            })
            .catch((error) => console.log(error));
        } else if (usdtPay) {
          orderData.paymentMethod = "Usdt TRC20";
          window.location = `/paymentusdt/${user?.user}/${Number(
            fixedPriceBuyNow(totalPrice + 1)
          )}`;
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          });
        } else if (neosurfPay) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          }).then((res) => {
            alert(
              "Payment methods are not yet available.Go to the chat to discuss and pay your order"
            );
            language === "anglais" && notyFysuccessMessageEnglish();
            language === "espagnol" && notyFysuccessMessageEspagnol();
            language === "francais" && notyFysuccessMessageFrench();
            handleChatClick();
          });
        } else {
          language === "anglais" && notifyPaymentErrorEnglish();
          language === "francais" && notifyPaymentErrorFrench();
          language === "espagnol" && notifyPaymentErrorSpanish();
        }
      } else if (currency === "dollar") {
        if (visamastercardPay || visamasterPay || credicardPay) {
          orderData.paymentMethod = "Carte bancaire";
          payments = ["card"];
          cur = "usd";
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/create-checkout-session`,
            data: { line_items: orderToPush, devise: cur, pay: payments },
          })
            .then((res) => {
              window.location = res.data.url;
              // console.log(res.data.url);
              // language === "anglais" && notyFysuccessMessageEnglish();
              // language === "francais" && notyFysuccessMessageFrench();
            })
            .then(() => {
              axios({
                method: "post",
                url: `${process.env.REACT_APP_CLIENT_URL}/order`,
                data: orderData,
              })
                .then((res) => {
                  // console.log(res.data);
                  // language === "anglais" && notyFySuccessOrderEnglish();
                  // language === "francais" && notyFySuccessOrderFrench();
                })
                .catch((error) => console.log(error));
            })
            .catch((error) => console.log(error));
        } else if (cryptoPay) {
          orderData.paymentMethod = "Coinbase";
          getCoinbaseCommerce();
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          });
        } else if (coinpalPay) {
          orderData.paymentMethod = "coinpal";

          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/coinpal/make-payment`,
            data: {
              currency: "USD",
              amount: fixedPriceBuyNow(totalPrice),
              email: user?.person?.email,
              description: `${productInfo?.category} - ${productInfo?.server}`,
              orderNum: orderNum,
            },
          }).then((res) => {
            window.location.href = res?.data?.nextStepContent;
          });
        } else if (usdtPay) {
          orderData.paymentMethod = "Usdt TRC20";
          window.location = `/paymentusdt/${user?.user}/${Number(
            fixedPriceBuyNow(totalPrice)
          )}`;
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          });
        } else if (paypalPay) {
          // const handlePayment = async () => {
          //   try {
          //     const response = await axios.post(
          //       `${process.env.REACT_APP_CLIENT_URL}/paypal/create-paypal-order`,
          //       {
          //         currency_code: "USD",
          //         value: fixedPriceBuyNow(totalPrice),
          //       }
          //     );

          //     dispatch(
          //       addNewOrderId({
          //         id: response.data.id,
          //         page: "paymentPage",
          //         productId: response.data.id,
          //       })
          //     );
          //     orderData.orderIdPaid = response.data.id;
          //     axios({
          //       method: "post",
          //       url: `${process.env.REACT_APP_CLIENT_URL}/order`,
          //       data: orderData,
          //     }).then((res) => {
          //       // alert(
          //       //   "Payment methods are not yet available.Go to the chat to discuss and pay your order"
          //       // );
          //       // language === "anglais" && notyFysuccessMessageEnglish();
          //       // language === "espagnol" && notyFysuccessMessageEspagnol();
          //       // language === "francais" && notyFysuccessMessageFrench();
          //       // handleChatClick();
          //     });

          //     const { href } = response.data.links[1];

          //     // Redirection vers la page de paiement PayPal
          //     // window.open(href, "_blank");
          //     // // console.log(response.data);
          //     window.location.href = href;
          //   } catch (error) {
          //     console.error(
          //       "Erreur lors de la création de la commande PayPal :",
          //       error
          //     );
          //   }
          // };

          // handlePayment();

          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          }).then((res) => {
            alert(
              "Payment methods are not yet available.Go to the chat to discuss and pay your order"
            );
            language === "anglais" && notyFysuccessMessageEnglish();
            language === "espagnol" && notyFysuccessMessageEspagnol();
            language === "francais" && notyFysuccessMessageFrench();
            handleChatClick();
          });

          // window.location = `/paymentbtc/${user?.user}/${Number(
          //   fixedPriceBuyNow(totalPrice)
          // )}`;
          // axios({
          //   method: "post",
          //   url: `${process.env.REACT_APP_CLIENT_URL}/order`,
          //   data: orderData,
          // }).then((res) => {
          //   alert(
          //     "Payment methods are not yet available.Go to the chat to discuss and pay your order"
          //   );
          //   language === "anglais" && notyFysuccessMessageEnglish();
          //   language === "espagnol" && notyFysuccessMessageEspagnol();
          //   language === "francais" && notyFysuccessMessageFrench();
          //   handleChatClick();
          // });
        } else if (creditcardPay) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          }).then((res) => {
            alert(
              "Payment methods are not yet available.Go to the chat to discuss and pay your order"
            );
            language === "anglais" && notyFysuccessMessageEnglish();
            language === "espagnol" && notyFysuccessMessageEspagnol();
            language === "francais" && notyFysuccessMessageFrench();
            handleChatClick();
          });
        } else if (paysafecardPay) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          }).then((res) => {
            alert(
              "Payment methods are not yet available.Go to the chat to discuss and pay your order"
            );
            language === "anglais" && notyFysuccessMessageEnglish();
            language === "espagnol" && notyFysuccessMessageEspagnol();
            language === "francais" && notyFysuccessMessageFrench();
            handleChatClick();
          });
        } else if (googlePay) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          }).then((res) => {
            alert(
              "Payment methods are not yet available.Go to the chat to discuss and pay your order"
            );
            language === "anglais" && notyFysuccessMessageEnglish();
            language === "espagnol" && notyFysuccessMessageEspagnol();
            language === "francais" && notyFysuccessMessageFrench();
            handleChatClick();
          });
        } else if (creditcardgooglePay) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          }).then((res) => {
            alert(
              "Payment methods are not yet available.Go to the chat to discuss and pay your order"
            );
            language === "anglais" && notyFysuccessMessageEnglish();
            language === "espagnol" && notyFysuccessMessageEspagnol();
            language === "francais" && notyFysuccessMessageFrench();
            handleChatClick();
          });
        } else if (neosurfPay) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          }).then((res) => {
            alert(
              "Payment methods are not yet available.Go to the chat to discuss and pay your order"
            );
            language === "anglais" && notyFysuccessMessageEnglish();
            language === "espagnol" && notyFysuccessMessageEspagnol();
            language === "francais" && notyFysuccessMessageFrench();
            handleChatClick();
          });
        } else {
          language === "anglais" && notifyPaymentErrorEnglish();
          language === "francais" && notifyPaymentErrorFrench();
          language === "espagnol" && notifyPaymentErrorSpanish();
        }
      } else if (currency === "cad") {
        if (visamastercardPay || visamasterPay || credicardPay) {
          orderData.paymentMethod = "Carte bancaire";
          payments = ["card"];
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/create-checkout-session`,
            data: { line_items: orderToPush, devise: currency, pay: payments },
          })
            .then((res) => {
              window.location = res.data.url;
              // console.log(res.data.url);
              // language === "anglais" && notyFysuccessMessageEnglish();
              // language === "francais" && notyFysuccessMessageFrench();
            })
            .then(() => {
              axios({
                method: "post",
                url: `${process.env.REACT_APP_CLIENT_URL}/order`,
                data: orderData,
              })
                .then((res) => {
                  // console.log(res.data);
                  // language === "anglais" && notyFySuccessOrderEnglish();
                  // language === "francais" && notyFySuccessOrderFrench();
                })
                .catch((error) => console.log(error));
            })
            .catch((error) => console.log(error));
        } else if (paypalPay) {
          // const handlePayment = async () => {
          //   try {
          //     const response = await axios.post(
          //       `${process.env.REACT_APP_CLIENT_URL}/paypal/create-paypal-order`,
          //       {
          //         currency_code: "CAD",
          //         value: fixedPriceBuyNow(totalPrice),
          //       }
          //     );

          //     dispatch(
          //       addNewOrderId({
          //         id: response.data.id,
          //         page: "paymentPage",
          //         productId: response.data.id,
          //       })
          //     );
          //     orderData.orderIdPaid = response.data.id;
          //     axios({
          //       method: "post",
          //       url: `${process.env.REACT_APP_CLIENT_URL}/order`,
          //       data: orderData,
          //     }).then((res) => {
          //       // alert(
          //       //   "Payment methods are not yet available.Go to the chat to discuss and pay your order"
          //       // );
          //       // language === "anglais" && notyFysuccessMessageEnglish();
          //       // language === "espagnol" && notyFysuccessMessageEspagnol();
          //       // language === "francais" && notyFysuccessMessageFrench();
          //       // handleChatClick();
          //     });

          //     const { href } = response.data.links[1];

          //     // Redirection vers la page de paiement PayPal
          //     // window.open(href, "_blank");
          //     // // console.log(response.data);
          //     window.location.href = href;
          //   } catch (error) {
          //     console.error(
          //       "Erreur lors de la création de la commande PayPal :",
          //       error
          //     );
          //   }
          // };

          // handlePayment();

          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          }).then((res) => {
            alert(
              "Payment methods are not yet available.Go to the chat to discuss and pay your order"
            );
            language === "anglais" && notyFysuccessMessageEnglish();
            language === "espagnol" && notyFysuccessMessageEspagnol();
            language === "francais" && notyFysuccessMessageFrench();
            handleChatClick();
          });
        } else if (creditcardPay) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          }).then((res) => {
            alert(
              "Payment methods are not yet available.Go to the chat to discuss and pay your order"
            );
            language === "anglais" && notyFysuccessMessageEnglish();
            language === "espagnol" && notyFysuccessMessageEspagnol();
            language === "francais" && notyFysuccessMessageFrench();
            handleChatClick();
          });
        } else if (paysafecardPay) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          }).then((res) => {
            alert(
              "Payment methods are not yet available.Go to the chat to discuss and pay your order"
            );
            language === "anglais" && notyFysuccessMessageEnglish();
            language === "espagnol" && notyFysuccessMessageEspagnol();
            language === "francais" && notyFysuccessMessageFrench();
            handleChatClick();
          });
        } else if (googlePay) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          }).then((res) => {
            alert(
              "Payment methods are not yet available.Go to the chat to discuss and pay your order"
            );
            language === "anglais" && notyFysuccessMessageEnglish();
            language === "espagnol" && notyFysuccessMessageEspagnol();
            language === "francais" && notyFysuccessMessageFrench();
            handleChatClick();
          });
        } else if (creditcardgooglePay) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          }).then((res) => {
            alert(
              "Payment methods are not yet available.Go to the chat to discuss and pay your order"
            );
            language === "anglais" && notyFysuccessMessageEnglish();
            language === "espagnol" && notyFysuccessMessageEspagnol();
            language === "francais" && notyFysuccessMessageFrench();
            handleChatClick();
          });
        } else if (coinpalPay) {
          orderData.paymentMethod = "coinpal";

          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/coinpal/make-payment`,
            data: {
              currency: "CAD",
              amount: fixedPriceBuyNow(totalPrice),
              email: user?.person?.email,
              description: `${productInfo?.category} - ${productInfo?.server}`,
              orderNum: orderNum,
            },
          }).then((res) => {
            window.location.href = res?.data?.nextStepContent;
          });
        } else if (cryptoPay) {
          orderData.paymentMethod = "Coinbase";
          getCoinbaseCommerce();
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          });
        } else if (usdtPay) {
          orderData.paymentMethod = "Usdt TRC20";
          window.location = `/paymentusdt/${user?.user}/${Number(
            fixedPriceBuyNow(totalPrice)
          )}`;
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          });
        } else if (neosurfPay) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          }).then((res) => {
            alert(
              "Payment methods are not yet available.Go to the chat to discuss and pay your order"
            );
            language === "anglais" && notyFysuccessMessageEnglish();
            language === "espagnol" && notyFysuccessMessageEspagnol();
            language === "francais" && notyFysuccessMessageFrench();
            handleChatClick();
          });
        } else {
          language === "anglais" && notifyPaymentErrorEnglish();
          language === "francais" && notifyPaymentErrorFrench();
          language === "espagnol" && notifyPaymentErrorSpanish();
        }
      } else if (currency === "gbp") {
        if (visamastercardPay || visamasterPay || credicardPay) {
          orderData.paymentMethod = "Carte bancaire";
          payments = ["card"];
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/create-checkout-session`,
            data: { line_items: orderToPush, devise: currency, pay: payments },
          })
            .then((res) => {
              window.location = res.data.url;
              // console.log(res.data.url);
              // language === "anglais" && notyFysuccessMessageEnglish();
              // language === "francais" && notyFysuccessMessageFrench();
            })
            .then(() => {
              axios({
                method: "post",
                url: `${process.env.REACT_APP_CLIENT_URL}/order`,
                data: orderData,
              })
                .then((res) => {
                  // console.log(res.data);
                  // language === "anglais" && notyFySuccessOrderEnglish();
                  // language === "francais" && notyFySuccessOrderFrench();
                })
                .catch((error) => console.log(error));
            })
            .catch((error) => console.log(error));
        } else if (cryptoPay) {
          orderData.paymentMethod = "Coinbase";
          getCoinbaseCommerce();
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          });
        } else if (paypalPay) {
          // const handlePayment = async () => {
          //   try {
          //     const response = await axios.post(
          //       `${process.env.REACT_APP_CLIENT_URL}/paypal/create-paypal-order`,
          //       {
          //         currency_code: "GBP",
          //         value: fixedPriceBuyNow(totalPrice),
          //       }
          //     );

          //     dispatch(
          //       addNewOrderId({
          //         id: response.data.id,
          //         page: "paymentPage",
          //         productId: response.data.id,
          //       })
          //     );
          //     orderData.orderIdPaid = response.data.id;
          //     axios({
          //       method: "post",
          //       url: `${process.env.REACT_APP_CLIENT_URL}/order`,
          //       data: orderData,
          //     }).then((res) => {
          //       // alert(
          //       //   "Payment methods are not yet available.Go to the chat to discuss and pay your order"
          //       // );
          //       // language === "anglais" && notyFysuccessMessageEnglish();
          //       // language === "espagnol" && notyFysuccessMessageEspagnol();
          //       // language === "francais" && notyFysuccessMessageFrench();
          //       // handleChatClick();
          //     });

          //     const { href } = response.data.links[1];

          //     // Redirection vers la page de paiement PayPal
          //     // window.open(href, "_blank");
          //     // // console.log(response.data);
          //     window.location.href = href;
          //   } catch (error) {
          //     console.error(
          //       "Erreur lors de la création de la commande PayPal :",
          //       error
          //     );
          //   }
          // };

          // handlePayment();

          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          }).then((res) => {
            alert(
              "Payment methods are not yet available.Go to the chat to discuss and pay your order"
            );
            language === "anglais" && notyFysuccessMessageEnglish();
            language === "espagnol" && notyFysuccessMessageEspagnol();
            language === "francais" && notyFysuccessMessageFrench();
            handleChatClick();
          });

        } else if (creditcardPay) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          }).then((res) => {
            alert(
              "Payment methods are not yet available.Go to the chat to discuss and pay your order"
            );
            language === "anglais" && notyFysuccessMessageEnglish();
            language === "espagnol" && notyFysuccessMessageEspagnol();
            language === "francais" && notyFysuccessMessageFrench();
            handleChatClick();
          });
        } else if (paysafecardPay) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          }).then((res) => {
            alert(
              "Payment methods are not yet available.Go to the chat to discuss and pay your order"
            );
            language === "anglais" && notyFysuccessMessageEnglish();
            language === "espagnol" && notyFysuccessMessageEspagnol();
            language === "francais" && notyFysuccessMessageFrench();
            handleChatClick();
          });
        } else if (googlePay) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          }).then((res) => {
            alert(
              "Payment methods are not yet available.Go to the chat to discuss and pay your order"
            );
            language === "anglais" && notyFysuccessMessageEnglish();
            language === "espagnol" && notyFysuccessMessageEspagnol();
            language === "francais" && notyFysuccessMessageFrench();
            handleChatClick();
          });
        } else if (creditcardgooglePay) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          }).then((res) => {
            alert(
              "Payment methods are not yet available.Go to the chat to discuss and pay your order"
            );
            language === "anglais" && notyFysuccessMessageEnglish();
            language === "espagnol" && notyFysuccessMessageEspagnol();
            language === "francais" && notyFysuccessMessageFrench();
            handleChatClick();
          });
        } else if (coinpalPay) {
          orderData.paymentMethod = "coinpal";

          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/coinpal/make-payment`,
            data: {
              currency: "GBP",
              amount: fixedPriceBuyNow(totalPrice),
              email: user?.person?.email,
              description: `${productInfo?.category} - ${productInfo?.server}`,
              orderNum: orderNum,
            },
          }).then((res) => {
            window.location.href = res?.data?.nextStepContent;
          });
        } else if (usdtPay) {
          orderData.paymentMethod = "Usdt TRC20";
          window.location = `/paymentusdt/${user?.user}/${Number(
            fixedPriceBuyNow(totalPrice)
          )}`;
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          });
        } else if (neosurfPay) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          }).then((res) => {
            alert(
              "Payment methods are not yet available.Go to the chat to discuss and pay your order"
            );
            language === "anglais" && notyFysuccessMessageEnglish();
            language === "espagnol" && notyFysuccessMessageEspagnol();
            language === "francais" && notyFysuccessMessageFrench();
            handleChatClick();
          });
        } else {
          language === "anglais" && notifyPaymentErrorEnglish();
          language === "francais" && notifyPaymentErrorFrench();
          language === "espagnol" && notifyPaymentErrorSpanish();
        }
      } else if (currency === "chf") {
        if (visamastercardPay || visamasterPay || credicardPay) {
          orderData.paymentMethod = "Carte bancaire";
          payments = ["card"];
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/create-checkout-session`,
            data: { line_items: orderToPush, devise: currency, pay: payments },
          })
            .then((res) => {
              window.location = res.data.url;
              // console.log(res.data.url);
              // language === "anglais" && notyFysuccessMessageEnglish();
              // language === "francais" && notyFysuccessMessageFrench();
            })
            .then(() => {
              axios({
                method: "post",
                url: `${process.env.REACT_APP_CLIENT_URL}/order`,
                data: orderData,
              })
                .then((res) => {
                  // console.log(res.data);
                  // language === "anglais" && notyFySuccessOrderEnglish();
                  // language === "francais" && notyFySuccessOrderFrench();
                })
                .catch((error) => console.log(error));
            })
            .catch((error) => console.log(error));
        } else if (cryptoPay) {
          orderData.paymentMethod = "Coinbase";
          getCoinbaseCommerce();
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          });
        } else if (usdtPay) {
          orderData.paymentMethod = "Usdt TRC20";
          window.location = `/paymentusdt/${user?.user}/${Number(
            fixedPriceBuyNow(totalPrice)
          )}`;
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          });
        } else if (coinpalPay) {
          orderData.paymentMethod = "coinpal";

          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/coinpal/make-payment`,
            data: {
              currency: "CHF",
              amount: fixedPriceBuyNow(totalPrice),
              email: user?.person?.email,
              description: `${productInfo?.category} - ${productInfo?.server}`,
              orderNum: orderNum,
            },
          }).then((res) => {
            window.location.href = res?.data?.nextStepContent;
          });
        } else if (paypalPay) {
          // const handlePayment = async () => {
          //   try {
          //     const response = await axios.post(
          //       `${process.env.REACT_APP_CLIENT_URL}/paypal/create-paypal-order`,
          //       {
          //         currency_code: "CHF",
          //         value: fixedPriceBuyNow(totalPrice),
          //       }
          //     );

          //     dispatch(
          //       addNewOrderId({
          //         id: response.data.id,
          //         page: "paymentPage",
          //         productId: response.data.id,
          //       })
          //     );
          //     orderData.orderIdPaid = response.data.id;
          //     axios({
          //       method: "post",
          //       url: `${process.env.REACT_APP_CLIENT_URL}/order`,
          //       data: orderData,
          //     }).then((res) => {
          //       // alert(
          //       //   "Payment methods are not yet available.Go to the chat to discuss and pay your order"
          //       // );
          //       // language === "anglais" && notyFysuccessMessageEnglish();
          //       // language === "espagnol" && notyFysuccessMessageEspagnol();
          //       // language === "francais" && notyFysuccessMessageFrench();
          //       // handleChatClick();
          //     });

          //     const { href } = response.data.links[1];

          //     // Redirection vers la page de paiement PayPal
          //     // window.open(href, "_blank");
          //     // // console.log(response.data);
          //     window.location.href = href;
          //   } catch (error) {
          //     console.error(
          //       "Erreur lors de la création de la commande PayPal :",
          //       error
          //     );
          //   }
          // };

          // handlePayment();

          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          }).then((res) => {
            alert(
              "Payment methods are not yet available.Go to the chat to discuss and pay your order"
            );
            language === "anglais" && notyFysuccessMessageEnglish();
            language === "espagnol" && notyFysuccessMessageEspagnol();
            language === "francais" && notyFysuccessMessageFrench();
            handleChatClick();
          });
        } else if (creditcardPay) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          }).then((res) => {
            alert(
              "Payment methods are not yet available.Go to the chat to discuss and pay your order"
            );
            language === "anglais" && notyFysuccessMessageEnglish();
            language === "espagnol" && notyFysuccessMessageEspagnol();
            language === "francais" && notyFysuccessMessageFrench();
            handleChatClick();
          });
        } else if (paysafecardPay) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          }).then((res) => {
            alert(
              "Payment methods are not yet available.Go to the chat to discuss and pay your order"
            );
            language === "anglais" && notyFysuccessMessageEnglish();
            language === "espagnol" && notyFysuccessMessageEspagnol();
            language === "francais" && notyFysuccessMessageFrench();
            handleChatClick();
          });
        } else if (googlePay) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          }).then((res) => {
            alert(
              "Payment methods are not yet available.Go to the chat to discuss and pay your order"
            );
            language === "anglais" && notyFysuccessMessageEnglish();
            language === "espagnol" && notyFysuccessMessageEspagnol();
            language === "francais" && notyFysuccessMessageFrench();
            handleChatClick();
          });
        } else if (creditcardgooglePay) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          }).then((res) => {
            alert(
              "Payment methods are not yet available.Go to the chat to discuss and pay your order"
            );
            language === "anglais" && notyFysuccessMessageEnglish();
            language === "espagnol" && notyFysuccessMessageEspagnol();
            language === "francais" && notyFysuccessMessageFrench();
            handleChatClick();
          });
        } else if (neosurfPay) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          }).then((res) => {
            alert(
              "Payment methods are not yet available.Go to the chat to discuss and pay your order"
            );
            language === "anglais" && notyFysuccessMessageEnglish();
            language === "espagnol" && notyFysuccessMessageEspagnol();
            language === "francais" && notyFysuccessMessageFrench();
            handleChatClick();
          });
        } else {
          language === "anglais" && notifyPaymentErrorEnglish();
          language === "francais" && notifyPaymentErrorFrench();
          language === "espagnol" && notifyPaymentErrorSpanish();
        }
      } else if (currency === "mad") {
        if (visamastercardPay || visamasterPay || credicardPay) {
          orderData.paymentMethod = "Carte bancaire";
          payments = ["card"];
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/create-checkout-session`,
            data: { line_items: orderToPush, devise: currency, pay: payments },
          })
            .then((res) => {
              window.location = res.data.url;
              // console.log(res.data.url);
              // language === "anglais" && notyFysuccessMessageEnglish();
              // language === "francais" && notyFysuccessMessageFrench();
            })
            .then(() => {
              axios({
                method: "post",
                url: `${process.env.REACT_APP_CLIENT_URL}/order`,
                data: orderData,
              })
                .then((res) => {
                  // console.log(res.data);
                  // language === "anglais" && notyFySuccessOrderEnglish();
                  // language === "francais" && notyFySuccessOrderFrench();
                })
                .catch((error) => console.log(error));
            })
            .catch((error) => console.log(error));
        } else if (cryptoPay) {
          orderData.paymentMethod = "Coinbase";
          getCoinbaseCommerce();
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          });
        } else if (usdtPay) {
          orderData.paymentMethod = "Usdt TRC20";
          window.location = `/paymentusdt/${user?.user}/${Number(
            fixedPriceBuyNow(totalPrice)
          )}`;
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          });
        } else if (paypalPay) {
          // const handlePayment = async () => {
          //   try {
          //     const response = await axios.post(
          //       `${process.env.REACT_APP_CLIENT_URL}/paypal/create-paypal-order`,
          //       {
          //         currency_code: "MAD",
          //         value: fixedPriceBuyNow(totalPrice),
          //       }
          //     );

          //     dispatch(
          //       addNewOrderId({
          //         id: response.data.id,
          //         page: "paymentPage",
          //         productId: response.data.id,
          //       })
          //     );
          //     orderData.orderIdPaid = response.data.id;
          //     axios({
          //       method: "post",
          //       url: `${process.env.REACT_APP_CLIENT_URL}/order`,
          //       data: orderData,
          //     }).then((res) => {
          //       // alert(
          //       //   "Payment methods are not yet available.Go to the chat to discuss and pay your order"
          //       // );
          //       // language === "anglais" && notyFysuccessMessageEnglish();
          //       // language === "espagnol" && notyFysuccessMessageEspagnol();
          //       // language === "francais" && notyFysuccessMessageFrench();
          //       // handleChatClick();
          //     });

          //     const { href } = response.data.links[1];

          //     // Redirection vers la page de paiement PayPal
          //     // window.open(href, "_blank");
          //     // // console.log(response.data);
          //     window.location.href = href;
          //   } catch (error) {
          //     console.error(
          //       "Erreur lors de la création de la commande PayPal :",
          //       error
          //     );
          //   }
          // };

          // handlePayment();

          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          }).then((res) => {
            alert(
              "Payment methods are not yet available.Go to the chat to discuss and pay your order"
            );
            language === "anglais" && notyFysuccessMessageEnglish();
            language === "espagnol" && notyFysuccessMessageEspagnol();
            language === "francais" && notyFysuccessMessageFrench();
            handleChatClick();
          });
        } else if (creditcardPay) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          }).then((res) => {
            alert(
              "Payment methods are not yet available.Go to the chat to discuss and pay your order"
            );
            language === "anglais" && notyFysuccessMessageEnglish();
            language === "espagnol" && notyFysuccessMessageEspagnol();
            language === "francais" && notyFysuccessMessageFrench();
            handleChatClick();
          });
        } else if (paysafecardPay) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          }).then((res) => {
            alert(
              "Payment methods are not yet available.Go to the chat to discuss and pay your order"
            );
            language === "anglais" && notyFysuccessMessageEnglish();
            language === "espagnol" && notyFysuccessMessageEspagnol();
            language === "francais" && notyFysuccessMessageFrench();
            handleChatClick();
          });
        } else if (googlePay) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          }).then((res) => {
            alert(
              "Payment methods are not yet available.Go to the chat to discuss and pay your order"
            );
            language === "anglais" && notyFysuccessMessageEnglish();
            language === "espagnol" && notyFysuccessMessageEspagnol();
            language === "francais" && notyFysuccessMessageFrench();
            handleChatClick();
          });
        } else if (creditcardgooglePay) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          }).then((res) => {
            alert(
              "Payment methods are not yet available.Go to the chat to discuss and pay your order"
            );
            language === "anglais" && notyFysuccessMessageEnglish();
            language === "espagnol" && notyFysuccessMessageEspagnol();
            language === "francais" && notyFysuccessMessageFrench();
            handleChatClick();
          });
        } else if (neosurfPay) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          }).then((res) => {
            alert(
              "Payment methods are not yet available.Go to the chat to discuss and pay your order"
            );
            language === "anglais" && notyFysuccessMessageEnglish();
            language === "espagnol" && notyFysuccessMessageEspagnol();
            language === "francais" && notyFysuccessMessageFrench();
            handleChatClick();
          });
        } else if (binancePay) {
          // window.location = `/paymentbtc/${user?.user}/${Number(
          //   fixedPriceBuyNow(totalPrice)
          // )}`;
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          }).then((res) => {
            language === "anglais" && notyFysuccessMessageEnglish();
            language === "espagnol" && notyFysuccessMessageEspagnol();
            language === "francais" && notyFysuccessMessageFrench();
            handleChatClick();
          });
        } else if (coinpalPay) {
          orderData.paymentMethod = "coinpal";

          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/coinpal/make-payment`,
            data: {
              currency: "MAD",
              amount: fixedPriceBuyNow(totalPrice),
              email: user?.person?.email,
              description: `${productInfo?.category} - ${productInfo?.server}`,
              orderNum: orderNum,
            },
          }).then((res) => {
            window.location.href = res?.data?.nextStepContent;
          });
        } else if (skrillibenPay) {
          // window.location = `/paymentbtc/${user?.user}/${Number(
          //   fixedPriceBuyNow(totalPrice)
          // )}`;
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          }).then((res) => {
            language === "anglais" && notyFysuccessMessageEnglish();
            language === "espagnol" && notyFysuccessMessageEspagnol();
            language === "francais" && notyFysuccessMessageFrench();
            handleChatClick();
          });
        } else if (sepaPay) {
          // window.location = `/paymentbtc/${user?.user}/${Number(
          //   fixedPriceBuyNow(totalPrice)
          // )}`;
          axios({
            method: "post",
            url: `${process.env.REACT_APP_CLIENT_URL}/order`,
            data: orderData,
          }).then((res) => {
            language === "anglais" && notyFysuccessMessageEnglish();
            language === "espagnol" && notyFysuccessMessageEspagnol();
            language === "francais" && notyFysuccessMessageFrench();
            handleChatClick();
          });
        } else {
          language === "anglais" && notifyPaymentErrorEnglish();
          language === "francais" && notifyPaymentErrorFrench();
          language === "espagnol" && notifyPaymentErrorSpanish();
        }
      } else {
        language === "anglais" && notifyPaymentErrorEnglish();
        language === "francais" && notifyPaymentErrorFrench();
        language === "espagnol" && notifyPaymentErrorSpanish();
      }
      // console.log(orderToPush);
      // axios({
      //   method: "post",
      //   url: `${process.env.REACT_APP_CLIENT_URL}/create-checkout-session`,
      //   data: { line_items: orderToPush, devise: currency },
      // })
      //   .then((res) => {
      //     window.location = res.data.url;
      //     // console.log(res.data.url);
      //     // language === "anglais" && notyFysuccessMessageEnglish();
      //     // language === "francais" && notyFysuccessMessageFrench();
      //   })
      //   .catch((error) => console.log(error));

      // console.log(orderData);
      // fetch("http://localhost:5001/api/create-checkout-session", {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify({
      //     items: [
      //       { id: 1, quantity: 3 },
      //       { id: 2, quantity: 1 },
      //     ],
      //   }),
      // })
      //   .then((res) => {
      //     if (res.ok) return res.json();
      //     return res.json().then((json) => Promise.reject(json));
      //   })
      //   .then(({ url }) => {
      //     window.location = url;
      //   })
      //   .catch((e) => {
      //     console.error(e.error);
      //   });
    }
  };

  const handleTogglePaymentMethod = (e) => {
    if (e.target.id === "creditcard_pay") {
      setCreditcardPay(true);
      setUsdtPay(false);
      setPaypalPay(false);
      setVisamasterPay(false);
      setCredicardPay(false);
      setIdealPay(false);
      setCryptoPay(false);
      setNeosurfPay(false);
      setSkrillPay(false);
      setLtcPay(false);
      setBnbPay(false);
      setCihPay(false);
      setBaridPay(false);
      setAttiPay(false);
      setBmcePay(false);
      setCdmPay(false);
      setWafaPay(false);

      setBinancePay(false);
      setSkrillIbenPay(false);
      setSepaPay(false);
      setCoinpalPay(false);
      setPaysafecardPay(false);
      setGooglePay(false);
      setCreditcardgooglePay(false);
    } else if (e.target.id === "usdt") {
      setUsdtPay(true);
      setCreditcardPay(false);
      setPaypalPay(false);
      setVisamasterPay(false);
      setCredicardPay(false);
      setIdealPay(false);
      setCryptoPay(false);
      setNeosurfPay(false);
      setSkrillPay(false);
      setLtcPay(false);
      setBnbPay(false);
      setCihPay(false);
      setBaridPay(false);
      setAttiPay(false);
      setBmcePay(false);
      setCdmPay(false);
      setWafaPay(false);

      setBinancePay(false);
      setSkrillIbenPay(false);
      setSepaPay(false);
      setCoinpalPay(false);
      setPaysafecardPay(false);
      setGooglePay(false);
      setCreditcardgooglePay(false);
    } else if (e.target.id === "ltc") {
      setLtcPay(true);
      setUsdtPay(false);
      setCreditcardPay(false);
      setPaypalPay(false);
      setVisamasterPay(false);
      setCredicardPay(false);
      setIdealPay(false);
      setCryptoPay(false);
      setNeosurfPay(false);
      setSkrillPay(false);
      setBnbPay(false);

      setBinancePay(false);
      setSkrillIbenPay(false);
      setSepaPay(false);
      setCoinpalPay(false);
      setPaysafecardPay(false);
      setGooglePay(false);
      setCreditcardgooglePay(false);
    } else if (e.target.id === "bnb") {
      setBnbPay(true);
      setLtcPay(false);
      setUsdtPay(false);
      setCreditcardPay(false);
      setPaypalPay(false);
      setVisamasterPay(false);
      setCredicardPay(false);
      setIdealPay(false);
      setCryptoPay(false);
      setNeosurfPay(false);
      setSkrillPay(false);

      setBinancePay(false);
      setSkrillIbenPay(false);
      setSepaPay(false);
      setCoinpalPay(false);
      setPaysafecardPay(false);
      setGooglePay(false);
      setCreditcardgooglePay(false);
    } else if (e.target.id === "paypal") {
      setPaypalPay(true);
      setCreditcardPay(false);
      setUsdtPay(false);
      setVisamasterPay(false);
      setCredicardPay(false);
      setIdealPay(false);
      setCryptoPay(false);
      setNeosurfPay(false);
      setSkrillPay(false);
      setLtcPay(false);
      setBnbPay(false);
      setCihPay(false);
      setBaridPay(false);
      setAttiPay(false);
      setBmcePay(false);
      setCdmPay(false);
      setWafaPay(false);

      setBinancePay(false);
      setSkrillIbenPay(false);
      setSepaPay(false);
      setCoinpalPay(false);
      setPaysafecardPay(false);
      setGooglePay(false);
      setCreditcardgooglePay(false);
    } else if (e.target.id === "creditcardgooglepay") {
      setCreditcardgooglePay(true);
      setPaypalPay(false);
      setCreditcardPay(false);
      setUsdtPay(false);
      setVisamasterPay(false);
      setCredicardPay(false);
      setIdealPay(false);
      setCryptoPay(false);
      setNeosurfPay(false);
      setSkrillPay(false);
      setLtcPay(false);
      setBnbPay(false);
      setCihPay(false);
      setBaridPay(false);
      setAttiPay(false);
      setBmcePay(false);
      setCdmPay(false);
      setWafaPay(false);

      setBinancePay(false);
      setSkrillIbenPay(false);
      setSepaPay(false);
      setCoinpalPay(false);
      setPaysafecardPay(false);
      setGooglePay(false);
    } else if (e.target.id === "skrill") {
      setSkrillPay(true);
      setVisamasterPay(false);
      setPaypalPay(false);
      setCreditcardPay(false);
      setUsdtPay(false);
      setCredicardPay(false);
      setIdealPay(false);
      setCryptoPay(false);
      setNeosurfPay(false);
      setLtcPay(false);
      setBnbPay(false);
      setCihPay(false);
      setBaridPay(false);
      setAttiPay(false);
      setBmcePay(false);
      setCdmPay(false);
      setWafaPay(false);

      setBinancePay(false);
      setSkrillIbenPay(false);
      setSepaPay(false);
      setCoinpalPay(false);
      setPaysafecardPay(false);
      setGooglePay(false);
      setCreditcardgooglePay(false);
    } else if (e.target.id === "visa_master") {
      setVisamasterPay(true);
      setPaypalPay(false);
      setCreditcardPay(false);
      setUsdtPay(false);
      setCredicardPay(false);
      setIdealPay(false);
      setCryptoPay(false);
      setNeosurfPay(false);
      setSkrillPay(false);
      setLtcPay(false);
      setBnbPay(false);
      setCihPay(false);
      setBaridPay(false);
      setAttiPay(false);
      setBmcePay(false);
      setCdmPay(false);
      setWafaPay(false);

      setBinancePay(false);
      setSkrillIbenPay(false);
      setSepaPay(false);
      setCoinpalPay(false);
      setPaysafecardPay(false);
      setGooglePay(false);
      setCreditcardgooglePay(false);
    } else if (e.target.id === "creditcard") {
      setCredicardPay(true);
      setPaypalPay(false);
      setCreditcardPay(false);
      setUsdtPay(false);
      setVisamasterPay(false);
      setIdealPay(false);
      setCryptoPay(false);
      setNeosurfPay(false);
      setSkrillPay(false);
      setLtcPay(false);
      setBnbPay(false);
      setCihPay(false);
      setBaridPay(false);
      setAttiPay(false);
      setBmcePay(false);
      setCdmPay(false);
      setWafaPay(false);

      setBinancePay(false);
      setSkrillIbenPay(false);
      setSepaPay(false);
      setCoinpalPay(false);
      setPaysafecardPay(false);
      setGooglePay(false);
      setCreditcardgooglePay(false);
    } else if (e.target.id === "ideal") {
      setIdealPay(true);
      setPaypalPay(false);
      setCreditcardPay(false);
      setUsdtPay(false);
      setVisamasterPay(false);
      setCredicardPay(false);
      setCryptoPay(false);
      setNeosurfPay(false);
      setSkrillPay(false);
      setLtcPay(false);
      setBnbPay(false);

      setBinancePay(false);
      setSkrillIbenPay(false);
      setSepaPay(false);
      setCoinpalPay(false);
      setPaysafecardPay(false);
      setGooglePay(false);
      setCreditcardgooglePay(false);
    } else if (e.target.id === "crypto") {
      setCryptoPay(true);
      setPaypalPay(false);
      setCreditcardPay(false);
      setUsdtPay(false);
      setVisamasterPay(false);
      setCredicardPay(false);
      setIdealPay(false);
      setNeosurfPay(false);
      setSkrillPay(false);
      setLtcPay(false);
      setBnbPay(false);
      setCihPay(false);
      setBaridPay(false);
      setAttiPay(false);
      setBmcePay(false);
      setCdmPay(false);
      setWafaPay(false);

      setBinancePay(false);
      setSkrillIbenPay(false);
      setSepaPay(false);
      setCoinpalPay(false);
      setPaysafecardPay(false);
      setGooglePay(false);
      setCreditcardgooglePay(false);
    } else if (e.target.id === "neosurf") {
      setNeosurfPay(true);
      setPaypalPay(false);
      setCreditcardPay(false);
      setUsdtPay(false);
      setVisamasterPay(false);
      setCredicardPay(false);
      setIdealPay(false);
      setCryptoPay(false);
      setSkrillPay(false);
      setLtcPay(false);
      setBnbPay(false);
      setCihPay(false);
      setBaridPay(false);
      setAttiPay(false);
      setBmcePay(false);
      setCdmPay(false);
      setWafaPay(false);

      setBinancePay(false);
      setSkrillIbenPay(false);
      setSepaPay(false);
      setCoinpalPay(false);
      setPaysafecardPay(false);
      setGooglePay(false);
      setCreditcardgooglePay(false);
    } else if (e.target.id === "coinpal") {
      setCoinpalPay(true);
      setNeosurfPay(false);
      setPaypalPay(false);
      setCreditcardPay(false);
      setUsdtPay(false);
      setVisamasterPay(false);
      setCredicardPay(false);
      setIdealPay(false);
      setCryptoPay(false);
      setSkrillPay(false);
      setLtcPay(false);
      setBnbPay(false);
      setCihPay(false);
      setBaridPay(false);
      setAttiPay(false);
      setBmcePay(false);
      setCdmPay(false);
      setWafaPay(false);

      setBinancePay(false);
      setSkrillIbenPay(false);
      setSepaPay(false);
      setPaysafecardPay(false);
      setGooglePay(false);
      setCreditcardgooglePay(false);
    } else if (e.target.id === "google_pay") {
      setGooglePay(true);
      setCoinpalPay(false);
      setNeosurfPay(false);
      setPaypalPay(false);
      setCreditcardPay(false);
      setUsdtPay(false);
      setVisamasterPay(false);
      setCredicardPay(false);
      setIdealPay(false);
      setCryptoPay(false);
      setSkrillPay(false);
      setLtcPay(false);
      setBnbPay(false);
      setCihPay(false);
      setBaridPay(false);
      setAttiPay(false);
      setBmcePay(false);
      setCdmPay(false);
      setWafaPay(false);

      setBinancePay(false);
      setSkrillIbenPay(false);
      setSepaPay(false);
      setPaysafecardPay(false);
      setCreditcardgooglePay(false);
    } else if (e.target.id === "paysafecard") {
      setPaysafecardPay(true);
      setCoinpalPay(false);
      setNeosurfPay(false);
      setPaypalPay(false);
      setCreditcardPay(false);
      setUsdtPay(false);
      setVisamasterPay(false);
      setCredicardPay(false);
      setIdealPay(false);
      setCryptoPay(false);
      setSkrillPay(false);
      setLtcPay(false);
      setBnbPay(false);
      setCihPay(false);
      setBaridPay(false);
      setAttiPay(false);
      setBmcePay(false);
      setCdmPay(false);
      setWafaPay(false);

      setBinancePay(false);
      setSkrillIbenPay(false);
      setSepaPay(false);
      setGooglePay(false);
      setCreditcardgooglePay(false);
    } else if (e.target.id === "cih") {
      setCihPay(true);
      setBaridPay(false);
      setAttiPay(false);
      setBmcePay(false);
      setCdmPay(false);
      setNeosurfPay(false);
      setPaypalPay(false);
      setCreditcardPay(false);
      setUsdtPay(false);
      setVisamasterPay(false);
      setCredicardPay(false);
      setIdealPay(false);
      setCryptoPay(false);
      setSkrillPay(false);
      setLtcPay(false);
      setBnbPay(false);
      setCashPay(false);
      setWafaPay(false);
      setBinancePay(false);
      setSkrillIbenPay(false);
      setSepaPay(false);
      setCoinpalPay(false);
      setPaysafecardPay(false);
      setGooglePay(false);
      setCreditcardgooglePay(false);
    } else if (e.target.id === "barid") {
      setBaridPay(true);
      setCihPay(false);
      setAttiPay(false);
      setBmcePay(false);
      setCdmPay(false);
      setNeosurfPay(false);
      setPaypalPay(false);
      setCreditcardPay(false);
      setUsdtPay(false);
      setVisamasterPay(false);
      setCredicardPay(false);
      setIdealPay(false);
      setCryptoPay(false);
      setSkrillPay(false);
      setLtcPay(false);
      setBnbPay(false);
      setCashPay(false);
      setWafaPay(false);
      setBinancePay(false);
      setSkrillIbenPay(false);
      setSepaPay(false);
      setCoinpalPay(false);
      setPaysafecardPay(false);
      setGooglePay(false);
      setCreditcardgooglePay(false);
    } else if (e.target.id === "atti") {
      setAttiPay(true);
      setBaridPay(false);
      setCihPay(false);
      setBmcePay(false);
      setCdmPay(false);
      setNeosurfPay(false);
      setPaypalPay(false);
      setCreditcardPay(false);
      setUsdtPay(false);
      setVisamasterPay(false);
      setCredicardPay(false);
      setIdealPay(false);
      setCryptoPay(false);
      setSkrillPay(false);
      setLtcPay(false);
      setBnbPay(false);
      setCashPay(false);
      setWafaPay(false);
      setBinancePay(false);
      setSkrillIbenPay(false);
      setSepaPay(false);
      setCoinpalPay(false);
      setPaysafecardPay(false);
      setGooglePay(false);
      setCreditcardgooglePay(false);
    } else if (e.target.id === "bmce") {
      setBmcePay(true);
      setAttiPay(false);
      setBaridPay(false);
      setCihPay(false);
      setCdmPay(false);
      setNeosurfPay(false);
      setPaypalPay(false);
      setCreditcardPay(false);
      setUsdtPay(false);
      setVisamasterPay(false);
      setCredicardPay(false);
      setIdealPay(false);
      setCryptoPay(false);
      setSkrillPay(false);
      setLtcPay(false);
      setBnbPay(false);
      setCashPay(false);
      setWafaPay(false);
      setBinancePay(false);
      setSkrillIbenPay(false);
      setSepaPay(false);
      setCoinpalPay(false);
      setPaysafecardPay(false);
      setGooglePay(false);
      setCreditcardgooglePay(false);
    } else if (e.target.id === "cdm") {
      setCdmPay(true);
      setBmcePay(false);
      setAttiPay(false);
      setBaridPay(false);
      setCihPay(false);
      setNeosurfPay(false);
      setPaypalPay(false);
      setCreditcardPay(false);
      setUsdtPay(false);
      setVisamasterPay(false);
      setCredicardPay(false);
      setIdealPay(false);
      setCryptoPay(false);
      setSkrillPay(false);
      setLtcPay(false);
      setBnbPay(false);
      setCashPay(false);
      setWafaPay(false);
      setBinancePay(false);
      setSkrillIbenPay(false);
      setSepaPay(false);
      setCoinpalPay(false);
      setPaysafecardPay(false);
      setGooglePay(false);
      setCreditcardgooglePay(false);
    } else if (e.target.id === "wafacash") {
      setWafaPay(true);
      setCashPay(false);
      setCdmPay(false);
      setBmcePay(false);
      setAttiPay(false);
      setBaridPay(false);
      setCihPay(false);
      setNeosurfPay(false);
      setPaypalPay(false);
      setCreditcardPay(false);
      setUsdtPay(false);
      setVisamasterPay(false);
      setCredicardPay(false);
      setIdealPay(false);
      setCryptoPay(false);
      setSkrillPay(false);
      setLtcPay(false);
      setBnbPay(false);
      setBinancePay(false);
      setSkrillIbenPay(false);
      setSepaPay(false);
      setCoinpalPay(false);
      setPaysafecardPay(false);
      setGooglePay(false);
      setCreditcardgooglePay(false);
    } else if (e.target.id === "cashplus") {
      setCashPay(true);
      setWafaPay(false);
      setCdmPay(false);
      setBmcePay(false);
      setAttiPay(false);
      setBaridPay(false);
      setCihPay(false);
      setNeosurfPay(false);
      setPaypalPay(false);
      setCreditcardPay(false);
      setUsdtPay(false);
      setVisamasterPay(false);
      setCredicardPay(false);
      setIdealPay(false);
      setCryptoPay(false);
      setSkrillPay(false);
      setLtcPay(false);
      setBnbPay(false);
      setBinancePay(false);
      setSkrillIbenPay(false);
      setSepaPay(false);
      setCoinpalPay(false);
      setPaysafecardPay(false);
      setGooglePay(false);
      setCreditcardgooglePay(false);
    } else if (e.target.id === "sepa") {
      setSepaPay(true);
      setBinancePay(false);
      setSkrillIbenPay(false);
      setCashPay(false);
      setWafaPay(false);
      setCdmPay(false);
      setBmcePay(false);
      setAttiPay(false);
      setBaridPay(false);
      setCihPay(false);
      setNeosurfPay(false);
      setPaypalPay(false);
      setCreditcardPay(false);
      setUsdtPay(false);
      setVisamasterPay(false);
      setCredicardPay(false);
      setIdealPay(false);
      setCryptoPay(false);
      setSkrillPay(false);
      setLtcPay(false);
      setBnbPay(false);
      setCoinpalPay(false);
      setPaysafecardPay(false);
      setGooglePay(false);
      setCreditcardgooglePay(false);
    } else if (e.target.id === "skrilliben") {
      setSkrillIbenPay(true);
      setSepaPay(false);
      setBinancePay(false);
      setCashPay(false);
      setWafaPay(false);
      setCdmPay(false);
      setBmcePay(false);
      setAttiPay(false);
      setBaridPay(false);
      setCihPay(false);
      setNeosurfPay(false);
      setPaypalPay(false);
      setCreditcardPay(false);
      setUsdtPay(false);
      setVisamasterPay(false);
      setCredicardPay(false);
      setIdealPay(false);
      setCryptoPay(false);
      setSkrillPay(false);
      setLtcPay(false);
      setBnbPay(false);
      setCoinpalPay(false);
      setPaysafecardPay(false);
      setGooglePay(false);
      setCreditcardgooglePay(false);
    } else if (e.target.id === "binance") {
      setBinancePay(true);
      setSkrillIbenPay(false);
      setSepaPay(false);
      setCashPay(false);
      setWafaPay(false);
      setCdmPay(false);
      setBmcePay(false);
      setAttiPay(false);
      setBaridPay(false);
      setCihPay(false);
      setNeosurfPay(false);
      setPaypalPay(false);
      setCreditcardPay(false);
      setUsdtPay(false);
      setVisamasterPay(false);
      setCredicardPay(false);
      setIdealPay(false);
      setCryptoPay(false);
      setSkrillPay(false);
      setLtcPay(false);
      setBnbPay(false);
      setCoinpalPay(false);
      setPaysafecardPay(false);
      setGooglePay(false);
      setCreditcardgooglePay(false);
    } else {
      setNeosurfPay(false);
      setPaypalPay(false);
      setCreditcardPay(false);
      setUsdtPay(false);
      setVisamasterPay(false);
      setCredicardPay(false);
      setIdealPay(false);
      setCryptoPay(false);
      setSkrillPay(false);
      setLtcPay(false);
      setBnbPay(false);

      setBinancePay(false);
      setSkrillIbenPay(false);
      setSepaPay(false);
      setCoinpalPay(false);
      setPaysafecardPay(false);
      setGooglePay(false);
      setCreditcardgooglePay(false);
    }
  };

  const getCoinbaseCommerce = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("X-CC-Api-Key", "1d076333-7bf2-48fe-b1e2-8db2ef5fa3aa");
    let curr = "";
    if (currency === "euro") {
      curr = "eur";
    } else if (currency === "dollar") {
      curr = "usd";
    } else {
      curr = currency;
    }

    // console.log(curr);

    var raw = JSON.stringify({
      name: "Ibendouma",
      description: "Payer avec coinbase",
      pricing_type: "fixed_price",
      local_price: {
        amount: Number(fixedPriceBuyNow(totalPrice)),
        currency: curr,
      },
      metadata: {
        customer_id: user?.person?._id,
        customer_name: user?.person?.email,
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    await fetch("https://api.commerce.coinbase.com/charges", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        window.location = result?.data?.hosted_url;
      })
      .catch((error) => console.log("error", error));
  };

  // function createMarkup() {
  //   return {
  //     __html: (
  //       <script
  //         src="https://pay.glocash.com/public/gateway/js/embed.js"
  //         class="gc-embed-button"
  //         title="PAYER MAINTENANT"
  //         d-emkey="VlcDFQFVCg5QW1EG"
  //         d-merchant="MERCH-77 LTD."
  //         d-goodsname="Acheter vos produits dofus, dofus touch et dofus retro"
  //         d-price="12.78"
  //         d-currency="EUR"
  //         d-cc3ds="1"
  //         d-email="tipox1254@gmail.com"
  //         d-suceess="http://localhost:3000/checkout-success"
  //         d-failed="https://example.com/payNotify/failed"
  //         d-pending="https://example.com/payNotify/pending"
  //         d-notify="https://example.com/payNotify/notify"
  //         // d-cc3ds="https://example.com/payNotify/notify"
  //       ></script>
  //     ),
  //   };
  // }

  // function createMarkup() {
  //   return { __html: "First &middot; Second" };
  // }

  // const handleGlocash = async () => {
  //   await axios
  //     .post(" https://sandbox.glocash.com/gateway/payment/index", {
  //       REQ_EMAIL: "payment@ibendouma.com",
  //       REQ_INVOICE: "ORDER9087HBFFT56",
  //       REQ_SIGN:
  //         "ee8f48e8b619960cd59da28719d25b451eb9b2661455412c1dd2d2f335bd0842",
  //       CUS_EMAIL: "tipox1254@gmail.com",
  //       BIL_PRICE: "37.86",
  //       URL_SUCCESS: "https://ibendouma.com//checkout-success",
  //     })
  //     .then(function (response) {
  //       console.log(response.json());
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  return (
    <div className="payment-page">
      <div className="payment-page-component">
        <div className="product-information">
          {language === "francais" && (
            <h2>
              <span className="text-red-600 font-bold">Ètape 1</span>:
              Récapitulatif de la commande
            </h2>
          )}
          {language === "anglais" && (
            <h2>
              <span className="text-red-600 font-bold">Step 1</span>: Order
              Summary
            </h2>
          )}
          {language === "espagnol" && (
            <h2>
              <span className="text-red-600 font-bold">Paso 1</span>: Resumen
              del pedido
            </h2>
          )}

          <div>
            <span
              style={{
                fontSize: "13px",
              }}
            >
              {" "}
              {language === "francais" && "Categorie"}
              {language === "anglais" && "Category"}
              {language === "espagnol" && "Categoría"}: {productInfo?.category}
            </span>
            <span
              style={{
                fontSize: "13px",
              }}
            >
              {" "}
              {language === "francais" && "Serveur"}
              {language === "anglais" && "Server"}
              {language === "espagnol" && "Servidora"}: {productInfo?.server}
            </span>
            <span
              style={{
                fontSize: "13px",
              }}
            >
              Bonus
              : <span
                style={{
                  fontFamily: "Roboto",
                  fontSize: "12px",
                  fontWeight: 600,
                  color: "#ff0000",
                }}
              >+{productInfo?.bonus}MAD</span>
            </span>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
              }}
            >
              <span
                style={{
                  fontSize: "13px",
                }}
              >
                {" "}
                {language === "francais" && "Prix unitaire"}
                {language === "anglais" && "Unit price"}
                {language === "espagnol" && "Precio unitario"}:
              </span>{" "}
              <span
                style={{
                  fontFamily: "Roboto",
                  fontSize: "12px",
                  fontWeight: 600,
                  color: "#ff0000",
                }}
              >
                {productInfo?.price} {productInfo?.cur === "euro" && "EUR"}
                {productInfo?.cur === "" && "MAD"}
                {productInfo?.cur === "dollar" && "USD"}
                {productInfo?.cur === "mad" && "MAD"}
                {productInfo?.cur === "usdt" && "USDT"}
                {productInfo?.cur === "cad" && "CAD"}
                {productInfo?.cur === "chf" && "CHF"}
                {productInfo?.cur === "rub" && "RUB"}
                {productInfo?.cur === "gbp" && "gbp"}{" "}
              </span>
            </div>
          </div>
        </div>

        <div className="inform-to-delivery">
          {language === "francais" && (
            <h2>
              <span className="text-red-600 font-bold">Ètape 2</span> :
              Information de livraison
            </h2>
          )}
          {language === "anglais" && (
            <h2>
              <span className="text-red-600 font-bold">Step 2</span>: Delivery
              Information
            </h2>
          )}
          {language === "espagnol" && (
            <h2>
              <span className="text-red-600 font-bold">Paso 2</span>:
              Información de entrega
            </h2>
          )}
          <div className="devivery-select-contain">
            <select
              name="qty-choisi"
              id="qty-choisi"
              value={selectedChange}
              onChange={handleChangeValue}
            >
              {/* <option value="1000000">{numberWithCommas(1000)} K</option>
              <option value="2000000">{numberWithCommas(2000)} K</option>
              <option value="3000000">{numberWithCommas(3000)} K</option>
              <option value="4000000">{numberWithCommas(4000)} K</option>
              <option value="5000000">{numberWithCommas(5000)} K</option>
              <option value="6000000">{numberWithCommas(6000)} K</option>
              <option value="7000000">{numberWithCommas(7000)} K</option>
              <option value="8000000">{numberWithCommas(8000)} K</option>
              <option value="9000000">{numberWithCommas(9000)} K</option> */}
              <option value="10000000">{numberWithCommas(10000)} K</option>
              <option value="15000000">{numberWithCommas(15000)} K</option>
              <option value="20000000">{numberWithCommas(20000)} K</option>
              <option value="30000000">{numberWithCommas(30000)} K</option>
              <option value="40000000">{numberWithCommas(40000)} K</option>
              <option value="50000000">{numberWithCommas(50000)} K</option>
              <option value="100000000">{numberWithCommas(100000)} K</option>
              <option value="200000000">{numberWithCommas(200000)} K</option>
              <option value="300000000">{numberWithCommas(300000)} K</option>
              <option value="400000000">{numberWithCommas(400000)} K</option>
              <option value="500000000">{numberWithCommas(500000)} K</option>
            </select>
            <select name="delivery-method" id="delevery-method">
              {language === "francais" && (
                <option value="Kamas Face à Face">Kamas Face à Face</option>
              )}
              {language === "anglais" && (
                <option value="Kamas face to face">Kamas face to face</option>
              )}
              {language === "espagnol" && (
                <option value="kamas cara a cara">Kamas cara a cara</option>
              )}
            </select>
          </div>
        </div>
        <div className="fraud-alert">
          <span>
            {language === "francais" && (
              <span className="fraud">
                <MdOutlineWarning className="-mt-[2px]" /> Alerte a la
                prévention des fraudes: Veuillez lire cette{" "}
                <Link
                  to="/faqs"
                  style={{
                    color: "#E53333",
                    fontSize: "10px",
                    marginTop: "-1px",
                  }}
                >
                  FAQ
                </Link>{" "}
                avant d'acheter !
              </span>
            )}
            {language === "anglais" && (
              <span className="fraud">
                <MdOutlineWarning className="-mt-[2px]" /> Fraud Prevention
                Alert: Please read this FAQ before you buy!
              </span>
            )}
            {language === "espagnol" && (
              <span>
                <MdOutlineWarning /> Alerta de fraude: ¡Por favor, lea este{" "}
                <Link
                  to="/faqs"
                  style={{
                    color: "#E53333",
                    fontSize: "10px",
                    marginTop: "-1px",
                  }}
                >
                  FAQ
                </Link>{" "}
                antes de comprar!
              </span>
            )}
          </span>
        </div>
        <div className="character">
          <input
            type="text"
            name="character"
            className="placeholder:text-[#757575]"
            id="character"
            placeholder={
              language === "francais"
                ? "Nom de personnage"
                : language === "espagnol"
                ? "Nombre del personaje"
                : "Character name"
            }
            value={character1}
            onChange={(e) => setCharacter1(e.target.value)}
          />
          <input
            type="text"
            name="character-confirm"
            id="character-confirm"
            className="placeholder:text-[#757575]"
            placeholder={
              language === "francais"
                ? "Confirmez le nom personnage"
                : language === "espagnol"
                ? "Confirmar nombre de personaje"
                : "Confirm character name"
            }
            value={character2}
            onChange={(e) => setCharacter2(e.target.value)}
          />
        </div>

        <div className="checkout-step3">
          {language === "francais" && (
            <h2>
              <span className="text-red-600 font-bold">Étape 3</span>: Méthode
              de paiement
            </h2>
          )}
          {language === "anglais" && (
            <h2>
              <span className="text-red-600 font-bold">Step 3</span>: Payment
              Method
            </h2>
          )}
          {language === "espagnol" && (
            <h2>
              <span className="text-red-600 font-bold">Paso 3</span>: Método de
              pago
            </h2>
          )}

          <div className="flex flex-col cusxl:flex-row w-full items-start justify-between">
            <div className="paymentpage-checkout-methods">
              <div className="bouto-redi">
                <span>
                  {creditcardPay ? (
                    <MdRadioButtonChecked
                      style={{
                        color: "#129af6",
                        fontSize: "14px"
                      }}
                    />
                  ) : (
                    <MdRadioButtonUnchecked
                      style={{
                        color: "#ffffff",
                        fontSize: "14px"
                      }}
                      name="creditcard_pay"
                      id="creditcard_pay"
                      onClick={handleTogglePaymentMethod}
                    />
                  )}
                </span>

                <img
                  src={creditcard_pay}
                  alt="creditcard_pay"
                  id="creditcard_pay"
                  onClick={handleTogglePaymentMethod}
                  className="creditcard_pay"
                />

                <div className="fee-crypto"></div>
              </div>
              <div className="bouto-redi">
                <span>
                  {googlePay ? (
                    <MdRadioButtonChecked
                      style={{
                        color: "#129af6",
                        fontSize: "14px"
                      }}
                    />
                  ) : (
                    <MdRadioButtonUnchecked
                      style={{
                        color: "#ffffff",
                      }}
                      name="google_pay"
                      id="google_pay"
                      onClick={handleTogglePaymentMethod}
                    />
                  )}
                </span>

                <img
                  src={google_pay}
                  alt="google_pay"
                  id="google_pay"
                  onClick={handleTogglePaymentMethod}
                  className="google_pay"
                />

                <div className="fee-crypto"></div>
              </div>

              <div className="bouto-redi">
                <span>
                  {paypalPay ? (
                    <MdRadioButtonChecked
                      style={{
                        color: "#129af6",
                        fontSize: "14px"
                      }}
                    />
                  ) : (
                    <MdRadioButtonUnchecked
                      style={{
                        color: "#ffffff",
                        fontSize: "14px"
                      }}
                      name="paypal"
                      id="paypal"
                      onClick={handleTogglePaymentMethod}
                    />
                  )}
                </span>

                <img
                  src={paypals}
                  alt="paypal"
                  id="paypal"
                  onClick={handleTogglePaymentMethod}
                  className="paypals"
                />

                <span>3% frais</span>
              </div>

              {/* <div className="bouto-redi">
                <span>
                  {coinpalPay ? (
                    <MdRadioButtonChecked
                      style={{
                        color: "#129af6",
                        fontSize: "14px"
                      }}
                    />
                  ) : (
                    <MdRadioButtonUnchecked
                      style={{
                        color: "#ffffff",
                        fontSize: "14px"
                      }}
                      name="coinpal"
                      id="coinpal"
                      onClick={handleTogglePaymentMethod}
                    />
                  )}
                </span>

                <img
                  src={coinpal}
                  alt="coinpal"
                  id="coinpal"
                  onClick={handleTogglePaymentMethod}
                  className="coinpal"
                />

                
              </div> */}

              {/* <div className="bouto-redi">
                <span>
                  {cryptoPay ? (
                    <MdRadioButtonChecked
                      style={{
                        color: "#129af6",
                        fontSize: "14px"
                      }}
                    />
                  ) : (
                    <MdRadioButtonUnchecked
                      style={{
                        color: "#ffffff",
                        fontSize: "14px"
                      }}
                      name="crypto"
                      id="crypto"
                      onClick={handleTogglePaymentMethod}
                    />
                  )}
                </span>

                <img
                  src={crypto}
                  alt="crypto"
                  id="crypto"
                  onClick={handleTogglePaymentMethod}
                  className="cryptos"
                />

                <div className="fee-crypto"></div>
              </div> */}
{/* 
              <div className="bouto-redi">
                <span>
                  {usdtPay ? (
                    <MdRadioButtonChecked
                      style={{
                        color: "#129af6",
                        fontSize: "14px"
                      }}
                    />
                  ) : (
                    <MdRadioButtonUnchecked
                      style={{
                        color: "#ffffff",
                        fontSize: "14px"
                        
                      }}
                      name="usdt"
                      id="usdt"
                      onClick={handleTogglePaymentMethod}
                    />
                  )}
                </span>

                <img
                  src={usdt}
                  alt="usdt"
                  id="usdt"
                  onClick={handleTogglePaymentMethod}
                  className="usdtpay"
                />

                <span id="usdt" onClick={handleTogglePaymentMethod}>
                  USDT(TRC20)
                </span>
              </div> */}

              <div className="flex items-center gap-1 cihattipop">
                  <span>
                    {cihPay ? (
                      <MdRadioButtonChecked
                        style={{
                          color: "#129af6",
                          fontSize: "14px"
                        }}
                      />
                    ) : (
                      <MdRadioButtonUnchecked
                        name="cih"
                        id="cih"
                        onClick={handleTogglePaymentMethod}
                        style={{
                          color: "#ffffff",
                          fontSize: "14px"
                        }}
                      />
                    )}
                  </span>

                  <img
                    src={marocbank}
                    alt="cih"
                    id="cih"
                    onClick={handleTogglePaymentMethod}
                    className="w-[110px] h-[26px] bg-cover object-center"
                  />
                </div>
            </div>
              {/* Maroc Bank Transfert */}
              {/* <div className="flex flex-col gap-2 items-start">
                <div className="flex items-center gap-1">
                  <span>
                    {cihPay ? (
                      <MdRadioButtonChecked
                        style={{
                          color: "#129af6",
                          fontSize: "14px"
                        }}
                      />
                    ) : (
                      <MdRadioButtonUnchecked
                        name="cih"
                        id="cih"
                        onClick={handleTogglePaymentMethod}
                        style={{
                          color: "#ffffff",
                          fontSize: "14px"
                        }}
                      />
                    )}
                  </span>

                  <img
                    src={cih}
                    alt="cih"
                    id="cih"
                    onClick={handleTogglePaymentMethod}
                    className="w-36 h-10 object-contain object-center"
                  />
                </div>

                <div className="flex items-center gap-1">
                  <span>
                    {baridPay ? (
                      <MdRadioButtonChecked
                        style={{
                          color: "#129af6",
                          fontSize: "14px"
                        }}
                      />
                    ) : (
                      <MdRadioButtonUnchecked
                        name="barid"
                        id="barid"
                        onClick={handleTogglePaymentMethod}
                        style={{
                          color: "#ffffff",
                          fontSize: "14px"
                        }}
                      />
                    )}
                  </span>

                  <img
                    src={barid}
                    alt="barid"
                    id="barid"
                    onClick={handleTogglePaymentMethod}
                    className="w-36 h-10 object-contain object-center"
                  />
                </div>

                <div className="flex items-center gap-1">
                  <span>
                    {attiPay ? (
                      <MdRadioButtonChecked
                        style={{
                          color: "#129af6",
                          fontSize: "14px"
                        }}
                      />
                    ) : (
                      <MdRadioButtonUnchecked
                        name="atti"
                        id="atti"
                        onClick={handleTogglePaymentMethod}
                        style={{
                          color: "#ffffff",
                          fontSize: "14px"
                        }}
                      />
                    )}
                  </span>

                  <img
                    src={atti}
                    alt="atti"
                    id="atti"
                    onClick={handleTogglePaymentMethod}
                    className="w-36 h-10 object-contain object-center"
                  />
                </div>

                <div className="flex items-center gap-1">
                  <span>
                    {bmcePay ? (
                      <MdRadioButtonChecked
                        style={{
                          color: "#129af6",
                          fontSize: "14px"
                        }}
                      />
                    ) : (
                      <MdRadioButtonUnchecked
                        name="bmce"
                        id="bmce"
                        onClick={handleTogglePaymentMethod}
                        style={{
                          color: "#ffffff",
                          fontSize: "14px"
                        }}
                      />
                    )}
                  </span>

                  <img
                    src={wu}
                    alt="bmce"
                    id="bmce"
                    onClick={handleTogglePaymentMethod}
                    className="w-36 h-10 object-contain object-center"
                  />
                </div>

                <div className="flex items-center gap-1">
                  <span>
                    {cdmPay ? (
                      <MdRadioButtonChecked
                        style={{
                          color: "#129af6",
                          fontSize: "14px"
                        }}
                      />
                    ) : (
                      <MdRadioButtonUnchecked
                        name="cdm"
                        id="cdm"
                        onClick={handleTogglePaymentMethod}
                        style={{
                          color: "#ffffff",
                          fontSize: "14px"
                        }}
                      />
                    )}
                  </span>

                  <img
                    src={cdm}
                    alt="cdm"
                    id="cdm"
                    onClick={handleTogglePaymentMethod}
                    className="w-36 h-10 object-contain object-center"
                  />
                </div>

                <div className="flex items-center gap-1">
                  <span>
                    {wafaPay ? (
                      <MdRadioButtonChecked
                        style={{
                          color: "#129af6",
                          fontSize: "14px"
                        }}
                      />
                    ) : (
                      <MdRadioButtonUnchecked
                        name="wafacash"
                        id="wafacash"
                        onClick={handleTogglePaymentMethod}
                        style={{
                          color: "#ffffff",
                          fontSize: "14px"
                        }}
                      />
                    )}
                  </span>

                  <img
                    src={wafacash}
                    alt="wafacash"
                    id="wafacash"
                    onClick={handleTogglePaymentMethod}
                    className="w-36 h-10 object-contain object-center"
                  />
                </div>
              </div> */}
          </div>
        </div>
        <div className="checkout-step4">
          {language === "francais" && <h2>Laisser un commentaire</h2>}
          {language === "anglais" && (
            <h2
              style={{
                color: "#757575",
              }}
            >
              Leave a review
            </h2>
          )}
          {language === "espagnol" && (
            <h2
              style={{
                color: "#757575",
              }}
            >
              Dejar un comentario
            </h2>
          )}
          <div className="comment-container">
            <textarea
              className="text-comment placeholder:text-[#757575]"
              placeholder={
                language === "anglais"
                  ? "Add comments regarding this order"
                  : "Ajouter des commentaires concernant cette commande"
              }
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            ></textarea>
            <button className="text-comment-btn" onClick={handleConfirmComment}>
              {language === "anglais" && "Confirm"}

              {language === "francais" && "Confirmer"}

              {language === "espagnol" && "Confirmar"}
            </button>
          </div>
          {language === "francais" && (
            <div className="flex flex-col items-start text-sm">
              <p className="text-red-600">
                Acheter et vendre des Kamas Dofus
              </p>
              <p>
                Les Kamas vous aident à mieux profiter du jeu, obtenir des Kamas
                en les achetant est le meilleur moyen, avec les Kamas vous
                pouvez notamment acheter des objets comme des ressources ou de
                l'équipement, et voyager à travers le monde des douzes.
              </p>
              <p className="mt-2 text-red-600">
                Pourquoi acheter des Dofus Kamas chez iBendouma?
              </p>
              <p>
                Les Kamas Dofus sur iBendouma sont aux meilleurs prix, vous
                pouvez acheter des Kamas en grande quantité en stock afin de
                bénéficier de grands avantages en jouant à Dofus.
              </p>
              <p>
                Nous pouvons garantir des services très rapides et sûrs 24h/24
                et 7j/7, grâce au réseau important et fiable que nous
                développons et améliorons depuis plus de 10 ans.
              </p>
              <p>
                De plus, avec notre processus de livraison très simple, vous
                êtes assuré de recevoir votre Kamas Dofus rapidement.
              </p>
              <p>
                En témoigne le fait que 95 % des commandes sont généralement
                livrées dans les 10 minutes suivant la passation de la commande.
              </p>
              <p>
                Notre réputation dans le secteur nous permet de bénéficier de la
                confiance de partenaires de paiement fiables, mais également de
                fournisseurs, qui nous mettent donc à disposition un bon stock
                de Kamas pas chers pour tous les serveurs.
              </p>
              <p className="mt-2 text-red-600">
                Comment obtenir des Dofus Kamas?
              </p>
              <p>
                <span className="text-red-600">Étape 1 :</span>{" "}
                Inscrivez-vous ou connectez-vous simplement si vous possédez
                déjà un compte iBendouma.
              </p>
              <p>
                <span className="text-red-600">Étape 2 :</span> Choisissez
                le montant du serveur Dofus et des Kamas que vous souhaitez
                acheter, parcourez la page des offres du vendeur pour voir si
                quelque chose vous convient.
              </p>
              <p>
                <span className="text-red-600">Étape 3 :</span> Cliquez sur
                « Acheter maintenant », remplissez votre e-mail de contact et le
                nom de votre personnage, choisissez un paiement à payer.
              </p>
              <p>
                <span className="text-red-600">Étape 4 :</span>{" "}
                Connectez-vous au Dofus et attendez que le vendeur envoie des
                Dofus Kamas, ou entrez dans la page « Ma commande » pour
                contacter le vendeur, demandez-lui d'effectuer la livraison.
              </p>
              <p>
                <span className="text-red-600">Étape 5 :</span> Après avoir
                reçu les kamas, confirmez la livraison afin que le vendeur
                puisse recevoir l'argent de la commande. N'oubliez pas :
                veuillez ne rendre les Dofus Kamas à personne, et iBendouma ne
                demandera JAMAIS de paiements supplémentaires en raison de
                problèmes de commande après que la commande initiale ait été
                passée.
              </p>
              <p className="font-semibold mt-2 text-red-600">
                Comment recevoir vos Dofus Kamas ?
              </p>
              <p>
                Le mode de livraison « Face à Face » est utilisé par iBendouma
                pour livrer toutes les commandes de Dofus (ce qui signifie que
                vous devez être connecté en même temps que nous pour recevoir
                vos Dofus Kamas).
              </p>
              <p>
                Le processus est simple : après avoir passé votre commande,
                veuillez nous contacter via notre assistance par chat en direct
                24h/24 et 7j/7 ; Si vous connaissez notre point de rendez-vous
                Dofus, vous pouvez vous y rendre et utiliser la fonction « Je
                suis en ligne » sur iBendouma pour nous faire savoir que vous
                attendez que nous procédions à l'échange.
              </p>
              <p>
                Alors qu'est-ce que tu attends? Récupère tes Kamas Dofus dès
                maintenant et dirigez le Monde des Douze !
              </p>
            </div>
          )}
          {language === "anglais" && (
            <div className="flex flex-col items-start text-sm">
              <p className="text-red-600">
                Buy and sell Kamas Dofus
              </p>
              <p>
                Kamas help you to better enjoy the game, obtaining Kamas by
                buying them is the best way, with Kamas you can buy items such
                as resources or equipment, and travel through the world of
                twelve.
              </p>
              <p className="mt-2 text-red-600">
                Why buy Dofus Kamas from iBendouma?
              </p>
              <p>
                Kamas Dofus on iBendouma are at the best prices, you can buy
                Kamas in large quantities in stock in order to benefit from
                great advantages when playing Dofus.
              </p>
              <p>
                We can guarantee very fast and secure services 24/7, thanks to
                the large and reliable network that we have been developing and
                improving for more than 10 years.
              </p>
              <p>
                In addition, with our very simple delivery process, you are
                guaranteed to receive your Kamas Dofus quickly.
              </p>
              <p>
                This is evidenced by the fact that 95% of orders are generally
                delivered within 10 minutes of placing the order.
              </p>
              <p>
                Our reputation in the sector allows us to benefit from the trust
                of reliable payment partners, but also of suppliers, who
                therefore provide us with a good stock of inexpensive Kamas for
                all servers.
              </p>
              <p className="mt-2 text-red-600">
                How to obtain Dofus Kamas?
              </p>
              <p>
                <span className="text-red-600">Step 1 :</span> Sign up or
                log in simply if you already have an iBendouma account.
              </p>
              <p>
                <span className="text-red-600">Step 2 :</span> Choose the
                amount of Dofus Server and Kamas you want to purchase, browse
                the seller's offers page to see if something suits you.
              </p>
              <p>
                <span className="text-red-600">Step 3 :</span> Click “Buy
                Now”, fill in your contact email and character name, choose a
                payment to pay.
              </p>
              <p>
                <span className="text-red-600">Step 4 :</span> Log in to
                Dofus and wait for the seller to send Dofus Kamas, or enter the
                “My order” page to contact the seller, ask them to make the
                delivery.
              </p>
              <p>
                <span className="text-red-600">Step 5 :</span> After
                receiving the kamas, confirm delivery so that the seller can
                receive the money for the order. Remember: Please do not return
                Dofus Kamas to anyone, and iBendouma will NEVER ask you for
                additional payments due to order issues after the initial order
                has been placed.
              </p>
              <p className="mt-2 text-red-600">
                How to receive your Dofus Kamas?
              </p>
              <p>
                The “Face to Face” delivery method is used by iBendouma to
                deliver all orders Dofus (which means you must be connected at
                the same time as us to receive your Dofus Kamas).
              </p>
              <p>
                The process is simple: after past your order, please contact us
                via our support by 24/7 live chat; If you know our point of
                Dofus appointment, you can go there and use the “I’m online”
                function on iBendouma to let us know that you are expecting that
                we make the exchange.
              </p>
              <p>
                So what are you waiting for? Collect your Kamas Dofus as soon as
                now and rule the World of Twelve!
              </p>
            </div>
          )}
          {language === "espagnol" && (
            <div className="flex flex-col items-start text-sm">
              <p className="text-red-600">
                Comprar y vender Kamas Dofus
              </p>
              <p>
                Los Kamas te ayudan a disfrutar mejor del juego, obtener Kamas
                comprándolos es la mejor manera, con Kamas puedes comprar
                artículos como recursos o equipo, y viajar por el mundo de los
                doce.
              </p>
              <p className="mt-2 text-red-600">
                ¿Por qué comprar Dofus Kamas en iBendouma?
              </p>
              <p>
                Los Kamas Dofus en iBendouma están a los mejores precios, puedes
                comprar Kamas en grandes cantidades en stock para beneficiarte
                de grandes ventajas al jugar Dofus.
              </p>
              <p>
                Podemos garantizar servicios muy rápidos y seguros 24 horas al
                día, 7 días a la semana, gracias a la amplia y confiable red que
                hemos estado desarrollando y mejorando durante más de 10 años.
              </p>
              <p>
                Además, con nuestro proceso de entrega muy sencillo, tienes la
                garantía de recibir tus Kamas Dofus rápidamente.
              </p>
              <p>
                Esto se evidencia en el hecho de que el 95% de los pedidos son
                generalmente entregado dentro de los 10 minutos posteriores a la
                realización del pedido.
              </p>
              <p>
                Nuestra reputación en el sector nos permite contar con la
                confianza de socios de pago fiables, pero también de
                proveedores, que nos proporcionan un buen stock de Kamas
                económicos para todos los servidores.
              </p>
              <p className="mt-2 text-red-600">
                How to obtain Dofus Kamas?
              </p>
              <p>
                <span className="text-red-600">Paso 1 :</span> Regístrate o
                inicia sesión simplemente si ya tienes una cuenta en iBendouma.
              </p>
              <p>
                <span className="text-red-600">Paso 2 :</span> Elige la
                cantidad de Dofus Server y Kamas que deseas comprar, navega por
                la página de ofertas del vendedor para ver si algo te conviene.
              </p>
              <p>
                <span className="text-red-600">Paso 3 :</span> Haz clic en
                “Comprar Ahora”, completa tu correo electrónico de contacto y
                nombre del personaje, elige un pago para pagar.
              </p>
              <p>
                <span className="text-red-600">Paso 4 :</span> Inicia sesión
                en Dofus y espera a que el vendedor te envíe los Dofus Kamas, o
                entra en la página "Mi pedido" para contacta al vendedor, pídele
                que haga la entrega.
              </p>
              <p>
                <span className="text-red-600">Paso 5 :</span> Después de
                recibir los kamas, confirma entrega para que el vendedor pueda
                recibir el dinero del pedido. Recuerde: no devuelva Dofus Kamas
                a nadie, e iBendouma NUNCA le pedirá pagos adicionales debido a
                problemas con el pedido después de realizar el pedido inicial.
              </p>
              <p className="mt-2 text-red-600">
                ¿Cómo recibir tus Dofus Kamas?
              </p>
              <p>
                iBendouma utiliza el método de entrega “cara a cara” para
                entregar todos los pedidos de Dofus (lo que significa que debes
                estar conectado al mismo tiempo que nosotros para recibir tus
                Dofus Kamas).
              </p>
              <p>
                El proceso es simple: después de realizar su pedido, contáctenos
                a través de nuestro soporte mediante chat en vivo las 24 horas,
                los 7 días de la semana; Si conoces nuestro punto de cita de
                Dofus, puedes ir allí y utilizar la función "Estoy en línea" en
                iBendouma para informarnos que estás esperando que realicemos el
                intercambio.
              </p>
              <p>
                ¿Entonces, Qué esperas? ¡Recoge tus Kamas Dofus ahora y domina
                el Mundo de los Doce!
              </p>
            </div>
          )}
        </div>
      </div>
      <div
        className="payment-page-checkout"
        style={{
          marginTop: `${screenSize >= 859 ? isScrolled : 0}px`,
        }}
      >
        <div className="page-checkout-cart">
          {language === "francais" && (
            <span className="page-checkout-textname">Total</span>
          )}
          {language === "anglais" && (
            <span className="page-checkout-textname">Total</span>
          )}
          {language === "espagnol" && (
            <span className="page-checkout-textname">Total</span>
          )}

          <span className="checkout-price">
            {fixedPriceBuyNow(totalPrice)}{" "}
            {productInfo?.cur === "euro" && "EUR"}
            {productInfo?.cur === "" && "MAD"}
            {productInfo?.cur === "dollar" && "USD"}
            {productInfo?.cur === "mad" && "MAD"}
            {productInfo?.cur === "usdt" && "USDT"}
            {productInfo?.cur === "cad" && "CAD"}
            {productInfo?.cur === "chf" && "CHF"}
            {productInfo?.cur === "rub" && "RUB"}
            {productInfo?.cur === "gbp" && "GBP"}{" "}
          </span>
        </div>
        <div className="page-checkout-cart">
          {language === "francais" && (
            <span className="page-checkout-textname">Coupon</span>
          )}
          {language === "anglais" && (
            <span className="page-checkout-textname">Coupon</span>
          )}
          {language === "espagnol" && (
            <span className="page-checkout-textname">Cupón</span>
          )}
          <span className="checkout-number">
            0.00 {productInfo?.cur === "euro" && "EUR"}
            {productInfo?.cur === "" && "MAD"}
            {productInfo?.cur === "dollar" && "USD"}
            {productInfo?.cur === "mad" && "MAD"}
            {productInfo?.cur === "usdt" && "USDT"}
            {productInfo?.cur === "cad" && "CAD"}
            {productInfo?.cur === "chf" && "CHF"}
            {productInfo?.cur === "rub" && "RUB"}
            {productInfo?.cur === "gbp" && "GBP"}{" "}
          </span>
        </div>
        <div className="page-checkout-cart">
          {language === "francais" && (
            <span className="page-checkout-textname">
              Commission de transaction
            </span>
          )}
          {language === "anglais" && (
            <span className="page-checkout-textname">Transaction fee</span>
          )}
          {language === "espagnol" && (
            <span className="page-checkout-textname">
              Tarifa de transacción
            </span>
          )}

          <span className="checkout-number">
            + 0 {productInfo?.cur === "euro" && "EUR"}
            {productInfo?.cur === "" && "MAD"}
            {productInfo?.cur === "dollar" && "USD"}
            {productInfo?.cur === "mad" && "MAD"}
            {productInfo?.cur === "usdt" && "USDT"}
            {productInfo?.cur === "cad" && "CAD"}
            {productInfo?.cur === "chf" && "CHF"}
            {productInfo?.cur === "rub" && "RUB"}
            {productInfo?.cur === "gbp" && "GBP"}{" "}
          </span>
        </div>
        <div className="page-checkout-cart">
          {language === "francais" && (
            <span className="page-checkout-textname">
              Commission de payment
            </span>
          )}
          {language === "anglais" && (
            <span className="page-checkout-textname">Payment fee</span>
          )}
          {language === "espagnol" && (
            <span className="page-checkout-textname">Cuota de pago</span>
          )}

          <span className="checkout-number">
            +{" "}
            {usdtPay
              ? `${currency === "mad" ? 11.15 : currencyValue / currencyValue}`
              : "0"}{" "}
            {productInfo?.cur === "euro" && "EUR"}
            {productInfo?.cur === "" && "MAD"}
            {productInfo?.cur === "dollar" && "USD"}
            {productInfo?.cur === "mad" && "MAD"}
            {productInfo?.cur === "usdt" && "USDT"}
            {productInfo?.cur === "cad" && "CAD"}
            {productInfo?.cur === "chf" && "CHF"}
            {productInfo?.cur === "rub" && "RUB"}
            {productInfo?.cur === "gbp" && "GBP"}{" "}
          </span>
        </div>
        <div className="checkout-checkbox-terms">
          <input
            type="checkbox"
            name=""
            id="payCheckPayment"
            style={{
              cursor: "pointer",
            }}
          />

          {language === "francais" && (
            <span>
              J'ai lu et accepté la{" "}
              <Link to="/privacy-policy" className="link-checkout">
                {" "}
                Politique De Confidentialité
              </Link>{" "}
              <Link to="/term-and-conditions" className="link-checkout">
                {" "}
                et les Termes et Conditions
              </Link>{" "}
            </span>
          )}
          {language === "anglais" && (
            <span>
              I have read and accept the{" "}
              <Link to="/privacy-policy" className="link-checkout">
                {" "}
                Privacy policy
              </Link>{" "}
              <Link to="/term-and-conditions" className="link-checkout">
                {" "}
                and the Terms and Conditions
              </Link>{" "}
            </span>
          )}
          {language === "espagnol" && (
            <span>
              He leído y acepto la{" "}
              <Link to="/privacy-policy" className="link-checkout">
                {" "}
                Política de privacidad
              </Link>{" "}
              <Link to="/term-and-conditions" className="link-checkout">
                {" "}
                y los Términos y Condiciones
              </Link>{" "}
            </span>
          )}
        </div>
        <div
          className="button-to-checkout-pay"
          onClick={handleAddOrdersPayment}
        >
          <button className="checkout-btn">
            <span className="checkout-icon">
              <MdLockOutline />
            </span>
            <span className="checkout-pay-mtn">
              {language === "francais" && "Payer"}
              {language === "anglais" && "Pay"}
              {language === "espagnol" && "Pagar"}{" "}
            </span>
          </button>
        </div>

        <div className="checkout-security">
          <div>
            <a href="https://hubsecurity.com/" target="__blank">
              <img src={image1} alt="dss security" />
            </a>
            <a href="https://fr.trustpilot.com/" target="__blank">
              <img src={image2} alt="Trustpilot" />
            </a>
            <a href="https://us.norton.com/" target="__blank">
              <img src={image3} alt="shopping guarantee" />
            </a>
          </div>

          {language === "francais" && (
            <p>
              Nous utilisons la dernière technologie PCI DSS pour chiffrer
              toutes vos données personnelles. Ceci permet une transmission
              sécurisée et empêche l'accès à vos données par des tiers.
            </p>
          )}
          {language === "anglais" && (
            <p>
              We use the latest PCI DSS technology to encrypt all your personal
              data. This allows secure transmission and prevents access to your
              data by third parties.
            </p>
          )}
          {language === "espagnol" && (
            <p>
              Utilizamos la última tecnología PCI DSS para cifrar todos tus
              datos personales Esto permite una transmisión segura y impide el
              acceso a sus datos por parte de terceros.
            </p>
          )}
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default PaymentPage;
