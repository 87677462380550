import React from "react";
import { IoShieldCheckmarkOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

const AccountDofusProduct = ({ product, title, name }) => {
  const handleAddDots = (chaine) => {
    if (typeof chaine === "string" && chaine !== undefined && chaine !== null) {
      if (chaine.length > 80) {
        return chaine.substring(0, 80) + "...";
      } else {
        return chaine;
      }
    }
  };

  const checkIfPriceIsAvailable = (chaine) => {
    let price;
    const regex = /[-+]?[0-9]*\.?[0-9]+/g;
    if (regex.test(chaine)) {
      price = chaine.match(regex)[0];
    } else {
      price = Math.floor(Math.random() * 100) + 1;
    }
    return Number(price);
  };

  // {`/:title/:name/:idgame`}

  // console.log(product);
  // console.log(title, name);

  return (
    <Link
      to={`/${title}/${name}/${product.id}`}
      className="flex flex-col items-start gap-1 p-2 w-full cursor-pointer transition duration-300 ease-in-out"
    >
      <img
        src={product?.imageUrl}
        alt={product.title}
        className="w-full h-[220px] bg-cover bg-center rounded"
      />
      <div className="flex ml-[2px]">
        <p
          className="font-medium text-sm w-full overflow-hidden overflow-ellipsis"
          style={{ maxHeight: "2.7em" }}
        >
          {handleAddDots(product?.title?.toLowerCase())}
        </p>
      </div>
      <p className="flex items-center text-sm gap-1 ml-[2px]">
        A partir de
        <span className="text-red-600 font-semibold ">
          {checkIfPriceIsAvailable(product.price)} {product?.currency}
        </span>
      </p>
      {product.warranty && (
        <div className="flex items-center gap-1 p-1 bg-green-100 rounded-[10px]">
          <IoShieldCheckmarkOutline className="text-green-400 mt-[2px]" />
          <span className="text-xs text-green-700">{product.warranty}</span>
        </div>
      )}
    </Link>
  );
};

export default AccountDofusProduct;
