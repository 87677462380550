import React, { useState } from "react";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import FileBase64 from "react-file-base64";

import { categories } from "./data/categoriesShop";

const AddProduct = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [category, setCategory] = useState("");
  const [flashSale, setFlashSale] = useState(false);
  const [price, setPrice] = useState(0);
  const [discountPrice, setDiscountPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [inStock, setInStock] = useState(0);
  const [stars, setStars] = useState(5);
  const [reduce, setReduce] = useState(0);
  const [tag, setTag] = useState([]);
  const [slug, setSlug] = useState("");
  const [type, setType] = useState("");
  const [selectedTag, setSelectedTag] = useState("");

  const handleTagSelect = (e) => {
    const { value } = e.target;
    setSelectedTag(value);
  };

  const handleAddTag = () => {
    if (selectedTag && !tag.includes(selectedTag)) {
      setTag([...tag, selectedTag]);
      setSelectedTag("");
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    const updatedTags = tag.filter((tag) => tag !== tagToRemove);
    setTag(updatedTags);
  };

  const handleCreateProduct = () => {
    if (!title || !description || !image || !price || !slug || !type) {
      toast.error("Veuillez remplir tous les champs");
    } else {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_CLIENT_URL}/products`,
        data: {
          title,
          description,
          image,
          price,
          slug,
          type,
          tag,
          discountPrice,
          discount,
          flashSale,
          category,
          quantity,
          inStock,
          stars,
          reduce,
        },
      })
        .then((res) => {
          toast.success("Produit créé avec succès");
          setImage("");
          setTitle("");
          setDescription("");
          setFlashSale(false);
          setPrice(0);
          setDiscountPrice(0);
          setDiscount(0);
          setTag([]);
          setSlug("");
          setType("");
          setSelectedTag("");
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div className="flex flex-col">
      <h1 className="text-2xl font-semibold mb-4">Ajouter un produit</h1>
      <div className="w-full mx-auto p-4 md:flex">
        <div className="md:w-1/2 md:pr-4">
          <div className="mb-4">
            <label className="block mb-1 font-medium">Titre</label>
            <input
              type="text"
              name="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full px-4 py-2 border rounded-md outline-none border-red-200"
              required
              placeholder="Titre du produit"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-1 font-medium">Description</label>
            <textarea
              name="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              rows="4"
              placeholder="Description du produit"
              className="w-full px-4 py-2 border rounded-md border-red-200 outline-none "
            />
          </div>
          <div className="mb-4">
            <label className="block mb-1 font-medium">Image</label>
            <FileBase64
              type="file"
              multiple={false}
              onDone={({ base64 }) => setImage(base64)}
              className="w-full px-4 py-2 border rounded-md border-red-200 outline-none "
              required
            />
          </div>
          <div className="mb-4">
            <label className="block mb-1 font-medium">Prix</label>
            <input
              type="number"
              name="price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              className="w-full px-4 py-2 border rounded-md border-red-200 outline-none "
              required
            />
          </div>
          <div className="mb-4">
            <label className="block mb-1 font-medium">Catégorie</label>
            <select
              name="category"
              value={type}
              onChange={(e) => setType(e.target.value)}
              className="w-full px-4 py-2 border rounded-md border-red-200 outline-none "
              required
            >
              <option value="">Choisir une catégorie</option>
              {categories?.map((category, index) => (
                <option key={index} value={category}>
                  {category}
                </option>
              ))}
            </select>
          </div>
          {/* ... Ajoutez les autres champs ici ... */}
        </div>
        <div className="md:w-1/2 md:pl-4">
          <div className="mb-4">
            <label className="block mb-1 font-medium">Slug</label>
            <input
              type="text"
              name="slug"
              value={slug}
              onChange={(e) => setSlug(e.target.value)}
              placeholder="Slug du produit"
              className="w-full px-4 py-2 border rounded-md border-red-200 outline-none "
            />
          </div>
          <div className="mb-4">
            <label className="block mb-1 font-medium">Tags</label>
            <div className="flex flex-wrap mb-2">
              {tag.map((tag) => (
                <span
                  key={tag}
                  className="bg-red-500 text-white px-2 py-1 mr-2 my-1 rounded-md cursor-pointer"
                  onClick={() => handleRemoveTag(tag)}
                >
                  {tag}
                </span>
              ))}
            </div>
            <input
              type="text"
              name="tag"
              value={selectedTag}
              onChange={handleTagSelect}
              placeholder="Tag du produit"
              className="w-full px-4 py-2 border rounded-md border-red-200 outline-none "
            />
            <button
              type="button"
              onClick={handleAddTag}
              className="mt-2 bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
            >
              Ajouter le tag
            </button>
          </div>
          <div className="mb-4">
            <label className="block mb-1 font-medium">Discount price</label>
            <input
              type="number"
              name="discountPrice"
              value={discountPrice}
              onChange={(e) => setDiscountPrice(e.target.value)}
              className="w-full px-4 py-2 border rounded-md border-red-200 outline-none "
            />
          </div>
          <div className="mb-4">
            <label className="block mb-1 font-medium">Discount</label>
            <input
              type="number"
              name="discount"
              value={discount}
              onChange={(e) => setDiscount(e.target.value)}
              className="w-full px-4 py-2 border rounded-md border-red-200 outline-none "
            />
          </div>
          <div className="mb-4">
            <label className="block mb-1 font-medium">Flash Sale</label>
            <input
              type="checkbox"
              name="flashSale"
              checked={flashSale}
              onChange={(e) => setFlashSale(e.target.checked)}
              className="mr-2"
            />
            <span className="text-sm">En solde</span>
          </div>
          <button
            onClick={handleCreateProduct}
            className="w-full bg-red-500 text-white py-2 px-4 rounded-md hover:bg-red-600"
          >
            Ajouter le produit
          </button>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default AddProduct;
