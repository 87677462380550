import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { igvproducts } from "../utils/igvoriginaldata";
import { useSelector } from "react-redux";

const MobileSearch = ({ searchTerm }) => {
  const { language } = useSelector((state) => state.language);
  const [allProducts, setAllProducts] = useState([]);
  useEffect(() => {
    const productGetted = igvproducts.map((product) => {
      return product?.details?.filter((product) => {
        return product?.title.toLowerCase().includes(searchTerm.toLowerCase());
      });
    });
    const isProductLengthIsNotEmpty = productGetted?.filter(
      (product) => product.length > 0
    );
    setAllProducts(isProductLengthIsNotEmpty);
  }, [searchTerm]);

  return (
    searchTerm && (
      <div className="absolute flex flex-col items-start p-4 gap-4 bg-white z-[90] w-full h-screen overflow-y-scroll text-gray-500 top-[82%]">
        <div className="flex flex-col items-start w-full">
          <div className="flex items-center w-full gap-2">
            <span className="font-normal text-black">
              {language === "anglais" && "Related games"}
              {language === "francais" && "Jeux liés"}
              {language === "espagnol" && "Juegos relacionados"}
            </span>
            "
            <span className="text-red-600 antialiased font-normal capitalize">
              {searchTerm}
            </span>
            "
          </div>
        </div>
        <div className="flex flex-col items-start w-full">
          {allProducts &&
            allProducts.map((product, index) => (
              <div key={index} className="flex flex-col items-start -mt-4">
                {product?.map((prod) => (
                  <div
                    key={prod?.id}
                    className="flex flex-col items-start gap-1 mt-4"
                  >
                    <span className="font-normal antialiased text-black">
                      {prod?.title}
                    </span>
                    <div className="flex items-center gap-2">
                      {prod?.items?.map((produ) => (
                        <Link
                          to={produ?.link?.split("fr")[1]}
                          className="flex items-center justify-center p-1 text-sm rounded bg-gray-200 border border-gray-300 text-gray-600 mb-2"
                        >
                          {produ?.title}
                        </Link>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            ))}
        </div>
        <div></div>
        <div></div>
      </div>
    )
  );
};

export default MobileSearch;
