import React from "react";
import { useSelector } from "react-redux";

const ProductDescription = ({ item, serverDesc }) => {
  // console.log(item);
  const { language } = useSelector((state) => state.language);
  return language === "anglais" ? (
    <div className="productdescription mt-2">
      <p className="productdescription-desc">
        <div>
          <p>
            Why buy Kamas <span>{serverDesc}</span> on ibendouma?{" "}
          </p>
          <span>
            <span className="text-red-600 font-bold">Speed ✨</span>
          </span>
          <p>
            Since the creation of the ibendouma site, speed delivery has been
            our main objective. More than 99% of our orders are delivered
            between 3 and 15 minutes.
          </p>{" "}
          <span>
            <span className="text-red-600 font-bold">accessibility and reliability ✨</span>
          </span>
          <p>
            On ibendouma, you can buy Kamas <span>{serverDesc}</span> at low
            prices in order to benefit from many advantages and improve your
            gaming experience. We have stocks of kamas on all Dofus servers and
            we work with reliable suppliers to ensure the correct delivery
            process. We guarantee EXCELLENT quality service, 24/7, fast and
            safe. Save time and fully enjoy your game with ibendouma. Best price
            on the market If you find better on another online store, contact us
            and we will do our best to beat it.
          </p>
          <span>
            <span className="text-red-600 font-bold">Fast process ✨</span>
          </span>
          <p>
            How to receive your Kamas on <span>{serverDesc}</span>? After
            placing the order, our agent will contact you to give you the
            payment information and inform you of the meeting point (in-game
            map) to deliver your Kama. Make sure you can receive private
            messages in-game, then go to the meeting point. 99% of orders are
            delivered within 3-15 minutes.
          </p>
          <p>
            NB : Please check your emails or contact our customer service in
            livechat if you do not receive the kamas within the next 15 minutes.
          </p>
        </div>
      </p>
    </div>
  ) : language === "espagnol" ? (
    <div className="productdescription mt-2">
      <p className="productdescription-desc">
        <div>
          <p>
            Por qué comprar Kamas <span>{serverDesc}</span> en ibendouma?{" "}
          </p>
          <span>
            <span className="text-red-600 font-bold">Velocidad ✨</span>
          </span>
          <p>
            Desde la creación del sitio ibendouma, la entrega rápida ha sido
            nuestro principal objetivo. Más del 99% de nuestros pedidos se
            entregan entre 3 y 15 minutos.
          </p>{" "}
          <span>
            <span className="text-red-600 font-bold">Accesibilidad y fiabilidad✨</span>
          </span>
          <p>
            En ibendouma, puede comprar Kamas <span>{serverDesc}</span> a
            precios bajos con el fin de beneficiarse de muchas ventajas y
            mejorar su experiencia de juego. Tenemos existencias de kamas en
            todos los servidores de Dofus y trabajamos con proveedores fiables
            para garantizar el proceso de entrega correcto. Garantizamos un
            servicio de calidad EXCELENTE, 24/7, rápido y seguro. Ahorre tiempo
            y disfrute plenamente de su juego con ibendouma. Mejor precio en el
            mercado Si encuentra mejor en otra tienda en línea, contáctenos y
            haremos todo lo posible para vencerlo.
          </p>
          <span>
            <span className="text-red-600 font-bold">Proceso rápido ✨</span>
          </span>
          <p>
            Cómo recibir tus Kamas en <span>{serverDesc}</span>? Después de
            realizar el pedido, nuestro agente se pondrá en contacto contigo
            para darte la información de pago e informarle del punto de
            encuentro (en el juego mapa) para entregar su Kama. Asegúrate de que
            puedes recibir mensajes privados en el juego, luego ve al punto de
            encuentro. El 99% de los pedidos se entregan dentro de 3-15 minutos.
          </p>
          <p>
            NB : Por favor revise sus correos electrónicos o póngase en contacto
            con nuestro servicio al cliente en livechat si no recibe los kamas
            dentro del próximos 15 minutos
          </p>
        </div>
      </p>
    </div>
  ) : (
    <div className="productdescription mt-2">
      <div className="productdescription-desc">
        <div>
          <p>
            Pourquoi acheter des Kamas <span>{serverDesc}</span> sur ibendouma?{" "}
          </p>

          <span>
            <span className="text-red-600 font-bold">Rapidité ✨</span>
          </span>

          <p>
            Depuis la création du site ibendouma , la rapidité de livraison est
            notre principal objectif. Plus de 99% de nos commandes sont livrées
            entre 3 et 15 minutes.
          </p>

          <span>
            <span className="text-red-600 font-bold">
              Accessibilité et fiabilité ✨
            </span>
          </span>

          <p>
            Chez ibendouma, vous pouvez acheter des Kamas sur{" "}
            <span className="text-red-600 font-bold">{serverDesc}</span> à bas
            prix afin de bénéficier de nombreux avantages et d’améliorer votre
            expérience de jeu. Nous disposons de stocks de kamas sur tous les
            serveurs de Dofus et nous travaillons avec des fournisseurs fiables
            pour assurer le bon déroulement de la livraison. Nous vous
            garantissons un service d'EXCELLENTE qualité,24/7, rapide et sûr.
            Gagnez du temps et profitez pleinement de votre jeu avec
            ibendouma.Meilleur prix sur le marché. Si vous trouvez mieux sur un
            autre magasin en ligne, contactez nous et nous ferons bien notre
            possible pour le battre.
          </p>

          <span>
            <span className="text-red-600 font-bold">Processus rapide ✨</span>
          </span>

          <p>
            Comment recevoir vos Kamas sur <span>{serverDesc}</span>? Après
            avoir passé la commande, notre agent vous contactera pour vous
            donner les informations de paiement et vous informera du point de
            rencontre (coordonnées de la map en jeu) pour vous livrer vos Kamas.
            Assurez-vous de pouvoir recevoir les messages privés en jeu, puis
            rendez vous aux coordonnées indiquées. 99% des commandes sont
            livrées dans un délai de 3-15 minutes.
          </p>

          <p>
            NB : Veuillez vérifier vos e-mails ou contactez notre service client
            en livechat si vous ne recevez pas les kamas dans les 15 prochaines
            minutes.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProductDescription;
