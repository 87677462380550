import React, { useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { removeUser } from "../features/userSlice";
import logo from "../assets/iben-logo.jpg";
import { Link } from "react-router-dom";
import { GrLanguage } from "react-icons/gr";
import { FaRegUserCircle } from "react-icons/fa";
import defaultUser from "../assets/user.png";

import {
  CiLogout,
  CiShoppingCart,
  CiUndo,
  CiUnlock,
  CiUser,
} from "react-icons/ci";
import { HiChevronUp } from "react-icons/hi";

const MobileNavbar = ({ toggleArrowProfile, handleToggleArrow, click }) => {
  const dispatch = useDispatch();
  let { language } = useSelector((state) => state.language);
  let { user } = useSelector((state) => state.user);
  let quantity = useSelector((state) => state.cart.quantity);
  let totalPrice = useSelector((state) => state.cart.totalPrice);

  const [toggleDisplayCart, setToggleDisplayCart] = useState(false);

  const { currency } = useSelector((state) => state.currency);

  const handleLogout = () => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_CLIENT_URL}/users/logout`,
      withCredentials: true,
    })
      .then(() => {
        dispatch(removeUser());
        window.location.href = "/";
      })
      .catch((error) => console.log(error));
  };

  const handleCartEnter = () => {
    setToggleDisplayCart(true);
  };

  const handleCartLeave = () => {
    setToggleDisplayCart(false);
  };

  const handleMoveToCart = () => {
    if (user?.person?._id) {
      window.location.href = "/cart";
    } else {
      window.location.href = "/login";
    }
  };

  return (
    <div className="flex xcustom:hidden items-center justify-between bg-black h-[50px] w-screen px-2">
      <Link to="/" className="flex items-center gap-1">
        <img
          src={logo}
          alt="ibendouma logo"
          className="w-10 h-10object-cover rounded-full"
        />
        <div className="flex flex-col items-start justify-between">
          <p className="text-sm font-extrabold text-[#C9C9C9]">iBENDOUMA</p>
          <p className="text-xs font-bold text-[#959595]">GAME FOR GAMER</p>
        </div>
      </Link>
      <div className="flex items-center gap-4">
        <div className="flex flex-col items-center gap-1 cursor-pointer">
          <span
            onMouseEnter={handleCartEnter}
            onMouseLeave={handleCartLeave}
            className="text-white relative"
            onClick={handleMoveToCart}
          >
            <CiShoppingCart className="h-8 w-8" />
            {user?.person?._id && quantity > 0 && (
              <span className="absolute flex items-center justify-center h-4 w-4 rounded-full bg-red-600 text-white text-[10px] -top-[10%] left-[55%]">
                {quantity}
              </span>
            )}
            {toggleDisplayCart && user?.person?._id && (
              <div
                className="absolute flex flex-col gap-4 rounded bg-white shadow-2xl p-4 w-[300px] top-[120%] -left-[820%] z-[1000]"
                onMouseEnter={handleCartEnter}
                onMouseLeave={handleCartLeave}
              >
                <HiChevronUp className="absolute -top-[13%] left-[88%] text-white z-[100] text-[32px]" />
                <div className="flex items-center justify-between mx-4">
                  <div className="flex items-center">
                    <span className="font-normal text-sm text-black">
                      Total
                    </span>
                    <span className="font-normal text-sm text-black">
                      ({quantity}x)
                    </span>
                  </div>
                  <div className="flex items-center gap-2">
                    <span className="font-bold text-sm text-red-600">
                      {totalPrice}
                    </span>
                    <span className="font-bold text-sm text-red-600">
                      {currency === "euro"
                        ? "EUR"
                        : currency === "dollar"
                        ? "USD"
                        : currency === "mad"
                        ? "MAD"
                        : currency === "cad"
                        ? "CAD"
                        : currency === "chf"
                        ? "CHF"
                        : currency === "gbp"
                        ? "GBP"
                        : currency === "rub"
                        ? "RUB"
                        : "EUR"}
                    </span>
                  </div>
                </div>

                <div className="flex mx-4">
                  <button className="text-sm text-white rounded bg-red-600 p-2 w-full mx-4 outline-none">
                    <Link to="/cart">
                      {language === "anglais" && "View cart"}
                      {language === "francais" && "Voir le panier"}
                      {language === "espagnol" && "Ver carrito"}
                    </Link>
                  </button>
                </div>
              </div>
            )}
          </span>
        </div>
        <div
          className="relative flex flex-col items-center cursor-pointer"
          onClick={handleToggleArrow("non")}
        >
          {user?.person?._id ? (
            <div className="flex items-center">
              <img
                className="w-[28px] h-[28px] rounded-full bg-cover bg-center"
                src={user?.person?.profil ? user?.person?.profil : defaultUser}
                alt="user"
              />
            </div>
          ) : (
            ""
          )}

          {user?.person?._id ? (
            toggleArrowProfile && (
              <div className="absolute top-[130%] -left-[590%] flex flex-col gap-2 p-2 items-start rounded bg-white w-[210px] shadow-lg z-[1000]">
                <div className="flex items-center space-x-2 text-sm p-1 sm:p-2 transition duration-500 ease-in-out hover:bg-red-600 hover:text-white rounded w-full item1">
                  <span className="text-[21px]">
                    <CiUser />
                  </span>
                  <Link to="/personal-infos">
                    {language === "anglais" && "My profile"}
                    {language === "francais" && "Mon profil"}
                    {language === "espagnol" && "Mi perfil"}
                  </Link>
                </div>
                <div className="flex items-center space-x-2 text-sm p-2 transition duration-500 ease-in-out hover:bg-red-600 hover:text-white rounded w-full item2">
                  <span className="text-[21px]">
                    <CiShoppingCart />
                  </span>
                  <Link to="/orders">
                    {language === "anglais" && "My orders"}
                    {language === "francais" && "Mes commandes"}
                    {language === "espagnol" && "Mis pedidos"}
                  </Link>
                </div>
                <div className="flex items-center space-x-2 text-sm p-2 transition duration-500 ease-in-out hover:bg-red-600 hover:text-white rounded w-full item3">
                  <span className="text-[21px]">
                    <CiUndo />
                  </span>
                  <Link to="/update-profil">
                    {language === "anglais" && "Update my profile"}
                    {language === "francais" && "Mettre à jour mon profil"}
                    {language === "espagnol" && "Actualizar mi perfil"}
                  </Link>
                </div>
                <div className="flex items-center space-x-2 text-sm p-2 transition duration-500 ease-in-out hover:bg-red-600 hover:text-white rounded w-full item4">
                  <span className="text-[21px]">
                    <CiUnlock />
                  </span>
                  <Link to="/password">
                    {language === "anglais" && "Change password"}
                    {language === "francais" && "Modifier mot de passe"}
                    {language === "espagnol" && "Cambiar contraseña"}
                  </Link>
                </div>
                <div
                  className="flex items-center space-x-2 text-sm p-2 transition duration-500 ease-in-out hover:bg-red-600 hover:text-white rounded w-full item4"
                  onClick={handleLogout}
                >
                  <span className="text-[21px]">
                    <CiLogout />
                  </span>
                  <span>
                    {language === "francais" && "Se déconnecter"}
                    {language === "anglais" && "Log out"}
                    {language === "espagnol" && "desconectar"}
                  </span>
                </div>
              </div>
            )
          ) : (
            <div className="text-white">
              <Link to="/login" className="text-white text-[25px]">
                <FaRegUserCircle />
              </Link>
            </div>
          )}
        </div>

        <span className="text-white text-[24px]" onClick={click}>
          <GrLanguage />
        </span>
      </div>
    </div>
  );
};

export default MobileNavbar;
