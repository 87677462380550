import React, { useState } from "react";

import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import SideBar from "./SideBar";
import { useSelector } from "react-redux";

function Password() {
  const { user } = useSelector((state) => state?.user);
  const { language } = useSelector((state) => state.language);
  const [oldpass, setOldpass] = useState("");
  const [newpass, setNewpass] = useState("");
  const [confirmNewpass, setConfirmNewpass] = useState("");

  // userId

  const handleUpdatePassword = () => {
    if (oldpass.length < 0) {
      toast.error("Veuillez indiquer l'ancien mot de passe");
    } else if (newpass && newpass !== confirmNewpass) {
      toast.error("Les mots de passe ne correspondent pas");
    } else {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_CLIENT_URL}/users/password/new`,
        data: {
          oldpass,
          newpass,
          userId: user?._id,
        },
      })
        .then((res) => {
          toast.success("Votre mot de passe a été modifié");
        })
        .catch(({ response }) => toast.error(response?.data?.message));
    }
  };

  return (
    <SideBar>
      <div className="flex flex-col gap-4">
        <h2 className="text-xl font-bold">
          {language === "francais" && "Modifier Votre Mot de Passe"}
          {language === "anglais" && "Change your password"}
          {language === "espagnol" && "Cambiar su contraseña"}
        </h2>
        <div className="text-sm">
          <label className="font-semibold text-gray-400">
            {language === "francais" && "Ancien mot de passe"}
            {language === "anglais" && "Old password"}
            {language === "espagnol" && "Contraseña anterior"}
          </label>
          <input
            required
            type="password"
            placeholder="*******"
            value={oldpass}
            onChange={(e) => setOldpass(e.target.value)}
            className="w-full text-sm mt-2 p-4 border border-gray-200 rounded text-gray-500 bg-white"
          />
        </div>

        <div className="text-sm">
          <label className="font-semibold text-gray-400">
            {language === "francais" && "Nouveau mot de passe"}
            {language === "anglais" && "Nueva contraseña"}
            {language === "espagnol" && "New password"}
          </label>
          <input
            required
            type="password"
            placeholder="*******"
            value={newpass}
            onChange={(e) => setNewpass(e.target.value)}
            className="w-full text-sm mt-2 p-4 border border-gray-200 rounded text-gray-500 bg-white"
          />
        </div>
        <div className="text-sm">
          <label className="font-semibold text-gray-400">
            {language === "francais" && "Confirmer le mot de passe"}
            {language === "anglais" && "Confirm password"}
            {language === "espagnol" && "Confirmar contraseña"}
          </label>
          <input
            required
            type="password"
            placeholder="*******"
            value={confirmNewpass}
            onChange={(e) => setConfirmNewpass(e.target.value)}
            className="w-full text-sm mt-2 p-4 border border-gray-200 rounded text-gray-500 bg-white"
          />
        </div>
        <div className="flex justify-end items-center my-4">
          <button
            className="bg-main sm:w-auto w-full font-medium text-white py-3 px-6 rounded"
            onClick={handleUpdatePassword}
          >
            {language === "francais" && "Modifier le mot de passe"}
            {language === "anglais" && "Change password"}
            {language === "espagnol" && "Cambiar la contraseña"}
          </button>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </SideBar>
  );
}

export default Password;
