import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import axios from "axios";

import { useDispatch, useSelector } from "react-redux";

import { igvproducts, customMenuData } from "../utils/igvoriginaldata";

// import cookie from "js-cookie";

import { addNewLanguage } from "../features/languageSliceSelected";

import { useLocation } from "react-router-dom";

import { addNewCurrencyExchange } from "../features/currencyExchange";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import callofduty from "../assets/hero/callofduty.jpg";

import { removeUser } from "../features/userSlice";

import { HiChevronUp, HiOutlineChevronDown } from "react-icons/hi";

import { CgMenuGridO } from "react-icons/cg";
import { TfiMenuAlt, TfiHelpAlt } from "react-icons/tfi";
import { FiSearch, FiMenu } from "react-icons/fi";
import { FaChevronDown } from "react-icons/fa";

import defaultUser from "../assets/user.png";

import "./style.css";

import {
  CiUser,
  CiShoppingCart,
  CiShop,
  CiUnlock,
  CiUndo,
  CiLogout,
} from "react-icons/ci";

import fr from "../assets/lang/france.png";
import en from "../assets/lang/us.png";
import spain from "../assets/lang/esp.png";

import "./style.css";

// import logo from "../assets/ibendouma-logo.png";
import logo from "../assets/iben-logo.jpg";

// import { AiFillCloseSquare } from "react-icons/ai";
// import { IoLogoDiscord } from "react-icons/io5";
// import { MdMenu } from "react-icons/md";

const Header = ({ toggleArrowProfile, handleToggleArrow, click }) => {
  const location = useLocation();
  const { ipAddr } = useSelector((state) => state.ipAddr);
  // const [ipAddr, setIpAddr] = useState("France");
  let quantity = useSelector((state) => state.cart.quantity);
  let totalPrice = useSelector((state) => state.cart.totalPrice);
  let { user } = useSelector((state) => state.user);
  const [languageHeader, setLanguageHeader] = useState("anglais");
  const [selectCurrency, setSelectCurrency] = useState("");
  const { currency } = useSelector((state) => state.currency);
  const [isScrolled, setIsScrolled] = useState(0);
  const [isGaming, setIsGaming] = useState(false);

  // console.log(isScrolled);

  const [getWith, setGetWith] = useState(window.innerWidth);

  const handleResize = () => {
    setGetWith(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [getWith]);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    setIsScrolled(scrollPosition);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleSetGaming = () => {
    setIsGaming(true);
  };
  const handleRevomeGaming = () => {
    setIsGaming(false);
  };

  const [letters, setLetters] = useState([
    "ALL",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
  ]);

  const handleMoveToCart = () => {
    if (user?.person?._id) {
      window.location.href = "/cart";
    } else {
      window.location.href = "/login";
    }
  };

  const [toggleDisplayCart, setToggleDisplayCart] = useState(false);
  const [navToggle, setNavToggle] = useState(false);
  const [toggleCurrencyAndLanguage, setToggleCurrencyAndLanguage] =
    useState(false);
  const handleMouseEnter = () => {
    setToggleCurrencyAndLanguage(true);
  };

  const handleMouseLeave = () => {
    setToggleCurrencyAndLanguage(false);
  };
  // const [toggleUserProfile, setToggleUserProfile] = useState(false);
  const [regularCart, setRegularCart] = useState(false);

  const [profilToggle, setProfilToggle] = useState(false);

  const [activeItem, setActiveItem] = useState("ALL");
  const [dataFiltered, setDataFiltered] = useState(igvproducts);

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleChangeItem = (item) => {
    setActiveItem(item);
  };

  useEffect(() => {
    if (activeItem === "ALL") {
      setDataFiltered(customMenuData);
    } else {
      setDataFiltered(
        igvproducts.filter((item) => item.letterStart === activeItem)
      );
    }
  }, [activeItem]);

  const dispatch = useDispatch();

  const handleSelectLanguage = (e) => {
    dispatch(addNewLanguage(e.target.value));
    setLanguageHeader(e.target.value);
  };

  const notyFyErrorCartFrench = () =>
    toast.error("Votre panier est vide, Ajouter un produit pour continuer");
  const notyFyErrorCartEnglish = () =>
    toast.error("Your cart is empty, Add a product to continue");

  const handleSelectCurrency = (e) => {
    dispatch(addNewCurrencyExchange(e.target.value));
    setSelectCurrency(e.target.value);
  };

  const handleCartEnter = () => {
    setToggleDisplayCart(true);
  };

  const handleCartLeave = () => {
    setToggleDisplayCart(false);
  };
  const navEnter = () => {
    setNavToggle(true);
  };

  const navLeave = () => {
    setNavToggle(false);
  };

  useEffect(() => {
    let filteredResults = igvproducts?.filter((product) =>
      product?.letterStart
        ?.toLowerCase()
        ?.includes(searchTerm[0]?.toLowerCase())
    );

    setFilteredProducts(filteredResults[0]);
  }, [searchTerm]);

  const handleLogout = () => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_CLIENT_URL}/users/logout`,
      withCredentials: true,
    })
      .then(() => {
        dispatch(removeUser());
        window.location.href = "/";
      })
      .catch((error) => console.log(error));
  };

  const handleOpenCart = () => {
    setRegularCart((prevRegularCart) => !prevRegularCart);
  };

  let { language } = useSelector((state) => state.language);

  const handleGoTheCart = () => {
    setRegularCart(false);
  };

  const handleToggleprofil = () => {
    setProfilToggle(true);
  };
  const handleToggleLeaveProfil = () => {
    setProfilToggle(false);
  };

  // console.log(currency);

  return (
    <div className="fixed top-0 z-[999] h-[60px] hidden xcustom:flex flex-col gap-[px] items-center justify-between px-3 bsm:px-4 py-1 w-[100vw] max-w-[w-screen] bg-[#18191a]">
      <div className="flex items-center justify-between w-full">
        {/* First navbar items */}

        <Link to="/" className="flex items-center gap-1">
          <img
            src={logo}
            alt="ibendouma logo"
            className="w-10 h-10 bsm:w-12 bsm:h-12 mr-4 bsm:mr-0 object-cover rounded-full"
          />
          <div className="hidden searchcustom:flex flex-col items-start justify-between">
            <p className="text-lg font-extrabold text-[#C9C9C9]">iBENDOUMA</p>
            <p className="text-xs font-bold text-[#959595]">GAME FOR GAMER</p>
          </div>
        </Link>

        {/* Third navbar items */}

        <div className="relative hidden search:flex items-center mx-6 customfl:mx-12">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearch}
            placeholder={
              language === "anglais"
                ? "Search what you want"
                : "Rechercher ce que vous voulez"
            }
            className="w-[250px] rmlg:w-[300px] cusmd:w-[400px] cusxl:w-[500px] cuslg:w-[600px] blg:w-[700px] px-4 py-1 pl-10 placeholder:text-gray-400 text-gray-500 pb-1 rounded-full outline-none"
          />
          <span className="absolute cursor-pointer left-[2%] top-[20%] text-gray-400">
            <FiSearch className="text-[20px]" />
          </span>
          {filteredProducts && (
            <div className="absolute z-50 w-full rounded flex flex-col items-start max-h-[300px] overflow-x-scroll searchScroll p-4 gap-2 border border-gray-300 bg-white shadow-lg top-[90%]">
              {filteredProducts?.details?.map((detail) => (
                <div
                  key={detail?.id}
                  className="flex flex-col items-start text-lg gap-2 border-b pb-4 border-gray-300"
                >
                  {detail?.title}
                  <div className="flex items-center gap-2">
                    {detail?.items?.map((item) => (
                      <Link
                        key={item?.id}
                        to={item?.link?.split("fr")[1]}
                        className="text-base capitalize text-[#3174a1] cursor-pointer transition duration-500 ease-in-out hover:text-red-600"
                      >
                        {item?.title}
                      </Link>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Third navbar items */}

        <div className="flex items-center gap-6">
          <div className="flex flex-col items-center gap-1 cursor-pointer">
            <span
              onMouseEnter={handleCartEnter}
              onMouseLeave={handleCartLeave}
              className="text-white relative"
              onClick={handleMoveToCart}
            >
              <CiShoppingCart className="h-6 w-6" />
              {user?.person?._id && quantity > 0 && (
                <span className="absolute flex items-center justify-center h-4 w-4 rounded-full bg-red-600 text-white text-[10px] -top-[10%] left-[55%]">
                  {quantity}
                </span>
              )}
              {toggleDisplayCart && user?.person?._id && (
                <div
                  className="absolute flex flex-col gap-4 rounded bg-white shadow-2xl p-4 w-[300px] top-[200%] -left-[1100%] z-[1000]"
                  onMouseEnter={handleCartEnter}
                  onMouseLeave={handleCartLeave}
                >
                  <HiChevronUp className="absolute -top-[13%] left-[88%] text-white z-[100] text-[32px]" />
                  <div className="flex items-center justify-between mx-4">
                    <div className="flex items-center">
                      <span className="font-normal text-sm text-black">
                        Total
                      </span>
                      <span className="font-normal text-sm text-black">
                        ({quantity}x)
                      </span>
                    </div>
                    <div className="flex items-center gap-2">
                      <span className="font-bold text-sm text-red-600">
                        {totalPrice}
                      </span>
                      <span className="font-bold text-sm text-red-600">
                        {currency === "euro"
                          ? "EUR"
                          : currency === "dollar"
                          ? "USD"
                          : currency === "mad"
                          ? "MAD"
                          : currency === "cad"
                          ? "CAD"
                          : currency === "chf"
                          ? "CHF"
                          : currency === "gbp"
                          ? "GBP"
                          : currency === "rub"
                          ? "RUB"
                          : "EUR"}
                      </span>
                    </div>
                  </div>

                  <div className="flex mx-4">
                    <button className="text-sm text-white rounded bg-red-600 p-2 w-full mx-4 outline-none">
                      <Link to="/cart">
                        {language === "anglais" && "View cart"}
                        {language === "francais" && "Voir le panier"}
                        {language === "espagnol" && "Ver carrito"}
                      </Link>
                    </button>
                  </div>
                </div>
              )}
            </span>
            <p
              className="text-white text-xs"
              onMouseEnter={handleCartEnter}
              onMouseLeave={handleCartLeave}
            >
              {language === "anglais" && "Cart"}
              {language === "francais" && "Panier"}
              {language === "espagnol" && "Carro"}
            </p>
          </div>

          <div className="relative flex flex-col items-center gap-1">
            {language === "anglais" && (
              <img
                src={en}
                alt="anglais"
                className="w-5 h-5 bg-cover bg-center"
              />
            )}
            {language === "francais" && (
              <img
                src={fr}
                alt="francais"
                className="w-5 h-5 bg-cover bg-center"
              />
            )}
            {language === "espagnol" && (
              <img
                src={spain}
                alt="francais"
                className="w-5 h-5 bg-cover bg-center"
              />
            )}
            <p
              className="flex items-center text-white text-xs cursor-pointer"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              {language === "anglais"
                ? "EN"
                : language === "francais"
                ? "FR"
                : language === "espagnol"
                ? "ES"
                : "EN"}{" "}
              /{" "}
              {currency === "euro"
                ? "EUR"
                : currency === "dollar"
                ? "USD"
                : currency === "mad"
                ? "MAD"
                : currency === "cad"
                ? "CAD"
                : currency === "chf"
                ? "CHF"
                : currency === "gbp"
                ? "GBP"
                : currency === "rub"
                ? "RUB"
                : "MAD"}
              <span className="text-[16px] cursor-pointer ml-[2px]">
                <HiOutlineChevronDown
                  className={`transform ${
                    toggleCurrencyAndLanguage
                      ? "-rotate-180 transition duration-500 ease-in-out"
                      : ""
                  }`}
                />
              </span>
            </p>
            {toggleCurrencyAndLanguage && (
              <div
                className="absolute top-[104%] bg-[#242526] p-4 cursor-pointer"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <div className="w-full">
                  <span className="text-sm text-white">
                    {language === "anglais" && "Languages"}
                    {language === "francais" && "Languages"}
                    {language === "espagnol" && "Idiomas"}:
                  </span>
                  <br />
                  <select
                    name="language"
                    id="language"
                    value={languageHeader}
                    onChange={handleSelectLanguage}
                    className="w-[200px] text-sm p-1 bg-white text-black outline-none mt-2"
                  >
                    <option value="anglais">
                      {language === "francais" && "Anglais"}
                      {language === "anglais" && "English"}
                      {language === "espagnol" && "Inglés"}
                    </option>
                    <option value="francais">
                      {language === "francais" && "Francais"}
                      {language === "anglais" && "French"}
                      {language === "espagnol" && "Francés"}
                    </option>

                    <option value="espagnol">
                      {language === "francais" && "Espagnol"}
                      {language === "anglais" && "Spanish"}
                      {language === "espagnol" && "Español"}
                    </option>
                  </select>
                </div>
                <div className="w-full mt-4">
                  <span className="text-sm text-white">
                    {language === "anglais" && "currency"}
                    {language === "francais" && "Monnaie"}
                    {language === "espagnol" && "Dinero"}:
                  </span>
                  <br />
                  <select
                    name="currency"
                    id="currency"
                    value={selectCurrency}
                    onChange={handleSelectCurrency}
                    className="w-[200px] text-sm p-1 bg-white text-black outline-none mt-2"
                  >
                    <option value="euro">EUR</option>
                    <option value="dollar">USD</option>
                    <option value="cad">CAD</option>
                    <option value="gbp">GBP</option>
                    <option value="mad">MAD</option>
                    <option value="chf">CHF</option>
                    <option value="rub">RUB</option>
                  </select>
                </div>
              </div>
            )}
          </div>

          <Link
            to="/how-to-buy"
            className="hidden cushelp:flex flex-col items-center gap-1 mt-1 cursor-pointer"
          >
            <span className="text-white">
              <TfiHelpAlt className="h-5 w-5" />
            </span>
            <p className="text-xs text-white">
              {language === "anglais" && "Help"}
              {language === "francais" && "Aide"}
              {language === "espagnol" && "Ayuda"}
            </p>
          </Link>
          <div
            className="relative flex flex-col items-center cursor-pointer"
            onClick={handleToggleArrow("non")}
          >
            {user?.person?._id ? (
              <div className="flex items-center">
                <img
                  className="w-[30px] h-[30px] -mt-[8px] rounded-full bg-cover bg-center"
                  src={
                    user?.person?.profil ? user?.person?.profil : defaultUser
                  }
                  alt="user"
                />
              </div>
            ) : (
              <Link to="/login" className="text-white mt-1">
                <CiUser className="h-6 w-6" />
              </Link>
            )}

            {user?.person?._id ? (
              toggleArrowProfile && (
                <div className="absolute top-[150%] -left-[570%] flex flex-col gap-2 p-2 items-start rounded bg-white w-[210px] shadow-lg z-[1000]">
                  <div className="flex items-center space-x-2 text-sm p-1 sm:p-2 transition duration-500 ease-in-out hover:bg-red-600 hover:text-white rounded w-full item1">
                    <span className="text-[21px]">
                      <CiUser />
                    </span>
                    <Link to="/personal-infos">
                      {language === "anglais" && "My profile"}
                      {language === "francais" && "Mon profil"}
                      {language === "espagnol" && "Mi perfil"}
                    </Link>
                  </div>
                  <div className="flex items-center space-x-2 text-sm p-2 transition duration-500 ease-in-out hover:bg-red-600 hover:text-white rounded w-full item2">
                    <span className="text-[21px]">
                      <CiShoppingCart />
                    </span>
                    <Link to="/orders">
                      {language === "anglais" && "My orders"}
                      {language === "francais" && "Mes commandes"}
                      {language === "espagnol" && "Mis pedidos"}
                    </Link>
                  </div>
                  <div className="flex items-center space-x-2 text-sm p-2 transition duration-500 ease-in-out hover:bg-red-600 hover:text-white rounded w-full item3">
                    <span className="text-[21px]">
                      <CiUndo />
                    </span>
                    <Link to="/update-profil">
                      {language === "anglais" && "Update my profile"}
                      {language === "francais" && "Mettre à jour mon profil"}
                      {language === "espagnol" && "Actualizar mi perfil"}
                    </Link>
                  </div>
                  <div className="flex items-center space-x-2 text-sm p-2 transition duration-500 ease-in-out hover:bg-red-600 hover:text-white rounded w-full item4">
                    <span className="text-[21px]">
                      <CiUnlock />
                    </span>
                    <Link to="/password">
                      {language === "anglais" && "Change password"}
                      {language === "francais" && "Modifier mot de passe"}
                      {language === "espagnol" && "Cambiar contraseña"}
                    </Link>
                  </div>
                  <div
                    className="flex items-center space-x-2 text-sm p-2 transition duration-500 ease-in-out hover:bg-red-600 hover:text-white rounded w-full item4"
                    onClick={handleLogout}
                  >
                    <span className="text-[21px]">
                      <CiLogout />
                    </span>
                    <span>
                      {language === "francais" && "Se déconnecter"}
                      {language === "anglais" && "Log out"}
                      {language === "espagnol" && "desconectar"}
                    </span>
                  </div>
                </div>
              )
            ) : (
              <div className="text-white">
                <Link
                  to="/login"
                  className="text-white text-xs transition duration-500 ease-in-out hover:text-red-600"
                >
                  {language === "anglais" && "Sign in"}
                  {language === "francais" && "Se connecter"}
                  {language === "espagnol" && "Conectarse"}{" "}
                </Link>
              </div>
            )}
          </div>
          <span
            className="flex search:hidden text-[32px] -mt-2 mr-2 md:mr-0 cursor-pointer"
            onClick={click}
          >
            <FiMenu className="text-white font-extrabold" />
          </span>
        </div>
      </div>

      {/* Second navbar which is at the bottom of the search bar */}

      <div
        className="flex items-center gap-6 bg-[#333333] rounded"
        style={{
          marginTop:
            isScrolled < 500 && getWith > 450 && location.pathname === "/"
              ? "10px"
              : "2px",
        }}
      >
        <div
          onMouseEnter={navEnter}
          onMouseLeave={navLeave}
          className="relative hidden cussheaw:flex items-center justify-center gap-[2px] bg-[#333333] text-white text-sm py-1 px-2 rounded cursor-pointer"
          style={{
            whiteSpace: "nowrap",
          }}
        >
          <CgMenuGridO className="text-[17px] -mt-[2px]" />
          <span>All Games</span>
          {navToggle && (
            <div
              className="absolute grid-cols-1 sm:grid-cols-2 md:grid-cols-3 blg:grid-cols-4 cuslg:grid-cols-5 gap-8 bg-[#333333] p-4 top-[95%] -left-[380%]  shadow-md w-[1700px] mx-0 z-[999] overflow-hidden"
              onMouseEnter={navEnter}
              onMouseLeave={navLeave}
            >
              <div className="flex items-center justify-center gap-4 mt-2 mb-4">
                {letters?.map((item, index) => (
                  <p
                    key={index}
                    className={`font-semibold text-xs cuslg:text-sm shadow-2xl transition duration-700 ease-in-out  rounded-[2px] px-3 blgcus:px-[13px] headereset:px-4 py-2 cursor-pointer select-none ${
                      item === activeItem ? "bg-red-600 text-white" : ""
                    }`}
                    onMouseEnter={() => handleChangeItem(item)}
                  >
                    {item}
                  </p>
                ))}
              </div>
              <div className="flex items-center w-full gap-6 h-[250px] ml-64 cuslg:ml-64 blgcus:ml-56 headereset:ml-36">
                {dataFiltered?.map((data) => (
                  <div
                    className="flex flex-col items-start flex-wrap space-x-8 gap-4 h-full"
                    key={data?.id}
                  >
                    {data?.details?.map((detail, index) =>
                      detail?.items?.map((item, i) => (
                        <Link
                          key={item?.id}
                          className="flex items-start gap-2 text-[13px] cuslg:text-[16px] transition duration-300 ease-in-out hover:text-red-600"
                          style={{ display: i === 0 && index === 0 && "none" }}
                          to={item?.link?.split("fr")[1]}
                        >
                          {`${detail?.title} ${item?.title}`}
                        </Link>
                      ))
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div
          className="relative flex items-center z-[999] gap-[2px] hover:text-white hover:bg-red-600 duration-300= bg-[#333333] text-white text-sm py-1 px-2 rounded cursor-pointer"
          style={{
            whiteSpace: "nowrap",
          }}
          onMouseEnter={handleSetGaming}
          onMouseLeave={handleRevomeGaming}
        >
          <span className="flex items-center gap-2">
            {" "}
            Gaming
            <FaChevronDown className="text-[13px]" />
          </span>
          {isGaming && (
            <div
              onMouseEnter={handleSetGaming}
              onMouseLeave={handleRevomeGaming}
              className="absolute w-[350px] top-[90%] left-[0%] flex items-start justify-around gap-8 p-4 bg-[#333333] text-white rounded"
            >
              <div className="flex flex-col items-start w-1/2">
                <span className="w-full text-[#9ca3af] text-base border-b pb-[1px] border-[#9ca3af]">
                  TOP SELLERS
                </span>

                <div className="flex flex-col flex-start gap-4 mt-4 ml-1">
                  <Link
                    to="/playstation/accounts"
                    className="text-base text-white duration-300 hover:text-red-600"
                  >
                    PlayStation
                  </Link>
                  <Link
                    to="/xbox-membership/items"
                    className="text-base text-white duration-300 hover:text-red-600"
                  >
                    Xbox
                  </Link>
                </div>
              </div>
              <div className="flex flex-col items-start w-1/2">
                <span className="w-full text-[#9ca3af] border-b pb-[1px] border-[#9ca3af] text-base">
                  OTHERS
                </span>
                <div className="flex flex-col flex-start gap-4 mt-4 ml-1">
                  <Link
                    to="/pubg/accounts"
                    className="text-base text-white duration-300 hover:text-red-600"
                  >
                    PUBG Mobile
                  </Link>
                  <Link
                    to="/garrys-mod/accounts"
                    className="text-base text-white duration-300 hover:text-red-600"
                  >
                    Garrys Mod
                  </Link>
                  <Link
                    to="/fortnite/accounts"
                    className="text-base text-white duration-300 hover:text-red-600"
                  >
                    Fortnite
                  </Link>
                  <Link
                    to="/mincraft/accounts"
                    className="text-base text-white duration-300 hover:text-red-600"
                  >
                    Minecraft
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>

        <Link
          to="/dofus/dofus-kamas"
          className="flex items-center hover:text-white hover:bg-red-600 duration-300 z-[999] gap-[2px] bg-[#333333] text-white text-sm py-1 px-2 rounded cursor-pointer"
          style={{
            whiteSpace: "nowrap",
          }}
        >
          Dofus Kamas
        </Link>
        <Link
          to="/dofus/dofus-touch"
          className="flex items-center hover:text-white hover:bg-red-600 duration-300 z-[999] gap-[2px] bg-[#333333] text-white text-sm py-1 px-2 rounded cursor-pointer"
          style={{
            whiteSpace: "nowrap",
          }}
        >
          Dofus Touch
        </Link>
        <Link
          to="/ibytrade/vendre-des-kamas"
          className="flex items-center z-[999] gap-[2px] hover:text-white hover:bg-red-600 text-white text-sm py-1 px-2 rounded cursor-pointer"
          style={{
            whiteSpace: "nowrap",
          }}
        >
          Vendre Kamas
        </Link>
        <Link
          to="/dofus/dofus-retro"
          className="flex items-center hover:text-white hover:bg-red-600 duration-300 z-[999] gap-[2px] bg-[#333333] text-white text-sm py-1 px-2 rounded cursor-pointer"
          style={{
            whiteSpace: "nowrap",
          }}
        >
          Dofus Retro
        </Link>
        <Link
          to="/clash-royale/accounts"
          className="flex items-center hover:text-white hover:bg-red-600 duration-300 z-[999] gap-[2px] bg-[#333333] text-white text-sm py-1 px-2 rounded cursor-pointer"
          style={{
            whiteSpace: "nowrap",
          }}
        >
          Clash Royale
        </Link>
        <Link
          to="/forza-horizon-5/accounts"
          className="flex items-center hover:text-white hover:bg-red-600 duration-300 z-[999] gap-[2px] bg-[#333333] text-white text-sm py-1 px-2 rounded cursor-pointer"
          style={{
            whiteSpace: "nowrap",
          }}
        >
          Forza Horizon 5
        </Link>
      </div>
    </div>
  );
};

export default Header;
