import React, { useState, useEffect } from "react";

import { AiFillDelete } from "react-icons/ai";
import { FaRegEye } from "react-icons/fa";

import Chart from "chart.js/auto";

import { generateCouponCode } from "../../screens/utiles/data";

const ShowOrders = () => {
  const [orderSolde, setOrderSolde] = useState(0);
  const [orderCane, setOrderCane] = useState(0);
  const [orderPende, setOrderPende] = useState(0);
  const [orderChecke, setOrderChecke] = useState(0);

  const [heur, setHeur] = useState(0);
  const [minute, setMinute] = useState(0);
  const [second, setSecond] = useState(0);
  const [couponCode, setCouponCode] = useState("");

  const [couponDetails, setCouponDetails] = useState(null);

  const [activeRemise, setActiveRemise] = useState(null);
  const [activeRemiseState, setActiveRemiseState] = useState("");

  const [mesCoupons, setMesCoupons] = useState([
    {
      id: "145",
      coupon: "UFnr3n8RkVAD2hrhmleC",
      discount: 10,
    },
    {
      id: "135",
      coupon: "UFnr3n8RkVAD2hrhmleC",
      discount: 30,
    },
    {
      id: "125",
      coupon: "UFnr3n8RkVAD2hrhmleC",
      discount: 20,
    },
    {
      id: "115",
      coupon: "UFnr3n8RkVAD2hrhmleC",
      discount: 10,
    },
    {
      id: "297",
      coupon: "UFnr3n8RkVAD2hrhmleC",
      discount: 50,
    },
    {
      id: "914",
      coupon: "UFnr3n8RkVAD2hrhmleC",
      discount: 15,
    },
  ]);

  const [limitCoupon, setLimitCoupon] = useState(4);

  // console.log(activeRemise);

  const [discount, setDiscount] = useState([
    {
      id: "1452",
      val: 10,
    },
    {
      id: "1453",
      val: 20,
    },
    {
      id: "1552",
      val: 30,
    },
    {
      id: "1752",
      val: 40,
    },
    {
      id: "1852",
      val: 50,
    },
    {
      id: "1457",
      val: 60,
    },
    {
      id: "1481",
      val: 70,
    },
    {
      id: "2415",
      val: 80,
    },
    {
      id: "6252",
      val: 90,
    },
    {
      id: "3125",
      val: 100,
    },
  ]);

  const [isActiveDiscount, setIsActiveDiscount] = useState(false);

  const handleMouseEnter = (coupon) => {
    // Récupérer les détails du coupon et les stocker dans l'état local
    setCouponDetails(coupon);
  };

  const handleMouseLeave = () => {
    // Effacer les détails du coupon lorsque le curseur quitte l'élément
    setCouponDetails(null);
  };

  useEffect(() => {
    const interSeconds = setInterval(() => {
      setSecond((prevSecond) => prevSecond + 1);
    }, 1000);

    if (second >= 60) {
      clearInterval(interSeconds);
      setSecond(0);
      setMinute((prevMinute) => prevMinute + 1);
    }

    return () => {
      clearInterval(interSeconds);
    };
  }, [second]);

  useEffect(() => {
    if (minute >= 60) {
      setHeur((prevHeur) => {
        if (prevHeur >= 24) {
          return 0;
        } else {
          return prevHeur + 1;
        }
      });
    }
  }, [minute]);

  useEffect(() => {
    const intervalSold = setInterval(() => {
      setOrderSolde((prevNum) => prevNum + 1);
    }, 10);
    if (orderSolde >= 250) {
      clearInterval(intervalSold);
    }

    return () => {
      clearInterval(intervalSold);
    };
  }, [orderSolde]);

  useEffect(() => {
    const intervalCan = setInterval(() => {
      setOrderCane((prevNum) => prevNum + 1);
    }, 10);

    if (orderCane >= 170) {
      clearInterval(intervalCan);
    }

    return () => {
      clearInterval(intervalCan);
    };
  }, [orderCane]);

  useEffect(() => {
    const intervalPend = setInterval(() => {
      setOrderPende((prevNum) => prevNum + 1);
    }, 10);
    if (orderPende >= 250) {
      clearInterval(intervalPend);
    }

    return () => {
      clearInterval(intervalPend);
    };
  }, [orderPende]);

  useEffect(() => {
    const intervalCheck = setInterval(() => {
      setOrderChecke((prevNum) => prevNum + 1);
    }, 10);
    if (orderChecke >= 125) {
      clearInterval(intervalCheck);
    }

    return () => {
      clearInterval(intervalCheck);
    };
  }, [orderChecke]);

  const orderSold = 75;
  const orderCan = 20;
  const orderPend = 30;
  const orderCheck = 50;

  const handleDisplayCouponCode = () => {
    if (!activeRemise) {
      alert("Choisissez d'abord une remise avant de générer un coupon code.");
    } else {
      let coupon = generateCouponCode();
      setCouponCode(coupon);
    }
  };

  const handleRemoveCouponCode = () => {
    setCouponCode("");
  };

  const handleDiscount = (item) => {
    setActiveRemise(item?.val / 100);
    setActiveRemiseState(item?.id);
  };

  const handleIncreaseLimitCoupon = () => {
    if (mesCoupons?.length >= 4) {
      setLimitCoupon((prevLimtCoupon) => prevLimtCoupon + 2);
    } else {
      alert("Il y'a seulement 4 coupons code");
    }
  };

  useEffect(() => {
    // Diagramme circulaire pour les commandes vendues
    const soldChart = new Chart(document.getElementById("soldChart"), {
      type: "doughnut",
      data: {
        labels: ["Sold", "Remaining"],
        datasets: [
          {
            data: [orderSold, 100 - orderSold],
            backgroundColor: ["#4CAF50", "#e0e0e0"],
          },
        ],
      },
    });

    // Diagramme circulaire pour les commandes annulées
    const cancelledChart = new Chart(
      document.getElementById("cancelledChart"),
      {
        type: "doughnut",
        data: {
          labels: ["Cancelled", "Remaining"],
          datasets: [
            {
              data: [orderCan, 100 - orderCan],
              backgroundColor: ["#FF6384", "#e0e0e0"],
            },
          ],
        },
      }
    );

    // Diagramme circulaire pour les commandes en attente
    const pendingChart = new Chart(document.getElementById("pendingChart"), {
      type: "doughnut",
      data: {
        labels: ["Pending", "Remaining"],
        datasets: [
          {
            data: [orderPend, 100 - orderPend],
            backgroundColor: ["#FFCE56", "#e0e0e0"],
          },
        ],
      },
    });

    // Diagramme circulaire pour les commandes vérifiées
    const checkedChart = new Chart(document.getElementById("checkedChart"), {
      type: "doughnut",
      data: {
        labels: ["Checked", "Remaining"],
        datasets: [
          {
            data: [orderCheck, 100 - orderCheck],
            backgroundColor: ["#36A2EB", "#e0e0e0"],
          },
        ],
      },
    });

    // Nettoyer les graphiques lors du démontage du composant
    return () => {
      soldChart.destroy();
      cancelledChart.destroy();
      pendingChart.destroy();
      checkedChart.destroy();
    };
  }, [orderSold, orderCan, orderPend, orderCheck]);

  return (
    <div className="flex flex-col items-start gap-8">
      {/* <div className="flex items-start gap-4">
        <div className="flex flex-col gap-2 items-center justify-center rounded bg-green-700 text-white shadow-2xl p-4 cursor-pointer w-[200px]">
          <h2 className="text-xl">Orders sold</h2>
          <p className="text-[28px] font-bold">{orderSold}</p>
        </div>
        <div className="flex flex-col gap-2 items-center justify-center rounded bg-red-600 text-white shadow-2xl p-4 cursor-pointer w-[200px]">
          <h2 className="text-xl">Canceled orders</h2>
          <p className="text-[28px] font-bold">{orderCan}</p>
        </div>
        <div className="flex flex-col gap-2 items-center justify-center rounded bg-yellow-600 text-white shadow-2xl p-4 cursor-pointer w-[200px]">
          <h2 className="text-xl">Pending orders</h2>
          <p className="text-[28px] font-bold">{orderPend}</p>
        </div>
        <div className="flex flex-col gap-2 items-center justify-center rounded bg-blue-700 text-white shadow-2xl p-4 cursor-pointer w-[200px]">
          <h2 className="text-xl">Checked orders</h2>
          <p className="text-[28px] font-bold">{orderCheck}</p>
        </div>
        <div></div>
        <div></div>
        <div></div>
      </div> */}
      <div className="flex items-start gap-4">
        <div className="flex flex-col gap-2 items-center justify-center rounded bg-green-700 text-white shadow-2xl p-4 cursor-pointer w-[200px]">
          <h2 className="text-xl">Orders sold</h2>
          <p className="text-[28px] font-bold">{orderSolde}</p>
          <canvas id="soldChart" width="100" height="100"></canvas>
        </div>
        <div className="flex flex-col gap-2 items-center justify-center rounded bg-red-600 text-white shadow-2xl p-4 cursor-pointer w-[200px]">
          <h2 className="text-xl">Canceled orders</h2>
          <p className="text-[28px] font-bold">{orderCane}</p>
          <canvas id="cancelledChart" width="100" height="100"></canvas>
        </div>
        <div className="flex flex-col gap-2 items-center justify-center rounded bg-yellow-600 text-white shadow-2xl p-4 cursor-pointer w-[200px]">
          <h2 className="text-xl">Pending orders</h2>
          <p className="text-[28px] font-bold">{orderPende}</p>
          <canvas id="pendingChart" width="100" height="100"></canvas>
        </div>
        <div className="flex flex-col gap-2 items-center justify-center rounded bg-blue-700 text-white shadow-2xl p-4 cursor-pointer w-[200px]">
          <h2 className="text-xl">Checked orders</h2>
          <p className="text-[28px] font-bold">{orderChecke}</p>
          <canvas id="checkedChart" width="100" height="100"></canvas>
        </div>
      </div>
      <div className="flex flex-col gap-2 items-center justify-center rounded bg-green-700 text-white shadow-2xl p-4 cursor-pointer w-[200px]">
        <h2 className="text-xl">Orders sold</h2>
        <p className="text-[20px] font-bold">{`${
          heur >= 10 ? "" : "0"
        }${heur}h:${minute >= 10 ? "" : "0"}${minute}m:${
          second >= 10 ? "" : "0"
        }${second}s`}</p>
      </div>
      <div className="flex flex-col items-start gap-4">
        <h2 className="flex text-xl text-gray-800 font-semibold">
          Création de coupon de code
        </h2>
        <div className="flex flex-col items-start gap-2">
          <span
            className="flex items-center max-w-[80px] justify-center p-1 rounded text-lg shadow-2xl bg-green-700 text-white cursor-pointer ml-1"
            onClick={() => setIsActiveDiscount((prevDiscount) => !prevDiscount)}
          >
            Remise
          </span>
          <div className="flex items-center space-x-1 bg-green-200 p-1 rounded">
            {discount?.map((dis) => (
              <span
                key={dis?.id}
                className={`flex text-sm items-center justify-center ${
                  activeRemiseState === dis?.id
                    ? "bg-green-800"
                    : "bg-green-600"
                } text-white cursor-pointer p-2 rounded`}
                onClick={() => handleDiscount(dis)}
              >{`${dis?.val}%`}</span>
            ))}
          </div>
        </div>
        <div className="flex items-center gap-2">
          <button
            className="flex items-center justify-center p-2 text-base bg-green-700 text-white rounded tansition duration-500 ease-in-out hover:bg-green-600 ml-1"
            onClick={handleDisplayCouponCode}
          >
            Générer un coupon
          </button>
          <div className="flex items-center space-x-1">
            <span
              className={`text-base ${couponCode ? "bg-green-200" : ""} p-2`}
            >
              {couponCode}
            </span>
            {couponCode && (
              <AiFillDelete
                className="flex text-red-600 text-[24px] cursor-pointer"
                onClick={handleRemoveCouponCode}
              />
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col items-start gap-2">
        <h2 className="flex text-xl text-gray-800 font-semibold">
          Mes coupons code
        </h2>
        <div className="flex items-center p-[6px] bg-green-700 text-white rounded gap-2 cursor-pointer transition duration-500 ease-in-out hover:bg-green-600">
          <FaRegEye className="text-[22px]" />
          <span className="text-lg">Voir</span>
        </div>
        <div className="flex items-center flex-wrap relative gap-2">
          {mesCoupons?.length <= 0 ? (
            <span className="flex text-xl text-gray-800 font-semibold">
              Vous n'avez pas de coupons code
            </span>
          ) : (
            mesCoupons?.slice(0, limitCoupon)?.map((coupon) => (
              <span
                key={coupon?.id}
                className="text-base bg-green-300 p-1 rounded cursor-pointer transition duration-500 ease-in-out hover:bg-green-400"
                onMouseEnter={() => handleMouseEnter(coupon)}
                onMouseLeave={handleMouseLeave}
              >
                {coupon?.coupon}
              </span>
            ))
          )}
        </div>
        <button
          className="text-lg p-2 rounded bg-red-600 text-white cursor-pointer"
          onClick={handleIncreaseLimitCoupon}
        >
          Voir plus
        </button>
      </div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default ShowOrders;
